import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import { addStaffWorkLoad } from "../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import {
    getRecords,
    postRecords,
    catchAsync,
    isSuccess,
    toastSuccessMessages,
    toastErrorMessages,
} from "../../helper/helper";
import moment from "moment/moment";
import {
    BASE_URL,
    GET_ALL_STAFF_MEMBERS_URL,
    POST_STAFF_WORKLOADS_URL,
} from "../../env";

function AddStaffHoliday({ userId, subModalAddWorkLoad }) {
    const [userData, setUserData] = useState([]);

    const handleShowHideModal = () => {
        subModalAddWorkLoad((prevShow) => !prevShow);
    };
    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
    const [isToastAddHoliday, setIsToastAddHoliday] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState(null);
    const handleUserSelect = (userId) => {
        setSelectedUserId(userId);
        setFieldValue("staffMember", userId);
    };

    useEffect(() => {
        fetchAllRecords();
    }, [selectedUserId])

    const [apiQuery, setApiQuery] = useState({
        sortproperty: "createdAt",
        sortorder: -1,
        offset: 0,
        query: {
            critarion: { active: true },
        },
    });

    const processFetchRecords = (method, data) => {
        if (data?.status !== isSuccess) {
            toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
        } else if (method === "get" && data?.status === isSuccess) {
            const userData = data?.data?.staffMembers?.filter(
                (item) => item?._id === selectedUserId
            );
            console.log("🚀 ~ processFetchRecords ~ userData:", userData)
            setUserData(userData[0]);
        } else if (method === "update" && data?.status === isSuccess) {
            fetchAllRecords();
            toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
        }
    };

    const fetchAllRecords = catchAsync(async () => {
        try {
            const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
            processFetchRecords("get", response);
        } catch (error) {
            console.error("Error fetching staff workloads:", error);
        }
    });



    const [suFormData, setSuFormData] = useState({
        staffMember: selectedUserId,
        startDate: "",
        endDate: "",
        staffWorkLoadid: "",
        holidayAlwnNoOfDys: 0,
        holidayAlwnNoOfHours: 0,
        holidayHoursRemaining: 0,
        holidaysRemaining: 0,
        carriedOverHours: 0,
        isCurrentWrkLd: true,
    });

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
    } = useFormik({
        initialValues: suFormData,
        validationSchema: addStaffWorkLoad,
        onSubmit: async (values) => {
            try {
                setIsSubmitButtonClicked(true);
                await postAddHolidayFormData(values);
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        },
    });

    const processPostRecords = (method, data) => {
        if (data?.status !== isSuccess) {
            setIsToastAddHoliday(true);
            toastErrorMessages(toast.error, data?.message, "", 5000, 5000);
            setIsSubmitButtonClicked(false);
            setTimeout(() => {
                handleShowHideModal();
                setIsToastAddHoliday(false)
            }, 2000);
        } else if (
            method === "postAddHolidayFormData" &&
            data?.status === isSuccess
        ) {
            setIsToastAddHoliday(true);
            toastSuccessMessages(toast.success, data?.message, "", 5000, 5000);
            setIsSubmitButtonClicked(false);
            setTimeout(() => {
                handleShowHideModal();
                setIsToastAddHoliday(false)
            }, 2000);
        }
    };

    const postAddHolidayFormData = catchAsync(async (req, res) => {
        // console.log("🚀 ~ postAddHolidayFormData ~ values:", values)
        const updatedValues = {
            ...values,
            startDate: moment(values.startDate).valueOf(),
            endDate: moment(values.endDate).valueOf(),
            isCurrentWrkLd: true,

        };
        // console.log("🚀 ~ postAddHolidayFormData ~ values:", updatedValues)
        const response = await postRecords(POST_STAFF_WORKLOADS_URL, updatedValues);
        processPostRecords("postAddHolidayFormData", response);
        setIsSubmitButtonClicked(false);
    });



    // Function to calculate the difference in days
    const calculateDaysDifference = (startDate, endDate) => {
        const start = moment(startDate);
        const end = moment(endDate);
        const diff = end.diff(start, 'days');
        // setFieldValue("numberOfDays", diff);
        return diff + 1; // Difference in days
    };

    useEffect(() => {
        if (values.startDate && values.endDate) {
            const daysDiff = calculateDaysDifference(
                values.startDate,
                values.endDate
            );
            setFieldValue("numberOfDays", daysDiff);
        }
    }, [values.startDate, values.endDate, setFieldValue]);


    useEffect(() => {
        if (userData?.contractedHours?.contractedHours && values?.numberOfDays) {
            const holidayHours = userData?.contractedHours?.contractedHours * 5.6 / 365 * values?.numberOfDays + values?.carriedOverHours;
            setFieldValue("holidayAlwnNoOfHours", holidayHours);
            setFieldValue("holidayHoursRemaining", holidayHours);
            // setFieldValue("holidayHoursRemaining", holidayHours);
        }

    }, [userData?.contractedHours?.contractedHours, values?.numberOfDays, values?.carriedOverHours])




    return (
        <>
            <div className="incidents consentCapacityAddNew">
                <form onSubmit={handleSubmit}>
                    <div className="formBlockContent">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <CustomSelect
                                        id=""
                                        name="staffMember"
                                        onSelect={handleUserSelect}
                                        defaultValue={selectedUserId}
                                        filterBy=""
                                        label="Staff Requester"
                                        customError={touched?.staffMember && errors?.staffMember}
                                        errorMessage={errors?.staffMember}
                                        errorClass={
                                            touched?.staffMember && errors?.staffMember
                                                ? "validationError"
                                                : ""
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <Input
                                    type="date"
                                    datePicker={true}
                                    name="startDate"
                                    selected={values?.startDate ? moment(values?.startDate).toDate() : null}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => setFieldValue("startDate", date)}
                                    onBlur={() => setFieldTouched("startDate", true)} // Ensure Formik's touched state is set on blur
                                    labelText="Start Date"
                                    placeholderText="Select Start Date"
                                    customError={touched?.startDate && errors?.startDate}
                                    errorMessage={errors?.startDate}
                                    errorClass={touched?.startDate && errors?.startDate ? "validationError" : ""}
                                    requiredStar={true}
                                    showYearDropdown={true}
                                />
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <Input
                                    type="date"
                                    datePicker={true}
                                    name="endDate"
                                    selected={values?.endDate ? moment(values?.endDate).toDate() : null}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => setFieldValue("endDate", date)}
                                    onBlur={() => setFieldTouched("endDate", true)} // Ensure Formik's touched state is set on blur
                                    labelText="End Date"
                                    placeholderText="Select End Date"
                                    customError={touched?.endDate && errors?.endDate}
                                    errorMessage={errors?.endDate}
                                    errorClass={touched?.endDate && errors?.endDate ? "validationError" : ""}
                                    requiredStar={true}
                                    showYearDropdown={true}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <Input
                                    type="number"
                                    name="numberOfDays"
                                    value={
                                        // Calculated number of days
                                        values?.startDate && values?.endDate
                                            ? calculateDaysDifference(
                                                values?.startDate,
                                                values?.endDate
                                            )
                                            : ""
                                    }
                                    onChange={(e) => {
                                        setFieldValue("numberOfDays", e.target?.value);
                                    }}
                                    onBlur={handleBlur}
                                    labelText="Total Days"
                                    customError={
                                        touched?.numberOfDays && errors?.numberOfDays
                                    }
                                    errorMessage={errors?.numberOfDays}
                                    errorClass={
                                        touched?.numberOfDays && errors?.numberOfDays
                                            ? "validationError"
                                            : ""
                                    }
                                    disabledStatus={true}
                                    readOnly={true}
                                    requiredStar={false}
                                />
                            </div>

                            <div className="col-sm-12 col-md-6">
                                {/* {userData?.contractedHours?.contractedHours * 5.6 / 365 * values?.numberOfDays}<br />
                                {userData?.contractedHours?.contractedHours * 5.6 / 365}<br />
                                {userData?.contractedHours?.contractedHours}<br />
                                {values?.numberOfDays}<br /> */}
                                <Input
                                    type="number"
                                    name="holidayAlwnNoOfHours"
                                    value={(userData?.contractedHours?.contractedHours * 5.6 / 365 * values?.numberOfDays) + values?.carriedOverHours}
                                    onChange={(e) => {
                                        setFieldValue("holidayAlwnNoOfHours", e.target?.value);
                                    }}
                                    onBlur={handleBlur}
                                    labelText="Holiday Hours"
                                    customError={
                                        touched?.holidayAlwnNoOfHours && errors?.holidayAlwnNoOfHours
                                    }
                                    errorMessage={errors?.holidayAlwnNoOfHours}
                                    errorClass={
                                        touched?.holidayAlwnNoOfHours && errors?.holidayAlwnNoOfHours
                                            ? "validationError"
                                            : ""
                                    }
                                    disabledStatus={true}
                                    readOnly={true}
                                // requiredStar={false}
                                />
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="number"
                                        name="holidayHoursRemaining"
                                        value={values?.holidayHoursRemaining}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        labelText="Remaining Hours"
                                        customError={
                                            touched?.holidayHoursRemaining && errors?.holidayHoursRemaining
                                        }
                                        errorMessage={errors?.holidayHoursRemaining}
                                        errorClass={
                                            touched?.holidayHoursRemaining && errors?.holidayHoursRemaining
                                                ? "validationError"
                                                : ""
                                        }
                                        requiredStar={true}
                                        disabledStatus={true}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="formActionButtons">
                        <span
                            onClick={subModalAddWorkLoad}
                            className="btn btn-sm btn-outline-primary"
                        >
                            Close
                        </span>
                        {isSubmitButtonClicked ? (
                            <Button
                                onClick={subModalAddWorkLoad}
                                className="btn-sm"
                                variant="primary"
                                disabled
                            >
                                Loading...
                            </Button>
                        ) : (
                            <Button className="btn-sm" variant="primary" type="submit">
                                Save
                            </Button>
                        )}
                    </div>
                </form>
            </div>
            {isToastAddHoliday && <ToastContainer />}
        </>
    );
}

export default AddStaffHoliday;