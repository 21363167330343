import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import loadingGif from "../../assets/images/icons/loadingGif.gif";
import eyeView from "../../assets/images/icons/eyeview.svg";
import eyeViewHide from "../../assets/images/icons/eye_password_see_view_icon.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Input from "../forms-fields/Input";
import { ToastContainer, toast } from "react-toastify";
import ProgressComponent from "../general/ProgressComponent";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import CustomSelect from "../forms-fields/CustomSelectStaffMembers";
import CustomSelectCheckbox from "../../components/forms-fields/CustomCheckBox";
import CustomSelectSupervisionTemplates from "../../components/forms-fields/CustomSelectSupervisionTemplates";
import camera from "../../assets/images/icons/camera.svg";
import closeImage from "../../assets/images/icons/closeImage.svg";
import moment from 'moment';
import {
  isSuccess,
  toastErrorMessages,
  toastSuccessMessages,
  catchAsync,
  getRecords,
  phoneRegExp,
  formateDateString,
  formateDate,
  calculateNextYearEndDate,
  calculateHolidayHours,
  convertDateFormat,
} from "../../helper/helper";
import {
  CustomOption,
  handleReload,
  calculateTimeDifference,
  handleSetIdFormikFormData,
  handleTimeChangeToNumeric,
  useShiftEffect,
  days,
  weeksOptions,
  getFormDataProps,
  GenerateScheduleFormData,
  handleFormUpdate,
  handleCheckboxChange,
  useCheckedEffect,
  uploadFile,
  staffDesignationOptions,
  vehicleTypeOptions,
  userInputFields,
  workLoadFields,
  sprStatus,
  sprResult,
  handleSelectChange,
  updateDesignationFields,
  passwordRegex,
  updateStaffValidationSchema,
  staffRoleOptions,
} from "../../helper/helperJsx";
import {
  PUT_USERS_PROFILE_PICTURE_URL,
  POST_UPDATE_STAFF_MEMBER_DETAILS_URL,
  ADMIN_AUTH_TOKEN,
  BASE_URL,
} from "../../env";
import ProtectedComponent from "../../permissions/ProtectedComponent";
import { permissions } from "../../permissions/roles";

function UserProfileUpdate({
  showModal,
  closeModal,
  userData,
  fetchAllRecords,
}) {
  const initialUserData = userData;
  const [userDataRecord, setUserDataRecord] = useState(initialUserData);
  // console.log("🚀 ~ userDataRecord:", userDataRecord)
  const [isFileUploadedToast, setIsFileUploadedToast] = useState(false);
  const [isToastUpdateUserRecord, setIsToastUpdateUserRecord] = useState(false);
  const [isLoadingIcon, setIsLoadingIcon] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    BASE_URL + initialUserData?.user?.profile_picture_url
  );
  const [isDesignationManager, setIsDesignationManager] = useState(false);
  const [isDesignationSupervisor, setIsDesignationSupervisor] = useState(false);
  const [isHealthCheckedState, setIsHealthCheckedState] = useState(false);
  const [isUpdateFormSubmit, setIsUpdateFormSubmit] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isDriverCheckedState, setIsDriverCheckedState] = useState(false);


  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChangeStaffRole = (selected) => {
    if (!selected) {
      setSelectedOptions([]);
      setFieldValue('staffRoles', []); // Update Formik's value with an empty array
      return;
    }

    const isSelectAllSelected = selected.some(option => option.value === 'selectAll');

    if (isSelectAllSelected) {
      // Select All logic: select all days if "Select All" is selected
      if (selectedOptions.length === staffRoleOptions.length) {
        setSelectedOptions([]);
        setFieldValue('staffRoles', []); // Deselect all in Formik
      } else {
        setSelectedOptions(staffRoleOptions);
        setFieldValue('staffRoles', staffRoleOptions.map(option => option.value)); // Update Formik's value with all days
      }
    } else {
      // Normal selection logic: exclude "Select All" from Formik's value
      const selectedDays = selected.filter(option => option.value !== 'selectAll');
      setSelectedOptions(selectedDays);
      setFieldValue('staffRoles', selectedDays.map(option => option.value)); // Update Formik's value with selected days
    }
  };

  // console.log(userDataRecord);
  const updateUserFormData = useFormik({
    initialValues: {
      title: userDataRecord?.title,
      lastName: userDataRecord?.lastName,
      initials: userDataRecord?.initials,
      secondaryContactNo: userDataRecord?.secondaryContactNo,
      houseFlatNumber: userDataRecord?.houseFlatNumber,
      addressLine1: userDataRecord?.addressLine1,
      town: userDataRecord?.town,
      city: userDataRecord?.city,
      postcode: userDataRecord?.postcode,
      inductionDate: userDataRecord?.inductionDate,

      userId: userDataRecord?.user?._id || initialUserData?._id,
      name: userDataRecord?.user?.name,
      email: userDataRecord?.user?.email,
      password: userDataRecord?.user?.password,
      phoneNumber: userDataRecord?.user?.phoneNumber,
      deviceId: userDataRecord?.user?.deviceId,
      version: userDataRecord?.user?.version,
      role: userDataRecord?.user?.role,
      userModelName: "staffMembers",
      active: userDataRecord?.user?.active,
      is_verified: userDataRecord?.user?.is_verified,
      approved: userDataRecord?.user?.approved,
      signUpFromWeb: true,
      fcm_tokens: {
        token: "",
        deviceType: userDataRecord?.fcm_tokens?.deviceType,
      },
      location: {
        type: "Point",
        coordinates: [74.28911289869138, 31.624848273644956],
      },
      supervisorId: userDataRecord?.supervisorId?._id,
      managerId: userDataRecord?.managerId?._id,
      stafDob: userDataRecord?.stafDob,
      staffOnLeave: userDataRecord?.staffOnLeave,
      staffDesignation: userDataRecord?.staffDesignation,
      staffRoles: userDataRecord?.user?.staffRoles,
      contractedHours: {
        contractedHours: userDataRecord?.contractedHours?.contractedHours,
        totalShiftHoursWeek:
          userDataRecord?.contractedHours?.totalShiftHoursWeek,
        noOfShifts: userDataRecord?.contractedHours?.noOfShifts,
        perHourRate: userDataRecord?.contractedHours?.perHourRate || 0,
        overNightSleepRate: userDataRecord?.contractedHours?.overNightSleepRate || 0,
        enhancedRate: userDataRecord?.contractedHours?.enhancedRate || 0,
        trainingRate: userDataRecord?.contractedHours?.trainingRate || 0,
        overtimeRate: userDataRecord?.contractedHours?.overtimeRate || 0,
      },
      supervision: {
        supervisionName: userDataRecord?.supervision?.supervisionName,
        sprDueDate: moment(userDataRecord?.supervision?.sprDueDate).valueOf(),
        sprStatus: userDataRecord?.supervision?.sprStatus,
        sprResult: userDataRecord?.supervision?.sprResult,
        templateTitleId: userDataRecord?.supervision?.templateTitleId,
      },
      holidays: [],
      complianceDocuments: [],
      niNumber: userDataRecord?.niNumber,
      kin: userDataRecord?.kin,
      clients: [],
      covidCheck: userDataRecord?.covidCheck,
      driverFields: {
        isDriver: userDataRecord?.driverFields?.isDriver,
        vehicleType: userDataRecord?.driverFields?.vehicleType,
      },
    },
    validationSchema: updateStaffValidationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setIsUpdateFormSubmit(true);
        // console.log("🚀 ~ onSubmit: ~ values:", values)
        await updateUserRecordFunc(values);
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error . Please try again.",
          "",
          1000,
          1000
        );
        setIsUpdateFormSubmit(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = updateUserFormData;

  const updateUserRecordFunc = catchAsync(async (req, res) => {
    const response = await getRecords(
      POST_UPDATE_STAFF_MEMBER_DETAILS_URL,
      req
    );
    processFetchRecords("update", response);
    console.log("🚀 ~ updateUserRecordFunc ~ req:", req)
    console.log("🚀 ~ updateUserRecordFunc ~ response:", response)
  });

  const handleFileChangeUploadDoc = async (e) => {
    try {
      setIsLoadingIcon(true);
      const selectedUploadDocFile = e.target?.files[0];
      const userId = userData?._id || userData?._id;
      await uploadFile({
        isUpload: false,
        updateFieldName: "userId",
        updateFieldId: userId,
        fileName: "file",
        file: selectedUploadDocFile,
        apiUrl: PUT_USERS_PROFILE_PICTURE_URL,
        successCallback: (result) => {
          handleFileChange(e);
          setIsLoadingIcon(false);
          fetchAllRecords();
          setIsFileUploadedToast(true);
          toastSuccessMessages(toast?.success, result?.message, "", 1000, 1000);
        },
      });
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastUpdateUserRecord(true);
      setIsUpdateFormSubmit(false);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "update" && data?.status === isSuccess) {
      fetchAllRecords();
      setIsUpdateFormSubmit(false);
      setIsToastUpdateUserRecord(true);
      closeModal();
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfilePicture(event?.target?.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleManagerSelect = (userId) => {
    handleSelectChange(setFieldValue, "managerId", userId);
  };

  const handleSupervisorSelect = (userId) => {
    handleSelectChange(setFieldValue, "supervisorId", userId);
  };

  const handleSupervisionTemplateSelect = (templateId) => {
    handleSelectChange(
      setFieldValue,
      "supervision.templateTitleId",
      templateId
    );
  };

  useEffect(() => {
    if (values?.staffDesignation && values?.staffDesignation !== null) {
      if (values?.staffDesignation === "manager") {
        setIsDesignationManager(false);
        setIsDesignationSupervisor(true);
      } else if (values?.staffDesignation === "supervisor") {
        setIsDesignationSupervisor(false);
        setIsDesignationManager(true);
      } else {
        setIsDesignationManager(true);
        setIsDesignationSupervisor(true);
      }
    }
  }, [values?.staffDesignation, userDataRecord]);

  const handleIsDriverCheckBox = (e) => {
    handleCheckboxChange(e, setFieldValue, "driverFields.isDriver");
  };

  useEffect(() => {
    if (values?.covidCheck) {
      setIsHealthCheckedState(true);
    } else {
      setIsHealthCheckedState(false);
    }
  }, [values?.covidCheck]);

  useEffect(() => {
    if (values?.driverFields?.isDriver) {
      setFieldValue(
        "driverFields.vehicleType",
        userDataRecord?.driverFields?.vehicleType
      );
      setIsDriverCheckedState(true);
    } else {
      setFieldValue("driverFields.vehicleType", "");
      setIsDriverCheckedState(false);
    }
  }, [values?.driverFields?.isDriver]);


  // console.log("🚀 ~ AddNewStaff ~ values:", values)

  const [customOptions, setCustomOptions] = useState(staffDesignationOptions);

  useEffect(() => {
    // Check if the current staffDesignation is not in the options, add it dynamically
    const existingOption = customOptions.find(
      (option) => option.value === values?.staffDesignation
    );

    if (!existingOption && values?.staffDesignation) {
      // Add the new custom value if it's not in the existing options
      setCustomOptions((prevOptions) => [
        ...prevOptions,
        { value: values?.staffDesignation, label: values?.staffDesignation },
      ]);
    }
  }, [values?.staffDesignation]);

  const handleCreateOption = (newOptionLabel) => {
    const newOption = {
      value: newOptionLabel.toLowerCase().replace(/\s+/g, ""),
      label: newOptionLabel,
    };

    setCustomOptions([...customOptions, newOption]);
    setFieldValue("staffDesignation", newOption.value);
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        className="ftcModalPopup editProfileModalPopup suProfileEdit"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="editBlockContent mt-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="closeButton modalCloseButton">
                    <span onClick={closeModal}>
                      <img src={closeImage} alt={closeImage} />
                    </span>
                  </div>

                  <div className="text-center">
                    <label htmlFor="profilePicture" className="upload-icon">
                      {isLoadingIcon ? (
                        <div className="avatarAction">
                          <img src={loadingGif} alt={"Loading..."} />
                        </div>
                      ) : (
                        <div className="avatarAction">
                          <input
                            type="file"
                            id="profilePicture"
                            name="profilePic"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleFileChangeUploadDoc(e);
                            }}
                          />
                          <img
                            src={profilePicture}
                            alt={initialUserData?.user?.name}
                            style={{ cursor: "pointer" }}
                          />
                          <span className="uploadImage">
                            <img src={camera} alt="Camera" />
                          </span>
                        </div>
                      )}
                    </label>
                    <h3 className="userTitle">
                      {initialUserData?.user?.name} {" "}
                      {initialUserData?.lastName ? initialUserData?.lastName : ""}
                      <a
                        href={`mailto:${initialUserData?.user?.email}`}
                        className="greyColor"
                      >
                        <small>{initialUserData?.user?.email}</small>
                      </a>
                    </h3>
                  </div>
                  <div className="formBlockContent row">
                    {userInputFields?.map((field, index) => (
                      <div key={index} className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <Input
                            type={field?.type}
                            name={field?.name}
                            value={values[field?.name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText={field?.labelText}
                            customError={
                              touched[field?.name] && errors[field?.name]
                            }
                            errorMessage={errors[field?.name]}
                            errorClass={
                              touched[field?.name] && errors[field?.name]
                                ? "validationError"
                                : ""
                            }
                            requiredStar={field?.required}
                          />

                        </div>
                      </div>
                    ))}
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <Input
                          type="date"
                          datePicker={true}
                          name="stafDob"
                          selected={values?.stafDob ? moment(values?.stafDob).toDate() : null}
                          dateFormat="dd/MM/yyyy"
                          onChange={(stafDob) => setFieldValue("stafDob", moment(stafDob).valueOf())}
                          onBlur={() => setFieldTouched("stafDob", true)} // Ensure Formik's touched state is set on blur
                          labelText="Date of Birth"
                          placeholderText="Select Start Date"
                          // customError={touched?.stafDob && errors?.stafDob}
                          // errorMessage={errors?.stafDob}
                          // errorClass={touched?.stafDob && errors?.stafDob ? "validationError" : ""}
                          // requiredStar={true}
                          showYearDropdown={true}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <Input
                          type="text"
                          id="niNumber"
                          name="niNumber"
                          value={values?.niNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          labelText="Ni Numbers"
                          customError={
                            errors.niNumber && touched.niNumber
                          }
                          errorMessage={errors.niNumber}
                          errorClass={
                            errors.niNumber && touched.niNumber
                          }
                        />
                      </div>
                    </div>


                    {/* Address Fields */}
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <Input
                          type="text"
                          id="houseFlatNumber"
                          name="houseFlatNumber"
                          value={values?.houseFlatNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          labelText="House / Flat Number"
                          customError={errors.houseFlatNumber && touched.houseFlatNumber}
                          errorMessage={errors.houseFlatNumber}
                          errorClass={errors.houseFlatNumber && touched.houseFlatNumber}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <Input
                          type="text"
                          id="addressLine1"
                          name="addressLine1"
                          value={values?.addressLine1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          labelText="Address Line 1"
                          customError={errors.addressLine1 && touched.addressLine1}
                          errorMessage={errors.addressLine1}
                          errorClass={errors.addressLine1 && touched.addressLine1}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <Input
                          type="text"
                          id="town"
                          name="town"
                          value={values?.town}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          labelText="Town"
                          customError={errors.town && touched.town}
                          errorMessage={errors.town}
                          errorClass={errors.town && touched.town}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <Input
                          type="text"
                          id="city"
                          name="city"
                          value={values?.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          labelText="City"
                          customError={errors.city && touched.city}
                          errorMessage={errors.city}
                          errorClass={errors.city && touched.city}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <Input
                          type="text"
                          id="postcode"
                          name="postcode"
                          value={values?.postcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          labelText="Postcode"
                          customError={errors.postcode && touched.postcode}
                          errorMessage={errors.postcode}
                          errorClass={errors.postcode && touched.postcode}
                        />
                      </div>
                    </div>

                    {!isDriverCheckedState ? (
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelectCheckbox
                            name="isDriver"
                            tick={
                              values?.driverFields?.isDriver &&
                              values?.driverFields?.isDriver
                            }
                            value={isDriverCheckedState}
                            onChange={handleIsDriverCheckBox}
                            onBlur={handleBlur}
                            labelText={"Driving Status"}
                          />
                        </div>
                      </div>
                    ) : null}

                    {isDriverCheckedState ? (
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <div
                            className={`customSelectBox ${touched?.driverFields?.vehicleType &&
                              errors?.driverFields?.vehicleType
                              ? "validationError"
                              : ""
                              }`}
                          >
                            <>
                              <Select
                                className="basic-multi-select cursor-pointer"
                                classNamePrefix="select"
                                value={vehicleTypeOptions?.find(
                                  (option) =>
                                    option?.value ===
                                    values?.driverFields?.vehicleType
                                )}
                                options={vehicleTypeOptions}
                                onChange={(selectedOptions) => {
                                  setFieldValue(
                                    "driverFields.vehicleType",
                                    selectedOptions?.value
                                  );
                                  // console.log(selectedOptions);
                                }}
                                onBlur={handleBlur}
                                isSearchable
                                getOptionLabel={(selectedOptions) =>
                                  selectedOptions?.label
                                }
                                getOptionValue={(selectedOptions) =>
                                  selectedOptions?.value
                                }
                                components={{ Option: CustomOption }}
                              />
                              <label className="floatingLabel">
                                Vehicle Type
                                <span className="text-danger">*</span>
                              </label>
                              {touched?.driverFields?.vehicleType &&
                                errors?.driverFields?.vehicleType && (
                                  <div className="text-danger">
                                    {errors?.driverFields?.vehicleType}
                                  </div>
                                )}
                            </>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {isDriverCheckedState ? (
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelectCheckbox
                            name="isDriver"
                            tick={
                              values?.driverFields?.isDriver &&
                              values?.driverFields?.isDriver
                            }
                            value={isDriverCheckedState}
                            onChange={handleIsDriverCheckBox}
                            onBlur={handleBlur}
                            labelText={"Driving Status"}
                          />
                        </div>
                      </div>
                    ) : null}

                    <div className="col-md-12">
                      <p>
                        <strong>Contract Hours</strong>
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="col">
                        <Input
                          type="number"
                          name="contractedHours"
                          value={values?.contractedHours?.contractedHours}
                          onChange={(e) => {
                            setFieldValue(
                              "contractedHours.contractedHours",
                              e.target?.value
                            );
                          }}
                          onBlur={handleBlur}
                          labelText="Contract Hours"
                          customError={
                            touched?.contractedHours?.contractedHours &&
                            errors?.contractedHours?.contractedHours
                          }
                          errorMessage={errors?.contractedHours?.contractedHours}
                          errorClass={
                            touched?.contractedHours?.contractedHours &&
                              errors?.contractedHours?.contractedHours
                              ? "validationError"
                              : ""
                          }
                          requiredStar={true}
                        />
                      </div>
                    </div>
                    <ProtectedComponent requiredPermissions={[permissions.PAYROLL]}>
                      <div className="col-sm-6 col-md-4">
                        <div className="col">
                          <Input
                            type="number"
                            name="perHourRate"
                            value={values?.contractedHours?.perHourRate}
                            onChange={(e) => {
                              setFieldValue(
                                "contractedHours.perHourRate",
                                e.target?.value
                              );
                            }}
                            onBlur={handleBlur}
                            labelText="Contracted Hours Rate"
                            customError={
                              touched?.contractedHours?.perHourRate &&
                              errors?.contractedHours?.perHourRate
                            }
                            errorMessage={errors?.contractedHours?.perHourRate}
                            errorClass={
                              touched?.contractedHours?.perHourRate &&
                                errors?.contractedHours?.perHourRate
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-4">
                        <div className="col">
                          <Input
                            type="number"
                            name="overNightSleepRate"
                            value={values?.contractedHours?.overNightSleepRate}
                            onChange={(e) => {
                              setFieldValue(
                                "contractedHours.overNightSleepRate",
                                e.target?.value
                              );
                            }}
                            onBlur={handleBlur}
                            labelText="Over Night Sleep Rate"
                            customError={
                              touched?.contractedHours?.overNightSleepRate &&
                              errors?.contractedHours?.overNightSleepRate
                            }
                            errorMessage={errors?.contractedHours?.overNightSleepRate}
                            errorClass={
                              touched?.contractedHours?.overNightSleepRate &&
                                errors?.contractedHours?.overNightSleepRate
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-4">
                        <Input
                          type="number"
                          name="enhancedRate"
                          value={values?.contractedHours?.enhancedRate}
                          onChange={(e) => {
                            setFieldValue(
                              "contractedHours.enhancedRate",
                              e.target?.value
                            );
                          }}
                          onBlur={handleBlur}
                          labelText="Enhanced Rate"
                          customError={
                            touched?.contractedHours?.enhancedRate &&
                            errors?.contractedHours?.enhancedRate
                          }
                          errorMessage={errors?.contractedHours?.enhancedRate}
                          errorClass={
                            touched?.contractedHours?.enhancedRate &&
                              errors?.contractedHours?.enhancedRate
                              ? "validationError"
                              : ""
                          }
                          requiredStar={true}
                        />
                      </div>

                      <div className="col-sm-6 col-md-4">
                        <Input
                          type="number"
                          name="trainingRate"
                          value={values?.contractedHours?.trainingRate}
                          onChange={(e) => {
                            setFieldValue(
                              "contractedHours.trainingRate",
                              e.target?.value
                            );
                          }}
                          onBlur={handleBlur}
                          labelText="Training Rate"
                          customError={
                            touched?.contractedHours?.trainingRate &&
                            errors?.contractedHours?.trainingRate
                          }
                          errorMessage={errors?.contractedHours?.trainingRate}
                          errorClass={
                            touched?.contractedHours?.trainingRate &&
                              errors?.contractedHours?.trainingRate
                              ? "validationError"
                              : ""
                          }
                          requiredStar={true}
                        />
                      </div>

                      <div className="col-sm-6 col-md-4">
                        <Input
                          type="number"
                          name="overtimeRate"
                          value={values?.contractedHours?.overtimeRate}
                          onChange={(e) => {
                            setFieldValue(
                              "contractedHours.overtimeRate",
                              e.target?.value
                            );
                          }}
                          onBlur={handleBlur}
                          labelText="Overtime Rate"
                          customError={
                            touched?.contractedHours?.overtimeRate &&
                            errors?.contractedHours?.overtimeRate
                          }
                          errorMessage={errors?.contractedHours?.overtimeRate}
                          errorClass={
                            touched?.contractedHours?.overtimeRate &&
                              errors?.contractedHours?.overtimeRate
                              ? "validationError"
                              : ""
                          }
                          requiredStar={true}
                        />
                      </div>
                    </ProtectedComponent>

                    <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                      {/* Other fields */}
                      <p>
                        <strong>Staff Role</strong>
                      </p>
                      <div className="col-md-6">

                        <div className="form-group">
                          <div
                            className={`customSelectBox ${errors?.staffDesignation ? "validationError" : ""
                              }`}
                          >
                            <CreatableSelect
                              isClearable={true}
                              name="staffDesignation"
                              value={customOptions.find(
                                (option) => option?.value === values?.staffDesignation
                              )}
                              className="basic-multi-select cursor-pointer"
                              classNamePrefix="select"
                              options={customOptions}
                              onChange={(selectedOption) => {
                                setFieldValue("staffDesignation", selectedOption?.value);
                              }}
                              onCreateOption={handleCreateOption} // Add this to handle new custom options
                              isSearchable
                              getOptionLabel={(option) => option?.label}
                              getOptionValue={(option) => option?.value}
                              components={{ Option: CustomOption }}
                            />
                            <label className="floatingLabel">
                              Staff Roles
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            {errors?.staffDesignation && (
                              <div className="text-danger">{errors?.staffDesignation}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">

                        <div className="form-group">
                          <div className="selectBoxLevel">
                            <span className="multiSelectCustomLabel">Staff Permissions</span>
                            <Select
                              name="staffRoles"
                              isMulti
                              options={staffRoleOptions}
                              // value={selectedOptions}
                              value={staffRoleOptions.filter(staffRoleOptions => values.staffRoles.includes(staffRoleOptions.value))}
                              onChange={handleSelectChangeStaffRole}
                              getOptionLabel={staffRoleOptions => staffRoleOptions.label}
                              getOptionValue={staffRoleOptions => staffRoleOptions.value}
                            />
                            {errors.staffRoles && touched.staffRoles && (
                              <div className="text-danger">{errors.staffRoles}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      {isDesignationSupervisor && (

                        <div className="col-md-6">
                          <div className="form-group">
                            <CustomSelect
                              value={values?.supervisorId}
                              defaultValue={values?.supervisorId}
                              filterBy="supervisor"
                              onSelect={handleSupervisorSelect}
                              customError={
                                touched?.supervisorId && errors?.supervisorId
                              }
                              errorMessage={errors?.supervisorId}
                              errorClass={
                                touched?.supervisorId && errors?.supervisorId
                                  ? "validationError"
                                  : ""
                              }
                              label="Supervisor"
                              requiredStar={true}
                            />
                          </div>
                        </div>

                      )}
                      {isDesignationManager && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <CustomSelect
                              value={values?.managerId}
                              defaultValue={values?.managerId}
                              onSelect={handleManagerSelect}
                              filterBy="manager"
                              label="Manager"
                              customError={
                                touched?.managerId && errors?.managerId
                              }
                              errorMessage={errors?.managerId}
                              errorClass={
                                touched?.managerId && errors?.managerId
                                  ? "validationError"
                                  : ""
                              }
                              requiredStar={true}
                            />
                          </div>
                        </div>
                      )}
                    </ProtectedComponent>

                    {/* <p>
                      <strong>Supervision</strong>
                    </p>
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomSelectSupervisionTemplates
                          onSelect={handleSupervisionTemplateSelect}
                          value={values?.supervision?.templateTitleId}
                          defaultValue={values?.supervision?.templateTitleId}
                          filterBy=""
                          label="Supervision Template"
                          customError={
                            touched?.supervision?.templateTitleId &&
                            errors?.supervision?.templateTitleId
                          }
                          errorMessage={errors?.supervision?.templateTitleId}
                          errorClass={
                            touched?.supervision?.templateTitleId &&
                              errors?.supervision?.templateTitleId
                              ? "validationError"
                              : ""
                          }
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Input
                        type="date"
                        datePicker={true}
                        name="supervision.sprDueDate"
                        selected={values?.supervision?.sprDueDate ? moment(values?.supervision?.sprDueDate).toDate() : null}
                        dateFormat="dd/MM/yyyy"
                        onChange={(sprDueDate) => setFieldValue("supervision.sprDueDate", moment(sprDueDate).valueOf())}
                        onBlur={() => setFieldTouched("supervision.sprDueDate", true)} // Ensure Formik's touched state is set on blur
                        labelText="Supervision Due Date"
                        placeholderText="Supervision Due Date"
                        requiredStar={true}
                        showYearDropdown={true}
                      />
                    
                    </div> */}
                    {/* <div className="col-sm-12 col-md-4">
                      <p>
                        <strong>COVID-19</strong>
                      </p>
                      <div className="form-group">
                        <CustomSelectCheckbox
                          name="covidCheck"
                          tick={values?.covidCheck && values?.covidCheck}
                          value={values?.covidCheck}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          labelText={
                            isHealthCheckedState ? "Status Checked" : "Check"
                          }
                          customError={touched?.covidCheck && errors?.covidCheck}
                          errorMessage={errors?.covidCheck}
                          errorClass={
                            touched?.covidCheck && errors?.covidCheck
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isUpdateFormSubmit ? (
              <ProgressComponent />
            ) : (
              <>
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={closeModal}
                >
                  Close
                </Button>
                <Button className="btn-sm" variant="primary" type="submit">
                  Update
                </Button>
              </>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      {isFileUploadedToast && <ToastContainer />}
      {isToastUpdateUserRecord && <ToastContainer />}
    </>
  );
}

export default UserProfileUpdate;
