import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import {
  BASE_URL,
} from "../../../env";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import useColumnsWithPermission from "../../../permissions/useColumnsWithPermission";
import ProgressComponent from "../../../components/general/ProgressComponent";
import moment from "moment";
const RotaSchedule = ({
  editShiftDetailForrRotaSchedule,
  shiftIdForRotaSchedule,
  liveRosterLogsURL,
  showViewShiftLogs,
}) => {
  console.log("🚀 ~ editShiftDetailForrRotaSchedule:", editShiftDetailForrRotaSchedule)
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // console.log("🚀 ~ RotaSchedule ~ liveRosterLogsURL:", liveRosterLogsURL)
  const [matchingShifts, setMatchingShifts] = useState([]);
  console.log("🚀 ~ RotaSchedule ~ matchingShifts:", matchingShifts)

  useEffect(() => {
    const rosterData = liveRosterLogsURL;

    const findShifts = (data, shiftId) => {
      const result = [];
      data.forEach((roster) => {
        roster.lastUpdatedData.forEach((location) => {
          location.weekArray.forEach((week) => {
            week.daysArray.forEach((day) => {
              if (day._id === shiftId && day.lastModifiedTime) {
                result.push(day);
              }
            });
          });
        });
      });
      return result;
    };

    let shifts = findShifts(rosterData, shiftIdForRotaSchedule);

    // Sort by lastModifiedTime in descending order
    shifts = shifts.sort((a, b) => moment(b.lastModifiedTime).diff(moment(a.lastModifiedTime)));

    setMatchingShifts(shifts);
  }, [shiftIdForRotaSchedule, liveRosterLogsURL]);


  // Define your columns
  const columns = [
    {
      name: "Staff Member",
      selector: (row) => {
        return (
          <>
            <span className="usersNameInfo shiftLogs"            >
              <span className="userInfo">
                <span className="nameBlock">{row?.staffUserId?.staffMemberName} {row?.staffUserId?.lastName}</span>
              </span>
            </span>
          </>
        );
      },
      width: "150px",
    },
    {
      name: "Service User",
      selector: (row) => {
        return (
          <>
            <span className="usersNameInfo shiftLogs"            >
              <span className="userInfo">
                <span className="nameBlock">{row?.serviceUserId?.modelId?.suFirstMiddleName}&nbsp;{row?.serviceUserId?.modelId?.suLastName}</span>
              </span>
            </span>
          </>
        );
      },
      width: "150px",
    },
    {
      name: "Last Modifier",
      selector: (row) => {
        return (
          <>
            <span className="usersNameInfo shiftLogs">
              <span className="userInfo">
                <span className="nameBlock">{row?.lastModifiedBy?.name}</span>
                {
                  row.lastModifiedTime && (
                    <span className="dateTime">{moment(row.lastModifiedTime).format('DD/MM/YYYY h:mm A')}</span>
                  )
                }

              </span>
            </span>
          </>
        );
      },
      width: "150px",
    },
    {
      name: 'Day Name',
      selector: row => row.dayName,
      center: true,
    },
    {
      name: 'Start Time',
      selector: row => row.shiftStartTime,
      center: true,
    },
    {
      name: 'End Time',
      selector: row => row.shiftEndTime,
      center: true,
    },
    {
      name: 'Note',
      selector: row => row.note,
      width: "250px"
    },
  ];

  return (
    <>

      {/* <h2>Matching Shifts:</h2>
      <ul>
        {matchingShifts.map((shift, index) => (
          <li key={`${shift._id}-${index}`}>
            {shift.dayName}: {shift.shiftStartTime} - {shift.shiftEndTime} - {shift.note}
          </li>
        ))}
      </ul> */}
      <div className="dataTableWrapper">
        <DataTable
          className="maxWidthTable"
          columns={columns}
          data={matchingShifts}
          pagination
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationPerPage={10}
          fixedHeader
          fixedHeaderScrollHeight="520px"
          highlightOnHover
          responsive={true}
        />
      </div>
    </>
  );
};

export default RotaSchedule;
