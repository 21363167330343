import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { addHealthInjury } from "../../../../../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_CARE_PLAN_URL } from "../../../../../../env";
import ReactDOMServer from 'react-dom/server';
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Layout from "../../../../../../components/layouts/Layout";
import AddHealthIssues from "../../../../../health-issue/AddHealthIssues";
import HealthCategories from "../../../../../health-issue/HealthCategories";
function AddInjuryHealthIssue() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [parentSubCatValidationStatus, setParentSubCatValidationStatus] = useState(false);
  // const handleUserSelect = (userId) => {
  //   setSelectedUserId(userId);
  //   setFieldValue("addedby", userId);
  // };

  const [suFormData, setSuFormData] = useState({
    addedby: selectedUserId,
    nameOfWitnesses: "",
    placeOfAccident: "",
    accidentDescription: "",
    recordOfInjury: "",
    conditionOfPatient: "",
    isParentContacted: "",
    howParentContacted: "",
    nameOfParentContacted: "",
    parentContactedTime: moment().format('HH:mm'),
    category: selectedCategoryId,
    subcategory: selectedCategoryId,
    eventDateTime: moment().valueOf(),
  });


  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addHealthInjury,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "/care-plan/general-notes", 10, 10);
      setIsSubmitButtonClicked(false);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const noteDetailsString = ReactDOMServer.renderToString(htmlData);
    const updateValues = {
      userId: userDetails?._id,
      addedby: selectedUserId,
      noteType: "InjuryHealthIssue",
      isHTML: true,
      title: values?.accidentDescription,
      flag: false,
      noteDetails: noteDetailsString,
      category: values?.category,
      // subcategory: selectedCategoryId,
      healthNote: values?.placeOfAccident,
      complaint: values?.accidentDescription,
      eventDateTime: moment(values.eventDateTime).valueOf(),
    }

    // console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(POST_CARE_PLAN_URL, updateValues);
    console.log("🚀 ~ response ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });


  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    handleChange(e);
    handleBlur(e);
  };




  // parent contact check boxes list
  const [isParentContacted, setIsParentContacted] = useState([
    { id: 1, values: "Yes", label: "Yes", checked: true },
    { id: 2, values: "No", label: "No", checked: false },
  ]);

  // how parent contact check boxes list
  const [howParentContacted, setHowParentContacted] = useState([
    { id: 1, values: "Call", label: "Call", checked: true },
    { id: 2, values: "Email", label: "Email", checked: false },
    { id: 2, values: "Text", label: "Text", checked: false },
  ]);

  const [htmlData, setHtmlData] = useState("")
  useEffect(() => {
    setHtmlData(
      <>
        <div className="col-md-12">
          <p><strong>Name of witnesses/adults present: </strong><span id="nameOfWitnessesData">{values?.nameOfWitnesses}</span></p>
        </div>

        <div className="col-md-12">
          <p><strong>Place accident occured: </strong><span id="placeOfAccidentData">{values?.placeOfAccident} </span></p>
        </div>

        <div className="col-md-12">
          <p><strong>Description how the accident occured: </strong><span id="accidentDescriptionData">{values?.accidentDescription}</span></p>
        </div>

        <div className="col-md-12">
          <p><strong>Record of any injury and action taken: </strong><span id="recordOfInjuryData">{values?.recordOfInjury}</span></p>
        </div>
        <div className="col-md-12">
          <p><strong>Condition of the patient following of the accident: </strong><span id="conditionOfPatientData">{values?.conditionOfPatient}</span></p>
        </div>
        <div className="col-md-12">
          <p><strong>Parent Contacted: </strong><span id="isParentContactedData">{values?.isParentContacted}</span></p>
        </div>
        {
          values?.isParentContacted === "Yes" && (
            <>
              <div className="col-md-12">
                <p><strong>If Yes how parent was contacted?: </strong><span id="howParentContactedData">{values?.howParentContacted}</span></p>
              </div>
              <div className="col-md-12">
                <p><strong>Name of Parent Contacted: </strong>
                  <span id="nameOfParentContactedData">{values?.nameOfParentContacted}</span><br />
                  Contacted at: <span id="parentContactedTimeData">
                    {moment(values?.parentContactedTime, "HH:mm").format("h:mm A")}</span>
                </p>
              </div>
            </>
          )
        }

      </>
    );

  }, [values]);


  const handleUserSelect = async (userId) => {
    setSelectedUserId(userId)
    setFieldValue("addedby", userId);
  }
  
  const handleBodyPartSelect = async (catId) => {
    setSelectedCategoryId(catId);

    setSuFormData((prevFormData) => ({
      ...prevFormData,
      category: catId,
    }));
  };


  const handleSubCatValidationStatus = (status) => {
    // console.log("Sub Cat Validation Status (Parent):", status);
    setParentSubCatValidationStatus(status);
  };

  useEffect(() => {
    // console.log("Parent Sub Cat Validation Status 01:", parentSubCatValidationStatus);
  }, [parentSubCatValidationStatus]);

  useEffect(() => {
    setFieldValue("category", selectedCategoryId);
    if (!parentSubCatValidationStatus) {
      setFieldValue("subcategory", "no-child");
    }
    else {
      setFieldValue("subcategory", "");
    }
  }, [selectedCategoryId, parentSubCatValidationStatus]);
  // console.log("🚀 ~ file: AddHealthIssues.jsx:112 ~ useEffect ~ selectedCategoryId:", selectedCategoryId)

  const [receivedSubCategory, setReceivedSubCategory] = useState(null);
  // console.log("🚀 ~asfsdfdsfsdfry:", receivedSubCategory)

  const handleSubCategoryChange = (subCategoryValue) => {
    setReceivedSubCategory(subCategoryValue);
    setFieldValue("category", subCategoryValue?.value);
    setFieldValue("subcategory", subCategoryValue?.value);
    setFieldTouched("subcategory", false); // Mark subcategory as touched
    // Other handling logic if needed
  };


  return (
    <>
      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Add Injury Health Issue note</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">

                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={selectedUserId}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date and time of accident"
                        customError={touched?.eventDateTime && errors?.eventDateTime}
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="nameOfWitnesses"
                        name="nameOfWitnesses"
                        value={values?.nameOfWitnesses}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Name of witnesses/adults present"
                        rows="3"
                        className="mb-0"
                        customError={touched?.nameOfWitnesses && errors?.nameOfWitnesses}
                        errorMessage={errors?.nameOfWitnesses}
                        erroClass={
                          touched?.nameOfWitnesses && errors?.nameOfWitnesses
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="placeOfAccident"
                        name="placeOfAccident"
                        value={values?.placeOfAccident}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Place accident occured"
                        rows="3"
                        className="mb-0"
                        customError={touched?.placeOfAccident && errors?.placeOfAccident}
                        errorMessage={errors?.placeOfAccident}
                        erroClass={
                          touched?.placeOfAccident && errors?.placeOfAccident
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="accidentDescription"
                        name="accidentDescription"
                        value={values?.accidentDescription}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Description how the accident occured"
                        rows="3"
                        className="mb-0"
                        customError={touched?.accidentDescription && errors?.accidentDescription}
                        errorMessage={errors?.accidentDescription}
                        erroClass={
                          touched?.accidentDescription && errors?.accidentDescription
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="recordOfInjury"
                        name="recordOfInjury"
                        value={values?.recordOfInjury}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Record of any injury and action taken"
                        rows="3"
                        className="mb-0"
                        customError={touched?.recordOfInjury && errors?.recordOfInjury}
                        errorMessage={errors?.recordOfInjury}
                        erroClass={
                          touched?.recordOfInjury && errors?.recordOfInjury
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-12">

                      <TextArea
                        id="conditionOfPatient"
                        name="conditionOfPatient"
                        value={values?.conditionOfPatient}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Condition of the patient following of the accident"
                        rows="3"
                        className="mb-0"
                        customError={touched?.conditionOfPatient && errors?.conditionOfPatient}
                        errorMessage={errors?.conditionOfPatient}
                        erroClass={
                          touched?.conditionOfPatient && errors?.conditionOfPatient
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-12">
                      <HealthCategories
                        name="category"
                        onSelect={handleBodyPartSelect}
                        onSelectSubCat={handleSubCategoryChange}
                        selectedSubCategory={receivedSubCategory}
                        selectedCategoryId={selectedCategoryId}
                        value={values?.category}
                        filterBy={""}
                        label="Select Category"
                        sublabel="Select Subcategory"
                        subCatValidationStatus={parentSubCatValidationStatus}
                        setSubCatValidationStatus={handleSubCatValidationStatus}
                        erroClassCategory={touched.category && errors.category ? "validationError" : ""}
                        customErrorCategory={touched.category && errors.category}
                        errorMessageCategory={touched.category && errors.category ? errors.category : ""}
                        customErrorSubcategory={touched.subcategory && errors.subcategory}
                        errorMessageSubcategory={touched.subcategory && errors.subcategory ? errors.subcategory : ""}
                        erroClassSubcategory={touched.subcategory && errors.subcategory ? "validationError" : ""}

                      />
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="general m-2">Parent Contacted</label>
                      <div className="checkboxList spaceInCheckList">
                        {isParentContacted.map((row, index) => (
                          <>
                            <Form.Check
                              key={row.id}
                              name={"isParentContacted"}
                              type="radio"
                              id={row?.label}
                              label={row?.label}
                              value={row.values}
                              onChange={handleInputChangePrevFields}
                              onBlur={handleBlur}
                            />
                          </>
                        ))}

                      </div>
                    </div>

                    {values?.isParentContacted === "Yes" && (
                      <>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id="nameOfParentContacted"
                            name="nameOfParentContacted"
                            value={values?.nameOfParentContacted}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Name of Parent Contacted"
                            customError={touched?.nameOfParentContacted && errors?.nameOfParentContacted}
                            errorMessage={errors?.nameOfParentContacted}
                            erroClass={
                              touched?.nameOfParentContacted && errors?.nameOfParentContacted
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>

                        <div className="col-md-6">
                          <Input
                            type="time"
                            id="parentContactedTime"
                            name="parentContactedTime"
                            value={values?.parentContactedTime}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Contact time"
                            customError={touched?.parentContactedTime && errors?.parentContactedTime}
                            errorMessage={errors?.parentContactedTime}
                            erroClass={
                              touched?.parentContactedTime && errors?.parentContactedTime
                                ? "validationError"
                                : ""
                            }
                          />



                        </div>

                        <div className="col-md-12">
                          <label className="general m-2">How parent was contacted</label>
                          <div className="checkboxList spaceInCheckList">
                            {howParentContacted.map((row, index) => (
                              <>
                                <Form.Check
                                  key={row.id}
                                  name="howParentContacted"
                                  type="radio"
                                  id={row.label}
                                  label={row.label}
                                  value={row.values}
                                  onChange={handleInputChangePrevFields}
                                  onBlur={handleBlur}
                                />
                              </>
                            ))}

                          </div>
                        </div>
                      </>
                    )}


                  </div>
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      // onClick={subModalAddCarePlans}
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
      {isToastAddCarePlans && <ToastContainer />}
    </>
  );
}


export default AddInjuryHealthIssue