import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';
import Button from "react-bootstrap/Button";
import Input from "../../../components/forms-fields/Input";
import { modifySupportPlan } from "../../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_SUPPORT_PLAN_URL } from "../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../helper/helper";
import { Link } from "react-router-dom";
import Layout from "../../../components/layouts/Layout";
import moment from 'moment';
import CustomSelect from "../../../components/forms-fields/CustomSelectStaffMembers";
import { supportPlanDummyData } from "../SampleData";

function AddCrisisManagement() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddPbsPlans, setIsToastAddPbsPlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    setFieldValue("addedby", userId);
    setFieldValue("lastModifiedBy", userId);
  };

  const [suFormData, setSuFormData] = useState({
    supportType: "crisismanagement",
    userId: userDetails?._id,
    addedby: selectedUserId,
    lastModifiedBy: selectedUserId,
    note: "Type your text here",
    title: ""
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: modifySupportPlan,
    onSubmit: async (values) => {
      try {
        console.log("Form Data:", values);
        setIsSubmitButtonClicked(true);
        await postEditPbsPlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddPbsPlans(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (
      method === "postEditPbsPlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddPbsPlans(true);
      toastSuccessMessages(toast.success, data?.message, "/support-plan/crisis-management", 500, 500);

      setIsSubmitButtonClicked(false);
      setTimeout(() => {
      }, 2000);
    }
  };

  const postEditPbsPlansFormData = catchAsync(async (req, res) => {
    console.log(values);
    const updatedValues = {
      ...values,
      addedby: selectedUserId,
      lastModifiedBy: selectedUserId,
      note: content,
    }
    const response = await postRecords(POST_SUPPORT_PLAN_URL, updatedValues);
    console.log("🚀 ~ postEditPbsPlansFormData ~ updatedValues:", updatedValues)
    setIsSubmitButtonClicked(false);
    processPostRecords("postEditPbsPlansFormData", response);
  });


  const handleChangeTextEditor = (newContent) => {
    setContent(newContent);
  };

  const loadSamplePbsData = (fieldName, sampleData) => {
    setFieldValue(fieldName, sampleData)
  }
  
  return (
    <>
      <Layout
        pageTitleText={
          <>
            Support Plan Crisis Management
          </>
        }
      >
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent">
            <div className="mainContentText">
              <h3>Add Support Plan Crisis Management</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={selectedUserId}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <Input
                        type="text"
                        id="title"
                        name="title"
                        value={values?.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Title"
                        customError={touched?.title && errors?.title}
                        errorMessage={errors?.title}
                        erroClass={
                          touched?.title && errors?.title
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="pbsFieldWrapper">
                        <header className="alert alert-dark">
                          <h3>Note</h3>
                          <span onClick={() => { loadSamplePbsData("note", supportPlanDummyData) }} className='btn btn-sm btn-success'>Load Sample Data</span>
                        </header>

                        <JoditEditor
                          ref={editor}
                          value={values?.note}
                          tabIndex={1}
                          onChange={handleChangeTextEditor}
                        />
                        {errors.note && (
                          <div className="validationError">
                            <div className="text-danger">{errors.note}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="formActionButtons mt-5">
                  <Link to="/support-plan/crisis-management">
                    <span
                      className="btn btn-sm btn-outline-primary"
                    >
                      Cancel
                    </span>
                  </Link>
                  {isSubmitButtonClicked ? (
                    <Button
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
      {isToastAddPbsPlans && <ToastContainer />}
    </>
  );
}

export default AddCrisisManagement