
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import Layout from "../../../components/layouts/Layout";
import editBlock from "../../../assets/images/icons/editBlock.svg";
import eventDel from "../../../assets/images/icons/eventDel.svg";
// import AddTrainingQuiz from "./AddTrainingQuiz";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactHtmlParser from 'html-react-parser';
import { ToastContainer, toast } from "react-toastify";
import password_eye_icon_circle from "../../../assets/images/icons/password_eye_icon_circle.svg";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import useColumnsWithPermission from "../../../permissions/useColumnsWithPermission";
import "react-toastify/dist/ReactToastify.css";
import {
    BASE_URL,
    GET_TRAINING_QUIZZES,
    UPDATE_TRAINING_QUIZZES,
} from "../../../env";
import {
    getRecords,
    isSuccess,
    toastSuccessMessages,
    toastErrorMessages,
    deleteRecords,
    catchAsync,
} from "../../../helper/helper";
// import EditTrainingQuiz from "./EditTrainingQuiz";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
function QuizzesList() {
    if (localStorage.getItem('quizzesListDetail')) {
        localStorage.removeItem('quizzesListDetail');
    }
    const storedUserDetails = localStorage.getItem("profileUserDetails");
    const userDetails = JSON.parse(storedUserDetails);
    const [userData, setUserData] = useState([]);
    const apiQuery = {
        sortproperty: "createdAt",
        sortorder: -1,
        offset: 0,
        query: {
            critarion: {}
        }
    };


    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [filterSearch, setFilterSearch] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [medicationId, setTrainingQuizIdData] = useState({
        quizId: null,
        isDeleted: false,
    });
    const [pbsPlansDetail, setTrainingQuizDetail] = useState();
    const [isTrainingQuiz, setIsTrainingQuiz] = useState(false);
    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

    // Modal Popup
    // const [showAddTrainingQuiz, setShowAddTrainingQuiz] = useState(false);
    // const subModalAddTrainingQuiz = () => {
    //   setShowAddTrainingQuiz(!showAddTrainingQuiz);
    //   fetchAllRecords();
    // };

    const [showDeleteTrainingQuiz, setShowDeleteTrainingQuiz] = useState(false);
    const subModalDeleteTrainingQuiz = (delQuizId) => {
        setTrainingQuizIdData({
            quizId: delQuizId,
            isDeleted: true,
        });
        setShowDeleteTrainingQuiz(!showDeleteTrainingQuiz);
        fetchAllRecords();
    };

    // const [showEditTrainingQuiz, setShowEditTrainingQuiz] = useState(false);
    // const subModalEditTrainingQuiz = (pbsItem) => {
    //   setTrainingQuizDetail(pbsItem);
    //   setShowEditTrainingQuiz(!showEditTrainingQuiz);
    //   fetchAllRecords();
    // };

    // Getting records from DB
    const processFetchRecords = (method, data) => {
        if (method === "get" && data?.status === isSuccess) {
            const actionRecentIncidentsData = data?.data?.quizList.filter((item) => item?.isDeleted === false)
            const medicineListsData = actionRecentIncidentsData?.map((item) => item);
            setUserData(medicineListsData);
            setFilterSearch(medicineListsData);
            setIsLoading(false);
            // toastSuccessMessages(toast.success, data?.message, "", 500, 500);
        } else if (method === "delete" && data?.status === isSuccess) {
            toastSuccessMessages(toast.success, data?.message, "", 500, 500);
            setTimeout(() => {
                setIsTrainingQuiz(false);
            }, 2000);
        }
        // else {
        //   console.log({ data });
        //   toastErrorMessages(toast.error, data?.message, 1000);
        // }
    };

    const fetchAllRecords = catchAsync(async (req, res) => {
        const response = await getRecords(GET_TRAINING_QUIZZES, apiQuery);
        console.log("🚀 ~ fetchAllRecords ~ response:", response)
        processFetchRecords("get", response);
    });

    useEffect(() => {
        fetchAllRecords();
    }, []);

    // Table Columns

    const baseColumns = [
        {
            name: "Training Quiz",
            selector: (row) => {
                return (
                    <div>
                        <p className="m-0">{row?.quizName}</p>
                    </div>
                );
            },
            width: "65%"
        },
        {
            name: "Passing Marks (%)",
            selector: (row) => {
                return (
                    <div>
                        <p className="m-0">{row?.passMarksPercent}%</p>
                    </div>
                );
            },
            width: "15%",
            center: true
        },
        {
            name: "Created On",
            selector: (row) => {
                return (
                    <div className="pt-3 pb-3">
                        <p className="m-0">{moment(row?.createdAt).format("DD/MM/YYYY")}</p>
                    </div>
                );
            },
            width: "10%",
            center: true
        },
        {
            name: "Action",
            selector: (row) => {
                return (
                    <div className="actionColumns individualReviewsActions">
                        <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                            <span className="edit" onClick={() => handleEditViewPBSPlans(row, "/edit-training-quizzes")}>
                                <img src={editBlock} alt="Edit" title="Edit" />
                            </span>
                        </ProtectedComponent>
                        <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
                            <span
                                className="delete"
                                onClick={() => {
                                    subModalDeleteTrainingQuiz(row?._id);
                                }}
                            >
                                <img src={eventDel} alt="Delete" title="Delete" />
                            </span>
                        </ProtectedComponent>
                    </div>
                );
            },
            width: "10%",
            center: true,
            wrap: true,
        },
    ];

    const columns = useColumnsWithPermission(baseColumns, [permissions.EDIT, permissions.DELETE]);

    // Search Filter
    useEffect(() => {
        const result = userData.filter((user) => {
            const filterTrainingQuizName = user?.quizName
            return filterTrainingQuizName.toLowerCase().match(search.toLowerCase());
        });
        setFilterSearch(result);
    }, [search, userData]);

    // Delete TrainingQuiz records
    const deleteRecord = catchAsync(async (req, res) => {
        setIsSubmitButtonClicked(true);
        if (medicationId !== "") {
            const response = await deleteRecords(
                UPDATE_TRAINING_QUIZZES,
                medicationId
            );

            setIsTrainingQuiz(true);
            processFetchRecords("delete", response);
            fetchAllRecords();
            setIsSubmitButtonClicked(false);
            setShowDeleteTrainingQuiz(!showDeleteTrainingQuiz);
            console.log("🚀 ~ deleteRecord ~ medicationId:", medicationId)
            console.log("🚀 ~ deleteRecord ~ response:", response)
        } else {
            setShowDeleteTrainingQuiz(!showDeleteTrainingQuiz);
            setIsSubmitButtonClicked(false);
            setIsTrainingQuiz(true);
            toastErrorMessages(toast.error, "Unable to remove", "", 1000);
        }
    });
    useEffect(() => {
        setTimeout(() => {
            setIsTrainingQuiz(false);
        }, 2000);
    }, [isTrainingQuiz])

    const handleEditViewPBSPlans = (item, url) => {
        localStorage.setItem(
            "quizzesListDetail",
            JSON.stringify(item)
        );
        navigate(url);
    };

    return (
        <>
            <Layout pageTitleText="Training Quizzes">
                <div className="rowClickUnset pbsPlanUpdated">
                    <div className="mainContent">

                        <div className="dataTableWrapper">
                            <DataTable
                                className="maxWidthTable minWidthTable"
                                columns={columns}
                                data={filterSearch}
                                pagination
                                paginationRowsPerPageOptions={[1, 25, 50, 100]}
                                paginationPerPage={10}
                                fixedHeader
                                fixedHeaderScrollHeight="520px"
                                highlightOnHover
                                actions={
                                    <>
                                        <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                                            <Link to="/add-training-quizzes" className="btn btn-sm btn-primary">
                                                Add New Quiz
                                            </Link>
                                        </ProtectedComponent>
                                    </>
                                }
                                responsive={true}
                                subHeader={true}
                                subHeaderComponent={
                                    <div className="searchContainer">
                                        <div className="searchTextBox">
                                            <input
                                                type="text"
                                                placeholder="Search by Quiz Title"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                }
                                subHeaderAlign="left"
                                subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
                                progressPending={isLoading}
                                progressComponent={<ProgressComponent />}
                            />
                        </div>
                    </div>
                </div>
            </Layout>


            {/* Delete PBS Plans */}
            <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
                <Modal
                    show={showDeleteTrainingQuiz}
                    onHide={subModalDeleteTrainingQuiz}
                    className="ftcModalPopup confirmationPopup deletionConfirmation"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="editBlockContent text-center">
                            <img src={eventDel} alt={eventDel} />
                            <p>Are you sure you want to delete this item?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {isSubmitButtonClicked ? (
                            <Button className="btn-sm" variant="danger" disabled>
                                Loading...
                            </Button>
                        ) : (
                            <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                                Yes
                            </Button>
                        )}

                        <Button
                            className="btn-sm"
                            variant="outline-secondary"
                            onClick={subModalDeleteTrainingQuiz}
                        >
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </ProtectedComponent>
            {isTrainingQuiz && <ToastContainer />}
        </>
    );
}

export default QuizzesList