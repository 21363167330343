import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import Button from "react-bootstrap/Button";
import Accordion from 'react-bootstrap/Accordion';
import Input from "../../components/forms-fields/Input";
import eyeView from "../../assets/images/icons/eyeview.svg";
import eyeViewHide from "../../assets/images/icons/eye_password_see_view_icon.svg";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import CustomSelectSupervisionTemplates from "../../components/forms-fields/CustomSelectSupervisionTemplates";
import CustomSelectCheckbox from "../../components/forms-fields/CustomCheckBox";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedComponent from "../../permissions/ProtectedComponent";
import { permissions } from "../../permissions/roles";
import moment from 'moment';
import {
  POST_STAFF_RECORD_URL,
  POST_USER_PROFILE_PICTURE_URL,
  UPLOAD_REFERENCE_DOCUMENT_URL,
  ADMIN_AUTH_TOKEN,
} from "../../env";
import {
  CustomOption,
  handleReload,
  calculateTimeDifference,
  handleSetIdFormikFormData,
  handleTimeChangeToNumeric,
  useShiftEffect,
  days,
  weeksOptions,
  getFormDataProps,
  GenerateScheduleFormData,
  handleFormUpdate,
  handleCheckboxChange,
  useCheckedEffect,
  uploadFile,
  staffDesignationOptions,
  staffRoleOptions,
  vehicleTypeOptions,
  userInputFields,
  workLoadFields,
  sprStatus,
  sprResult,
  handleSelectChange,
  updateDesignationFields,
  updateStaffWorkLoad,
  passwordRegex,
  staffValidationSchema,
} from "../../helper/helperJsx";
import {
  postRecords,
  isSuccess,
  catchAsync,
  calculateHolidayHours,
  calculateNextYearEndDate,
  toastSuccessMessages,
  toastErrorMessages,
  updateFormFields,
  phoneRegExp,
  MAX_FILE_SIZE,
} from "../../helper/helper";

function AddNewStaff() {
  const navigate = useNavigate();
  const [isDesignationManager, setIsDesignationManager] = useState(false);
  const [isDesignationSupervisor, setIsDesignationSupervisor] = useState(false);
  const [isStaffSingUpToast, setIsStaffSingUpToast] = useState(false);
  const [isHealthCheckedState, setIsHealthCheckedState] = useState(false);
  const [isDriverCheckedState, setIsDriverCheckedState] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [payrollStartDate, setPayrollStartDate] = useState(moment().valueOf());


  // Documents upload section in add new staff member
  const [fileSizeError, setFileSizeError] = useState(false);

  const handleFileUpload = async (e, documentEnum, settingTheArray, documentType, title) => {
    handleChange(e)
    setIsFormSubmit(true);
    try {
      const file = e.target.files[0];
      const fileName = file.name; // Get the file name
      const fileType = file.type;
      const fileSize = file.size;

      // Validate file size (1MB = 1,048,576 bytes)
      if (fileSize > 1048576) {
        alert('File size exceeds 1MB');
        setFileSizeError(true);
        setIsFormSubmit(false);
        return;
      }

      // Validate file type
      if (
        fileType === 'application/pdf' ||
        fileType === 'application/msword' ||
        fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        const fileData = new FormData();
        fileData.append('attachements', file);
        // console.log("🚀 ~ handleFileUpload ~ fileData:", fileData);
        const apiResponse = await fetch(UPLOAD_REFERENCE_DOCUMENT_URL, {
          method: 'POST',
          body: fileData,
          headers: {
            Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
          },
        });
        const resultResponse = await apiResponse.json();
        // console.log("🚀 ~ handleFileUpload ~ resultResponse:", resultResponse);

        const fileLink = `${resultResponse?.data[0]?.fileNameInDirectory}`;


        // Update the path in the interviewDoc array
        settingTheArray(prevDoc =>
          prevDoc.map(doc =>
            doc.documentEnum === documentEnum ? { ...doc, path: fileLink, docType: documentType, documentEnum: documentEnum, title: title } : doc
          )
        );
      } else {
        alert('Unsupported file format');
      }
      setIsFormSubmit(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsFormSubmit(false);
    }
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChangeStaffRole = (selected) => {
    if (!selected) {
      setSelectedOptions([]);
      setFieldValue('staffRoles', []); // Update Formik's value with an empty array
      return;
    }

    const isSelectAllSelected = selected.some(option => option.value === 'selectAll');

    if (isSelectAllSelected) {
      // Select All logic: select all days if "Select All" is selected
      if (selectedOptions.length === staffRoleOptions.length) {
        setSelectedOptions([]);
        setFieldValue('staffRoles', []); // Deselect all in Formik
      } else {
        setSelectedOptions(staffRoleOptions);
        setFieldValue('staffRoles', staffRoleOptions.map(option => option.value)); // Update Formik's value with all days
      }
    } else {
      // Normal selection logic: exclude "Select All" from Formik's value
      const selectedDays = selected.filter(option => option.value !== 'selectAll');
      setSelectedOptions(selectedDays);
      setFieldValue('staffRoles', selectedDays.map(option => option.value)); // Update Formik's value with selected days
    }
  };

  //File upload section fields
  const [interviewUploadedDoc, setInterviewUploadedDoc] = useState([
    { docType: "interviewDoc", documentEnum: "preScreen", path: "", title: "Pre-Screen", },
    { docType: "interviewDoc", documentEnum: "cv", path: "", title: "CV" },
    { docType: "interviewDoc", documentEnum: "interviewForm", path: "", title: "Interview form" },
    { docType: "interviewDoc", documentEnum: "offerLetter", path: "", title: "Offer Letter" },
    { docType: "interviewDoc", documentEnum: "applicationForm", path: "", title: "Application form" },
  ]);

  const [documentSubmitUploadedDoc, setDocumentSubmitUploadedDoc] = useState([
    { documentEnum: "photographicIdentification", path: "", title: "Photographic Identification", docType: "documentSubmitDoc", },
    { documentEnum: "proofOfAddress", path: "", title: "Proof of Address", docType: "documentSubmitDoc", },
    { documentEnum: "rightToWork", path: "", title: "Right to work", docType: "documentSubmitDoc", },
    { documentEnum: "proofOfNationalInsurance", path: "", title: "Proof of National Insurance", docType: "documentSubmitDoc", },
  ]);

  const [DBSCUploadedDoc, setDBSCUploadedDoc] = useState([
    { documentEnum: "DBSCertificate", path: "", title: "DBS Certificate", },
  ]);

  const [complianceUploadedDoc, setComplianceUploadedDoc] = useState([
    { documentEnum: "references", path: "", title: "References", docType: "complianceDoc" },
    { documentEnum: "bankDetailsForm", path: "", title: "Bank Details form", docType: "complianceDoc" },
    { documentEnum: "personalDetailsForm", path: "", title: "Personal Details Form", docType: "complianceDoc" },
    { documentEnum: "hourOptOutForm", path: "", title: "48-hour Opt out form", docType: "complianceDoc" },
    { documentEnum: "healthDeclaration", path: "", title: "Health declaration", docType: "complianceDoc" },
    { documentEnum: "equalOpportunities", path: "", title: "Equal opportunities", docType: "complianceDoc" },
    { documentEnum: "BYODSignedDeclaration", path: "", title: "BYOD signed declaration", docType: "complianceDoc" },
  ]);

  const [referencesUploadedDoc, setReferencesUploadedDoc] = useState([
    { documentEnum: "uploadFile1", path: "", title: "Upload File 1", docType: "refDoc" },
    { documentEnum: "uploadFile2", path: "", title: "Upload File 2", docType: "refDoc" },
    { documentEnum: "uploadFile3", path: "", title: "Upload File 3", docType: "refDoc" },
  ]);


  const [disciplinaryUploadedDoc, setDisciplinaryUploadedDoc] = useState([
    { documentEnum: "uploadFile1", path: "", title: "Upload File 1", docType: "disciplinaryDoc" },
    { documentEnum: "uploadFile2", path: "", title: "Upload File 2", docType: "disciplinaryDoc" },
    { documentEnum: "uploadFile3", path: "", title: "Upload File 3", docType: "disciplinaryDoc" },
  ]);

  // Document Upload Process
  const [interviewFormFields, setInterviewFormFields] = useState([
    { id: 1, name: "preScreen", label: "Pre - Screen", documentEnum: "preScreen", },
    { id: 2, name: "cv", label: "CV", documentEnum: "cv", },
    { id: 3, name: "interviewForm", label: "Interview form", documentEnum: "interviewForm", },
    { id: 4, name: "offerLetter", label: "Offer Letter", documentEnum: "offerLetter", },
    { id: 5, name: "applicationForm", label: "Application form", documentEnum: "applicationForm", },
  ])

  const [documentSubmitFormFields, setDocumentSubmitFormFields] = useState([
    { id: 1, name: "photographicIdentification", label: "Photographic Identification", documentEnum: "photographicIdentification", },
    { id: 2, name: "proofOfAddress", label: "Proof of address", documentEnum: "proofOfAddress", },
    { id: 3, name: "rightToWork", label: "Right to work", documentEnum: "rightToWork", },
    { id: 4, name: "ProofOfNationalInsurance", label: "Proof of National Insurance", documentEnum: "ProofOfNationalInsurance", },
  ])

  const [DBSCertificateFormFields, setDBSCertificateFormFields] = useState([
    { id: 1, name: "DBSCertificate", label: "DBS Certificate", documentEnum: "DBSCertificate", },

  ])

  const [complianceFormFields, setComplianceFormFields] = useState([
    {
      id: 1,
      name: "",
      label: "References",
      documentEnum: "references",
    },
    {
      id: 2,
      name: "",
      label: "Bank Details form",
      documentEnum: "bankDetailsForm",
    },
    {
      id: 3,
      name: "",
      label: "Personal Details form",
      documentEnum: "personalDetailsForm",
    },
    {
      id: 4,
      name: "",
      label: "48-hour Opt out form",
      documentEnum: "48HourOptOutForm",
    },
    {
      id: 5,
      name: "",
      label: "Health declaration",
      documentEnum: "healthDeclaration",
    },
    {
      id: 6,
      name: "",
      label: "Equal opportunities",
      documentEnum: "equalOpportunities",
    },
    {
      id: 7,
      name: "",
      label: "BYOD signed declaration",
      documentEnum: "BYODSignedeclaration",
    }
  ])

  const [referencesFormFields, setReferencesFormFields] = useState([
    { id: 1, name: "file1", label: "Upload File 1", documentEnum: "uploadFile1", },
    { id: 2, name: "file2", label: "Upload File 2", documentEnum: "uploadFile2", },
    { id: 3, name: "file3", label: "Upload File 3", documentEnum: "uploadFile3", },
  ])

  const [disciplinaryFormFields, setDisciplinaryFormFields] = useState([
    { id: 1, name: "file1", label: "Upload File 1", documentEnum: "uploadFile1", },
    { id: 2, name: "file2", label: "Upload File 2", documentEnum: "uploadFile2", },
    { id: 3, name: "file3", label: "Upload File 3", documentEnum: "uploadFile3", },
  ])


  const registerStaffMemberFormData = useFormik({
    initialValues: {
      title: "A",
      lastName: "B",
      initials: "C",
      secondaryContactNo: "+44123654",
      houseFlatNumber: "123",
      addressLine1: "Green Lane",
      town: "Some Town",
      city: "London",
      postcode: "SW1A 1AA",
      inductionDate: "",
      interviewDoc: interviewUploadedDoc.filter(doc => doc.path !== ""),
      documentSubmitDoc: documentSubmitUploadedDoc.filter(doc => doc.path !== ""),
      DSBCDoc: DBSCUploadedDoc.filter(doc => doc.path !== ""),
      complianceDoc: complianceUploadedDoc.filter(doc => doc.path !== ""),
      refDoc: referencesUploadedDoc.filter(doc => doc.path !== ""),
      disciplinaryDoc: disciplinaryUploadedDoc.filter(doc => doc.path !== ""),
      profile_picture_url: "/uploads/dp/default.png",
      name: "C",
      email: "d@d.com",
      password: "123456",
      phoneNumber: "+44123654",
      deviceId: "EWR",
      version: "0",
      role: "staffmember",
      userModelName: "staffMembers",
      active: true,
      is_verified: false,
      approved: false,
      signUpFromWeb: true,
      fcm_tokens: {
        token: "",
        deviceType: "android",
      },
      location: {
        type: "Point",
        coordinates: [74.28911289869139, 31.624848273644957],
      },
      covidCheck: false,
      supervisorId: "",
      managerId: "",
      stafDob: "",
      staffOnLeave: false,
      staffDesignation: "staffmember",
      staffRoles: [],
      staffWorkLoad: {
        isCurrentWrkLd: true,
        startDate: "",
        endDate: "",
        numberOfDays: 0,
        holidayEntitlement: {
          numberOfDays: 0,
          numberOfHours: 0,
          numberOfWeeks: 0,
        },
        holidayAlwnNoOfDys: 0,
        holidayAlwnNoOfHours: 0,
        holidaysAvailed: 0,
        holidaysRemaining: 0,
        carriedOverHours: 0,
      },
      contractedHours: {
        contractedHours: 0,
        totalShiftHoursWeek: 0,
        noOfShifts: 0,
        perHourRate: 0,
        overNightSleepRate: 0,
        enhancedRate: 0,
        trainingRate: 0,
        overtimeRate: 0,
      },
      supervision: {
        supervisionName: "default",
        sprDueDate: moment().valueOf(),
        sprStatus: "assigned",
        sprResult: "passed",
        templateTitleId: "",
      },
      holidays: [],
      niNumber: "",
      kin: "",
      clients: [],
      driverFields: {
        isDriver: false,
        vehicleType: "auto",
      },
    },
    validationSchema: staffValidationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setIsFormSubmit(true);
        console.log("🚀 ~ onSubmit: ~ values:", values)
        setIsFormSubmit(false);
        // await addNewStaffMember(values);
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error. Please try again.",
          "",
          500,
          500
        );
        setIsFormSubmit(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = registerStaffMemberFormData;

  const vehicleTypeOptions = [
    { value: "auto", label: "Automatic" },
    { value: "manual", label: "Manual" },
  ];

  const addNewStaffMember = catchAsync(async (req, res) => {
    // console.log("clicked")
    setIsFormSubmit(false)
    const response = await postRecords(POST_STAFF_RECORD_URL, req);
    processFetchRecords("signUpStaff", response);
  });

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsStaffSingUpToast(true);
      toastErrorMessages(toast?.error, data?.message, "", 2000, 2000);
      setIsFormSubmit(false);
    } else if (method === "signUpStaff" && data?.status === isSuccess) {
      // navigate("/staff-records");
      setIsStaffSingUpToast(true);
      toastSuccessMessages(toast?.success, data?.message, "/staff-records", 500, 500);
    }
  };

  const handleManagerSelect = (userId) => {
    handleSelectChange(setFieldValue, "managerId", userId);
  };

  const handleSupervisorSelect = (userId) => {
    handleSelectChange(setFieldValue, "supervisorId", userId);
  };

  const handleSupervisionTemplateSelect = (templateId) => {
    handleSelectChange(
      setFieldValue,
      "supervision.templateTitleId",
      templateId
    );
  };

  const handleIsDriverCheckBox = (e) => {
    handleCheckboxChange(e, setFieldValue, "driverFields.isDriver");
  };

  const handleFileChangeUploadDoc = async (e) => {
    try {
      const selectedUploadDocFile = e.target?.files[0];
      await uploadFile({
        isUpload: true,
        updateFieldName: "",
        updateFieldId: "",
        fileName: "file",
        file: selectedUploadDocFile,
        apiUrl: POST_USER_PROFILE_PICTURE_URL,
        successCallback: (result) => {
          handleAddPath(result);
        },
      });
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const handleAddPath = (filePath) => {
    handleFormUpdate(setFieldValue, "profile_picture_url", filePath);
  };

  useEffect(() => {
    updateStaffWorkLoad(
      setValues,
      values,
      setIsDesignationManager,
      setIsDesignationSupervisor
    );
  }, [values?.staffWorkLoad?.startDate]);

  useEffect(() => {
    updateDesignationFields(
      setValues,
      values,
      setIsDesignationManager,
      setIsDesignationSupervisor
    );
  }, [values?.staffDesignation]);

  useEffect(() => {
    if (values?.covidCheck) {
      setIsHealthCheckedState(true);
    } else {
      setIsHealthCheckedState(false);
    }
  }, [values?.covidCheck]);

  useEffect(() => {
    if (values?.driverFields?.isDriver) {
      setFieldValue("driverFields.vehicleType", "auto");
      setIsDriverCheckedState(true);
    } else {
      setIsDriverCheckedState(false);
      setFieldValue("driverFields.vehicleType", "");
    }
  }, [values?.driverFields?.isDriver]);

  // Function to calculate the difference in days
  const calculateDaysDifference = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const diff = end.diff(start, 'days');
    // setFieldValue("staffWorkLoad.numberOfDays", diff);
    return diff + 1; // Difference in days
  };

  useEffect(() => {
    if (values.staffWorkLoad.startDate && values.staffWorkLoad.endDate) {
      const daysDiff = calculateDaysDifference(
        values.staffWorkLoad.startDate,
        values.staffWorkLoad.endDate
      );
      setFieldValue("staffWorkLoad.numberOfDays", daysDiff);
    }
  }, [values.staffWorkLoad.startDate, values.staffWorkLoad.endDate, setFieldValue]);


  useEffect(() => {


    if (values?.contractedHours?.contractedHours && values?.staffWorkLoad.numberOfDays) {
      const holidayHours = values?.contractedHours?.contractedHours * 5.6 / 365 * values?.staffWorkLoad.numberOfDays;
      setFieldValue("staffWorkLoad.holidayAlwnNoOfHours", holidayHours);
      setFieldValue("staffWorkLoad.holidaysRemaining", holidayHours);
    }

  }, [values?.contractedHours?.contractedHours, values?.staffWorkLoad.numberOfDays])


  // Interview Doc
  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      interviewDoc: interviewUploadedDoc.filter(doc => doc.path !== ""),
    }));
  }, [interviewUploadedDoc]);

  // Document Submit Doc
  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      documentSubmitDoc: documentSubmitUploadedDoc.filter(doc => doc.path !== ""),
    }));
  }, [documentSubmitUploadedDoc]);

  // BSBC Doc
  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      DSBCDoc: DBSCUploadedDoc.filter(doc => doc.path !== ""),
    }));
  }, [DBSCUploadedDoc]);

  // Compliance Doc
  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      complianceDoc: complianceUploadedDoc.filter(doc => doc.path !== ""),
    }));
  }, [complianceUploadedDoc]);


  // References Doc
  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      refDoc: referencesUploadedDoc.filter(doc => doc.path !== ""),
    }));
  }, [referencesUploadedDoc]);

  // Disciplinary Doc
  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      disciplinaryDoc: disciplinaryUploadedDoc.filter(doc => doc.path !== ""),
    }));
  }, [disciplinaryUploadedDoc]);

  return (
    <>
      <Layout pageTitleText="Add New Staff Records">
        <form method="post" onSubmit={handleSubmit}>
          <div className="incidents consentCapacityAddNew mainContentHeightAuto">
            <div className="mainContent">
              <div className="mainContentText">
                {/* Personal Detail */}
                <h3>Personal Detail</h3>
                <div className="formBlockContent row">
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <Input
                        type="file"
                        name={"profile_picture_url"}
                        accept={"image/*"}
                        onChange={(e) => handleFileChangeUploadDoc(e)}
                        labelText={"Staff Photo"}
                        className="form-control uploadProfileImage"
                      />
                    </div>
                  </div>
                  <div className="col-md-12"></div>
                  {userInputFields?.map((field, index) => (
                    <div key={index} className="col-sm-6 col-md-4">
                      <div className="form-group">
                        <Input
                          type={field?.type}
                          name={field?.name}
                          value={values[field?.name]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          labelText={field?.labelText}
                          customError={
                            touched[field?.name] && errors[field?.name]
                          }
                          errorMessage={errors[field?.name]}
                          errorClass={
                            touched[field?.name] && errors[field?.name]
                              ? "validationError"
                              : ""
                          }
                          requiredStar={field?.required}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <Input
                        type="date"
                        datePicker={true}
                        name="stafDob"
                        selected={values?.stafDob ? moment(values?.stafDob).toDate() : null}
                        dateFormat="dd/MM/yyyy"
                        onChange={(stafDob) => setFieldValue("stafDob", moment(stafDob).valueOf())}
                        onBlur={() => setFieldTouched("stafDob", true)} // Ensure Formik's touched state is set on blur
                        labelText="Date of Birth"
                        placeholderText="Select Start Date"
                        customError={touched?.stafDob && errors?.stafDob}
                        errorMessage={errors?.stafDob}
                        errorClass={touched?.stafDob && errors?.stafDob ? "validationError" : ""}
                        requiredStar={true}
                        showYearDropdown={true}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <Input
                        type="text"
                        id="niNumber"
                        name="niNumber"
                        value={values?.niNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Ni Numbers"
                        customError={
                          errors.niNumber && touched.niNumber
                        }
                        errorMessage={errors.niNumber}
                        errorClass={
                          errors.niNumber && touched.niNumber
                        }
                      />
                    </div>
                  </div>


                  {/* Address Fields */}
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <Input
                        type="text"
                        id="houseFlatNumber"
                        name="houseFlatNumber"
                        value={values?.houseFlatNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="House / Flat Number"
                        customError={errors.houseFlatNumber && touched.houseFlatNumber}
                        errorMessage={errors.houseFlatNumber}
                        errorClass={errors.houseFlatNumber && touched.houseFlatNumber}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <Input
                        type="text"
                        id="addressLine1"
                        name="addressLine1"
                        value={values?.addressLine1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Address Line 1"
                        customError={errors.addressLine1 && touched.addressLine1}
                        errorMessage={errors.addressLine1}
                        errorClass={errors.addressLine1 && touched.addressLine1}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <Input
                        type="text"
                        id="town"
                        name="town"
                        value={values?.town}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Town"
                        customError={errors.town && touched.town}
                        errorMessage={errors.town}
                        errorClass={errors.town && touched.town}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <Input
                        type="text"
                        id="city"
                        name="city"
                        value={values?.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="City"
                        customError={errors.city && touched.city}
                        errorMessage={errors.city}
                        errorClass={errors.city && touched.city}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <Input
                        type="text"
                        id="postcode"
                        name="postcode"
                        value={values?.postcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Postcode"
                        customError={errors.postcode && touched.postcode}
                        errorMessage={errors.postcode}
                        errorClass={errors.postcode && touched.postcode}
                      />
                    </div>
                  </div>
                  <div className="col-md-12"></div>
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group mb-2">
                      <CustomSelectCheckbox
                        name="isDriver"
                        value={isDriverCheckedState}
                        onChange={handleIsDriverCheckBox}
                        onBlur={handleBlur}
                        labelText={"Driving Status"}
                      />
                    </div>
                  </div>
                  {isDriverCheckedState ? (
                    <div className="col-sm-6 col-md-4">
                      <div className="form-group">
                        <div
                          className={`customSelectBox ${touched?.driverFields?.vehicleType &&
                            errors?.driverFields?.vehicleType
                            ? "validationError"
                            : ""
                            }`}
                        >
                          <>
                            <Select
                              className="basic-multi-select cursor-pointer"
                              classNamePrefix="select"
                              value={vehicleTypeOptions?.filter(
                                (option) =>
                                  option?.value ===
                                  values?.driverFields?.vehicleType
                              )}
                              options={vehicleTypeOptions}
                              onChange={(selectedOptions) => {
                                setFieldValue(
                                  "driverFields.vehicleType",
                                  selectedOptions?.value
                                );
                              }}
                              onBlur={handleBlur}
                              isSearchable
                              getOptionLabel={(selectedOptions) =>
                                selectedOptions?.label
                              }
                              getOptionValue={(selectedOptions) =>
                                selectedOptions?.value
                              }
                              components={{ Option: CustomOption }}
                            />
                            <label className="floatingLabel">
                              Vehicle Type
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            {touched?.driverFields?.vehicleType &&
                              errors?.driverFields?.vehicleType && (
                                <div className="text-danger">
                                  {errors?.driverFields?.vehicleType}
                                </div>
                              )}
                          </>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

              </div>
            </div>

            <div className="mainContent mt-3">
              <div className="mainContentText">
                {/* Other Detail */}
                <h3>Other Detail</h3>
                <div className="formBlockContent row">
                  <div className="col-sm-12 col-md-4">
                    <Input
                      type="date"
                      datePicker={true}
                      name="startDate"
                      selected={values?.staffWorkLoad?.startDate ? moment(values?.staffWorkLoad?.startDate).toDate() : null}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        setFieldValue("staffWorkLoad.startDate", date);

                        // Calculate the upcoming 31st March based on selected start date
                        const selectedDate = moment(date);
                        let upcomingMarch = moment(selectedDate).month(2).date(31); // March 31st of the same year

                        // If startDate is after March 31st of the same year, use March 31st of the next year
                        if (selectedDate.isAfter(upcomingMarch)) {
                          upcomingMarch = upcomingMarch.add(1, 'year');
                        }

                        // Set the calculated 31st March as end date
                        setFieldValue("staffWorkLoad.endDate", upcomingMarch.toDate());
                      }}
                      onBlur={() => setFieldTouched("staffWorkLoad.startDate", true)} // Ensure Formik's touched state is set on blur
                      labelText="Start Date"
                      placeholderText="Select Start Date"
                      customError={touched?.staffWorkLoad?.startDate && errors?.staffWorkLoad?.startDate}
                      errorMessage={errors?.staffWorkLoad?.startDate}
                      errorClass={touched?.staffWorkLoad?.startDate && errors?.staffWorkLoad?.startDate ? "validationError" : ""}
                      requiredStar={true}
                      showYearDropdown={true}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <Input
                      type="number"
                      name="contractedHours"
                      value={values?.contractedHours?.contractedHours}
                      onChange={(e) => {
                        setFieldValue(
                          "contractedHours.contractedHours",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Contract Hours"
                      customError={
                        touched?.contractedHours?.contractedHours &&
                        errors?.contractedHours?.contractedHours
                      }
                      errorMessage={errors?.contractedHours?.contractedHours}
                      errorClass={
                        touched?.contractedHours?.contractedHours &&
                          errors?.contractedHours?.contractedHours
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                  <ProtectedComponent requiredPermissions={[permissions.PAYROLL]}>
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="number"
                        name="perHourRate"
                        value={values?.contractedHours?.perHourRate}
                        onChange={(e) => {
                          setFieldValue(
                            "contractedHours.perHourRate",
                            e.target?.value
                          );
                        }}
                        onBlur={handleBlur}
                        labelText="Contracted Hours Rate"
                        customError={
                          touched?.contractedHours?.perHourRate &&
                          errors?.contractedHours?.perHourRate
                        }
                        errorMessage={errors?.contractedHours?.perHourRate}
                        errorClass={
                          touched?.contractedHours?.perHourRate &&
                            errors?.contractedHours?.perHourRate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="number"
                        name="overNightSleepRate"
                        value={values?.contractedHours?.overNightSleepRate}
                        onChange={(e) => {
                          setFieldValue(
                            "contractedHours.overNightSleepRate",
                            e.target?.value
                          );
                        }}
                        onBlur={handleBlur}
                        labelText="Over Night Sleep Rate"
                        customError={
                          touched?.contractedHours?.overNightSleepRate &&
                          errors?.contractedHours?.overNightSleepRate
                        }
                        errorMessage={errors?.contractedHours?.overNightSleepRate}
                        errorClass={
                          touched?.contractedHours?.overNightSleepRate &&
                            errors?.contractedHours?.overNightSleepRate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="number"
                        name="enhancedRate"
                        value={values?.contractedHours?.enhancedRate}
                        onChange={(e) => {
                          setFieldValue(
                            "contractedHours.enhancedRate",
                            e.target?.value
                          );
                        }}
                        onBlur={handleBlur}
                        labelText="Enhanced Rate"
                        customError={
                          touched?.contractedHours?.enhancedRate &&
                          errors?.contractedHours?.enhancedRate
                        }
                        errorMessage={errors?.contractedHours?.enhancedRate}
                        errorClass={
                          touched?.contractedHours?.enhancedRate &&
                            errors?.contractedHours?.enhancedRate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="number"
                        name="trainingRate"
                        value={values?.contractedHours?.trainingRate}
                        onChange={(e) => {
                          setFieldValue(
                            "contractedHours.trainingRate",
                            e.target?.value
                          );
                        }}
                        onBlur={handleBlur}
                        labelText="Training Rate"
                        customError={
                          touched?.contractedHours?.trainingRate &&
                          errors?.contractedHours?.trainingRate
                        }
                        errorMessage={errors?.contractedHours?.trainingRate}
                        errorClass={
                          touched?.contractedHours?.trainingRate &&
                            errors?.contractedHours?.trainingRate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="number"
                        name="overtimeRate"
                        value={values?.contractedHours?.overtimeRate}
                        onChange={(e) => {
                          setFieldValue(
                            "contractedHours.overtimeRate",
                            e.target?.value
                          );
                        }}
                        onBlur={handleBlur}
                        labelText="Overtime Rate"
                        customError={
                          touched?.contractedHours?.overtimeRate &&
                          errors?.contractedHours?.overtimeRate
                        }
                        errorMessage={errors?.contractedHours?.overtimeRate}
                        errorClass={
                          touched?.contractedHours?.overtimeRate &&
                            errors?.contractedHours?.overtimeRate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </ProtectedComponent>

                  {/* Staff Work Load */}
                  <div className="hiddenDiv">
                    <div className="col-sm-12 col-md-4">
                      <Input
                        type="date"
                        datePicker={true}
                        name="endDate"
                        selected={values?.staffWorkLoad?.endDate ? moment(values?.staffWorkLoad?.endDate).toDate() : null}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => setFieldValue("staffWorkLoad.endDate", date)}
                        onBlur={() => setFieldTouched("staffWorkLoad.endDate", true)} // Ensure Formik's touched state is set on blur
                        labelText="End Date"
                        placeholderText="Select End Date"
                        customError={touched?.staffWorkLoad?.endDate && errors?.staffWorkLoad?.endDate}
                        errorMessage={errors?.staffWorkLoad?.endDate}
                        errorClass={touched?.staffWorkLoad?.endDate && errors?.staffWorkLoad?.endDate ? "validationError" : ""}
                        showYearDropdown={true}
                      />
                    </div>

                    <div className="col-sm-12 col-md-4">
                      <Input
                        type="number"
                        name="numberOfDays"
                        value={
                          values?.staffWorkLoad?.startDate && values?.staffWorkLoad?.endDate
                            ? calculateDaysDifference(
                              values?.staffWorkLoad?.startDate,
                              values?.staffWorkLoad?.endDate
                            )
                            : ""
                        }
                        onChange={(e) => {
                          setFieldValue("staffWorkLoad.numberOfDays", e.target?.value);
                        }}
                        onBlur={handleBlur}
                        labelText="Total Days"
                        customError={
                          touched?.staffWorkLoad?.numberOfDays && errors?.staffWorkLoad?.numberOfDays
                        }
                        errorMessage={errors?.staffWorkLoad?.numberOfDays}
                        errorClass={
                          touched?.staffWorkLoad?.numberOfDays && errors?.staffWorkLoad?.numberOfDays
                            ? "validationError"
                            : ""
                        }
                        disabledStatus={true}
                        readOnly={true}
                        requiredStar={false}
                      />
                    </div>

                    <div className="col-sm-12 col-md-4">
                      <Input
                        type="number"
                        name="holidayAlwnNoOfHours"
                        value={values?.contractedHours?.contractedHours * 5.6 / 365 * values?.staffWorkLoad.numberOfDays}
                        onChange={(e) => {
                          setFieldValue("staffWorkLoad.holidayAlwnNoOfHours", e.target?.value);
                        }}
                        onBlur={handleBlur}
                        labelText="Holiday Hours"
                        customError={
                          touched?.staffWorkLoad?.holidayAlwnNoOfHours && errors?.staffWorkLoad?.holidayAlwnNoOfHours
                        }
                        errorMessage={errors?.staffWorkLoad?.holidayAlwnNoOfHours}
                        errorClass={
                          touched?.staffWorkLoad?.holidayAlwnNoOfHours && errors?.staffWorkLoad?.holidayAlwnNoOfHours
                            ? "validationError"
                            : ""
                        }
                        disabledStatus={true}
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <ProtectedComponent requiredPermissions={[permissions.EDIT]}>

                    {/* Other fields */}
                    <p>
                      <strong>Staff Role</strong>
                    </p>
                    <div className="col-sm-12 col-md-4">

                      <div className="form-group">
                        <div
                          className={`customSelectBox ${errors?.staffDesignation ? "validationError" : ""
                            }`}
                        >
                          <CreatableSelect
                            isClearable={true}
                            name="staffDesignation"
                            value={staffDesignationOptions?.find(
                              (option) =>
                                option?.value === values?.staffDesignation
                            )}
                            className="basic-multi-select cursor-pointer"
                            classNamePrefix="select"
                            options={staffDesignationOptions}
                            onChange={(selectedOption) => {
                              setFieldValue(
                                "staffDesignation",
                                selectedOption?.value
                              );
                            }}
                            isSearchable
                            getOptionLabel={(option) => option?.label}
                            getOptionValue={(option) => option?.value}
                            components={{ Option: CustomOption }}
                          />
                          <label className="floatingLabel">
                            Staff Roles
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          {errors?.staffDesignation && (
                            <div className="text-danger">
                              {errors?.staffDesignation}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">

                      <div className="form-group">
                        <div className="selectBoxLevel">
                          <span className="multiSelectCustomLabel">Staff Permissions</span>
                          <Select
                            name="staffRoles"
                            isMulti
                            options={staffRoleOptions}
                            value={selectedOptions}
                            onChange={handleSelectChangeStaffRole}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                          />
                          {errors.days && touched.days && (
                            <div className="text-danger">{errors.days}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    {isDesignationSupervisor && (

                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <CustomSelect
                            value={values?.supervisorId}
                            filterBy="supervisor"
                            onSelect={handleSupervisorSelect}
                            customError={
                              touched?.supervisorId && errors?.supervisorId
                            }
                            errorMessage={errors?.supervisorId}
                            errorClass={
                              touched?.supervisorId && errors?.supervisorId
                                ? "validationError"
                                : ""
                            }
                            label="Supervisor"
                            requiredStar={true}
                          />
                        </div>
                      </div>

                    )}
                    {isDesignationManager && (
                      <div className="col-sm-12 col-md-4">
                        <div className="form-group">
                          <CustomSelect
                            value={values?.managerId}
                            onSelect={handleManagerSelect}
                            filterBy="manager"
                            label="Manager"
                            customError={
                              touched?.managerId && errors?.managerId
                            }
                            errorMessage={errors?.managerId}
                            errorClass={
                              touched?.managerId && errors?.managerId
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                        </div>
                      </div>
                    )}
                  </ProtectedComponent>
                </div>
                <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                  <div className="row">
                    <p>
                      <strong>Supervision</strong>
                    </p>
                    <div className="col-sm-6 col-md-4">
                      <div className="form-group">
                        <CustomSelectSupervisionTemplates
                          onSelect={handleSupervisionTemplateSelect}
                          value={values?.supervision?.templateTitleId}
                          filterBy=""
                          label="Supervision Template"
                          customError={
                            touched?.supervision?.templateTitleId &&
                            errors?.supervision?.templateTitleId
                          }
                          errorMessage={errors?.supervision?.templateTitleId}
                          errorClass={
                            touched?.supervision?.templateTitleId &&
                              errors?.supervision?.templateTitleId
                              ? "validationError"
                              : ""
                          }
                        // requiredStar={true}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="date"
                        datePicker={true}
                        name="supervision.sprDueDate"
                        selected={values?.supervision?.sprDueDate ? moment(values?.supervision?.sprDueDate).toDate() : null}
                        dateFormat="dd/MM/yyyy"
                        onChange={(sprDueDate) => setFieldValue("supervision.sprDueDate", moment(sprDueDate).valueOf())}
                        onBlur={() => setFieldTouched("supervision.sprDueDate", true)} // Ensure Formik's touched state is set on blur
                        labelText="Supervision Due Date"
                        placeholderText="Supervision Due Date"
                        customError={touched?.supervision?.sprDueDate && errors?.supervision?.sprDueDate}
                        errorMessage={errors?.supervision?.sprDueDate}
                        errorClass={touched?.supervision?.sprDueDate && errors?.supervision?.sprDueDate ? "validationError" : ""}
                        // requiredStar={true}
                        showYearDropdown={true}
                      />
                    </div>
                  </div>
                </ProtectedComponent>
              </div>
            </div>
            {/* <div className="formBlockContent row">
                  <div className="col-sm-12 col-md-4">
                    <p>
                      <strong>COVID-19</strong>
                    </p>
                    <CustomSelectCheckbox
                      name="covidCheck"
                      value={values?.covidCheck}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelText={
                        isHealthCheckedState ? "Status Checked" : "Check"
                      }
                      customError={touched?.covidCheck && errors?.covidCheck}
                      errorMessage={errors?.covidCheck}
                      errorClass={
                        touched?.covidCheck && errors?.covidCheck
                          ? "validationError"
                          : ""
                      }
                    />
                  </div>
                </div> */}

            {/* Interview */}
            <div className="mainContent mt-3">
              <div className="mainContentText">
                <h3>Interview</h3>
                <div className="formBlockContent row">
                  {interviewFormFields.map((item, index) => (
                    <div key={index} className="col-sm-6 col-md-4">
                      <div className="form-group">
                        <input
                          name={item?.name}
                          id={item?.name}
                          type="file"
                          accept=".pdf, .doc, .docx"
                          onBlur={handleBlur}
                          onChange={(e) => handleFileUpload(e, item?.documentEnum, setInterviewUploadedDoc, "interviewDoc", item?.label)}
                        />
                        <label htmlFor={item?.name} className="floatingLabel">
                          {item?.label}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        {fileSizeError && (
                          <div className="validationError">
                            <div className="text-danger">
                              Error: File size exceeds the 1MB limit. Please upload a smaller file.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
            </div>

            {/* Documents Submit */}
            <div className="mainContent mt-3">
              <div className="mainContentText">
                <h3>Documents Submit</h3>
                <div className="formBlockContent row">
                  {
                    documentSubmitFormFields.map((item, index) => (
                      <div key={index} className="col-sm-6 col-md-4">
                        <div className="form-group">
                          <input
                            name={item?.name}
                            id={item?.name}
                            type="file"
                            accept=".pdf, .doc, .docx"
                            onChange={(e) => handleFileUpload(e, item?.documentEnum, setDocumentSubmitUploadedDoc, "documentSubmitDoc", item?.label)}
                          />
                          <label htmlFor={item?.name} className="floatingLabel">
                            {item?.label}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          {fileSizeError && (
                            <div className="validationError">
                              <div className="text-danger">
                                Error: File size exceeds the 1MB limit. Please upload a smaller file.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

            {/* DBS */}
            <div className="mainContent mt-3">
              <div className="mainContentText">
                <h3>DBS</h3>
                <div className="formBlockContent row">
                  {
                    DBSCertificateFormFields.map((item, index) => (
                      <div key={index} className="col-sm-6 col-md-4">
                        <div className="form-group">
                          <input
                            name={item?.name}
                            id={item?.name}
                            type="file"
                            accept=".pdf, .doc, .docx"
                            onChange={(e) => handleFileUpload(e, item?.documentEnum, setDBSCUploadedDoc, "dbsDoc", item?.label)}
                          />
                          <label htmlFor={item?.name} className="floatingLabel">
                            {item?.label}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          {fileSizeError && (
                            <div className="validationError">
                              <div className="text-danger">
                                Error: File size exceeds the 1MB limit. Please upload a smaller file.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

            {/* Disciplinary */}
            <div className="mainContent mt-3">
              <div className="mainContentText">
                <h3>Disciplinary</h3>
                <div className="formBlockContent row">
                  {disciplinaryFormFields.map((item, index) => (
                    <div key={index} className="col-sm-6 col-md-4">
                      <div className="form-group">
                        <input
                          name={item?.name}
                          id={item?.name}
                          type="file"
                          accept=".pdf, .doc, .docx"
                          onBlur={handleBlur}
                          onChange={(e) => handleFileUpload(e, item?.documentEnum, setDisciplinaryUploadedDoc, "disciplinaryDoc", item?.label)}
                        />
                        <label htmlFor={item?.name} className="floatingLabel">
                          {item?.label}
                        </label>
                        {fileSizeError && (
                          <div className="validationError">
                            <div className="text-danger">
                              Error: File size exceeds the 1MB limit. Please upload a smaller file.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
            </div>

            {/* Compliance Documents */}
            <div className="mainContent mt-3">
              <div className="mainContentText">
                <h3>Compliance Documents</h3>
                <div className="formBlockContent row">
                  {
                    complianceFormFields.map((item, index) => (
                      <div key={index} className="col-sm-6 col-md-4">
                        <div className="form-group">
                          <input
                            name={item?.name}
                            id={item?.name}
                            type="file"
                            accept=".pdf, .doc, .docx"
                            onChange={(e) => handleFileUpload(e, item?.documentEnum, setComplianceUploadedDoc, "complianceDoc", item?.label)}
                          />
                          <label htmlFor={item?.name} className="floatingLabel">
                            {item?.label}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          {fileSizeError && (
                            <div className="validationError">
                              <div className="text-danger">
                                Error: File size exceeds the 1MB limit. Please upload a smaller file.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

            {/* References */}
            <div className="mainContent mt-3">
              <div className="mainContentText">
                <h3>References</h3>
                <div className="formBlockContent row">
                  {referencesFormFields.map((item, index) => (
                    <div key={index} className="col-sm-6 col-md-4">
                      <div className="form-group">
                        <input
                          name={item?.name}
                          id={item?.name}
                          type="file"
                          accept=".pdf, .doc, .docx"
                          onBlur={handleBlur}
                          onChange={(e) => handleFileUpload(e, item?.documentEnum, setReferencesUploadedDoc, "refDoc", item?.label)}
                        />
                        <label htmlFor={item?.name} className="floatingLabel">
                          {item?.label}
                        </label>
                        {fileSizeError && (
                          <div className="validationError">
                            <div className="text-danger">
                              Error: File size exceeds the 1MB limit. Please upload a smaller file.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
            </div>



          </div>
          <div className="formActionButtons">
            {isFormSubmit ? (
              <Button type="button" className="btn-sm btn-primary" disabled>
                Loading...
              </Button>
            ) : (
              <>
                <Link
                  to="/staff-records"
                  className="btn btn-sm btn-outline-primary"
                >
                  Cancel
                </Link>
                <Button type="submit" className="btn-sm btn-primary">
                  Submit
                </Button>
              </>
            )}
          </div>
        </form>
      </Layout>
      {isStaffSingUpToast && <ToastContainer />}
    </>
  );
}

export default AddNewStaff;
