import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../../components/general/ProgressComponent";
import { BASE_URL, GET_ALL_STAFF_MEMBERS_URL } from "../../env";
import {
  getRecords,
  isSuccess,
  filterColumns,
  filterRowsByProperty,
  filterArrayBySearchTerm,
  catchAsync,
  displayEnumProperty,
  toastErrorMessages,
  formateDate,
} from "../../helper/helper";
import moment from "moment/moment";

function SupervisionGroup() {
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState();
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isToastSupervisionGroup, setIsToastSupervisionGroup] = useState(false);
  const navigate = useNavigate();
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastSupervisionGroup(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const staffMembers = data?.data?.staffMembers?.map((item) => item);
      const filteredRows = filterRowsByProperty(
        staffMembers,
        (row) => row?.user?.role
      );
      setUserData(filteredRows);
      setFilteredRows(filteredRows);
      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const handleSearch = (e) => {
    const searchVal = e?.target?.value;
    setSearch(searchVal);
    if (searchVal !== "") {
      const lowercaseSearch = searchVal?.toLowerCase();
      const filteredRecords = filterArrayBySearchTerm(
        filteredRows,
        lowercaseSearch,
        (record) => record?.user?.name?.toLowerCase()
      );
      setFilteredRows(filteredRecords);
    } else {
      setFilteredRows(userData);
    }
  };

  const handleTableRowClick = (row) => {
    navigate("/supervisions", { state: { userId: row } });
  };

  const handleRowClickSupervision = (e, row) => {
    e.preventDefault();
    navigate("/supervisions", { state: { userId: row } });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <span
          className="usersNameInfo"
          onClick={(e) => handleRowClickSupervision(e, row)}
        >
          <span className="userImage">
            <img
              src={`${BASE_URL}${row?.user?.profile_picture_url}`}
              alt={row?.name}
            />
          </span>
          <span className="userInfo">
            <span className="nameBlock">{`${row?.user?.name}`}</span>
          </span>
        </span>
      ),
      width: "30%",
      style: { whiteSpace: "normal" },
    },
    {
      name: "Supervisor Name",
      selector: (row) => (
        <div
          className="tableText"
          onClick={(e) => handleRowClickSupervision(e, row)}
        >
          <span>{row?.supervisorId?.staffMemberName}</span>
        </div>
      ),
      width: "30%",
    },
    {
      name: "Due Date",
      selector: (row) => {
        return (
          <span
            onClick={(e) => handleRowClickSupervision(e, row)}
            style={{ paddingLeft: "15px" }}
          >
            {moment(row?.sprDueDate).format("DD/MM/YYYY")}
          </span>
        );
      },
      width: "20%",
    },
    {
      name: "Status",
      selector: (row) => {
        const sprStatus = displayEnumProperty(
          "sprStatus",
          row?.supervision?.sprStatus
        );
        return (
          <div
            className={`tableText ${sprStatus === "Assigned"
              ? "warning"
              : sprStatus === "Completed"
                ? "success"
                : sprStatus === "Over Due"
                  ? "danger"
                  : ""
              }`}
            onClick={(e) => handleRowClickSupervision(e, row)}
          >
            <p className="blackColor text-center">{sprStatus}</p>
          </div>
        );
      },
      width: "20%",
      center: true,
    },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== null);

  return (
    <>
      <div className="pbsPlan supervisionBlocks">
        <div className="mainContent">
          <div className="dataTableWrapper">
            <DataTable
              className="maxWidthTable"
              columns={filteredColumns}
              data={filteredRows}
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationPerPage={10}
              fixedHeader
              fixedHeaderScrollHeight="600px"
              highlightOnHover
              // actions={
              //   <>
              //     <button className="btn btn-sm btn-outline-primary">
              //       Export Sheet
              //     </button>{" "}
              //   </>
              // }
              responsive={true}
              subHeader={true}
              subHeaderComponent={
                <div className="searchContainer">
                  <div className="searchTextBox">
                    <input
                      type="text"
                      placeholder="Type your search"
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              }
              subHeaderAlign="left"
              subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
              progressPending={isLoading}
              progressComponent={<ProgressComponent />}
              onRowClicked={handleTableRowClick}
            />
          </div>
        </div>
      </div>
      {isToastSupervisionGroup && <ToastContainer />}
    </>
  );
}

export default SupervisionGroup;
