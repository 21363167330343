import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelectBox from "../../components/forms-fields/SelectBox";
import Select from "react-select";

import Layout from "../../components/layouts/Layout";
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import TextArea from "../../components/forms-fields/TextArea";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment/moment";
import {
  ADMIN_AUTH_TOKEN,
  ADMIN_ID,
  POST_RECRUITMENT_CANDIDATE_URL,
} from "../../env";
import {
  postRecords,
  isSuccess,
  catchAsync,
  toastSuccessMessages,
  toastErrorMessages,
  MAX_FILE_SIZE,
} from "../../helper/helper";
import {
  CustomOption,
  candidateStatusOptions,
  genderOptions,
} from "../../helper/helperJsx";

function AddNewCandidates() {
  const navigate = useNavigate();
  const [isToastAddNewCandidates, setIsToastAddNewCandidates] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [formFields, setFormFields] = useState([
    { name: "firstName", label: "First Name" },
    { name: "lastName", label: "Last Name" },
    { name: "dob", label: "Date of Birth" },
    { name: "email", label: "Email" },
    { name: "phone", label: "Phone number" },
    { name: "addedby", label: "Added By" },
    { name: "status", label: "Status" },
    { name: "gender", label: "Gender" },
    { name: "otherDetails", label: "Other Details" },
    { name: "dp", label: "Profile Picture" },
  ]);

  const generateValidationSchema = (fields) => {
    const validationObject = {};

    fields?.forEach((field) => {
      // if (field?.name === "dp") {
      //   validationObject[field?.name] = Yup.mixed()
      //     .required(`${field?.label} is required`)
      //     .test("fileSize", "File size is too large", (value) => {
      //       return value ? value?.size <= MAX_FILE_SIZE : true;
      //     });
      // } else {
      // } else {s
      validationObject[field?.name] = Yup.string().required(
        `${field?.label} is required`
      );
    });

    return Yup.object().shape(validationObject);
  };

  const validationSchema = generateValidationSchema(formFields);

  const formikFormData = useFormik({
    initialValues: {
      dp: "/uploads/dp/default.png",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dob: moment().valueOf(),
      status: "new",
      active: true,
      gender: "male",
      otherDetails: "",
      addedby: ADMIN_ID,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFormSubmit(true);
        console.log("🚀 ~ onSubmit: ~ values:", values)
        await addNewCandidate(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setValues,
    resetForm,
  } = formikFormData;

  const processFetchRecords = (data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddNewCandidates(true);
      toastErrorMessages(toast?.error, data?.message, "", 1500, 1500);
      setIsFormSubmit(false);
    } else if (data?.status === isSuccess) {
      // navigate("/recruitment");
      setIsToastAddNewCandidates(true);
      toastSuccessMessages(toast?.success, data?.message, "/recruitment", 500, 500);
      setIsFormSubmit(false);
    }
  };
  const addNewCandidate = catchAsync(async (data) => {
    try {
      const formData = new FormData();
      for (const key in data) {
        formData?.append(key, data[key]);
      }
      const response = await fetch(POST_RECRUITMENT_CANDIDATE_URL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
        },
        body: formData,
      });
      const result = await response.json();
      processFetchRecords(result);
      setIsToastAddNewCandidates(true)
    } catch (error) {
      console.error("Error adding new candidate:", error);
    }
  });

  const handleDateChangeToNumeric = (e) => {
    const name = e.target?.name;
    const dateValue = e.target?.value;
    const dateObject = new Date(dateValue);
    const millisecondsSinceEpoch = dateObject?.getTime();
    setFieldValue(name, millisecondsSinceEpoch);
  };

  return (
    <>
      <Layout pageTitleText="Recruitment">
        <form
          onSubmit={handleSubmit}
          method="post"
          encType="multipart/form-data"
        >
          <div className="incidents consentCapacityAddNew">
            <div className="mainContent">
              <div className="mainContentText">
                <h3>Add New Candidate</h3>
                <div className="formBlockContent">
                  <section>
                    <p>
                      <strong>Personal Details</strong>
                    </p>

                    <div className="row">
                      <section className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <Input
                              type="file"
                              name={"dp"}
                              accept={"image/*"}
                              onChange={(event) => {
                                setFieldValue(
                                  "dp",
                                  event.currentTarget?.files[0]
                                );
                              }}
                              onBlur={handleBlur}
                              labelText="Profile Picture"
                              customError={touched?.dp && errors?.dp}
                              errorMessage={errors?.dp}
                              errorClass={
                                touched?.dp && errors?.dp
                                  ? "validationError"
                                  : ""
                              }
                              requiredStar={true}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <Input
                              type="email"
                              id="email"
                              name="email"
                              value={values?.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              labelText="Email"
                              customError={touched?.email && errors?.email}
                              errorMessage={errors?.email}
                              errorClass={
                                touched?.email && errors?.email
                                  ? "validationError"
                                  : ""
                              }
                              requiredStar={true}
                            />
                          </div>
                        </div>
                      </section>
                      <section className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <Input
                              type="text"
                              name="firstName"
                              value={values?.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              labelText="First Name"
                              customError={
                                touched?.firstName && errors?.firstName
                              }
                              errorMessage={errors?.firstName}
                              errorClass={
                                touched?.firstName && errors?.firstName
                                  ? "validationError"
                                  : ""
                              }
                              requiredStar={true}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <Input
                              type="text"
                              name="lastName"
                              value={values?.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              labelText="Last Name"
                              customError={
                                touched?.lastName && errors?.lastName
                              }
                              errorMessage={errors?.lastName}
                              errorClass={
                                touched?.lastName && errors?.lastName
                                  ? "validationError"
                                  : ""
                              }
                              requiredStar={true}
                            />
                          </div>
                        </div>
                      </section>

                      <div className="row">
                        <section className="col-sm-12 col-md-6">
                          <p>
                            <strong>Date Of Birth</strong>
                          </p>
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <div className="form-group">
                                <Input
                                  type="date"
                                  datePicker={true}
                                  name="dob"
                                  selected={values?.dob ? moment(values?.dob).toDate() : null}
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(dob) => setFieldValue("dob", moment(dob).valueOf())}
                                  onBlur={() => setFieldTouched("dob", true)} // Ensure Formik's touched state is set on blur
                                  labelText="Date of Birth"
                                  placeholderText="Select Start Date"
                                  customError={touched?.dob && errors?.dob}
                                  errorMessage={errors?.dob}
                                  errorClass={touched?.dob && errors?.dob ? "validationError" : ""}
                                  requiredStar={true}
                                  showYearDropdown={true}
                                />
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="col-sm-12 col-md-6">
                          <p>
                            <strong>Phone</strong>
                          </p>
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <div className="form-group">
                                <Input
                                  type="tel"
                                  name="phone"
                                  value={values?.phone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  labelText="Phone number"
                                  customError={touched?.phone && errors?.phone}
                                  errorMessage={errors?.phone}
                                  errorClass={
                                    touched?.phone && errors?.phone
                                      ? "validationError"
                                      : ""
                                  }
                                  requiredStar={true}
                                />
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <section className="row">
                        <section className="col-sm-12 col-md-6">
                          <p>
                            <strong>Gender</strong>
                          </p>
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <div className="form-group">
                                <div
                                  className={`customSelectBox ${errors?.gender ? "validationError" : ""
                                    }`}
                                >
                                  <Select
                                    name="gender"
                                    value={genderOptions?.find(
                                      (option) =>
                                        option?.value === values?.gender
                                    )}
                                    className="basic-multi-select cursor-pointer"
                                    classNamePrefix="select"
                                    options={genderOptions}
                                    onChange={(selectedOption) => {
                                      setFieldValue(
                                        "gender",
                                        selectedOption?.value
                                      );
                                    }}
                                    isSearchable
                                    getOptionLabel={(option) => option?.label}
                                    getOptionValue={(option) => option?.value}
                                    components={{ Option: CustomOption }}
                                  />
                                  <label className="floatingLabel">
                                    Gender
                                    <span className="text-danger">*</span>
                                  </label>
                                  {errors?.gender && (
                                    <div className="text-danger">
                                      {errors?.gender}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="col-sm-12 col-md-6">
                          <p>
                            <strong>Status</strong>
                          </p>
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <div className="form-group">
                                <div
                                  className={`customSelectBox ${errors?.status ? "validationError" : ""
                                    }`}
                                >
                                  <Select
                                    name="status"
                                    value={candidateStatusOptions?.find(
                                      (option) =>
                                        option?.value === values?.status
                                    )}
                                    className="basic-multi-select cursor-pointer"
                                    classNamePrefix="select"
                                    options={candidateStatusOptions}
                                    onChange={(selectedOption) => {
                                      setFieldValue(
                                        "status",
                                        selectedOption?.value
                                      );
                                    }}
                                    isSearchable
                                    getOptionLabel={(option) => option?.label}
                                    getOptionValue={(option) => option?.value}
                                    components={{ Option: CustomOption }}
                                  />
                                  <label className="floatingLabel">
                                    Status
                                    <span className="text-danger">*</span>
                                  </label>
                                  {errors?.status && (
                                    <div className="text-danger">
                                      {errors?.status}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </section>

                      <section>
                        <p>
                          <strong>Other Details</strong>
                        </p>
                        <div className="row">
                          <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                              <TextArea
                                name="otherDetails"
                                value={values?.otherDetails}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                cols={5}
                                rows={5}
                                labelText="Details"
                                customError={
                                  touched?.otherDetails && errors?.otherDetails
                                }
                                errorMessage={errors?.otherDetails}
                                errorClass={
                                  touched?.otherDetails && errors?.otherDetails
                                    ? "validationError"
                                    : ""
                                }
                                requiredStar={true}
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div className="formActionButtons">
            <Link to="/recruitment" className="btn btn-sm btn-outline-primary">
              Cancel
            </Link>
            {isFormSubmit ? (
              <Button className="btn-sm btn-primary" disabled>
                Loading...
              </Button>
            ) : (
              <Button type="submit" className="btn-sm btn-primary">
                Submit
              </Button>
            )}
          </div>
        </form>
      </Layout>
      <ToastContainer />
    </>
  );
}

export default AddNewCandidates;
