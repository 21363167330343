import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../../components/general/ProgressComponent";
import StatusUpdated from "../../components/general/StatusUpdated";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import {
  BASE_URL,
  ADMIN_ID,
  GET_STAFFS_HOLIDAYS_DETAILS_URL,
  PUT_HOLIDAY_STAFF_MEMBER_URL,
} from "../../env";
import {
  getRecords,
  updateRecords,
  isSuccess,
  formateDate,
  calculateHours,
  filterColumns,
  filterRowsByProperty,
  displayEnumProperty,
  catchAsync,
  toastErrorMessages,
  toastSuccessMessages,
} from "../../helper/helper";
import moment from "moment/moment";

function AbsenceLeave() {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [isSuccessReturn, setIsSuccessReturn] = useState(false);
  const [isToastAbsenceLeaves, setIsToastAbsenceLeaves] = useState(false);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      staffRequesterFields: "_id email name profile_picture_url",
      staffMemberFields: "staffMemberName",
      usersFields: "profile_picture_url",
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAbsenceLeaves(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
   
      const userData = data?.data?.staffHolidayRequests?.filter((item) => item.hldRequestType !== "holiday");
      setIsLoading(false);
      setUserData(userData);
    } else if (method === "update" && data?.status === isSuccess) {
      fetchAllRecords();
      setIsToastAbsenceLeaves(true);
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    try {
      const response = await getRecords(
        GET_STAFFS_HOLIDAYS_DETAILS_URL,
        apiQuery
      );
      processFetchRecords("get", response);
    } catch (error) {
      console.error("Error fetching staff holidays:", error);
    }
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const updateStaffHoliday = catchAsync(async (req, res) => {
    try {
      // console.log("🚀 ~ updateStaffHoliday ~ req:", req)
      const response = await updateRecords(PUT_HOLIDAY_STAFF_MEMBER_URL, req);
      processFetchRecords("update", response);
    } catch (error) {
      console.error("Error updating staff holidays:", error);
    }
  });
  const [holidayRequestStatus, setHolidayRequestStatus] = useState(null);
  useEffect(() => {
    const updateHoliday = async () => {
      if (holidayRequestStatus !== null) {  // Only run if there's a valid status
        console.log("🚀 ~ HolidaysGroup ~ holidayRequestStatus:", holidayRequestStatus);
        await updateStaffHoliday(holidayRequestStatus);
        fetchAllRecords();
      }
    };
    updateHoliday();
    // updateStaffHoliday(holidayRequestStatus);
  }, [holidayRequestStatus]);
  // Update Status of the Shifts
  const subModalUpdateRosterUserShift = (onChanageReject, shiftData) => {
    setHolidayRequestStatus({
      staffHolidayRequestid: shiftData?._id,
      hldRqStartDate: shiftData?.hldRqStartDate,
      hldRqEndDate: shiftData?.hldRqEndDate,
      hldRqTotalDays: shiftData?.hldRqTotalDays,
      hldRqTotalHours: shiftData?.hldRqTotalHours,
      hldRqStatus: onChanageReject === "rejected" ? "rejected" : onChanageReject === "approved" ? "approved" : "pending",
      approvedBy: shiftData?.approvedBy,
      staffRequester: shiftData?.staffRequester,
      active: shiftData?.active,
    })
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => <span>{index + 1}</span>,
      width: "5%",
      center: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <span className="usersNameInfo">
          <span className="userImage">
            <img
              src={`${BASE_URL}${row?.staffRequester?.profile_picture_url}`}
              alt={row?.staffRequester?.name}
            />
          </span>
          <span className="userInfo">
            <span className="nameBlock">{row?.staffRequester?.name}</span>
          </span>
        </span>
      ),
      width: "20%",
    },
    {
      name: "Dates",
      selector: (row) => {
        const formatEdDate = formateDate(row?.createdAt, "m/d");
        const formatEdHours = calculateHours(
          row?.hldRqStartDate,
          row?.hldRqEndDate
        );
        return (
          <span className={`tableText ${row?.hldRqStatus === "rejected" ? "danger" : row?.hldRqStatus === "approved" ? "success" : "warning"}`}>
            From: {moment(row?.hldRqStartDate).format("DD/MM/YYYY")}<br />
            To: {moment(row?.hldRqEndDate).format("DD/MM/YYYY")}

          </span>
        );
      },
      width: "200px",
    },
    {
      name: "Type",
      selector: (row) =>
        displayEnumProperty("hldRequestType", row?.hldRequestType),
      center: true,
    },
    {
      name: "Hours Remaining",
      selector: (row) => {
        const remainingHours = row?.staffRequester?.modelId?.staffWorkLoads?.[0]?.holidayHoursRemaining || 0;
        return <span>{`${remainingHours} hours`}</span>;
      },
      center: true,
    },
    {
      name: "Reason",
      selector: (row) => {
        return row?.hldRqReason !== "" ? row?.hldRqReason : "Holiday";
      },
    },
    {
      name: "Status",
      selector: (row) => (
        <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
           <StatusUpdated
              initialStatus={row?.hldRqStatus}
              isSuccessReturn={isSuccessReturn}
              onChange={(e) => subModalUpdateRosterUserShift(e.target.value, row)}
            />
          {/* {row?.hldRqStatus !== "pending" ? (
            <span className={`statusButton ${row?.hldRqStatus === "rejected" ? "background-danger" : row?.hldRqStatus === "approved" ? "background-success" : "background-warning"}`}>
              {row?.hldRqStatus}</span>
          ) : (
            <StatusUpdated
              initialStatus={row?.hldRqStatus}
              isSuccessReturn={isSuccessReturn}
              onChange={(e) => subModalUpdateRosterUserShift(e.target.value, row)}
            />
          )
          } */}
        </ProtectedComponent>
      ),
      width: "12%",
      center: true,
    },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== null);
  const filteredRows = filterRowsByProperty(
    userData,
    (row) => row?.staffRequester?.name && row?.hldRqStatus !== undefined
  );

  return (
    <div className="mainContent rowClickUnset absenceLeave">
      <h3>Absence & Leaves</h3>
      <div className="dataTableWrapper">
        <DataTable
          className="maxWidthTable minWidthTable"
          columns={filteredColumns}
          data={filteredRows}
          pagination
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationPerPage={10}
          fixedHeader
          fixedHeaderScrollHeight="470px"
          highlightOnHover
          responsive={true}
          progressPending={isLoading}
          progressComponent={<ProgressComponent />}
        />
      </div>
      {isToastAbsenceLeaves && <ToastContainer />}
    </div>
  );
}

export default AbsenceLeave;
