import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Layout from "../../../../../../components/layouts/Layout";
import ProgressComponent from "../../../../../../components/general/ProgressComponent";
import editBlock from "../../../../../../assets/images/icons/editBlock.svg";
import eventDel from "../../../../../../assets/images/icons/eventDel.svg";
import password_eye_icon_circle from "../../../../../../assets/images/icons/password_eye_icon_circle.svg";
import ProtectedComponent from '../../../../../../permissions/ProtectedComponent';
import { permissions } from '../../../../../../permissions/roles';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactHtmlParser from 'html-react-parser';
import moment from 'moment';
import {
  GET_CARE_PLAN_URL,
  DELETE_CARE_PLAN_URL,
} from "../../../../../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
  ExportButton,
  pdfStyles
} from "../../../../../../helper/helper";
import UserDetailWithStatus from "../../../../../../components/user-detail/UserDetailWithStatus";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from 'react-bootstrap/Dropdown';
// BlobProvider, Canvas, Circle, ClipPath, Defs, Document, Ellipse, Font, G, Image, Line, LinearGradient, Link, Note, PDFDownloadLink, PDFViewer, Page, Path, Polygon, Polyline, RadialGradient, Rect, Stop, StyleSheet, Svg, Text, TextInstance, Tspan, View, createRenderer, default, pdf, render, renderToBuffer, renderToFile, renderToStream, renderToString, usePDF, version
import { Document, Page, Text, View, PDFDownloadLink } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
function WeightHeight() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [userData, setUserData] = useState([]);
  const apiQuery = {
    query: {
      critarion: {
        userId: userDetails?._id,
      },
    },
    sortproperty: "eventDateTime",
    sortorder: -1
  };
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [careNoteIdData, setCareNoteIdData] = useState({
    id: null,
  });
  const [carePlanDetail, setCarePlansDetail] = useState();
  const [isCarePlan, setIsCarePlan] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [pdfDocumentTitle, setPdfDocumentTitle] = useState("");
  const navigate = useNavigate();

  // View Care Plan Detail Modal Popup
  const [showViewCarePlan, setShowViewCarePlan] = useState(false);
  const subModalViewCarePlans = (details) => {
    setCarePlansDetail(details);
    setShowViewCarePlan(!showViewCarePlan);
  };

  // Modal Popup

  const [showDeleteCarePlan, setShowDeleteCarePlan] = useState(false);
  const subModalDeleteCareNotes = (careNoteDelId) => {
    setCareNoteIdData({ id: careNoteDelId });
    setShowDeleteCarePlan(!showDeleteCarePlan);
    fetchAllRecords();
  };

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const careNoteData = data?.data?.carePlans?.filter((item) => item.active === true && item.isHTML === true && item.noteType === "WeightHeight");
      console.log("🚀 ~ processFetchRecords ~ careNoteData:", careNoteData)
      setUserData(careNoteData);
      setFilterSearch(careNoteData);
      setIsLoading(false);
      setPdfDocumentTitle("WeightHeight")
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 10, 10);
      setTimeout(() => {
        setIsCarePlan(false);
      }, 2000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_CARE_PLAN_URL, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // Table Columns
  const columns = [
    {
      name: "Date / Time",
      selector: (row) => {
        return <p>{moment(row.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}</p>;
      },
      width: "20%",
      wrap: true
    },

    {
      name: "Entered By",
      selector: (row) => {
        return (
          <p>
            <strong>{row?.addedby?.name}</strong>
            <br />
            {moment(row.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}
          </p>
        );
      },
      width: "30%",
      wrap: true,
    },

    {
      name: "Title",
      selector: (row) => {
        return (
          <>
            {row?.title}
          </>
        );
      },
      width: "40%",
      wrap: true,
      center: false
    },

    {
      name: "",
      selector: (row) => {
        return (
          <div className="actionColumns individualReviewsActions">
            <span className="edit" onClick={() => {
              subModalViewCarePlans(row);
            }}>
              <img src={password_eye_icon_circle} alt="View" title="View" />
            </span>
            <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
              <span
                className="edit"
                onClick={() => {
                  editGeneralNotes(row);
                }}
              >
                <img src={editBlock} alt="Edit" title="Edit" />
              </span>
            </ProtectedComponent>
            <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
              <span
                className="delete"
                onClick={() => {
                  subModalDeleteCareNotes(row?._id);
                }}
              >
                <img src={eventDel} alt="Delete" title="Delete" />
              </span>
            </ProtectedComponent>
          </div>
        );

      },
      center: true,
      width: "10%",
      wrap: true,
    },

  ];


  // Delete Care Notes
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (careNoteIdData !== "") {
      const response = await deleteRecords(
        DELETE_CARE_PLAN_URL,
        careNoteIdData
      );
      console.log("🚀 ~ deleteRecord ~ response:", response)
      setIsCarePlan(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteCarePlan(!showDeleteCarePlan);
    } else {
      setShowDeleteCarePlan(!showDeleteCarePlan);
      setIsSubmitButtonClicked(false);
      setIsCarePlan(true);
      toastErrorMessages(toast.error, "Unable to remove", "", 10);
    }
  });

  useEffect(() => {
    setTimeout(() => {
      setIsCarePlan(false);
    }, 2000);
  }, [isCarePlan])


  useEffect(() => {
    const result = userData.filter((user) => {
      const eventDatTimeLive = moment(user?.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")
      return eventDatTimeLive.toLowerCase().match(search.toLowerCase());
    });
    setFilterSearch(result);
  }, [search, userData]);

  const editGeneralNotes = (item) => {
    navigate("/care-plan/edit-weight-height-notes", { state: { editCarePlanDetail: [item] } });
  };

  // Exporting to PDF
  const MyDocument = () => (
    <Document>
      <Page size="A4" orientation="landscape" style={pdfStyles.pages}>
        <View>
          <Text style={pdfStyles.sectionTitle}>Care Plan Note - {pdfDocumentTitle.replace(/([a-z])([A-Z])/g, '$1 $2')}</Text>
          <Text style={pdfStyles.sectionSubTitle}>Service User - {userDetails?.modelId?.suFirstMiddleName} {userDetails?.modelId?.suLastName}</Text>
          <View style={pdfStyles.tableHead}>
            <Text style={{ width: "25%" }}>Event Date / Time</Text>
            <Text style={{ width: "25%" }}>Entered By</Text>
            <Text style={{ width: "50%" }}>Note Details</Text>
          </View>
          {filterSearch.map((row, index) => (
            <View key={index} style={pdfStyles.tabBody}>
              <Text style={{ width: "25%" }}>{moment(row.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}</Text>
              <Text style={{ width: "25%" }}>
                {row.addedby.name}
                {"\n"}
                {moment(row.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}
              </Text>
              <Text style={[pdfStyles.wordwrap, { width: "50%" }]}>
                {row.noteDetails.replace(/<\/p>/g, '\n\n').replace(/<[^>]+>/g, '')}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
  return (
    <>
      <Layout pageTitleText="Health Weight / Height">
        <UserDetailWithStatus careNotesOptions={true} />
        {/* <PDFViewer style={{ width: "100%", height: "300px" }}>
          <MyDocument />
        </PDFViewer> */}
        <div className="careNotes">
          <div className="dataTableWrapper exportDataTable">
            <DataTable
              className="maxWidthTable minWidthTable"
              columns={columns}
              data={filterSearch}
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationPerPage={10}
              fixedHeader
              fixedHeaderScrollHeight="600px"
              highlightOnHover
              actions={
                <>
                  <Link
                    to={"/care-plan/add-weight-height"}
                    className="btn btn-sm btn-primary mt-2"
                  >
                    Add Care Note
                  </Link>
                  {
                    filterSearch.length > 0 && (
                      <Dropdown>
                        <Dropdown.Toggle variant="outline-success" size="sm" id="dropdown-basic">
                          Export Data
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="exportDataList">
                          <>
                            <span>
                              <PDFDownloadLink document={<MyDocument columns={columns} data={filterSearch} />} fileName={userDetails?.modelId?.suFirstMiddleName + userDetails?.modelId?.suLastName + " - " + pdfDocumentTitle.replace(/([a-z])([A-Z])/g, '$1 $2') + " - Care Notes"}>
                                {({ loading }) =>
                                  loading ? '...' : 'Export as PDF'
                                }
                              </PDFDownloadLink>
                            </span>
                            <span>
                              <ExportButton data={filterSearch} patientName={userDetails?.modelId?.suFirstMiddleName + userDetails?.modelId?.suLastName + " - " + pdfDocumentTitle.replace(/([a-z])([A-Z])/g, '$1 $2') + " - Care Notes"} removeMoreColumns={["active", "title", "flag", "isHTML", "updatedAt"]} />
                            </span>
                          </>

                        </Dropdown.Menu>
                      </Dropdown>
                    )
                  }
                </>
              }
              responsive={true}
              subHeader={true}
              subHeaderComponent={
                <div className="searchContainer">
                  <div className="searchTextBox">
                    <input
                      type="text"
                      placeholder="Search by Date"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              }
              subHeaderAlign="left"
              subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
              progressPending={isLoading}
              progressComponent={<ProgressComponent />}
            />
          </div>
        </div>
      </Layout>

      {/* View Care Notes */}
      <Modal
        show={showViewCarePlan}
        onHide={subModalViewCarePlans}
        className="ftcModalPopup medicineInventoryStock"
      >
        <Modal.Header closeButton>
          <Modal.Title>Health Issue Weight / Height</Modal.Title>
        </Modal.Header>
        <Modal.Body className="careNotesPopup specificFormat">
          <div className="row pt-3">
            <div className="col-md-12">
              <p>
                <strong>{carePlanDetail?.addedby?.name}</strong>
                <small>{moment(carePlanDetail?.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}</small></p>
            </div>

            {ReactHtmlParser(String(carePlanDetail?.noteDetails))}
          </div>




        </Modal.Body>
      </Modal>


      {/* Delete Care Note */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeleteCarePlan}
          onHide={subModalDeleteCareNotes}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this record?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteCareNotes}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {isCarePlan && <ToastContainer />}
    </>
  );
}

export default WeightHeight