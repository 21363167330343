import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import Layout from "../../../../components/layouts/Layout";
import ProgressComponent from "../../../../components/general/ProgressComponent";
import editBlock from "../../../../assets/images/icons/editBlock.svg";
import eventDel from "../../../../assets/images/icons/eventDel.svg";
import humanBody from "../../../../assets/images/humanBody.svg";
import circleHealthIssue from "../../../../assets/images/icons/circleHealthIssue.svg";
import password_eye_icon_circle from "../../../../assets/images/icons/password_eye_icon_circle.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import ReactHtmlParser from 'html-react-parser';
import Table from 'react-bootstrap/Table';
import {
  GET_CARE_DAILY_SUMMARY_URL,
  DELETE_CARE_PLAN_URL,
} from "../../../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
  ExportButton,
  stripHtmlTagsAndSlice
} from "../../../../helper/helper";
import UserDetailWithStatus from "../../../../components/user-detail/UserDetailWithStatus";
import { Link, useNavigate } from "react-router-dom";
// BlobProvider, Canvas, Circle, ClipPath, Defs, Document, Ellipse, Font, G, Image, Line, LinearGradient, Link, Note, PDFDownloadLink, PDFViewer, Page, Path, Polygon, Polyline, RadialGradient, Rect, Stop, StyleSheet, Svg, Text, TextInstance, Tspan, View, createRenderer, default, pdf, render, renderToBuffer, renderToFile, renderToStream, renderToString, usePDF, version
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Line, Image, Svg } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Input from "../../../../components/forms-fields/Input";

import Angry from "../../../../assets/images/mood-rating/Angry.png";
import Bored from "../../../../assets/images/mood-rating/Bored.png";
import Calm from "../../../../assets/images/mood-rating/Calm.png";
import Confident from "../../../../assets/images/mood-rating/Confident.png";
import Excited from "../../../../assets/images/mood-rating/Excited.png";
import Happy from "../../../../assets/images/mood-rating/Happy.png";
import Hopeful from "../../../../assets/images/mood-rating/Hopeful.png";
import Nervous from "../../../../assets/images/mood-rating/Nervous.png";
import Proud from "../../../../assets/images/mood-rating/Proud.png";
import Relaxed from "../../../../assets/images/mood-rating/Relaxed.png";
import Sad from "../../../../assets/images/mood-rating/Sad.png";
import Scared from "../../../../assets/images/mood-rating/Scared.png";
import Tired from "../../../../assets/images/mood-rating/Tired.png";
import Worried from "../../../../assets/images/mood-rating/Worried.png";
import MoodImageComponent from "./MoodImageComponent";
import { TextDecrease } from "@mui/icons-material";
import MoodImagePDF from "./MoodImagePDF";

import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import '@progress/kendo-theme-default/dist/all.css';
import DateInput from "../../../../components/forms-fields/DateInput";




function DailyData({
  titleandButton,
  dailySummaryDateProp
}) {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  console.log("🚀 ~ DailyData ~ userDetails:", userDetails)
  const [carePlansMorning, setCarePlansMorning] = useState([]);
  const [carePlansEvening, setCarePlansEvening] = useState([]);
  const [carePlansNight, setCarePlansNight] = useState([]);
  const [moodRatingMorning, setMoodRatingMorning] = useState([]);
  const [moodRatingEvening, setMoodRatingEvening] = useState([]);
  const [moodRatingNight, setMoodRatingNight] = useState([]);
  const [incidentRecordsMorning, setIncidentRecordsMorning] = useState([]);

  const [incidentRecordsEvening, setIncidentRecordsEvening] = useState([]);

  const [incidentRecordsNight, setIncidentRecordsNight] = useState([]);
  useEffect(() => {
    console.log("🚀 ~ processFetchRecords ~ incidentRecordsNight:", incidentRecordsNight)
  }, [incidentRecordsNight])
  const [careNotes8to14, setCareNotes8to14] = useState()
  // const [moodRatingRecords, setMoodRatingRecords] = useState([]);
  const [startDate, setStartDate] = useState(moment().valueOf())
  const [endDate, setEndDate] = useState(null)
  const [filterDateError, setFilterDateError] = useState(false)
  const [dailySummaryDate, setDailySummaryDate] = useState(moment().valueOf())
  const [timeOfWaking, setTimeOfWaking] = useState("07:00 AM")
  const [timeOfRetiringToRoom, setTimeOfRetiringToRoom] = useState("11:00 PM")


  const getInitials = (fullName) => {
    const names = fullName.split(" ");
    const initials = names.map((name) => name[0].toUpperCase()).join(".");
    return initials;
  };

  useEffect(() => {
    endDate < startDate ? setFilterDateError(true) : setFilterDateError(false);
  })

  const [defaultDate, setDefaultDate] = useState(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  });

  const [apiQuery, setApiQuery] = useState({
    userId: userDetails?._id,
    currentDate: moment(defaultDate).valueOf() //1715540400000 //moment(defaultDate).valueOf(), // moment().valueOf() // 1715108400000
  });

  const [searchNoteDetail, setSearchNoteDetail] = useState("");
  const [incidentsRecords, setIncidentsRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [careNoteIdData, setCareNoteIdData] = useState({
    id: null,
  });
  const [carePlanDetail, setCarePlansDetail] = useState();
  const [isCarePlan, setIsCarePlan] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const navigate = useNavigate();

  // View Care Plan Detail Modal Popup
  const [showViewCarePlan, setShowViewCarePlan] = useState(false);
  const subModalViewCarePlans = (details) => {
    setCarePlansDetail(details);
    setShowViewCarePlan(!showViewCarePlan);
  };

  // Modal Popup

  const [showDeleteCarePlan, setShowDeleteCarePlan] = useState(false);
  const subModalDeleteCareNotes = (careNoteDelId) => {
    setCareNoteIdData({ id: careNoteDelId });
    setShowDeleteCarePlan(!showDeleteCarePlan);
    fetchAllRecords();
  };

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {

      // CareNote Data for Morning
      const careNoteDataMorning = data?.data?.carePlans?.filter(
        (item) => item.active === true
          && item.noteType != "MoodRating"
          && moment(item?.eventDateTime).format("DD/MM/YYYY") === moment(apiQuery.currentDate).format("DD/MM/YYYY")
          && parseInt(moment(item?.eventDateTime).format("H")) >= 8
          && parseInt(moment(item?.eventDateTime).format("H")) <= 13
      );
      setCarePlansMorning(careNoteDataMorning);

      // CareNote Data for Evening
      const careNoteDataEvening = data?.data?.carePlans?.filter(
        (item) => item.active === true
          && item.noteType != "MoodRating"
          && moment(item?.eventDateTime).format("DD/MM/YYYY") === moment(apiQuery.currentDate).format("DD/MM/YYYY")
          && parseInt(moment(item?.eventDateTime).format("H")) >= 14
          && parseInt(moment(item?.eventDateTime).format("H")) <= 19
      );
      setCarePlansEvening(careNoteDataEvening);

      // CareNote Data for Night
      const careNoteDataNight = data?.data?.carePlans?.filter((item) => {
        const eventDateTime = moment(item?.eventDateTime);
        const currentDate = moment(apiQuery.currentDate);
        const hour = eventDateTime.hour();

        // If eventDateTime is on the same day and the hour is between 20:00 and 23:59
        if (eventDateTime.isSame(currentDate, 'day') && hour >= 20) {
          return item.noteType !== "MoodRating" && item.active === true;
        }

        // If eventDateTime is on the next day and the hour is between 00:00 and 07:00
        if (eventDateTime.isSame(currentDate.clone().add(1, 'day'), 'day') && hour <= 7) {
          return item.noteType !== "MoodRating" && item.active === true;
        }

        // If item.noteType is not "MoodRating", exclude it
        return false;
      });

      setCarePlansNight(careNoteDataNight);


      // Incidents Data for Morning
      const incidentRecordsDataMorning = data?.data?.carePlans?.filter(
        (item) => item.noteType === "ABC"
          || item.noteType === "PhysicalIntervention"
          && moment(item?.eventDateTime).format("DD/MM/YYYY") === moment(apiQuery.currentDate).format("DD/MM/YYYY")
          && parseInt(moment(item?.eventDateTime).format("H")) >= 8
          && parseInt(moment(item?.eventDateTime).format("H")) <= 13
      );
      setIncidentRecordsMorning(incidentRecordsDataMorning);

      // Incidents Data for Evening
      const incidentRecordsDataEvening = data?.data?.carePlans?.filter(
        (item) => item.noteType === "ABC"
          || item.noteType === "PhysicalIntervention"
          && moment(item?.eventDateTime).format("DD/MM/YYYY") === moment(apiQuery.currentDate).format("DD/MM/YYYY")
          && parseInt(moment(item?.eventDateTime).format("H")) >= 14
          && parseInt(moment(item?.eventDateTime).format("H")) <= 20
      );
      setIncidentRecordsEvening(incidentRecordsDataEvening);

      // Incidents Data for Night
      const incidentRecordsDataNight = data?.data?.carePlans?.filter((item) => {

        if (item.noteType === "ABC" || item.noteType === "PhysicalIntervention") {
          const eventDateTime = moment(item?.eventDateTime);
          const currentDate = moment(apiQuery.currentDate);
          const hour = eventDateTime.hour();

          // If eventDateTime is on the same day and the hour is between 20:00 and 23:59
          if (eventDateTime.isSame(currentDate, 'day') && hour >= 20) {
            return true;
          }

          // If incidentDate is on the next day and the hour is between 00:00 and 07:00
          if (eventDateTime.isSame(currentDate.clone().add(1, 'day'), 'day') && hour <= 7) {
            return true;
          }
        }

        return false;
      });
      console.log("🚀 ~ incidentRecordsDataNight ~ incidentRecordsDataNight:", incidentRecordsDataNight.length)
      setIncidentRecordsNight(incidentRecordsDataNight);



      // Mood Rating Data for Morning
      const moodRatingDataMorning = data?.data?.carePlans?.filter(
        (item) => item.active === true
          && item.noteType === "MoodRating"
          && moment(item?.eventDateTime).format("DD/MM/YYYY") === moment(apiQuery.currentDate).format("DD/MM/YYYY")
          && parseInt(moment(item?.eventDateTime).format("H")) >= 8
          && parseInt(moment(item?.eventDateTime).format("H")) <= 13
      );
      setMoodRatingMorning(moodRatingDataMorning);

      // Mood Rating Data for Evening
      const moodRatingDataEvening = data?.data?.carePlans?.filter(
        (item) => item.active === true
          && item.noteType === "MoodRating"
          && moment(item?.eventDateTime).format("DD/MM/YYYY") === moment(apiQuery.currentDate).format("DD/MM/YYYY")
          && parseInt(moment(item?.eventDateTime).format("H")) >= 14
          && parseInt(moment(item?.eventDateTime).format("H")) <= 19
      );
      setMoodRatingEvening(moodRatingDataEvening);


      // CareNote Data for Night
      const moodRatingDataNight = data?.data?.carePlans?.filter((item) => {
        const eventDateTime = moment(item?.eventDateTime);
        const currentDate = moment(apiQuery.currentDate);
        const hour = eventDateTime.hour();

        // Check if eventDateTime is on the same day and the hour is between 20:00 and 23:59
        if (eventDateTime.isSame(currentDate, 'day') && hour >= 20 && hour <= 23) {
          // Return true only if noteType is "MoodRating"
          return item.noteType === "MoodRating" && item.active === true;
        }

        // Check if eventDateTime is on the next day and the hour is between 00:00 and 07:00
        if (eventDateTime.isSame(currentDate.clone().add(1, 'day'), 'day') && hour >= 0 && hour <= 7) {
          // Return true only if noteType is "MoodRating"
          return item.noteType === "MoodRating" && item.active === true;
        }

        return false; // Return false for all other cases
      });

      setMoodRatingNight(moodRatingDataNight);

      // const moodRatingData = data?.data?.carePlans?.filter((item) => item.active === true && item.noteType === "MoodRating");
      // setMoodRatingRecords(moodRatingData);

      // setIncidentsRecords(careNoteData);
      setIsLoading(false);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {

        setIsCarePlan(false);
      }, 2000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_CARE_DAILY_SUMMARY_URL, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, [apiQuery]);




  const dateFilterForDailySummary = (currentDate) => {
    setApiQuery({
      ...apiQuery,
      currentDate: moment(currentDate).valueOf()
    });
    setIsLoading(true);
  }

  // console.log("🚀 ~ DailyData ~ apiQuery:", apiQuery)




  // Exporting to PDF
  // Create styles
  const styles = StyleSheet.create({
    pages: {
      padding: "10px",
    },
    sectionTitle: {
      fontSize: "16px",
      fontWeight: "black",
      marginBottom: "5px",
      textAlign: "center",
      padding: "10px",
      backgroundColor: "#f0f0f0"
    },
    marginTop: {
      marginTop: "10px"
    },
    marginBottom: {
      marginBottom: "10px"
    },
    sectionSubTitle: {
      fontSize: "14px",
      fontWeight: "black",
      marginBottom: "5px",
      textAlign: "center",
      padding: "10px",
      backgroundColor: "#f0f0f0"
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    tableHead: {
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: "#f0f0f0",
      padding: "10px 10px",
      marginBottom: "5px",
      fontSize: "10px",
      fontWeight: "bold",
      wordWrap: "break-word",
    },
    tabBody: {
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "10px 10px",
      borderBottom: "1px solid #ccc",
      fontSize: "10px",
      wordWrap: "break-word",
    },
    wordwrap: {
      flexBasis: "50%",
      wordWrap: "break-word",
    },
    smallText: {
      fontSize: "8px"
    },
    mediumText: {
      fontSize: "10px"
    },
    largeText: {
      fontSize: "12px"
    },
    alignCenter: {
      textAlign: "center",
    },
    image: {
      width: 30,
      height: 30,
    },
  });

  const MyDocument = (start) => (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.pages}>
        <View>
          <Text style={styles.sectionTitle}>Daily Summary</Text>
          <Text style={styles.sectionSubTitle}>Service User: {userDetails?.modelId?.suFirstMiddleName} {userDetails?.modelId?.suLastName}</Text>
          <Text style={[styles.mediumText, styles.marginTop, styles.marginBottom]}>Date: {moment(apiQuery?.currentDate).format("DD/MM/YYYY")}</Text>

          {/* BlobProvider, Canvas, Circle, ClipPath, Defs, Document, Ellipse, Font, G, Image, Line, LinearGradient, Link, Note, PDFDownloadLink, PDFViewer, Page, Path, Polygon, Polyline, RadialGradient, Rect, Stop, StyleSheet, Svg, Text, TextInstance, Tspan, View, createRenderer, default, pdf, render, renderToBuffer, renderToFile, renderToStream, renderToString, usePDF, version */}

          {/* Morning */}
          <View style={styles.tableHead}>
            <Text>Time from: 08:00 to 14:00</Text>
          </View>
          {carePlansMorning.map((item, index) => (
            <View key={index} style={styles.tabBody}>
              <Text>
                <Text style={styles.smallText}>Date: {moment(item?.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}{"\n"}</Text>
                <Text style={styles.smallText}>Note Type: {item?.noteType.replace(/([a-z])([A-Z])/g, '$1 $2')}{"\n"}</Text>
                <Text style={styles.largeText}>{item?.title}</Text>
              </Text>
            </View>
          ))}
          {/* Mood Rating */}

          <View style={styles.tableHead}>
            <Text>Mood Rating:</Text>
          </View>
          <View style={styles.tabBody}>
            {
              moodRatingTimingMorning?.map((item, index) => {
                const matchingMoodItem = moodRatingMorning.find(moodItem => moment(moodItem?.eventDateTime).format("H") === item);

                return (
                  <>
                    {matchingMoodItem ? (
                      <MoodImagePDF moodItem={matchingMoodItem} />
                    ) : (
                      <> <Image style={styles.image} src={Bored} alt={Bored} />
                      </>
                    )}
                  </>
                );
              })
            }
          </View>


          {/* Footer */}
          <View style={styles.tabBody}>
            <Text style={[styles.mediumText, { width: "50%" }]}>
              <Text style={{ fontWeight: "bold" }}>Medication / PRN:</Text>{"\n"}
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</Text>
            <Text style={[styles.mediumText, { width: "25%" }]}>
              <Text style={{ fontWeight: "bold" }}>Staff Initials:</Text>{"\n"}
              {Array.from(uniqueNamesMorning).map((name, index) => (
                <Text key={index}>{getInitials(name)},&nbsp; &nbsp;</Text>
              ))}
            </Text>
            <Text style={[styles.mediumText, { width: "25%" }]}>
              <Text style={{ fontWeight: "bold" }}>Number of Incidents:</Text>{"\n"}
              {incidentRecordsMorning && (
                <>
                  {incidentRecordsMorning.length}
                </>
              )}
            </Text>
          </View>

          {/* Evening */}
          <View style={styles.tableHead}>
            <Text>Time from: 14:00 to 20:00</Text>
          </View>
          {carePlansEvening.map((item, index) => (
            <View key={index} style={styles.tabBody}>
              <Text>
                <Text style={styles.smallText}>Date: {moment(item?.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}{"\n"}</Text>
                <Text style={styles.smallText}>Note Type: {item?.noteType.replace(/([a-z])([A-Z])/g, '$1 $2')}{"\n"}</Text>
                <Text style={styles.largeText}>{item?.title}</Text>
              </Text>
            </View>
          ))}
          {/* Mood Rating */}

          <View style={styles.tableHead}>
            <Text>Mood Rating:</Text>
          </View>
          <View style={styles.tabBody}>
            {
              moodRatingTimingEvening?.map((item, index) => {
                const matchingMoodItem = moodRatingEvening.find(moodItem => moment(moodItem?.eventDateTime).format("H") === item);

                return (
                  <>
                    {matchingMoodItem ? (
                      <MoodImagePDF moodItem={matchingMoodItem} />
                    ) : (
                      <> <Image style={styles.image} src={Bored} alt={Bored} />
                      </>
                    )}
                  </>
                );
              })
            }
          </View>


          {/* Footer */}
          <View style={styles.tabBody}>
            <Text style={[styles.mediumText, { width: "50%" }]}>
              <Text style={{ fontWeight: "bold" }}>Medication / PRN:</Text>{"\n"}
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</Text>
            <Text style={[styles.mediumText, { width: "25%" }]}>
              <Text style={{ fontWeight: "bold" }}>Staff Initials:</Text>{"\n"}
              {Array.from(uniqueNamesEvening).map((name, index) => (
                <Text key={index}>{getInitials(name)},&nbsp; &nbsp;</Text>
              ))}
            </Text>
            <Text style={[styles.mediumText, { width: "25%" }]}>
              <Text style={{ fontWeight: "bold" }}>Number of Incidents:</Text>{"\n"}
              {incidentRecordsEvening && (
                <>
                  {incidentRecordsEvening.length}
                </>
              )}
            </Text>
          </View>

          {/* Night */}
          <View style={styles.tableHead}>
            <Text>Time from: 22:00 to 07:00</Text>
          </View>
          {carePlansNight.map((item, index) => (
            <View key={index} style={styles.tabBody}>
              <Text>
                <Text style={styles.smallText}>Date: {moment(item?.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}{"\n"}</Text>
                <Text style={styles.smallText}>Note Type: {item?.noteType.replace(/([a-z])([A-Z])/g, '$1 $2')}{"\n"}</Text>
                <Text style={styles.largeText}>{item?.title}</Text>
              </Text>
            </View>
          ))}

          {/* Footer */}
          <View style={styles.tabBody}>
            <Text style={[styles.mediumText, { width: "50%" }]}>
              <Text style={{ fontWeight: "bold" }}>Medication / PRN:</Text>{"\n"}
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</Text>
            <Text style={[styles.mediumText, { width: "25%" }]}>
              <Text style={{ fontWeight: "bold" }}>Staff Initials:</Text>{"\n"}
              {Array.from(uniqueNamesNight).map((name, index) => (
                <Text key={index}>{getInitials(name)},&nbsp; &nbsp;</Text>
              ))}
            </Text>
            <Text style={[styles.mediumText, { width: "25%" }]}>
              <Text style={{ fontWeight: "bold" }}>Number of Incidents:</Text>{"\n"}
              {incidentRecordsNight && (
                <>
                  {incidentRecordsNight.length}
                </>
              )}
            </Text>
          </View>


          {/* Night Checks */}

          <View style={styles.tableHead}>
            <Text>Night Checks:</Text>
          </View>
          <View style={styles.tabBody}>
            <Text>Timing:</Text>
            {moodRatingTimingNight?.map((item, index) => (
              <Text style={{ width: "8.33%", textAlign: "center" }} key={index}>{item}:00</Text>
            ))}
          </View>
          <View style={styles.tabBody}>
            <Text>Initials:</Text>
            {
              moodRatingTimingNight?.map((item, index) => {
                const matchingMoodItem = moodRatingNight.find(moodItem => moment(moodItem?.eventDateTime).format("H") === item);

                return (
                  <Text style={{ width: "8.33%", textAlign: "center" }} key={index}>
                    {matchingMoodItem ? (
                      <Text>{getInitials(matchingMoodItem?.addedby?.name)}</Text>
                    ) : (
                      <>  <Text>{" - "}</Text>
                      </>
                    )}
                  </Text>
                );
              })
            }


          </View>
          <View style={styles.tabBody}>
            <Text style={styles.marginTop}>Mood:</Text>
            {
              moodRatingTimingNight?.map((item, index) => {
                const matchingMoodItem = moodRatingNight.find(moodItem => moment(moodItem?.eventDateTime).format("H") === item);

                return (
                  <>
                    <Text style={{ width: "8.33%", textAlign: "center" }} key={index}>
                      {matchingMoodItem ? (
                        <MoodImagePDF moodItem={matchingMoodItem} />
                      ) : (
                        <> <Image style={styles.image} src={Bored} alt={Bored} />
                        </>
                      )}
                    </Text>
                  </>
                );
              })
            }
          </View>

        </View>
        <Image style={{ width: "100px" }} src={window.location.origin + Bored} alt={Bored} />
      </Page>
    </Document >
  );


  const uniqueNamesMorning = new Set();
  const uniqueNamesEvening = new Set();
  const uniqueNamesNight = new Set();

  const [moodRatingTimingMorning, setMoodRatingTimingMorning] = useState(["8", "9", "10", "11", "12", "13"]);
  const [moodRatingTimingEvening, setMoodRatingTimingEvening] = useState(["14", "15", "16", "17", "18", "19"]);
  const [moodRatingTimingNight, setMoodRatingTimingNight] = useState(["20", "21", "22", "23", "0", "1", "2", "3", "4", "5", "6", "7",]);

  const pdfExportComponent = useRef(null);
  const [showInPrint, setShowInPrint] = useState(false);
  const exportPDF = () => {
    if (pdfExportComponent.current) {
      setShowInPrint(true);
      setTimeout(() => {
        pdfExportComponent.current.save();
      }, 100);
      setTimeout(() => {
        setShowInPrint(false);
      }, 500);


    }
  };
  return (
    <>


      {/* <PDFViewer style={{ width: "100%", height: "500px" }}>
        <MyDocument />
      </PDFViewer> */}

      {titleandButton && (
        <>
          <header className="mb-3">
            <div className="titleandButton">
              <h3>Daily Summary</h3>


              <div className="actionColumns">
                <span className="btn btn-sm btn-outline-primary" onClick={exportPDF}>Export to PDF</span>

                {/* <span>
              <PDFDownloadLink document={
                <MyDocument />} fileName={userDetails?.modelId?.suFirstMiddleName + userDetails?.modelId?.suLastName + " - Daily Summary"}>
                {({ loading }) =>
                  loading ? '...' : (
                    <>
                      <span className="btn btn-sm btn-primary">
                        Export as PDF
                      </span>
                    </>
                  )
                }
              </PDFDownloadLink>
            </span> */}

                <Link to="/common-options" className="btn btn-sm btn-outline-primary">
                  Back to Common Options
                </Link>
              </div>

            </div>
          </header>
        </>
      )}
      <div className="mainContent">
        <PDFExport
          ref={pdfExportComponent}
          paperSize={["900pt", "900pt"]}
          margin={20}
          fileName={`Daily Summary`}
        >
          {/* <h1 className="no-print" hidden>This will be excluded from the PDF</h1> */}
          <div className="dailyDiaryContent">
            <Table className="dailyDiaryWrapperTable">
              <tr>
                <td>
                  <Table bordered className="dailyDiaryContentTable">
                    {/* {
                      dailySummaryDateProp && ( */}
                        <>
                          <thead>
                            <tr>
                              <td colSpan={3} style={{ width: "75%" }}>
                                {
                                  showInPrint && (
                                    <div className="showInPrint">
                                      <p className="m-0">
                                        <small className="m-0">Daily Summary Report</small>
                                        Service User: <strong>{userDetails?.name} {userDetails?.modelId?.suLastName}</strong>
                                      </p>
                                    </div>
                                  )
                                }

                              </td>
                              <td>
                                <div className="dateInputWrapper">
                                  <DateInput
                                    id="startDate"
                                    name="startDate"
                                    valueText={moment(apiQuery?.currentDate).format('DD-MM-YYYY')}
                                    value={moment(apiQuery?.currentDate).format('YYYY-MM-DD')}
                                    onChange={(e) => dateFilterForDailySummary(e.target.value)}
                                    labelText="Summary Date"
                                    style={{ width: "170px" }}
                                  />
                                </div>
                              </td>
                            </tr>
                          </thead>
                        </>
                   

                    {
                      isLoading ? (
                        <td colSpan={4}>
                          <div className="preLoaderWrapper">
                            <ProgressComponent />
                          </div>
                        </td>
                      ) : (
                        <>
                          <tbody>
                            <tr className="theading">
                              <th colSpan={4}>Time from: 08:00 to 14:00</th>
                            </tr>
                            <tr>
                              <td colSpan={4}>
                                <div className="notesContent">
                                  <p className="pt-3 pb-3">
                                    {
                                      carePlansMorning?.map((item, index) => (
                                        <>
                                          {/* <div> */}
                                          {/* <small>{moment(item?.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}</small><br /> */}
                                          {/* <strong><small>{item?.noteType.replace(/([a-z])([A-Z])/g, '$1 $2')}</small></strong><br /> */}
                                          {item?.title}&nbsp;
                                          {/* <hr /> */}
                                          {/* </div> */}
                                        </>
                                      ))
                                    }
                                  </p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th colSpan={4}>Mood Rating</th>
                            </tr>
                            <tr>
                              <td colSpan={4} className="p-0">
                                <Table bordered className="moodRatingTable">
                                  <tr>
                                    {moodRatingTimingMorning?.map((item, index) => (
                                      <th key={index}>{item}:00</th>
                                    ))}
                                  </tr>
                                  <tr>
                                    {
                                      moodRatingTimingMorning?.map((item, index) => {
                                        const matchingMoodItem = moodRatingMorning.find(moodItem => moment(moodItem?.eventDateTime).format("H") === item);

                                        return (
                                          <td key={index}>
                                            {matchingMoodItem ? (
                                              <MoodImageComponent moodItem={matchingMoodItem} />
                                            ) : (
                                              <> <div className="feedBackIcons notSubmited"><img src={Bored} alt={Bored} /></div>
                                              </>
                                            )}
                                          </td>
                                        );
                                      })
                                    }
                                    {/* {moodRatingTimingMorning?.map((item, index) => (
                            <th key={index}>{item}</th>
                          ))} */}

                                  </tr>
                                </Table>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={2} style={{ width: "50%", border: "1px solid rgba(0,0,0,0.1)" }}><strong>Medication / PRN: </strong> <br />
                                <p> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p></td>
                              <td colSpan={1} style={{ border: "1px solid rgba(0,0,0,0.1)" }}><strong>Staff Initials: </strong><br />
                                <p className="initialNumbersOfInc">
                                  {
                                    carePlansMorning?.map((item, index) => {
                                      if (!uniqueNamesMorning.has(item?.addedby?.name)) {
                                        uniqueNamesMorning.add(item?.addedby?.name);
                                        return (
                                          <OverlayTrigger key={index} overlay={<Tooltip id="tooltip-disabled">{item?.addedby?.name}</Tooltip>}>
                                            <span>{getInitials(item?.addedby?.name)}</span>
                                          </OverlayTrigger>
                                        );
                                      }
                                      return null;
                                    })
                                  }
                                </p>
                              </td>
                              <td colSpan={1} style={{ border: "1px solid rgba(0,0,0,0.1)" }}><strong>Number of Incidents: </strong><br />
                                {incidentRecordsMorning && (
                                  <p className="initialNumbersOfInc"><span>{incidentRecordsMorning.length}</span></p>
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        </>
                      )
                    }

                  </Table>
                </td>
              </tr>

              <tr>

                {
                  isLoading ? (
                    <td colSpan={4}>
                      &nbsp;
                    </td>
                  ) : (
                    <td>
                      <Table bordered className="dailyDiaryContentTable">
                        <tbody>
                          <tr className="theading">
                            <th colSpan={4}>Time from: 14:00 to 20:00</th>
                          </tr>
                          <tr>
                            <td colSpan={4}>
                              <div className="notesContent">
                                <p className="pt-3 pb-3">
                                  {
                                    carePlansEvening?.map((item, index) => (
                                      <>
                                        {item?.title}&nbsp;
                                      </>
                                      // <div>
                                      //   <small>{moment(item?.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}</small><br />
                                      //   <strong><small>{item?.noteType.replace(/([a-z])([A-Z])/g, '$1 $2')}</small></strong><br />

                                      //   <hr />
                                      // </div>
                                    ))
                                  }
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th colSpan={4}>Mood Rating</th>
                          </tr>
                          <tr>
                            <td colSpan={4} className="p-0">
                              <Table bordered className="moodRatingTable">
                                <tr>
                                  {moodRatingTimingEvening?.map((item, index) => (
                                    <th key={index}>{item}:00</th>
                                  ))}
                                </tr>
                                <tr>
                                  {
                                    moodRatingTimingEvening?.map((item, index) => {
                                      const matchingMoodItem = moodRatingEvening.find(moodItem => moment(moodItem?.eventDateTime).format("H") === item);

                                      return (
                                        <td key={index}>
                                          {matchingMoodItem ? (
                                            <MoodImageComponent moodItem={matchingMoodItem} />
                                          ) : (
                                            <> <div className="feedBackIcons notSubmited"><img src={Bored} alt={Bored} /></div>
                                            </>
                                          )}
                                        </td>
                                      );
                                    })
                                  }


                                </tr>
                              </Table>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={2} style={{ width: "50%", border: "1px solid rgba(0,0,0,0.1)" }}><strong>Medication / PRN: </strong> <br />
                              <p> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p></td>
                            <td colSpan={1} style={{ border: "1px solid rgba(0,0,0,0.1)" }}><strong>Staff Initials: </strong><br />
                              <p className="initialNumbersOfInc">
                                {
                                  carePlansEvening?.map((item, index) => {
                                    if (!uniqueNamesEvening.has(item?.addedby?.name)) {
                                      uniqueNamesEvening.add(item?.addedby?.name);
                                      return (
                                        <OverlayTrigger key={index} overlay={<Tooltip id="tooltip-disabled">{item?.addedby?.name}</Tooltip>}>
                                          <span>{getInitials(item?.addedby?.name)}</span>
                                        </OverlayTrigger>
                                      );
                                    }
                                    return null;
                                  })
                                }
                              </p>
                            </td>
                            <td colSpan={1} style={{ border: "1px solid rgba(0,0,0,0.1)" }}><strong>Number of Incidents: </strong><br />
                              {incidentRecordsEvening && (
                                <p className="initialNumbersOfInc"><span>{incidentRecordsEvening.length}</span></p>
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </td>
                  )
                }



              </tr>

              <tr>

                {
                  isLoading ? (
                    <td colSpan={4}>
                      &nbsp;
                    </td>
                  ) : (
                    <td>
                      <Table bordered className="dailyDiaryContentTable">
                        <tbody>
                          <tr className="theading">
                            <th colSpan={4}>Time from: 22:00 to 07:00</th>
                          </tr>
                          <tr>
                            <td colSpan={4}>
                              <div className="notesContent">
                                <p className="pt-3 pb-3">
                                  {
                                    carePlansNight?.map((item, index) => (
                                      <>
                                        {item?.title}&nbsp;
                                      </>
                                      // <div>
                                      //   <small>{moment(item?.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}</small><br />
                                      //   <strong><small>{item?.noteType.replace(/([a-z])([A-Z])/g, '$1 $2')}</small></strong><br />

                                      //   <hr />
                                      // </div>
                                    ))
                                  }
                                </p>
                              </div>
                            </td>
                          </tr>

                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={2} style={{ width: "50%", border: "1px solid rgba(0,0,0,0.1)" }}><strong>Medication / PRN: </strong> <br />
                              <p> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p></td>
                            <td colSpan={1} style={{ border: "1px solid rgba(0,0,0,0.1)" }}><strong>Staff Initials: </strong><br />
                              <p className="initialNumbersOfInc">
                                {
                                  carePlansNight?.map((item, index) => {
                                    if (!uniqueNamesNight.has(item?.addedby?.name)) {
                                      uniqueNamesNight.add(item?.addedby?.name);
                                      return (
                                        <OverlayTrigger key={index} overlay={<Tooltip id="tooltip-disabled">{item?.addedby?.name}</Tooltip>}>
                                          <span>{getInitials(item?.addedby?.name)}</span>
                                        </OverlayTrigger>
                                      );
                                    }
                                    return null;
                                  })
                                }
                              </p>
                            </td>
                            <td colSpan={1} style={{ border: "1px solid rgba(0,0,0,0.1)" }}><strong>Number of Incidents: </strong><br />
                              {incidentRecordsNight && (
                                <p className="initialNumbersOfInc"><span>{incidentRecordsNight.length}</span></p>
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </td>
                  )
                }


              </tr>

              {/* Night Checks */}
              <tr>

                {
                  isLoading ? (
                    <td colSpan={4}>
                      &nbsp;
                    </td>
                  ) : (
                    <td>
                      <Table bordered className="dailyDiaryContentTable">
                        <tbody>
                          <tr>
                            <th colSpan={4}><strong>Night Checks:</strong></th>
                          </tr>
                          <tr>
                            <td colSpan={4} className="p-0">
                              <Table bordered className="moodRatingTable nightChecks">
                                <tr>
                                  <th>Time</th>
                                  {moodRatingTimingNight?.map((item, index) => (
                                    <th key={index}>{item}:00</th>
                                  ))}

                                </tr>

                                <tr>
                                  <th>Initials</th>

                                  {
                                    moodRatingTimingNight?.map((item, index) => {
                                      const matchingMoodItem = moodRatingNight.find(moodItem => moment(moodItem?.eventDateTime).format("H") === item);

                                      return (
                                        <td key={index}>
                                          {matchingMoodItem ? (
                                            <OverlayTrigger key={index} overlay={<Tooltip id="tooltip-disabled">{matchingMoodItem?.addedby?.name}</Tooltip>}>
                                              <span>{getInitials(matchingMoodItem?.addedby?.name)}</span>
                                            </OverlayTrigger>

                                          ) : (
                                            <> <div> - </div>
                                            </>
                                          )}
                                        </td>
                                      );
                                    })
                                  }
                                </tr>
                                <tr>
                                  <th>Mood</th>
                                  {
                                    moodRatingTimingNight?.map((item, index) => {
                                      const matchingMoodItem = moodRatingNight.find(moodItem => moment(moodItem?.eventDateTime).format("H") === item);

                                      return (
                                        <td key={index}>
                                          {matchingMoodItem ? (
                                            <MoodImageComponent moodItem={matchingMoodItem} />
                                          ) : (
                                            <> <div className="feedBackIcons notSubmited"><img src={Bored} alt={Bored} /></div>
                                            </>
                                          )}
                                        </td>
                                      );
                                    })
                                  }
                                </tr>
                              </Table>
                            </td>
                          </tr>
                        </tbody>

                      </Table>
                    </td>
                  )
                }
              </tr>



            </Table>

          </div >

        </PDFExport>
      </div>
    </>
  );
}

export default DailyData;