import React, { useState, useEffect } from "react";
import Select from "react-select";
import debounce from "lodash.debounce";
import { getRecords, catchAsync, isSuccess } from "../../helper/helper";
import { BASE_URL, GET_TRAINING_QUIZZES } from "../../env";

const CustomSelectQuizzTemp = ({
    onSelect,
    errorClass,
    customError,
    errorMessage,
    label,
}) => {
    const [userData, setUserData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [defaultSelection, setDefaultSelection] = useState(null);
    const apiQuery = {
        sortproperty: "createdAt",
        sortorder: -1,
        offset: 0,
        query: {
            critarion: {}
        }
    };

    const processTemplateData = (responseData) => {
        if (responseData?.status === isSuccess) {
            const getRecords = responseData?.data?.quizList?.map((item) => ({
                value: item?._id,
                label: item?.quizName,
            }));
            setUserData(getRecords);

            setIsLoading(false);

            if (!defaultSelection && getRecords?.length > 0) {
                setDefaultSelection(getRecords[0]);
                onSelect(getRecords[0]?.value);
            }
        }
    };

    const fetchAllRecords = catchAsync(async (req, res) => {
        const response = await getRecords(
            GET_TRAINING_QUIZZES,
            apiQuery
        );
        processTemplateData(response);
    });

    useEffect(() => {
        fetchAllRecords();
    }, []);

    const handleInputChange = (e) => {
        setIsLoading(false);
    };

    return (
        <div className={`customSelectBox ${errorClass}`}>
            {isLoading ? (
                <p className="loadingText">Loading...</p>
            ) : (
                <>
                    <Select
                        className="basic-single cursor-pointer"
                        classNamePrefix="select"
                        value={defaultSelection}
                        options={userData}
                        isSearchable
                        placeholder={"Select a template..."}
                        onChange={(selectedOption) => {
                            setDefaultSelection(selectedOption);
                            onSelect(selectedOption?.value);
                        }}
                        onInputChange={handleInputChange}
                        getOptionLabel={(selectedOption) => selectedOption?.label}
                        getOptionValue={(selectedOption) => selectedOption?.value}
                    />
                    {label && <label className="floatingLabel">{label}</label>}
                    {customError && <div className="text-danger">{errorMessage}</div>}
                </>
            )}
        </div>
    );
};

export default CustomSelectQuizzTemp;