import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ProgressComponent from "../../../components/general/ProgressComponent";
// import editBlock from "../../../assets/images/icons/editBlock.svg";
// import eventDel from "../../../assets/images/icons/eventDel.svg";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import folderIcon from "../../../assets/images/icons/folder.svg";
import password_eye_icon_circle from "../../../assets/images/icons/password_eye_icon_circle.svg";
import "react-toastify/dist/ReactToastify.css";
import downloadIcon from "../../../assets/images/icons/downloadIcon.svg";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import alreadyAssignedUser from "../../../assets/images/icons/alreadyAssignedUser.svg";
import {
    BASE_URL,
    GET_ALREADY_ASSIGNED_SHIFT_URL,
    DELETE_SU_DOCUMENTS_URL,
} from "../../../env";
import {
    getRecords,
    isSuccess,
    toastSuccessMessages,
    toastErrorMessages,
    deleteRecords,
    catchAsync,
} from "../../../helper/helper";
import moment from "moment";
function AlreadyAssignedUser({ liveRosterShiftId, serviceUserId, shiftDate, shiftStartTime, shiftEndTime }) {
    const [userData, setUserData] = useState([]);
    console.log("🚀 ~ AlreadyAssignedUser ~ userData:", userData)
    console.log("🚀 ~ AlreadyAssignedUser ~ shiftDate:", shiftDate)
    const [isLoading, setIsLoading] = useState(true);
    const apiQuery = {
        liveRosterShiftId: liveRosterShiftId,
        serviceUserId: serviceUserId
    }



    // Getting records from DB
    const processFetchRecords = (method, data) => {
        if (method === "get" && data?.status === isSuccess) {
            const assignedShifts = data?.data?.filter((item) => (
                item?.shiftDate === shiftDate &&
                item?.staffUserId !== null &&
                item?.shiftStartTime === shiftStartTime &&
                item?.shiftEndTime === shiftEndTime
            )
            );
            setUserData(assignedShifts);
            setIsLoading(false);
        } else if (method === "delete" && data?.status === isSuccess) {
            toastSuccessMessages(toast.success, data?.message, "", 500, 500);
        }
    };

    const fetchAllRecords = catchAsync(async (req, res) => {
        const response = await getRecords(GET_ALREADY_ASSIGNED_SHIFT_URL, apiQuery);
        console.log("🚀 ~ fetchAllRecords ~ response:", response)
        processFetchRecords("get", response);
    });

    useEffect(() => {
        fetchAllRecords();
    }, [liveRosterShiftId, serviceUserId]);

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ userData:", userData)
    }, [userData])


    const renderTooltip = () => (
        <Tooltip className="toolTipInfo assignedToolTipWrapper" id="custom-tooltip">
            {
                !isLoading ? (
                    userData.length !== 0 && userData?.staffUserId !== null ? (
                        userData?.map((item, index) => (
                            item?.staffUserId && (
                                <>
                                    {
                                        index > 0 && <hr />
                                    }
                                    <span key={index} className="usersNameInfo mt-1 mb-1">
                                        <span className="userImage">
                                            <img src="http://13.61.0.63:3000/uploads/dp/default.png" alt="Denim" />
                                        </span>
                                        <span className="userInfo">
                                            <span className="">
                                                {item?.staffUserId?.user?.name}{" "}{item?.staffUserId?.lastName}
                                            </span>
                                            <span class="nameBlock"><small>Driving: {item?.staffUserId?.driverFields?.isDriver ? "Yes" : "No"}</small></span>
                                        </span>
                                    </span>
                                </>

                            )
                        ))
                    ) : (
                        <div className="noRecordFound">
                            <p>No member currently assigned.</p>
                        </div>
                    )
                ) : (
                    <div className="noRecordFound assignedNoRecordFound">
                        <ProgressComponent />
                    </div>
                )
            }
        </Tooltip>
    );


    return (
        <>
            {

                userData.length !== 0 && userData?.staffUserId !== null ? (
                    <OverlayTrigger
                        trigger="click" // You can change this to "hover" or "focus" if needed
                        placement="right"
                        overlay={renderTooltip()}
                        rootClose // Closes the tooltip when clicked outside
                    >
                        <img src={alreadyAssignedUser} alt={alreadyAssignedUser} />


                    </OverlayTrigger>
                ) : null

            }


            {/* {liveRosterShiftId}<br />
            {serviceUserId}<br />
            {shiftDate}<br /> */}

        </>
    );
}


export default AlreadyAssignedUser