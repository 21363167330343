import React, { useState, useEffect } from "react";
import Input from "../../../components/forms-fields/Input";
import "react-toastify/dist/ReactToastify.css";
import eventCloseRed from "../../../assets/images/icons/eventCloseRed.svg";
import eventAdd from "../../../assets/images/icons/eventAdd.svg";
import eventDel from "../../../assets/images/icons/eventDel.svg";
import { addQuizzesValidationSchema } from "../../../schemas";
import { Link } from "react-router-dom";
import Layout from "../../../components/layouts/Layout";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { UPDATE_TRAINING_QUIZZES, ADMIN_ID } from "../../../env";
import ReactDOMServer from 'react-dom/server';
import {
    postRecords,
    catchAsync,
    isSuccess,
    toastSuccessMessages,
    toastErrorMessages,
} from "../../../helper/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditQuizzes() {
    const storedQuizzesListDetail = localStorage.getItem("quizzesListDetail");
    const trainingListDetail = JSON.parse(storedQuizzesListDetail);
    console.log("🚀 ~ EditQuizzes ~ trainingListDetail:", trainingListDetail)

    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
    const [isToastAddTrainingQuizzes, setIsToastAddTrainingQuizzes] = useState(false);


    // State for Questions and their options
    // Updated state for questions and options based on the provided structure
    const [questionData, setQuestionData] = useState([]);
    useEffect(() => {
        setQuestionData(trainingListDetail?.questionData)
        console.log("🚀 ~ EditQuizzes ~ questionData:", questionData)

    }, [storedQuizzesListDetail])

    // Function to add a new question
    const addQuestionRow = () => {
        const newQuestion = {
            question: "",
            questionOptions: [
                { option: "", isAnswer: true },
                { option: "", isAnswer: false }
            ]
        };
        setQuestionData([...questionData, newQuestion]);
    };

    // Function to remove a question
    const removeQuestionRow = (index) => {
        const updatedQuestions = [...questionData];
        updatedQuestions.splice(index, 1);
        setQuestionData(updatedQuestions);
    };

    // Function to handle changes in the question field
    const handleQuestionInputChange = (index, field, value) => {
        const updatedQuestions = [...questionData];
        updatedQuestions[index][field] = value;
        setQuestionData(updatedQuestions);
    };

    // Function to add a new option to a specific question
    const addQuestionOptionsRow = (questionIndex) => {
        const updatedQuestions = [...questionData];
        updatedQuestions[questionIndex].questionOptions.push({
            option: `New Option ${updatedQuestions[questionIndex].questionOptions.length + 1}`,
            isAnswer: false
        });
        setQuestionData(updatedQuestions);
    };

    // Function to remove an option from a specific question
    const removeQuestionOptionsRow = (questionIndex, optionIndex) => {
        const updatedQuestions = [...questionData];
        updatedQuestions[questionIndex].questionOptions.splice(optionIndex, 1);
        setQuestionData(updatedQuestions);
    };

    // Function to handle option selection (radio button)
    const handleRadioChange = (questionIndex, selectedOptionIndex) => {
        const updatedQuestions = [...questionData];
        updatedQuestions[questionIndex].questionOptions = updatedQuestions[questionIndex].questionOptions.map((option, index) => ({
            ...option,
            isAnswer: index === selectedOptionIndex
        }));
        setQuestionData(updatedQuestions);
    };

    // Function to handle changes in the option field for a specific question
    const handleQuestionOptionsInputChange = (questionIndex, optionIndex, value) => {
        const updatedQuestions = [...questionData];
        updatedQuestions[questionIndex].questionOptions[optionIndex].option = value;
        setQuestionData(updatedQuestions);
    };


    useEffect(() => {
        setFieldValue("questionData", questionData); // Update formik's values when questionData changes
    }, [questionData]);

    const [suFormData, setSuFormData] = useState({
        quizId: trainingListDetail?._id,
        quizName: trainingListDetail?.quizName,
        passMarksPercent: trainingListDetail?.passMarksPercent,
        quizCompletionTimeLimit: trainingListDetail?.quizCompletionTimeLimit,
        questionData: questionData,
        addedby: trainingListDetail?.addedby
    });


    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
    } = useFormik({
        initialValues: suFormData,
        validationSchema: addQuizzesValidationSchema,
        onSubmit: async (values) => {
            try {
                setIsSubmitButtonClicked(true);
                await postAddTrainingQuizzesFormData(values);
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        },
    });

    const processPostRecords = (method, data) => {
        if (data?.status !== isSuccess) {
            setIsToastAddTrainingQuizzes(true);
            toastErrorMessages(toast.error, data?.message, "", 10, 10);
            setIsSubmitButtonClicked(false);

        } else if (
            method === "postAddTrainingQuizzesFormData" &&
            data?.status === isSuccess
        ) {
            setIsToastAddTrainingQuizzes(true);
            toastSuccessMessages(toast.success, data?.message, "/training-quizzes", 10, 10);
            setIsSubmitButtonClicked(false);
        }
    };

    const postAddTrainingQuizzesFormData = catchAsync(async (req, res) => {

        const response = await postRecords(UPDATE_TRAINING_QUIZZES, values);
        processPostRecords("postAddTrainingQuizzesFormData", response);
        setIsSubmitButtonClicked(false);
        console.log("🚀 ~ response ~ response:", response)
        console.log("🚀 ~ postAddTrainingQuizzesFormData ~ values:", values)
    });

    return (
        <Layout pageTitleText="Edit Training Quizzes">
            <div className="incidents trainingQuizzesWrapper">
                <form onSubmit={handleSubmit}>
                    {/* Questions */}
                    <div className="actionsPoint">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="mainContent mb-4 p-3 pt-3">
                                    <Input
                                        type="text"
                                        id={`quizName`}
                                        name={`quizName`}
                                        value={values?.quizName}
                                        labelText={"Quiz Name"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        customError={touched?.quizName && errors?.quizName}
                                        errorMessage={errors?.quizName}
                                        erroClass={
                                            touched?.quizName && errors?.quizName
                                                ? "validationError"
                                                : ""
                                        }
                                        requiredStar={true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="mainContent mb-4 p-3 pt-3">
                                    <Input
                                        type="number"
                                        id={`passMarksPercent`}
                                        name={`passMarksPercent`}
                                        value={values?.passMarksPercent}
                                        labelText={"Passing Marks (%)"}
                                        onChange={handleChange}
                                        customError={touched?.passMarksPercent && errors?.passMarksPercent}
                                        errorMessage={errors?.passMarksPercent}
                                        erroClass={
                                            touched?.passMarksPercent && errors?.passMarksPercent
                                                ? "validationError"
                                                : ""
                                        }
                                        requiredStar={true}
                                    />
                                </div>
                            </div>
                            {
                                questionData?.length !== 0 && (
                                    questionData.map((question, questionIndex) => (
                                        <div className="col-md-6" key={questionIndex}>
                                            <div className="mainContent mb-4 p-3 pt-3">
                                                <p className="m-0">
                                                    <strong>
                                                        <small>Question No.{questionIndex + 1}</small>
                                                    </strong>
                                                </p>
                                                <div className="questionBox">
                                                    <Input
                                                        type="text"
                                                        id={`question_${questionIndex}`}
                                                        name={`questionData[${questionIndex}].question`}
                                                        value={question.question}
                                                        onChange={(e) => handleQuestionInputChange(questionIndex, 'question', e.target.value)}
                                                        customError={touched?.questionData?.[questionIndex]?.question && errors?.questionData?.[questionIndex]?.question}
                                                        errorMessage={errors?.questionData?.[questionIndex]?.question}
                                                        erroClass={
                                                            touched?.questionData?.[questionIndex]?.question && errors?.questionData?.[questionIndex]?.question
                                                                ? "validationError"
                                                                : ""
                                                        }
                                                    />
                                                    <div className="removeQuestionButton">
                                                        <img
                                                            className="removeRow Delete curSorPointer actionItem"
                                                            src={eventDel}
                                                            alt="Close"
                                                            onClick={() => removeQuestionRow(questionIndex)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p className="m-0">
                                                            <strong>
                                                                <small className="m-0">Choices for Question No.{questionIndex + 1}</small>
                                                            </strong>
                                                        </p>
                                                    </div>
                                                    {question.questionOptions.map((option, optionIndex) => (
                                                        <div className="col-md-6" key={optionIndex}>
                                                            <div className="choiceBox">
                                                                {/* Radio for correct answer */}
                                                                <span className="isRightAnswer">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        id={`isAnswer_${questionIndex}_${optionIndex}`}
                                                                        name={`isAnswer_${questionIndex}`} // Ensure radio buttons are grouped per question
                                                                        checked={option.isAnswer}
                                                                        onChange={() => handleRadioChange(questionIndex, optionIndex)}
                                                                    />
                                                                </span>

                                                                {/* Input for option action */}
                                                                <Input
                                                                    type="text"
                                                                    id={`option_${questionIndex}_${optionIndex}`}
                                                                    name={`questionData[${questionIndex}].questionOptions[${optionIndex}].option`}
                                                                    value={option.option}
                                                                    onChange={(e) => handleQuestionOptionsInputChange(questionIndex, optionIndex, e.target.value)}
                                                                    customError={touched?.questionData?.[questionIndex]?.questionOptions?.[optionIndex]?.option && errors?.questionData?.[questionIndex]?.questionOptions?.[optionIndex]?.option}
                                                                    errorMessage={errors?.questionData?.[questionIndex]?.questionOptions?.[optionIndex]?.option}
                                                                    erroClass={
                                                                        touched?.questionData?.[questionIndex]?.questionOptions?.[optionIndex]?.option && errors?.questionData?.[questionIndex]?.questionOptions?.[optionIndex]?.option
                                                                            ? "validationError"
                                                                            : ""
                                                                    }
                                                                />
                                                                <span className="removeButton">
                                                                    <img
                                                                        className="removeRow"
                                                                        src={eventCloseRed}
                                                                        alt="Close"
                                                                        onClick={() => removeQuestionOptionsRow(questionIndex, optionIndex)}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <span className="addRowSpan text-right">
                                                        <span
                                                            className="btn btn-sm btn-outline-success"
                                                            onClick={() => addQuestionOptionsRow(questionIndex)}
                                                        >
                                                            Add Choice(s)
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                            }
                            <div className="col-md-12">
                                <div className="mainContent text-right">
                                    <Link to="/training-quizzes" className="btn btn-sm btn-outline-primary">
                                        Back
                                    </Link>
                                    &nbsp;
                                    {isSubmitButtonClicked ? (
                                        <Button className="btn-sm" variant="primary" disabled>
                                            Loading...
                                        </Button>
                                    ) : (
                                        <Button className="btn-sm" variant="primary" type="submit">
                                            Submit Quizzes
                                        </Button>
                                    )}
                                    &nbsp;
                                    <span className="btn btn-sm btn-success" onClick={addQuestionRow}>
                                        Add Question(s)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="formActionButtons"></div>
                </form>
            </div>
            {isToastAddTrainingQuizzes && <ToastContainer />}
        </Layout>
    );
}

export default EditQuizzes;