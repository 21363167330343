import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import { permissions } from '../../../permissions/roles';
import useColumnsWithPermission from "../../../permissions/useColumnsWithPermission";
import {
  GET_PAYROLL_FINAL_LIST,
  BASE_URL,
  ADMIN_ID,
} from "../../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  catchAsync,
} from "../../../helper/helper";
import PayrollBlocks from "../components/PayrollBlocks";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import '@progress/kendo-theme-default/dist/all.css';
import moment from "moment";
import Input from "../../../components/forms-fields/Input";
import infoIcon from "../../../assets/images/icons/infoIcon.svg";
import RateInfo from "../components/RateInfo";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// import AddPayroll from "./finalization/AddPayroll";
import { Link } from "react-router-dom";
import Layout from "../../../components/layouts/Layout";
import FinalRateInfo from "./FinalRateInfo";
function GetFinalPayrollList() {

  const [payrollStartDate, setPayrollStartDate] = useState(moment().startOf("isoWeek").valueOf());
  const [payrollEndDate, setPayrollEndDate] = useState(moment().endOf("isoWeek").valueOf());
  const [viewPayrollTemp, setViewPayrollTemp] = useState(false);
  const [payrollViewData, setPayrollViewData] = useState();

  const [payrollInfo, setPayrollInfo] = useState(false)
  const [payrollInfoPopup, setPayrollInfoPopup] = useState(null)
  // console.log("🚀 ~ GetFinalPayrollList ~ payrollInfoPopup:", payrollInfoPopup)

  const handlePayrollInfo = (item) => {
    setPayrollInfo(!payrollInfo)
    setPayrollInfoPopup(item)
  }



  const [payrollDataList, setPayrollDataList] = useState([]);
  // console.log("🚀 ~ GetFinalPayrollList ~ payrollDataList:", payrollDataList)

  const [apiQuery, setApiQuery] = useState({
    startDate: moment(payrollStartDate).valueOf(),
    endDate: moment(payrollEndDate).valueOf()
  })

  useEffect(() => {
    setApiQuery({
      startDate: payrollStartDate,
      endDate: payrollEndDate
    })
  }, [payrollStartDate, payrollEndDate])
  // console.log("🚀 ~ GetFinalPayrollList ~ apiQuery:", apiQuery)
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);


  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const payrollData = data?.data?.payrollList?.map((item) => item);
      setPayrollDataList(payrollData);
      setFilterSearch(payrollData);
      setIsLoading(false);
      // toastSuccessMessages(toast.success, data?.message, "", 500, 500);
    } else if (method === "delete" && data?.status === isSuccess) {
      console.log(data?.message)

    }

  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_PAYROLL_FINAL_LIST, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, [apiQuery]);
  // console.log("🚀 ~ GetFinalPayrollList ~ apiQuery:", apiQuery)

  const [overTimeHours, setOverTimeHours] = useState(null)
  // Table Columns

  const baseColumns = [
    {
      name: "Name",
      // selector: (row) => row?.staffMemberName || "Unknown",
      selector: (row) => {
        return (
          <span className="usersNameInfo mt-2 mb-2">
            <span className="userImage">
              <img
                src={`${BASE_URL}${row?.userId?.profile_picture_url}`}
                alt={row?.userId?.name}
              />
            </span>
            <span className="userInfo">
              <span className="nameBlock">{`${row?.userId?.name}`}</span>
              <span className="nameBlock"><small>{`${row?.userId?.email}`}</small></span>
            </span>
            <div className="infoBlock">
              <img
                className="curSorPointer"
                onClick={() => handlePayrollViewClick(row)}
                src={infoIcon}
                alt="Settings"
              />
            </div>
          </span>
        );
      },
      width: "300px",
      sortable: true,
      id: "staffMemberName",
      // sortFunction: (a, b) => a.staffMemberName.toLowerCase().localeCompare(b.staffMemberName.toLowerCase()),
    },
    {
      name: "Contracted (Hrs)",
      selector: (row) => {
        const monthlyHours = parseFloat(((row?.contractHours * 52) / 12).toFixed(3));
        return monthlyHours || 0;
      },
      width: "150px",
      center: true
    },

    {
      name: "Training (Hrs)",
      selector: (row) => {
        return row?.trainingHours;
      },
      width: "150px",
      center: true
    },
    {
      name: "Completed (Hrs)",
      selector: (row) => {
        // const holidayHours = row?.holidayHours || 0;
        return row?.assignedHours;
      },
      width: "150px",
      center: true
    },
    {
      name: "Holiday (Hrs)",
      selector: (row) => {
        return row?.holidayHours;
      },
      width: "150px",
      center: true
    },
    {
      name: "Sick (Hrs)",
      selector: (row) => {
        return row?.sickHours;
      },
      width: "150px",
      center: true
    },
    {
      name: "Overtime (Hrs)",
      selector: (row) => {
        let monthlyHours = parseFloat(((row?.contractHours * 52) / 12).toFixed(3));
        let overTimeMonthlyHours;
        if (monthlyHours >= 156) {
          overTimeMonthlyHours = row?.assignedHours > monthlyHours ? row?.assignedHours - monthlyHours : 0;
        }
        else {
          monthlyHours = 156;
          overTimeMonthlyHours = row?.assignedHours > monthlyHours ? row?.assignedHours - monthlyHours : 0;
        }
        return overTimeMonthlyHours || 0;
      },
      width: "150px",
      center: true
    },
    {
      name: (
        <div className="text-center">
          Total Hours <br /> <small>Excluding Sleeping Shifts</small>
        </div>
      ),
      selector: (row) => {
        // trainingHours + assignedHours + holidayHours + holidaySickLeaves - sleepingHours
        const trainingHours = row?.trainingHours || 0;
        const assignedHours = row?.assignedHours || 0;
        const holidayHours = row?.holidayHours || 0;
        const holidaySickLeaves = row?.sickHours || 0;
        const sleepingHours = row?.sleepingHours || 0;
        return trainingHours + assignedHours - sleepingHours;
      },
      width: "180px",
      center: true
    },
    {
      name: "Sleeping Shifts",
      selector: (row) => {
        return row?.sleepCount;
      },
      width: "150px",
      center: true
    },
    {
      name: "Grand Total (£)",
      selector: (row) => {
        // trainingHours + assignedHours + holidayHours + holidaySickLeaves - sleepingHours) * perHourRate + (sleepingHours * overNightSleepRate)
        // const monthlyHours = parseFloat(((row?.contractedHours?.contractedHours * 52) / 12).toFixed(3));

        let monthlyHours = parseFloat(((row?.contractHours * 52) / 12).toFixed(3));
        let overTimeMonthlyHours;
        if (monthlyHours >= 156) {
          overTimeMonthlyHours = row?.assignedHours > monthlyHours ? row?.assignedHours - monthlyHours : 0;
        }
        else {
          monthlyHours = 156;
          overTimeMonthlyHours = row?.assignedHours > monthlyHours ? row?.assignedHours - monthlyHours : 0;
        }

        // const overTimeMonthlyHours = row?.assignedHours > monthlyHours ? row?.assignedHours - monthlyHours : 0;

        const trainingTotal = row?.trainingHours * row?.userId?.modelId?.contractedHours?.trainingRate;
        const assignedTotal = row?.assignedHours * row?.userId?.modelId?.contractedHours?.perHourRate;
        const overTimeTotal = overTimeMonthlyHours * row?.userId?.modelId?.contractedHours?.overtimeRate;
        const sleepingTotal = row?.sleepCount * row?.userId?.modelId?.contractedHours?.overNightSleepRate;

        return (
          <strong>
            £{(trainingTotal + assignedTotal + overTimeTotal + sleepingTotal) || 0}
          </strong>
        )
      },
      width: "150px",
      center: true
    },

  ];

  const columns = useColumnsWithPermission(baseColumns, [permissions.EDIT, permissions.DELETE]);
  // Search Filter
  useEffect(() => {
    const result = payrollDataList.filter((user) => {
      return user?.userId?.name.toLowerCase().match(search.toLowerCase());
    });
    setFilterSearch(result);
  }, [search, payrollDataList]);


  const pdfExportComponent = useRef(null);
  const [showInPrint, setShowInPrint] = useState(false);
  const exportPDF = () => {
    if (pdfExportComponent.current) {
      setShowInPrint(true);
      setTimeout(() => {
        pdfExportComponent.current.save();
      }, 100);
      setTimeout(() => {
        setShowInPrint(false);
      }, 500000);


    }
  };

  const handlePayrollViewClick = (row) => {
    setViewPayrollTemp(!viewPayrollTemp);
    setPayrollViewData(row)
  };


  const currentYear = moment().year();
  const currentMonth = moment().month() + 1;

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  useEffect(() => {
    generatePayrollDates(selectedYear, selectedMonth);
  }, [selectedYear, selectedMonth]);

  const generatePayrollDates = (year, month) => {
    const startDate = moment(`${year}-${month}-15`);
    const endDate = moment(startDate).add(1, 'month').date(14);

    setPayrollStartDate(startDate.valueOf());
    setPayrollEndDate(endDate.valueOf());
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const months = moment.months(); // Array of month names


  return (
    <>
      {/* <PayrollBlocks /> */}
      <Layout pageTitleText="Finalized Payroll">
        <div className="rowClickUnset">
          <div class="subTabsWrapper payrollSubHeader">
            <div class="subTabsHeader radioTabsHeader">
              <div class="tabsBlocks">
                <div class="payrollDateFilter">
                  <div className={`customSelectBox form-group`}>
                    <select value={selectedMonth} onChange={handleMonthChange}>
                      {months.map((month, index) => (
                        <option key={index} value={index + 1}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <label className="floatingLabel">
                      Month
                    </label>
                  </div>
                  <div className={`customSelectBox form-group`}>
                    <select value={selectedYear} onChange={handleYearChange}>
                      {[...Array(3)].map((_, i) => (
                        <option key={i} value={currentYear - i}>
                          {currentYear - i}
                        </option>
                      ))}
                    </select>
                    <label className="floatingLabel">
                      Year
                    </label>
                  </div>



                  <div className="payrollActionButtons">
                    <span className="btn btn-sm btn-outline-primary" onClick={exportPDF}>Export to PDF</span>{" "}
                    <Link to="/payroll" className="btn btn-outline-success btn-sm">Back to Payroll</Link>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="mainContent">

            <div className="dataTableWrapper finalPayrolDataTableWrapper">
              <DataTable
                className="maxWidthTable"
                columns={columns}
                data={filterSearch}
                fixedHeader
                fixedHeaderScrollHeight="450px"
                highlightOnHover
                defaultSortFieldId="staffMemberName"
                defaultSortAsc={true}
                // actions={
                //   <>

                //   </>
                // }
                responsive={true}
                subHeader={true}
                subHeaderComponent={
                  <div className="searchContainer">
                    <div className="searchTextBox">
                      <input
                        type="text"
                        placeholder="Type your search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                }
                subHeaderAlign="left"
                subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
                progressPending={isLoading}
                progressComponent={<ProgressComponent />}
              />
            </div>
          </div>
        </div>
        {viewPayrollTemp && (
          <FinalRateInfo
            payrollViewData={payrollViewData}
            onClose={handlePayrollViewClick}
            item={viewPayrollTemp}
          />
        )}
      </Layout>
    </>
  );
}

export default GetFinalPayrollList;