import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Nav } from "react-bootstrap";
import ProgressComponent from "../../components/general/ProgressComponent";
import { Link } from "react-router-dom";
import { GET_RECRUITMENT_CANDIDATES_URL, BASE_URL } from "../../env";
import {
  catchAsync,
  isSuccess,
  getRecords,
  toastErrorMessages,
  calculateAgeDifference,
} from "../../helper/helper";

function AchievedCandidates() {
  const [isLoading, setIsLoading] = useState(true);
  const [isToastAchievedCandidates, setIsToastAchievedCandidates] =
    useState(false);
  const [archivedCandidates, setArchivedCandidates] = useState([]);
  const [newCandidates, setNewCandidates] = useState([]);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAchievedCandidates(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const archivedCandidates = data?.data?.recruitment?.filter(
        (candidate) => candidate?.status === "archive" && candidate?.isDeleted === false
      );
      const newCandidates = data?.data?.recruitment?.filter(
        (candidate) => candidate?.status === "new" && candidate?.isDeleted === false
      );
      setArchivedCandidates(archivedCandidates);
      setNewCandidates(newCandidates);
      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_RECRUITMENT_CANDIDATES_URL, apiQuery);
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="archievedCandidates">
            <div className="tabsWrapper">
              <Tab.Container id="myTab" defaultActiveKey="activeCandidate">
                <div className="tabsHeader">
                  <div className="actionButtons">
                    <Link
                      to="/recruitment-application-process"
                      className="btn btn-sm btn-primary"
                    >
                      View Application Process
                    </Link>
                  </div>
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="activeCandidate">
                        Active Candidates
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="archivedCandidates">
                        Archieved Candidates
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>

                <Tab.Content>
                  <Tab.Pane eventKey="activeCandidate">
                    <div className="archievedCandidatesContent">
                      {isLoading ? (
                        <div className="ProgressPadding">
                          <ProgressComponent />
                        </div>
                      ) : (
                        <>
                          {newCandidates?.map((row) => (
                            <div key={row?._id} className="blocks">
                              <span className="usersNameInfo">
                                <span className="userImage">
                                  <img
                                    src={BASE_URL + row?.dp}
                                    alt={row?.firstName}
                                  />
                                </span>
                                <span className="userInfo">
                                  <span className="nameBlock">
                                    {row?.firstName} {row?.lastName}
                                  </span>
                                  <span className="infoBlock">
                                    {calculateAgeDifference(row?.dob)} Yrs |{" "}
                                    {row?.gender}
                                  </span>
                                </span>
                              </span>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="archivedCandidates">
                    <div className="archievedCandidatesContent">
                      {isLoading ? (
                        <div className="ProgressPadding">
                          <ProgressComponent />
                        </div>
                      ) : (
                        <>
                          {archivedCandidates?.map((row) => (
                            <div key={row?._id} className="blocks">
                              <span className="usersNameInfo">
                                <span className="userImage">
                                  <img
                                    src={BASE_URL + row?.dp}
                                    alt={row?.firstName}
                                  />
                                </span>
                                <span className="userInfo">
                                  <span className="nameBlock">
                                    {row?.firstName} {row?.lastName}
                                  </span>
                                  <span className="infoBlock">
                                    {calculateAgeDifference(row?.dob)} Yrs |{" "}
                                    {row?.gender}
                                  </span>
                                </span>
                              </span>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
      {isToastAchievedCandidates && <ToastContainer />}
    </>
  );
}

export default AchievedCandidates;
