import React, { useState, useEffect } from "react";
import { Modal, Card, Button } from "react-bootstrap";
import folderIcon from "../../../assets/images/icons/folder.svg";
import EyeView from "../../../assets/images/icons/eyeview.svg";
import Input from "../../../components/forms-fields/Input";
import TextArea from "../../../components/forms-fields/TextArea";
import { FileUploader } from "react-drag-drop-files";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeletePopup from "../../../components/modals/DeletePopup";
import CommentsBoxDisciplinary from "../comments/CommentsBoxDisciplinary";
import PaginationContainer from "../../../components/custom-pegination/PaginationContainer";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import {
  ADMIN_AUTH_TOKEN,
  GET_STAFF_COMPLIANCE_DOCUMENTS_URL,
  PUT_STAFF_COMPLIANCE_DOCUMENTS_URL,
  POST_STAFF_COMPLIANCE_DOCUMENTS_URL,
  UPLOAD_REFERENCE_DOCUMENT_URL,
  BASE_URL,
  DELETE_STAFF_COMPLIANCE_DOCUMENTS_URL,
} from "../../../env";
import {
  getRecords,
  postRecords,
  updateRecords,
  catchAsync,
  displayEnumProperty,
  toastErrorMessages,
  toastSuccessMessages,
  isSuccess,
  updateFormFields,
} from "../../../helper/helper";

const statusClassMapping = {
  approved: "background-success",
  pending: "background-warning",
  rejected: "background-danger",
};

function DisciplinaryDocuments({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUserData] = useState(initialUserData);
  const [documents, setDocuments] = useState([]);
  const [isUpdateDocumentData, setIsUpdateDocumentData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [showUpdateDocDetailsModel, setShowUpdateDocDetailsModel] =
    useState(false);
  const [isToastDisciplinary, setIsToastDisciplinary] = useState(false);
  const [deleteDocRecord, setDeleteDocRecord] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [addDocument, setAddDocument] = useState(false);
  const fileTypes = ["DOC", "DOCX", "PDF", "WORD"];
  const PAGE_SIZE = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastDocument = currentPage * PAGE_SIZE;
  const totalPages = Math.ceil(documents?.length / PAGE_SIZE);
  const onPageChange = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfFirstDocument = indexOfLastDocument - PAGE_SIZE;
  const currentDocuments = documents?.slice(
    indexOfFirstDocument,
    indexOfLastDocument
  );

  const validationSchema = Yup.object().shape({
    data: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Document Title is required"),
        details: Yup.string().required("Comment is required"),
        docPaths: Yup.string().required("Document is required"),
      })
    ),
  });

  const formData = useFormik({
    initialValues: {
      data: [
        {
          title: "",
          status: "pending",
          details: "",
          staffId: userData?._id && userData?._id,
          docPaths: null,
          docType: "disciplinaryDoc",
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const trimmedValues = {
          data: values?.data?.map((item) => ({
            ...item,
            title: item?.title?.trim(),
            status: item?.status?.trim(),
            details: item?.details?.trim(),
            staffId: item?.staffId?.trim(),
            docPaths: item?.docPaths?.trim(),
            docType: item?.docType?.trim(),
          })),
        };
        setAddDocument(!addDocument);
        await uploadReferenceDocument(trimmedValues);
        resetForm();
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error adding Document. Please try again.",
          "",
          1000,
          1000
        );
      }
    },
  });

  const validationSchemaUpdateDocDetails = Yup.object().shape({
    title: Yup.string().required("Document Title is required"),
    details: Yup.string().required("Comment is required"),
  });

  const updateDocDetailsFormData = useFormik({
    initialValues: {
      title: "",
      details: "",
      docId: "",
    },
    validationSchema: validationSchemaUpdateDocDetails,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const trimmedValues = {
          title: values?.title?.trim(),
          details: values?.details?.trim(),
          docId: values?.docId?.trim(),
        };
        handleCloseUpdateDocModel();
        await updateDocumentComment(trimmedValues);
        resetForm();
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error adding comment. Please try again.",
          "",
          1000,
          1000
        );
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = updateDocDetailsFormData;

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    docType: "disciplinaryDoc",
    staffId: userData?._id,
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastDisciplinary(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const result = data?.data?.filter(
        (item) => item?.docType === "disciplinaryDoc"
      );
      setDocuments(result);
      setIsLoading(false);
    } else if (method === "updateDocStatus" && data?.status === isSuccess) {
      setIsToastDisciplinary(true);
      fetchAllRecords();
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
    } else if (method === "uploadRefDocument" && data?.status === isSuccess) {
      setIsToastDisciplinary(true);
      fetchAllRecords();
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
      setIsFileUploaded(false);
    } else if (method === "updateDocDesc" && data?.status === isSuccess) {
      setIsToastDisciplinary(true);
      fetchAllRecords();
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
    } else if (method === "deleteComplianceDoc" && data?.status === isSuccess) {
      setIsToastDisciplinary(true);
      fetchAllRecords();
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
    }
  };

  const fetchAllRecords = catchAsync(async () => {
    const response = await getRecords(
      GET_STAFF_COMPLIANCE_DOCUMENTS_URL,
      apiQuery
    );
    processFetchRecords("get", response);
  });

  const updateDocumentStatus = catchAsync(async (docId, status) => {
    const updateDocument = {
      docId,
      status,
    };
    const response = await updateRecords(
      PUT_STAFF_COMPLIANCE_DOCUMENTS_URL,
      updateDocument
    );
    processFetchRecords("updateDocStatus", response);
  });

  const uploadReferenceDocument = catchAsync(async (req, res) => {
    const response = await postRecords(
      POST_STAFF_COMPLIANCE_DOCUMENTS_URL,
      req
    );
    formData?.resetForm();
    processFetchRecords("uploadRefDocument", response);
  });

  const updateDocumentComment = catchAsync(async (req, res) => {
    console.log(req);
    const response = await postRecords(PUT_STAFF_COMPLIANCE_DOCUMENTS_URL, req);
    console.log(response);
    processFetchRecords("updateDocDesc", response);
  });

  const deleteComplianceDocument = catchAsync(async (req, res) => {
    const response = await postRecords(
      DELETE_STAFF_COMPLIANCE_DOCUMENTS_URL,
      req
    );
    processFetchRecords("deleteComplianceDoc", response);
  });

  const handleFileChangeUploadDoc = catchAsync(async (files) => {
    const selectedUploadDocFile = files;
    if (!selectedUploadDocFile) {
      setIsToastDisciplinary(true);
      toastErrorMessages(toast?.error, "No file selected.", "", 1000, 1000);
      return;
    }
    const formDataToSend = new FormData();
    formDataToSend.append("attachements", selectedUploadDocFile);
    const response = await fetch(UPLOAD_REFERENCE_DOCUMENT_URL, {
      method: "POST",
      body: formDataToSend,
      headers: {
        Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
      },
    });
    const result = await response.json();
    if (result?.status === isSuccess) {
      handleAddDocument(`${result?.data[0]?.fileNameInDirectory}`);
    }
  });

  const handleViewDocument = (e, document) => {
    e.preventDefault();
    const urlToOpen = BASE_URL + document?.docPaths;
    if (document?.docPaths) {
      window.open(urlToOpen, "_blank");
    } else {
      alert("Invalid URL:", urlToOpen);
    }
  };

  const handleViewUploadedDocument = (e, document) => {
    e.preventDefault();
    const urlToOpen = BASE_URL + document?.docPaths;
    if (document?.docPaths) {
      window.open(urlToOpen, "_blank");
    } else {
      alert("Invalid URL:", urlToOpen);
    }
  };

  const handleOpenDocDetailsModel = (e, item) => {
    e.preventDefault();
    setFieldValue("docId", item?._id);
    setFieldValue("title", item?.title);
    setFieldValue("details", item?.details);
    setIsUpdateDocumentData(true);
    setShowUpdateDocDetailsModel(true);
  };

  const handleApproveDeny = async (e, document, status) => {
    e.preventDefault();
    updateDocumentStatus(document?._id, status);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    formData?.resetForm();
    setAddDocument(!addDocument);
    setIsFileUploaded(false);
  };

  const handleChangeFormData = (name, value) => {
    const updatedData = formData?.values?.data?.map((doc) => ({
      ...doc,
      [name]: value,
    }));
    formData.setFieldValue("data", updatedData);
  };

  const handleAddDocument = (docPath) => {
    const existingDocument = formData?.values?.data.find(
      (document) => !document.docPaths
    );

    if (existingDocument) {
      const updatedRefDoc = formData?.values?.data.map((document) =>
        document.docPaths ? document : { ...document, docPaths: docPath }
      );

      formData?.setValues({
        ...formData?.values,
        data: updatedRefDoc,
      });
      setIsFileUploaded(true);
    } else {
      const newRefDoc = {
        title: formData?.values?.data[0]?.title,
        status: "pending",
        details: formData?.values?.data[0]?.details,
        staffId: formData?.values?.data[0]?.staffId,
        docPaths: docPath,
        docType: "disciplinaryDoc",
      };
      formData?.setValues({
        ...formData?.values,
        data: [...formData?.values?.data, newRefDoc],
      });
      setIsFileUploaded(true);
    }
  };

  const handleOpenDeleteModel = (e, document) => {
    e.preventDefault();
    setDeleteDocRecord(document);
    setIsShowModal(true);
  };

  const handleCloseDeleModel = () => {
    setDeleteDocRecord(false);
    setIsShowModal(false);
  };

  const handleCloseUpdateDocModel = () => {
    formData?.resetForm();
    setIsUpdateDocumentData(false);
    setShowUpdateDocDetailsModel(false);
  };

  const deleteRecord = () => {
    const req = {
      docId: deleteDocRecord?._id,
    };
    if (req) {
      deleteComplianceDocument(req);
      setIsShowModal(false);
    } else {
      console.log("Error deleting document");
    }
  };

  const handleRemoveUploadedDoc = (e, document) => {
    e.preventDefault();
    const updatedRefDoc = formData?.values?.data.filter(
      (doc) => doc?.docPaths !== document?.docPaths
    );

    formData?.setValues((prevFormData) => ({
      ...prevFormData,
      data: updatedRefDoc,
    }));

    setIsFileUploaded(updatedRefDoc?.length > 0);
  };

  const handleShowAddDocument = (e) => {
    e.preventDefault();
    formData?.resetForm();
    setAddDocument(true);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () => (
    <PaginationContainer
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
    />
  );

  const renderDropdownItems = (document) => {
    const isApproved = document?.status === "approved";
    const isRejected = document?.status === "rejected";
    return (
      <>
        <Dropdown.Item
          href="#"
          onClick={(e) => handleViewDocument(e, document)}
        >
          View Document
        </Dropdown.Item>
        <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
          <Dropdown.Item
            href="#"
            onClick={(e) => handleOpenDocDetailsModel(e, document)}
          >
            View Comment
          </Dropdown.Item>
        </ProtectedComponent>
        <ProtectedComponent requiredPermissions={[permissions.ADD]}>
          {!isApproved && (
            <Dropdown.Item
              href="#"
              onClick={(e) => handleApproveDeny(e, document, "approved")}
            >
              Approve
            </Dropdown.Item>
          )}

          {!isRejected && (
            <Dropdown.Item
              href="#"
              onClick={(e) => handleApproveDeny(e, document, "rejected")}
            >
              Reject
            </Dropdown.Item>
          )}
        </ProtectedComponent>
        <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
          <Dropdown.Item
            href="#"
            onClick={(e) => handleOpenDeleteModel(e, document)}
          >
            Delete
          </Dropdown.Item>
        </ProtectedComponent>
      </>
    );
  };

  const documentsContentLoops = () => {
    return documents?.map((document, index) => (
      <div className="col-md-6 col-sm-6" key={document?._id}>
        <div className="contentBlock">
          <header className="contentHeader">
            <span
              className={`statusButton ${statusClassMapping[document?.status]}`}
            >
              {displayEnumProperty("disciplinaryDoc", document?.status)}
            </span>
            <DropdownButton align={{ lg: "end" }}>
              {renderDropdownItems(document)}
            </DropdownButton>
          </header>
          <div className="contentHeader">
            <img src={folderIcon} alt="folderIcon" className="mt-2" />
          </div>
          <div className="documentInfo mt-3">
            <h6>{document?.title && document?.title}</h6>
            <p>
              {document?.details ? document?.details?.slice(0, 20) + "..." : ""}
            </p>
          </div>
        </div>
      </div>
    ));
  };

  const uploadedDocumentsLoop = () => {
    return (
      formData?.values?.data?.docPaths !== null &&
      formData?.values?.data?.map((document, index) => (
        <div
          className="box-shadow "
          key={document?.docPaths}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span className="">
            <img src={folderIcon} alt="Folder Icon" style={{ width: "33px" }} />
          </span>
          <span className="display-flex-gap-10">
            <img
              src={EyeView}
              alt="View Document"
              className="addEditIcon cursor-pointer"
              onClick={(e) => handleViewUploadedDocument(e, document)}
            />
            <span
              onClick={(e) => handleRemoveUploadedDoc(e, document)}
              className="redColor cursor-pointer mt-1 font-size-16"
            >
              X
            </span>
          </span>
        </div>
      ))
    );
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  return isLoading ? (
    <div className="loadingText">Loading...</div>
  ) : (
    <>
      <div className="complianceDocuemnts">
        <div className="row">
          {!addDocument ? (
            <>
              <div className="display-flex-end mb-2">
                <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <Button
                    type="button"
                    className="btn-sm btn btn-primary"
                    onClick={(e) => handleShowAddDocument(e)}
                  >
                    Add Document
                  </Button>
                </ProtectedComponent>

              </div>
              <div className="col-sm-8">
                <div className="row">{documentsContentLoops()}</div>
                {/* <div className="row mb-0 paginationContainer">
                  <span>{totalPages > 1 && renderPagination()}</span>
                </div> */}
              </div>
              <div className="col-sm-4">
                <div className="commentColumn commentContent">
                  <h6 className="heading">Comment</h6>
                  <CommentsBoxDisciplinary userDataRecord={userData} />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="addDocumentForm h-100">
                <form onSubmit={formData?.handleSubmit}>
                  <div className="row h-100">
                    <div className="col-sm-12">
                      <div className="addDocumentFormFields h-100">
                        <h6 className="heading">Add Documents</h6>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group customFileUpload">
                              <FileUploader
                                handleChange={(files) =>
                                  handleFileChangeUploadDoc(files)
                                }
                                name="file"
                                types={fileTypes}
                                classes={`drop_area drop_zone ${formData?.errors?.data?.[0]?.docPaths !==
                                  undefined
                                  ? "docUploadValidation"
                                  : ""
                                  }`}
                                label="Drag and drop file here!"
                              />
                              {formData?.touched?.data?.[0]?.docPaths !==
                                undefined &&
                                formData?.errors?.data?.[0]?.docPaths !==
                                undefined ? (
                                <span className="form-group text-danger">
                                  {formData?.errors?.data?.[0]?.docPaths}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <Input
                            type="text"
                            name="title"
                            value={formData?.values?.data[0]?.title}
                            onBlur={formData?.handleBlur}
                            onChange={(e) =>
                              handleChangeFormData("title", e.target?.value)
                            }
                            labelText="Document Title"
                            placeholder="Type Here"
                            customError={
                              formData?.touched?.data?.[0]?.title &&
                              formData?.errors?.data?.[0]?.title
                            }
                            errorMessage={formData?.errors?.data?.[0]?.title}
                            errorClass={
                              formData?.touched?.data?.[0]?.title &&
                                formData?.errors?.data?.[0]?.title
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                          <Input
                            type="text"
                            name="details"
                            value={formData?.values?.data[0]?.details}
                            onBlur={formData?.handleBlur}
                            onChange={(e) =>
                              handleChangeFormData("details", e.target?.value)
                            }
                            labelText="Comment"
                            placeholder="Type Here"
                            customError={
                              formData?.touched?.data?.[0]?.details &&
                              formData?.errors?.data?.[0]?.details
                            }
                            errorMessage={formData?.errors?.data?.[0]?.details}
                            errorClass={
                              formData?.touched?.data?.[0]?.details &&
                                formData?.errors?.data?.[0]?.details
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                          {isFileUploaded ? (
                            <section className="col-md-12 col-sm-12 mt-4">
                              <p>Selected Documents</p>
                              <hr className="basic-hr" />
                              <div className="row display-grid-container">
                                {uploadedDocumentsLoop()}
                              </div>
                            </section>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer>
                    <div className="formActionButtons">
                      <Button
                        variant="primary-outline"
                        className="btn btn-sm btn-outline-primary"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        className="btn-sm"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </footer>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
      {isShowModal && (
        <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
          <DeletePopup
            show={isShowModal}
            onHide={handleCloseDeleModel}
            title="Confirmation"
            content="Are you sure you want to delete this document?"
            onConfirm={deleteRecord}
            onCancel={handleCloseDeleModel}
          />
        </ProtectedComponent>
      )}
      {isUpdateDocumentData && (
        <Modal
          show={showUpdateDocDetailsModel}
          onHide={handleCloseUpdateDocModel}
          className="ftcModalPopup"
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Complete Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mt-3">
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <Input
                    type="text"
                    name="title"
                    value={values?.title}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    labelText="Title"
                    placeholder="Type Here"
                    customError={touched?.title && errors?.title}
                    errorMessage={errors?.title}
                    errorClass={
                      touched?.title && errors?.title ? "validationError" : ""
                    }
                    requiredStar={true}
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <TextArea
                    name="details"
                    rows={6}
                    cols={6}
                    value={values?.details}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    labelText="Comment"
                    placeholder="Type Here"
                    customError={touched?.details && errors?.details}
                    errorMessage={errors?.details}
                    errorClass={
                      touched?.details && errors?.details
                        ? "validationError"
                        : ""
                    }
                    requiredStar={true}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="formActionButtons">
                <>
                  <Button
                    variant="outline-secondary"
                    className="btn-sm"
                    onClick={handleCloseUpdateDocModel}
                  >
                    Close
                  </Button>
                  <Button variant="primary" className="btn-sm" type="submit">
                    Update
                  </Button>
                </>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      )}
      {isToastDisciplinary ? <ToastContainer /> : ""}
    </>
  );
}

export default DisciplinaryDocuments;
