import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { addToileting } from "../../../../../../schemas";
import { useFormik } from "formik";
import ReactDOMServer from 'react-dom/server';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDTE_CARE_PLAN_URL } from "../../../../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import Layout from "../../../../../../components/layouts/Layout";
import { Form } from "react-bootstrap";

function EditToileting() {
  const location = useLocation();
  const carePlanDetail = location?.state ? location?.state?.editCarePlanDetail[0] : null;
  // console.log("🚀 ~ EditCareNotes ~ carePlanDetail:", carePlanDetail)
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(carePlanDetail?.addedby);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
  };
  const [assistanceRequiredString, setAssistanceRequiredString] = useState(false);

  const assistanceRequiredRef = useRef(null);
  const assistanceTypeRef = useRef(null);
  const commentsRef = useRef(null);
  useEffect(() => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = carePlanDetail?.noteDetails || '';

    assistanceRequiredRef.current.innerText = tempDiv.querySelector('#assistanceRequiredData')?.innerText || '';
    assistanceTypeRef.current.innerText = tempDiv.querySelector('#assistanceTypeData')?.innerText || '';
    commentsRef.current.innerText = tempDiv.querySelector('#commentsData')?.innerText || '';

    setAssistanceRequiredString(assistanceRequiredRef.current.innerText === "Yes");
  }, [carePlanDetail?.noteDetails]);

  // useEffect(() => {
  //   if (assistanceRequiredRef.current?.innerText === "Yes") {
  //     setAssistanceRequiredString(true)
  //   }
  //   else {
  //     setAssistanceRequiredString(false)
  //   }
  // }, [carePlanDetail?.noteDetails]);
  console.log("🚀 ~ EditToileting ~ assistanceRequiredString:", assistanceRequiredString)

  const [suFormData, setSuFormData] = useState({

    addedby: carePlanDetail?.addedby?._id,
    assistanceType: '',
    assistanceRequired: assistanceRequiredString,
    comments: '',
    eventDateTime: carePlanDetail?.eventDateTime,
  });




  console.log("🚀 ~ EditToileting ~ suFormData Updated:", suFormData)
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addToileting,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);

        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });


  useEffect(() => {
    setValues(prevState => ({
      ...prevState,
      assistanceType: assistanceTypeRef.current?.innerText || '',
      assistanceRequired: assistanceRequiredString,
      comments: commentsRef.current?.innerText || '',
    }));

  }, [carePlanDetail?.noteDetails, assistanceRequiredString]);

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "/care-plan/general-notes", 10, 10);
      setIsSubmitButtonClicked(false);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const noteDetailsString = ReactDOMServer.renderToString(htmlData);
    const updateValues = {
      carePlanid: carePlanDetail?._id,
      userId: carePlanDetail?.userId,
      noteType: carePlanDetail?.noteType,
      title: values?.comments,
      flag: carePlanDetail?.flag,
      noteDetails: carePlanDetail?.noteDetails,
      eventDateTime: moment(values.eventDateTime).valueOf(),

      addedby: selectedUserId,
      isHTML: true,
      noteDetails: noteDetailsString,
    }

    console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(UPDTE_CARE_PLAN_URL, updateValues);
    console.log("🚀 ~ response ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });



  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setSuFormData({
      ...suFormData,
      [name]: value
    });
    handleChange(e);
    handleBlur(e);
  };

  useEffect(() => {
    if (values.assistanceRequired) {
      setValues(prevState => ({
        ...prevState,
        assistanceType: assistanceTypeRef.current?.innerText || '',
        // assistanceRequired: assistanceRequiredString,
        comments: commentsRef.current?.innerText || '',
      }));
    } else {
      setValues(prevState => ({ ...prevState, assistanceType: "Handled alone", comments: "No assistance required" }));
    }
  }, [values.assistanceRequired]);

  const [htmlData, setHtmlData] = useState("")


  // parent contact check boxes list
  const [whichAssistanceType, setWhichAssistanceType] = useState([
    { id: 1, value: "Bowel", label: "Bowel", },
    { id: 2, value: "Urine", label: "Urine", },
  ]);

  useEffect(() => {
    setHtmlData(
      <>
        <div className="col-md-12">
          <p className="text-left"><strong>Was assistance required with toileting? </strong><span id="assistanceRequiredData" className={values?.assistanceRequired ? "text-success" : "text-danger"}>{values?.assistanceRequired ? "Yes" : "No"}</span></p>
        </div>
        <div className="col-md-12">
          <p><strong>Assistance type: </strong><span id="assistanceTypeData">{values?.assistanceType !== "" ? values?.assistanceType : "Handled alone"}</span></p>
        </div>
        <div className="col-md-12">
          <p><strong>Comments: </strong><span id="commentsData">{values?.comments !== "" ? values?.comments : "No assistance requried"}</span></p>
        </div>
      </>
    );
  }, [values]);

  return (
    <>

      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Edit Toileting note</h3>

              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={values?.addedby}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Date / Time"
                        customError={touched?.eventDateTime && errors?.eventDateTime}
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-12 mb-3">
                      <Form.Check
                        type="checkbox"
                        id={'assistanceRequired'}
                        name={'assistanceRequired'}
                        checked={values?.assistanceRequired}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        label={'Was assistance required with toileting?'}
                      />
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="general m-2">If yes, what assistance?</label>
                      <div className="checkboxList spaceInCheckList">
                        {whichAssistanceType.map((row, index) => (
                          <>
                            <Form.Check
                              name="assistanceType"
                              id={row?.label}
                              type="radio"
                              label={row?.label}
                              value={row?.value}
                              onClick={() => setValues(prevState => ({ ...prevState, assistanceType: row?.value }))}
                              checked={values?.assistanceType === row?.label && true}
                              onChange={handleInputChangePrevFields}
                              onBlur={handleBlur}
                              disabled={values?.assistanceRequired ? false : true}
                            />
                          </>
                        ))}

                      </div>
                    </div>
                    <div className="col-md-12">
                      <TextArea
                        id="comments"
                        name="comments"
                        value={values?.comments}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Comments"
                        rows="3"
                        className="mb-0"
                        customError={touched?.comments && errors?.comments}
                        errorMessage={errors?.comments}
                        erroClass={
                          touched?.comments && errors?.comments
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                        disabledStatus={values?.assistanceRequired ? false : true}
                      />
                    </div>

                  </div>
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      // onClick={subModalAddCarePlans}
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </form>
              <div className="referenceDiv">
                <div ref={assistanceRequiredRef}></div>
                <div ref={assistanceTypeRef}></div>
                <div ref={commentsRef}></div>
              </div>
              {isToastAddCarePlans && <ToastContainer />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default EditToileting