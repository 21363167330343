import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FileUploader } from "react-drag-drop-files";
import Layout from "../../components/layouts/Layout";
import UserDetailHeader from "../../components/user-detail/UserDetailHeader";
import SelectBox from "../../components/forms-fields/SelectBox";
import eyeView from "../../assets/images/icons/eyeview.svg";
import Input from "../../components/forms-fields/Input";
import TextArea from "../../components/forms-fields/TextArea";
import ProgressComponent from "../../components/general/ProgressComponent";
import CustomSelectBox from "../../components/forms-fields/CustomSelectBox";
import {
  trainingPrpsDescription,
  additionalFieldsOptions,
  formFields,
  trainingType,
  trainingClass,
  prpsTrgStatusOptions,
  trainingPrpsName,
  CustomOption,
} from "../../helper/helperJsx";
import {
  POST_CREATE_TRAINING_TEMPLATE_URL,
  POST_UPLOAD_TRAINING_FILE_URL,
  ADMIN_AUTH_TOKEN,
  BASE_URL,
} from "../../env";
import {
  toastErrorMessages,
  isSuccess,
  catchAsync,
  handleDateChangeNumeric,
  postRecords,
} from "../../helper/helper";

function RecentIncidents() {
  const navigate = useNavigate();
  const [isSuccessToast, setIsSuccessToast] = useState(false);
  const [isSubmitClicked, setIsSubmittedClicked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [startDateState, setStartDateState] = useState(null);
  const [endDateState, setEndDateState] = useState(null);
  const [registrationDate, setRegistrationDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const generateValidationSchema = (fields) => {
    const validationObject = {};
    fields?.forEach((field) => {
      const { name, label } = field;

      if (name === "prpsTrgStartDate") {
        validationObject[name] = Yup.number().required(`${label} is required`);
      } else if (name === "prpsTrgEndDate") {
        validationObject[name] = Yup.number()
          .required("End Date is required")
          .min(
            Yup.ref("prpsTrgStartDate"),
            "End Date must be greater than Start Date"
          );
      } else {
        validationObject[name] = Yup.string().required(`${label} is required`);
      }
    });
    return Yup.object().shape(validationObject);
  };

  const validationSchema = generateValidationSchema(formFields);

  const formikFormData = useFormik({
    initialValues: {
      prpsName: trainingPrpsName,
      prpsTrgType: "annual",
      prpsTrgClass: "onsite",
      prpsTrgStatus: "pending",
      prpsTrgStartDate: null,
      prpsTrgEndDate: null,
      prpsTrgRegisterationDate: null,
      prpsDescription: trainingPrpsDescription,
      trainingHours: 0,
      content: [
        {
          filePath: "",
          contentType: "",
          docName: ""
        }
      ]
    },
    // validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      setIsSubmittedClicked(true);
      try {
        console.log("🚀 ~ onSubmit: ~ values:", values)
        await addNewTrainingTemp(values);
        // console.log("Form submitted successfully!");
        resetForm();
      } catch (error) {
        setIsSubmittedClicked(false);
        console.error("Error submitting form:", error);
      }

    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = formikFormData;

  // console.log(formikFormData);
  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsSuccessToast(true);
      setIsSubmittedClicked(false);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "uploadTrainingTemp" && data?.status === isSuccess) {
      navigate("/training");
    }
  };

  const addNewTrainingTemp = catchAsync(async (req, res) => {
    const response = await postRecords(POST_CREATE_TRAINING_TEMPLATE_URL, req);
    resetForm();
    processFetchRecords("uploadTrainingTemp", response);
  });

  const handleFileChangeUpload = catchAsync(async (file) => {
    // console.log("handleFileChangeUpload", file);

    if (!file) {
      setIsSuccessToast(true);
      toastErrorMessages(toast?.error, "No file selected.", "", 1000, 1000);
      return;
    }
    // console.log(file);

    const formDataToSend = new FormData();
    formDataToSend.append("doc", file);

    const response = await fetch(POST_UPLOAD_TRAINING_FILE_URL, {
      method: "POST",
      body: formDataToSend,
      headers: {
        Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
      },
    });
    const result = await response.json();
    // console.log("🚀 ~ handleFileChangeUpload ~ resultresultresultresult:", result)
    if (result?.status === isSuccess) {
      setFieldValue("content", {
        filePath: result?.data,
        contentType: values?.content[0]?.contentType,
        docName: file?.name
      });
    }
  });

  const handleDateChangeToNumeric = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, handleDateChangeNumeric(value, "numeric"));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const selectBoxValueChange = (selected) => {
    const contentTypeValue = selected?.value;
    setFieldValue("content[0].contentType", contentTypeValue);  // Corrected this line
    setSelectedOptions([selected]);
  };

  const renderComponent = (option) => {
    switch (option?.value) {
      case "image":
      case "document":
      case "video":
        return (
          <FileUploader
            name={"content"}
            values={values}
            touched={touched}
            errors={errors}
            handleChange={(files) => handleFileChangeUpload(files)}
            allowedFileTypes={getAllowedFileTypes(option?.value)}
            label={`Drag and drop ${option?.label} here!`}
            requiredStar={true}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <Layout pageTitleText="Add Training">
      <div className="incidents training">
        <UserDetailHeader />
        <div className="mainContent">
          {isLoading ? (
            <ProgressComponent />
          ) : (
            <form
              encType="multipart/form-data"
              method="post"
              onSubmit={handleSubmit}
            >
              <div className="mainContentText addTrainingText">
                <h4 className="trainingTitleHr">
                  {trainingType?.find(
                    (type) => type?.value === values?.prpsTrgType
                  )?.label + " Training"}
                </h4>
                {values?.prpsName && <p>{values?.prpsName}</p>}
                <div className="mb-1">
                  <strong>Introduction</strong>
                </div>
                {values?.prpsDescription && <p>{values.prpsDescription}</p>}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <Input
                        type="text"
                        name="prpsName"
                        value={values?.prpsName}
                        onChange={handleChange}
                        omBlurFunc={handleBlur}
                        labelText="Training Title"
                        customError={touched?.prpsName && errors?.prpsName}
                        errorMessage={errors?.prpsName}
                        errorClass={
                          touched?.prpsName && errors?.prpsName
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <Input
                        type="date"
                        name="prpsTrgStartDate"
                        value={startDateState}
                        onChange={handleDateChangeToNumeric}
                        omBlurFunc={handleBlur}
                        labelText="Training Start Date"
                        customError={
                          touched?.prpsTrgStartDate && errors?.prpsTrgStartDate
                        }
                        errorMessage={errors?.prpsTrgStartDate}
                        errorClass={
                          touched?.prpsTrgStartDate && errors?.prpsTrgStartDate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <Input
                        type="date"
                        name="prpsTrgEndDate"
                        value={endDateState}
                        onChange={handleDateChangeToNumeric}
                        omBlurFunc={handleBlur}
                        labelText="Training End Date"
                        customError={
                          touched?.prpsTrgEndDate && errors?.prpsTrgEndDate
                        }
                        errorMessage={errors?.prpsTrgEndDate}
                        errorClass={
                          touched?.prpsTrgEndDate && errors?.prpsTrgEndDate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <Input
                        type="number"
                        name="trainingHours"
                        value={values?.trainingHours}
                        onChange={handleChange}
                        omBlurFunc={handleBlur}
                        labelText="Training Hours"
                        // customError={
                        //   touched?.trainingHours && errors?.trainingHours
                        // }
                        // errorMessage={errors?.trainingHours}
                        // errorClass={
                        //   touched?.trainingHours && errors?.trainingHours
                        //     ? "validationError"
                        //     : ""
                        // }
                        // requiredStar={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <Input
                        type="date"
                        name="prpsTrgRegisterationDate"
                        value={registrationDate}
                        onChange={handleDateChangeToNumeric}
                        omBlurFunc={handleBlur}
                        labelText="Training Registration Date"
                        customError={
                          touched?.prpsTrgRegisterationDate &&
                          errors?.prpsTrgRegisterationDate
                        }
                        errorMessage={errors?.prpsTrgRegisterationDate}
                        errorClass={
                          touched?.prpsTrgRegisterationDate &&
                            errors?.prpsTrgRegisterationDate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <CustomSelectBox
                        name="prpsTrgStatus"
                        defaultValue={prpsTrgStatusOptions?.find((item) => {
                          return item?.value === values?.prpsTrgStatus;
                        })}
                        options={prpsTrgStatusOptions}
                        onChange={(selectedOption) => {
                          setFieldValue("prpsTrgStatus", selectedOption?.value);
                        }}
                        errors={errors?.prpsTrgStatus}
                        labelText="Training Status"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <CustomSelectBox
                        name="prpsTrgType"
                        defaultValue={trainingType?.find((item) => {
                          return item?.value === values?.prpsTrgType;
                        })}
                        options={trainingType}
                        onChange={(selectedOption) => {
                          setFieldValue("prpsTrgType", selectedOption?.value);
                        }}
                        errors={errors?.prpsTrgType}
                        labelText="Training Type"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <CustomSelectBox
                        defaultValue={selectedOptions}
                        options={additionalFieldsOptions}
                        onChange={(selectedOption) =>
                          selectBoxValueChange(selectedOption)
                        }
                        isClearable={true}
                        isMulti={false}
                        errors={errors?.prpsTrgStatus}
                        labelText="Additional Content"
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <CustomSelectBox
                        name="prpsTrgClass"
                        defaultValue={trainingClass?.find((item) => {
                          return item?.value === values?.prpsTrgClass;
                        })}
                        options={trainingClass}
                        onChange={(selectedOption) => {
                          setFieldValue("prpsTrgClass", selectedOption?.value);
                        }}
                        errors={errors?.prpsTrgClass}
                        labelText="Training Class"
                        required
                      />
                    </div>
                  </div>
                  {selectedOptions &&
                    selectedOptions?.map((option) => (
                      <div className="col-sm-12 col-md-12">
                        {option?.value === "text" ? (
                          <div className="form-group">
                            <TextArea
                              name="content"
                              className="editableTextArea"
                              value={values?.content}
                              onChange={(e) => handleInputChange(e)}
                              labelText="Text"
                              placeholder="Type here"
                              rows={2}
                              customError={touched?.content && errors?.content}
                              errorMessage={errors?.content}
                              errorClass={
                                touched?.content && errors?.content
                                  ? "validationError"
                                  : ""
                              }
                              requiredStar={true}
                            />
                          </div>
                        ) : (
                          <div
                            key={option?.value}
                            className={`customFileUpload form-group ${touched?.content && errors?.content
                              ? "validationError"
                              : ""
                              }`}
                          >
                            <span
                              className={`${values?.content !== null &&
                                values?.content !== ""
                                ? "d-grid-17-2-fr"
                                : null
                                }`}
                            >
                              {renderComponent(option)}
                              {values?.content !== null &&
                                values?.content !== "" && (
                                  <a
                                    href={BASE_URL + values?.content}
                                    target="_blank"
                                  >
                                    <img src={eyeView} alt="" />
                                  </a>
                                )}
                            </span>
                            {touched?.content && errors?.content ? (
                              <div className="text-danger customValidationMsg">
                                {errors?.content}
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    ))}
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group">
                      <TextArea
                        type="text"
                        name="prpsDescription"
                        value={values?.prpsDescription}
                        onChange={handleChange}
                        omBlurFunc={handleBlur}
                        labelText="Description"
                        limitCounter={3000}
                        rows={3}
                        cols={3}
                        customError={
                          touched?.prpsDescription && errors?.prpsDescription
                        }
                        errorMessage={errors?.prpsDescription}
                        errorClass={
                          touched?.prpsDescription && errors?.prpsDescription
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="clearfix">&nbsp;</div>
                <div className="formActionButtons">
                  {isSubmitClicked ? (
                    <Button
                      type="button"
                      className="btn-sm btn-primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <>
                      <Link
                        to="/training"
                        className="btn btn-sm btn-outline-primary"
                      >
                        Cancel
                      </Link>
                      <Button type="submit" className="btn-sm btn-primary">
                        Submit
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      {isSuccessToast && <ToastContainer />}
    </Layout>
  );
}

const getAllowedFileTypes = (optionValue) => {
  switch (optionValue) {
    case "image":
      return ["JPG", "PNG", "GIF", "JPEG"];
    case "document":
      return ["DOC", "PDF", "WORD"];
    case "video":
      return ["MP4", "AVI", "MKV"];
    default:
      return [];
  }
};

export default RecentIncidents;
