import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import { addMedicineList } from "../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDTE_MEDICINE_STOCK_URL } from "../../env";
import {
  updateRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  postRecords
} from "../../helper/helper";
import { Link } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import moment from 'moment';
import Select from 'react-select';
import TextArea from '../../components/forms-fields/TextArea';

function EditMedicineList() {
  const storedMedicineDetail = localStorage.getItem("medicineListDetail");
  const medicineListDetail = JSON.parse(storedMedicineDetail);
  console.log("🚀 ~ EditMedicineList ~ medicineListDetail:", medicineListDetail)
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddMedicineList, setIsToastAddMedicineList] = useState(false);


  const editor = useRef(null);
  const [content, setContent] = useState(medicineListDetail?.note);

  const [suFormData, setSuFormData] = useState({
    medicationId: medicineListDetail?._id,
    medicationName: medicineListDetail?.medicationName,
    medicineType: medicineListDetail?.medicineType,
    strength: medicineListDetail?.strength,
    unitType: medicineListDetail?.unitType,
    sideEffect: medicineListDetail?.sideEffect,
    description: medicineListDetail?.description,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addMedicineList,
    onSubmit: async (values) => {
      try {
        console.log("Form Data:", values);
        setIsSubmitButtonClicked(true);
        await postEditPbsPlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddMedicineList(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (
      method === "postEditPbsPlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddMedicineList(true);
      toastSuccessMessages(toast.success, data?.message, "/medicine-list", 500, 500);

      setIsSubmitButtonClicked(false);
      setTimeout(() => {
      }, 2000);
    }
  };

  const postEditPbsPlansFormData = catchAsync(async (req, res) => {
    const response = await postRecords(UPDTE_MEDICINE_STOCK_URL, values);
    console.log("🚀 ~ postEditPbsPlansFormData ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postEditPbsPlansFormData", response);
  });



  const handleMedicineType = (e) => {
    if (e && e?.value !== undefined) {
      setFieldValue("medicineType", e.value);
    }
  };
  const handleUnitType = (e) => {
    if (e && e?.value !== undefined) {
      setFieldValue("unitType", e.value);
    }
    else {
      handleChange(e);
      handleBlur(e);
    }
  };
  const medicineTypeOptions = [
    { label: 'Controlled Drug', value: 'Controlled Drug' },
    { label: 'Regular Medication', value: 'Regular Medication' },
    { label: 'Topical Medication', value: 'Topical Medication' },
  ];
  const unitTypeOptions = [
    { label: 'Capsules', value: 'Capsules' },
    { label: 'Chewable Tablet', value: 'Chewable Tablet' },
    { label: 'Dispersible Tablet', value: 'Dispersible Tablet' },
    { label: 'Ear Drops', value: 'Ear Drops' },
    { label: 'Eye Drops', value: 'Eye Drops' },
    { label: 'Inhaler', value: 'Inhaler' },
    { label: 'Injection', value: 'Injection' },
    { label: 'Oral Suspension (liquid)', value: 'Oral Suspension (liquid)' },
    { label: 'Patch', value: 'Patch' },
    { label: 'Sachet', value: 'Sachet' },
    { label: 'Spray', value: 'Spray' },
    { label: 'Suppository', value: 'Suppository' },
    { label: 'Tablets', value: 'Tablets' },
    { label: 'Topical (Cream)', value: 'Topical (Cream)' },
    // Add more options as needed
  ];
  return (
    <>
      <Layout
        pageTitleText={
          <>
            Medicine List
          </>
        }
      >
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent">
            <div className="mainContentText">
              <h3>Edit Medicine</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-4">
                      <Input
                        type="text"
                        id="medicationName"
                        name="medicationName"
                        value={values?.medicationName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Medicine Name"
                        customError={touched?.medicationName && errors?.medicationName}
                        errorMessage={errors?.medicationName}
                        erroClass={
                          touched?.medicationName && errors?.medicationName
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        type="text"
                        id="strength"
                        name="strength"
                        value={values?.strength}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Strength"
                        customError={touched?.strength && errors?.strength}
                        errorMessage={errors?.strength}
                        erroClass={
                          touched?.strength && errors?.strength
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        type="text"
                        id="sideEffect"
                        name="sideEffect"
                        value={values?.sideEffect}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Side Effects"
                        customError={touched?.sideEffect && errors?.sideEffect}
                        errorMessage={errors?.sideEffect}
                        erroClass={
                          touched?.sideEffect && errors?.sideEffect
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="customSelectBox">
                        <div className={`form-group ${(errors.unitType && touched.unitType) ? "validationError" : null}`}>
                          <Select
                            name="unitType"
                            options={unitTypeOptions}
                            onChange={handleUnitType}
                            onBlur={handleBlur}
                            value={unitTypeOptions.find(unitTypeOptions => unitTypeOptions.value === values?.unitType)}

                          />
                          <label className="floatingLabel">Unit Type<span className="text-danger">*</span></label>
                          {/* Display error message if touched and has an error */}
                          {touched.unitType && errors.unitType && (
                            <div className="text-danger">{errors.unitType}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="customSelectBox">
                        <div className={`form-group ${(errors.medicineType && touched.medicineType) ? "validationError" : null}`}>
                          <Select
                            name="medicineType"
                            options={medicineTypeOptions}
                            onChange={handleMedicineType}
                            onBlur={handleBlur}
                            value={medicineTypeOptions.find(medicineTypeOptions => medicineTypeOptions.value === values?.medicineType)}

                          />
                          <label className="floatingLabel">Medicine Type <span className="text-danger">*</span></label>
                          {/* Display error message if touched and has an error */}
                          {touched.medicineType && errors.medicineType && (
                            <div className="text-danger">{errors.medicineType}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">

                      <TextArea
                        id="description"
                        name="description"
                        value={values?.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Description"
                        rows="3"
                        className="mb-0"
                        customError={touched?.description && errors?.description}
                        errorMessage={errors?.description}
                        erroClass={
                          touched?.description && errors?.description
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                  </div>
                </div>
                <div className="formActionButtons mt-5">
                  <Link to="/medicine-list">
                    <span
                      className="btn btn-sm btn-outline-primary"
                    >
                      Cancel
                    </span>
                  </Link>
                  {isSubmitButtonClicked ? (
                    <Button
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Save
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
      {isToastAddMedicineList && <ToastContainer />}
    </>
  );
}
export default EditMedicineList