import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import ProgressComponent from "../../components/general/ProgressComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL, GET_ALL_SUPERVISIONS_FULL_DETAILS_URL } from "../../env";
import {
  getRecords,
  isSuccess,
  filterColumns,
  catchAsync,
  displayEnumProperty,
  toastErrorMessages,
  formateDate,
} from "../../helper/helper";
import moment from "moment/moment";

function SupervisionGroup({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUserData] = useState(initialUserData);
  const [filteredRows, setFilteredRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isToastSupervisionDetails, setIsToastSupervisionDetails] =
    useState(false);

  const navigate = useNavigate();
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true, staffMember: userData?._id },
    },
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastSupervisionDetails(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const result = data?.data?.supervisions;
      setFilteredRows(result);
      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(
      GET_ALL_SUPERVISIONS_FULL_DETAILS_URL,
      apiQuery
    );
    console.log({ response });
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
    setUserData(initialUserData);
  }, [setUserData]);

  useEffect(() => {
    setUserData(initialUserData);
  }, [setUserData]);

  const handleSupervisionQAView = (row) => {
    navigate("/supervisions", {
      state: {
        userData: userData,
        supervisionQA: row,
      },
    });
  };

  const columns = [
    {
      name: "Supervisor Name",
      selector: (row) => {
        return (
          <div onClick={() => handleSupervisionQAView(row)}>
            <span>{row?.supervisor?.name}</span>
          </div>
        );
      },
      width: "40%",
    },
    {
      name: "Due Date",
      selector: (row) => {
        return (
          <span
            style={{ paddingLeft: "15px" }}
            onClick={() => handleSupervisionQAView(row)}
          >
            {moment(row?.sprDueDate).format("DD/MM/YYYY")}
          </span>
        );
      },
      width: "30%",
    },
    {
      name: "Status",
      selector: (row) => {
        const sprStatus = displayEnumProperty("sprStatus", row?.sprStatus);
        return (
          <div
            className={`tableText ${sprStatus === "Assigned"
                ? "warning"
                : sprStatus === "Completed"
                  ? "success"
                  : sprStatus === "Over Due"
                    ? "danger"
                    : ""
              }`}
            onClick={() => handleSupervisionQAView(row)}
          >
            <p
              className="blackColor text-center
            "
            >
              {sprStatus}
            </p>
          </div>
        );
      },
      width: "30%",
      center: true,
    },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== null);

  return (
    <>
      <div className="pbsPlan supervisionBlocks cursorPointer">
        <div className="mainContent">
          <div className="dataTableWrapper">
            <DataTable
              className="maxWidthTable"
              columns={filteredColumns}
              data={filteredRows}
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationPerPage={10}
              fixedHeader
              fixedHeaderScrollHeight="600px"
              highlightOnHover
              // actions={
              //   <CSVLink
              //     data={filteredColumns}
              //     headers={csvHeaders}
              //     className="btn btn-sm btn-outline-primary"
              //     onClick={handleExportData}
              //   >
              //     Export to CSV
              //   </CSVLink>
              // }
              responsive={true}
              subHeader={true}
              subHeaderComponent={
                <div className="searchContainer">
                  <strong>Supervisions</strong>
                </div>
              }
              subHeaderAlign="left"
              subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
              progressPending={isLoading}
              progressComponent={<ProgressComponent />}
              onRowClicked={(row) => handleSupervisionQAView(row)}
            />
          </div>
        </div>
      </div>
      {isToastSupervisionDetails && <ToastContainer />}
    </>
  );
}

export default SupervisionGroup;
