import React, { useState, useEffect } from "react";
import Layout from "../../../components/layouts/Layout";
import Button from "react-bootstrap/Button";
import CustomSelectServiceUsers from "../../../components/forms-fields/CustomSelectServiceUsers";
import SelectBox from "../../../components/forms-fields/SelectBox";
import TextArea from "../../../components/forms-fields/TextArea";
import Input from "../../../components/forms-fields/Input";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { addSUEnquiry } from "../../../schemas";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_SERVICE_USER_BY_ID_URL,
  UPDATE_SERVICE_USER_ENQUIRY_URL,
} from "../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  getRecords,
  toastErrorMessages,
  updateRecords,
  formateDate,
} from "../../../helper/helper";
import moment from "moment/moment";

const suStatus = [
  { value: "pending", label: "Pending" },
  { value: "accepted", label: "Accept" },
  { value: "rejected", label: "Reject" },
];

const requestTypeFields = [
  { value: "billing", label: "Billing Enquiry" },
  { value: "services", label: "Services Enquiry" },
  { value: "availability", label: "Availability Enquiry" },
  { value: "misc", label: "Misc Enquiry" },
];

function EditSuEnquiry() {
  const navigate = useNavigate();
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToast, setIsToast] = useState(false);

  const validationSchema = Yup.object().shape({
    referenceId: Yup.string().required("Ref ID is required"),
    name: Yup.string().required("First Name is required"),
    suEnqContactNo: Yup.number().required("Contact Number is required").typeError("Please provide a valid No")
      .label("Contact No"),
    suEnqEmail: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .typeError("Please provide a valid Email")
      .label("Email Address"),
  });

  // Edit Enquiry Form
  const location = useLocation();
  const userData = location?.state ? location?.state?.userDetails : "";
  const [suEnquiryData, setSuEnquiryData] = useState(userData);
  console.log("🚀 ~ EditSuEnquiry ~ suEnquiryData:", suEnquiryData)
  // console.log(
  //   "🚀 ~ file: EditSuEnquiry.jsx:74 ~ EditSuEnquiry ~ suEnquiryData:",
  //   suEnquiryData
  // );

  const [defaultSuValue, setDefaultSuValue] = useState(
    suEnquiryData[0]?.serviceUser?._id
  );

  const formik = useFormik({
    initialValues: {
      addedby: suEnquiryData[0]?.addedby?._id,
      suEnquiryid: suEnquiryData[0]?._id,
      referenceId: suEnquiryData[0]?.referenceId,
      initialContactDate: suEnquiryData[0]?.initialContactDate,
      suEnqContactNo: suEnquiryData[0]?.suEnqContactNo,
      suEnqEmail: suEnquiryData[0]?.suEnqEmail,
      reasonForNeed: suEnquiryData[0]?.reasonForNeed,
      suEnqStatus: suEnquiryData[0]?.suEnqStatus,
      requestType: suEnquiryData[0]?.requestType,
      suEnqComments: suEnquiryData[0]?.suEnqComments,
      serviceUser: suEnquiryData[0]?.serviceUser?._id,
      name: suEnquiryData[0]?.serviceUser?.modelId?.name,
      suLastName: suEnquiryData[0]?.serviceUser?.modelId?.suLastName,
    },

    validationSchema,
    onSubmit: async (values) => {
      setIsSubmitButtonClicked(true);
      try {
        console.log("My Values: ", values);
        setIsSubmitButtonClicked(true);
        await updateFormDataNewEnquires(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const updateEnquiryRecords = (method, data) => {
    console.log(data);
    if (data?.status !== isSuccess) {
      setIsToast(true);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    }
    if (method === "updateFormDataNewEnquires" && data?.status === isSuccess) {
      setIsToast(true);
      setIsSubmitButtonClicked(false);
      toastSuccessMessages(
        toast?.success,
        data?.message,
        "/outstanding-request",
        500,
        500
      );
    }
  };

  const updateFormDataNewEnquires = catchAsync(async (req, res) => {
    // console.log("🚀 ~ updateFormDataNewEnquires ~ req:", req)
    const response = await updateRecords(UPDATE_SERVICE_USER_ENQUIRY_URL, req);
    updateEnquiryRecords("updateFormDataNewEnquires", response);
    setIsSubmitButtonClicked(false);
    console.log("response: ", response);
  });

  const formattedDate = formik?.values?.initialContactDate
    ? formateDate(formik.values.initialContactDate, "yyyy-mm-dd")
    : "";

  return (
    <>
      <Layout
        pageTitleText={
          <>
            Service User <small>Edit Enquiry</small>
          </>
        }
      >
        <div className="incidents consentCapacityAddNew">
          <form onSubmit={formik?.handleSubmit}>
            <div className="mainContent ">
              <div className="mainContentText">
                <h3>Service User Enquiries</h3>

                <div className="formBlockContent">
                  <div className="row">
                    {/* <div className="col-sm-6 col-md-4">
                      <div className="form-group">
                        <CustomSelectServiceUsers
                          onSelect={handleUserSelect}
                          filterBy={""}
                          defaultSelectedValue={defaultSuValue}
                          errorClass={isStaffId ? "validationError" : ""}
                          customError={isStaffId}
                          errorMessage={isStaffId ? errorMessage : ""}
                          isDisabled={true}
                        />
                      </div>
                    </div> */}
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id=""
                        name="referenceId"
                        value={formik?.values?.referenceId}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Ref ID"
                        customError={
                          formik?.touched?.referenceId &&
                          formik?.errors?.referenceId
                        }
                        errorMessage={formik?.errors?.referenceId}
                        erroClass={
                          formik?.touched?.referenceId &&
                            formik?.errors?.referenceId
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                        disabledStatus={true}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id=""
                        name="name"
                        value={formik?.values?.name}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="First Name"
                        customError={
                          formik?.touched?.name && formik?.errors?.name
                        }
                        errorMessage={formik?.errors?.name}
                        errorClass={
                          formik?.touched?.name && formik?.errors?.name
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                        disabledStatus={true}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id=""
                        name="suLastName"
                        value={formik?.values?.suLastName}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Last Name"
                        disabledStatus={true}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="form-group">
                        <Input
                          type="date"
                          datePicker={true}
                          name="initialContactDate"
                          selected={formik?.values?.initialContactDate ? moment(formik?.values?.initialContactDate).toDate() : null}
                          dateFormat="dd/MM/yyyy"
                          onChange={(initialContactDate) => formik.setFieldValue("initialContactDate", moment(initialContactDate).valueOf())}
                          onBlur={() => formik.setFieldTouched("initialContactDate", true)} // Ensure Formik's touched state is set on blur
                          labelText="Date of Initial Contact"
                          placeholderText="Date of Initial Contact"
                          showYearDropdown={true}
                        />
                      </div>
                      {/* <Input
                        type="date"
                        id=""
                        name="initialContactDate"
                        value={formattedDate}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Date of Initial Contact"
                      /> */}
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id=""
                        name="suEnqContactNo"
                        value={formik?.values?.suEnqContactNo}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Contact Number"
                        customError={
                          formik?.touched?.suEnqContactNo &&
                          formik?.errors?.suEnqContactNo
                        }
                        errorMessage={formik?.errors?.suEnqContactNo}
                        errorClass={
                          formik?.touched?.suEnqContactNo &&
                            formik?.errors?.suEnqContactNo
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="email"
                        id=""
                        name="suEnqEmail"
                        value={formik?.values?.suEnqEmail}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Email Address"
                        customError={
                          formik?.touched?.suEnqEmail &&
                          formik?.errors?.suEnqEmail
                        }
                        errorMessage={formik?.errors?.suEnqEmail}
                        errorClass={
                          formik?.touched?.suEnqEmail &&
                            formik?.errors?.suEnqEmail
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id=""
                        name="reasonForNeed"
                        value={formik?.values?.reasonForNeed}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Reason for need"
                      />
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <SelectBox
                        name="suEnqStatus"
                        id=""
                        value={formik?.values?.suEnqStatus}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        options={suStatus}
                        labelText="Status"
                      />
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <SelectBox
                        name="requestType"
                        id=""
                        value={formik?.values?.requestType}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        options={requestTypeFields}
                        labelText="Request Type"
                      />
                    </div>

                    <div className="col-sm-12">
                      <TextArea
                        id="suEnqComments"
                        name="suEnqComments"
                        value={formik?.values?.suEnqComments}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Comments"
                        rows="3"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="formActionButtons">
                        <Link
                          to="/outstanding-request"
                          className="btn btn-sm btn-outline-primary"
                        >
                          Back
                        </Link>
                        {isSubmitButtonClicked ? (
                          <Button className="btn-sm" variant="primary" disabled>
                            Loading...
                          </Button>
                        ) : (
                          <Button
                            className="btn-sm"
                            variant="primary"
                            // onClick={handleFormSubmit}
                            type="submit"
                          >
                            Save
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
      </Layout>
      {isToast && <ToastContainer />}
    </>
  );
}

export default EditSuEnquiry;
