import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import Button from "react-bootstrap/Button";
import { editCarePlanDocument } from "../../../schemas";
import { useFormik } from "formik";
import { renderToString } from 'react-dom/server';
import { Form } from "react-bootstrap";
import pdfIcon from "../../../assets/images/icons/pdfIcon.svg";
import wordIcon from "../../../assets/images/icons/wordIcon.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  UPDTE_CARE_PLAN_DOC_URL,
  ADMIN_ID,
  BASE_URL,
  ADMIN_AUTH_TOKEN,
  UPLOAD_REFERENCE_DOCUMENT_URL,
} from "../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../helper/helper";
import CustomSelect from "../../../components/forms-fields/CustomSelectStaffMembers";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import TextArea from '../../../components/forms-fields/TextArea';
import SelectBox from '../../../components/forms-fields/SelectBox';
import Input from '../../../components/forms-fields/Input';
import eventClose from "../../../assets/images/icons/eventClose.svg";
import eventAdd from "../../../assets/images/icons/eventAdd.svg";

function EditCarePlanPopUp({ modalPopupData, handelModalPopupClose }) {
  console.log("🚀 ~ EditCarePlanPopUp ~ modalPopupData:", modalPopupData)
  const storedIncidentDetail = localStorage.getItem("carePlanDetail");
  const carePlanDetail = JSON.parse(storedIncidentDetail);
  const [selectedUserId, setSelectedUserId] = useState(ADMIN_ID);
  // const [uploadedFilePath, setUploadedFilePath] = useState("");
  const [fileSizeError, setFileSizeError] = useState(false);
  useEffect(() => {
    carePlanDetail?.lastModifiedBy?._id ? (
      setSelectedUserId(carePlanDetail?.lastModifiedBy?._id)
    ) : (
      setSelectedUserId(carePlanDetail?.addedby?._id)
    )
  })
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddPbsPlans, setIsToastAddPbsPlans] = useState(false);
  // Editor Variable
  const [sectionContentID, setSectionContentID] = useState(modalPopupData?.sectionId);
  const [sectionContentData, setSectionContentData] = useState(modalPopupData?.fieldValueContent);
  const [myContactListStatus, setMyContactListStatus] = useState(false);


  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    setFieldValue("lastModifiedBy.modifiedUser", userId);
  };


  const editor = useRef(null);

  const [suFormData, setSuFormData] = useState({
    id: carePlanDetail?._id,
    userId: carePlanDetail?.userId?._id,
    addedby: carePlanDetail?.addedby?._id,
    lastModifiedBy: {
      modifiedUser: selectedUserId,
      modifiedTime: moment().valueOf()
    },
    // lastModifiedBy: selectedUserId,
    [modalPopupData?.sectionId]: modalPopupData?.fieldValueContent || "Type your text here",

  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: editCarePlanDocument,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postEditPbsPlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddPbsPlans(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (
      method === "postEditPbsPlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddPbsPlans(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handelModalPopupClose()
      }, 2000);
    }
  };

  const postEditPbsPlansFormData = catchAsync(async (req, res) => {
    let updatedValues;
    if (myContactListStatus) {
      updatedValues = {
        ...values,
        [modalPopupData?.sectionId]: sectionContentData,
        myContacts: myContactsList.map(row => ({
          id: row?.id,
          name: row?.name,
          relationship: row?.relationship,
          address: row?.address,
          email: row?.email,
          phoneNumber: row?.phoneNumber,
        })),
      }
    } else {
      updatedValues = {
        ...values,
        [modalPopupData?.sectionId]: sectionContentData,
      }
    }

    const response = await postRecords(UPDTE_CARE_PLAN_DOC_URL, updatedValues);
    console.log("🚀 ~ postEditPbsPlansFormData ~ response:", response)
    // console.log("🚀 ~ postEditPbsPlansFormData ~ updatedValues:", updatedValues)
    setIsSubmitButtonClicked(false);
    processPostRecords("postEditPbsPlansFormData", response);
  });


  const handleOnChangeEditor = (newContent) => {
    setSectionContentData(newContent)
  }


  const handleFileUpload = async (e) => {
    setIsSubmitButtonClicked(true);
    try {
      const file = e.target.files[0];
      const fileName = file.name; // Get the file name
      const fileType = file.type;
      const fileSize = file.size;

      // Validate file size (1MB = 1,048,576 bytes)
      if (fileSize > 1048576) {
        alert('File size exceeds 1MB');
        setFileSizeError(true);
        setIsSubmitButtonClicked(false);
        return;
      }

      // Validate file type
      if (fileType === 'application/pdf' || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        const fileData = new FormData();
        fileData.append('attachements', file);
        console.log("🚀 ~ handleFileUpload ~ fileData:", fileData)
        const apiResponse = await fetch(UPLOAD_REFERENCE_DOCUMENT_URL, {
          method: "POST",
          body: fileData,
          headers: {
            Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
          },
        });
        const resultResponse = await apiResponse.json();
        console.log("🚀 ~ handleFileUpload ~ resultResponse:", resultResponse)

        // setUploadedFilePath(resultResponse?.data);

        // Append the file link to the JoditEditor content
        const fileLink = `${BASE_URL}${resultResponse?.data[0]?.fileNameInDirectory}`;
        // const fileLinkHTML = `<p><a href="${fileLink}" target="_blank">${fileName}</a></p>`;

        let fileLinkHTML;
        if (file.type === "application/pdf") {
          fileLinkHTML = (
            <p id={resultResponse?.data[0]?.timeStamp} className="mt-2 attachedFile">
              <a href={fileLink} target="_blank" rel="noopener noreferrer">
                <img src={pdfIcon} alt={fileName} />
                <span>{fileName}</span>
                {/* {resultResponse?.data[0]?.timeStamp} */}
                {/* <span onClick={() => removeParagraph(resultResponse?.data[0]?.timeStamp)} className="removedAttachment"> x </span> */}
              </a>
            </p>
          );
        } else {
          fileLinkHTML = (
            <p id={resultResponse?.data[0]?.timeStamp} className="mt-2 attachedFile">
              <a href={fileLink} target="_blank" rel="noopener noreferrer">
                <img src={wordIcon} alt={fileName} />
                <span>{fileName}</span>
                {/* {resultResponse?.data[0]?.timeStamp} */}
                {/* <span onClick={() => removeParagraph(resultResponse?.data[0]?.timeStamp)} className="removedAttachment"> x </span> */}
              </a>
            </p>
          );
        }

        const fileLinkHTMLString = renderToString(fileLinkHTML);

        // Update editor content directly
        if (editor.current) {
          const currentContent = editor.current.value;
          const newContent = `${currentContent} ${fileLinkHTMLString}`;
          editor.current.value = newContent;
          values[modalPopupData?.sectionId] = newContent;
          setSectionContentData(newContent);
        }
      } else {
        alert('Unsupported file format');
      }
      setIsSubmitButtonClicked(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsSubmitButtonClicked(false);
    }
  };

  useEffect(() => {
    if (sectionContentID === "myContacts") {
      setMyContactListStatus(true)
    } else {
      setMyContactListStatus(false)
    }
  }, [sectionContentID])
  // My Contact List

  const relationshipOptions = [
    { value: "Parent", label: "Parent" },
    { value: "Niece/Nephew", label: "Niece/Nephew" },
    { value: "Sibling", label: "Sibling" },
    { value: "Child", label: "Child" },
    { value: "Spouse/Partner", label: "Spouse/Partner" },
    { value: "Grandparent", label: "Grandparent" },
    { value: "Grandchild", label: "Grandchild" },
    { value: "Aunt/Uncle", label: "Aunt/Uncle" },
    { value: "Cousin", label: "Cousin" },
    { value: "In-law", label: "In-law" },
    { value: "Friends", label: "Friends" },
    { value: "Caregiver", label: "Caregiver" },
    { value: "Guardian", label: "Guardian" }
  ];

  const [myContactsList, setMyContactsList] = useState(modalPopupData?.fieldValueContent);
  console.log("🚀 ~ EditCarePlanPopUp ~ myContactsList:", myContactsList)
  const addMyContactsListRow = () => {
    const newRow = {
      id: myContactsList.length + 1,
      name: "",
      relationship: "",
      address: "",
      email: "",
      phoneNumber: "",
    };
    setMyContactsList([...myContactsList, newRow]);
  };

  const removeMyContactsListRow = (id) => {
    const newRows = myContactsList.filter((row) => row.id !== id);
    setMyContactsList(newRows);
  };

  const handleMyContactsListInputChange = (id, field, value) => {
    const updatedRows = myContactsList.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setMyContactsList(updatedRows);
  };




  return (
    <>


      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="formBlockContent">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <CustomSelect
                    id=""
                    name="modifiedUser"
                    onSelect={handleUserSelect}
                    defaultValue={selectedUserId}
                    filterBy=""
                    label="Modified By"
                  />
                </div>
              </div>

              {(sectionContentID === "myTenancyInformation" ||
                sectionContentID === "missingPersonsProfile" ||
                sectionContentID === "personalInventory" ||
                sectionContentID === "afterMyDeath" ||
                sectionContentID === "financialRecords") && (
                  <>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          name="fileName"
                          id="fileName"
                          type="file"
                          accept=".pdf, .doc, .docx"
                          onChange={handleFileUpload}
                        />
                        {fileSizeError ? (
                          <div className="validationError">
                            <div className="text-danger">
                              Error: File size exceeds the 1MB limit. Please upload a smaller file.
                            </div>
                          </div>
                        ) : (
                          <div>
                            <p><small>Please upload a file that is 1MB or smaller.</small></p>
                          </div>
                        )}

                      </div>
                    </div>
                  </>
                )}

              {
                sectionContentID != "myContacts" && (
                  <>
                    <div className="col-md-12">
                      <div className="pbsFieldWrapper">
                        <JoditEditor
                          name="sectionContentData"
                          ref={editor}
                          tabIndex={1}
                          // value={sectionContentData}
                          value={values[modalPopupData?.sectionId]}
                          onChange={handleOnChangeEditor}
                        // onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </>
                )
              }

              {myContactListStatus && (
                <>
                  <div className="myContactsPoints">
                    <div className="table-responsive">
                      <Table bordered className='dynamicRows'>
                        <tbody>
                          {myContactsList.map((row, id) => (
                            <tr>
                              <td>
                                <h3>Enter Contact Details</h3>
                                <div className="row">
                                  <div className="col-md-6">
                                    <Input
                                      type="text"
                                      id={`name_${row.id}`}
                                      name={`name_${row.id}`}
                                      value={row.name}
                                      onChange={(e) => handleMyContactsListInputChange(row.id, 'name', e.target.value)}
                                      labelText="Name"
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <SelectBox
                                      id={`relationship_${row.id}`}
                                      name={`relationship_${row.id}`}
                                      value={row.relationship}
                                      onChange={(e) => handleMyContactsListInputChange(row.id, 'relationship', e.target.value)}
                                      onBlur={handleBlur}
                                      options={relationshipOptions}
                                      labelText="Relationship"
                                    />
                                  </div>

                                  <div className="col-md-6">
                                    <Input
                                      type="email"
                                      id={`email_${row.id}`}
                                      name={`email_${row.id}`}
                                      value={row.email}
                                      onChange={(e) => handleMyContactsListInputChange(row.id, 'email', e.target.value)}
                                      labelText="Email"
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <Input
                                      type="number"
                                      id={`phoneNumber_${row.id}`}
                                      name={`phoneNumber_${row.id}`}
                                      value={row.phoneNumber}
                                      onChange={(e) => handleMyContactsListInputChange(row.id, 'phoneNumber', e.target.value)}
                                      labelText="Phone Number"
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="col-md-12">
                                    <Input
                                      type="text"
                                      id={`address_${row.id}`}
                                      name={`address_${row.id}`}
                                      value={row.address}
                                      onChange={(e) => handleMyContactsListInputChange(row.id, 'address', e.target.value)}
                                      labelText="Address"
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                              </td>
                              <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeMyContactsListRow(row.id) }} /></td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td></td>
                            <td><img className='addRow' src={eventAdd} alt="Add" onClick={addMyContactsListRow} /></td>
                          </tr>
                        </tfoot>
                      </Table>
                    </div>
                  </div>

                </>
              )
              }

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="formActionButtons">

            <span
              className="btn btn-sm btn-outline-primary"
              onClick={handelModalPopupClose}
            >
              Cancel
            </span>

            {isSubmitButtonClicked ? (
              <Button
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Submit
              </Button>
            )}
          </div>
        </Modal.Footer>
      </form>

      {isToastAddPbsPlans && <ToastContainer />}
    </>
  );
}

export default EditCarePlanPopUp