import React, { useState, useEffect } from "react";
import settingsIcon from "../../assets/images/icons/settingsIcon.svg";
import { Link } from "react-router-dom";
import ListAllMembers from "../../pages/messaging_system/modals/ListAllMembers";
import CreateGroupChat from "../../pages/messaging_system/modals/CreateGroupChat";

import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
function UserDetailHeader({
  actionButtons,
  addNewDocument,
  addNewIncident,
  typeSelectBox,
  addNewTraining,
  createNewForm,
  consentCapacitySelectBox,
  addNewAppointment,
  addNewMessage,
  serviceUsers,
  shiftLocation,
}) {
  const [isNewMsgSelectStaff, setIsNewMsgSelectStaff] = useState(false);
  const [isCreateGroupModal, setIsCreateGroupModal] = useState(false);

  const handleSelectStaffForMessage = () => {
    setIsNewMsgSelectStaff(true);
  };

  const handleCreateGroupModal = () => {
    setIsCreateGroupModal(true);
  };

  const handleCloseAddNewMsgModel = () => {
    setIsNewMsgSelectStaff(false);
  };

  const handleCloseCreateGroupModal = () => {
    setIsCreateGroupModal(false);
  };

  if (
    actionButtons ||
    addNewDocument ||
    addNewIncident ||
    typeSelectBox ||
    addNewTraining ||
    createNewForm ||
    consentCapacitySelectBox ||
    addNewAppointment ||
    addNewMessage ||
    serviceUsers
  ) {
    return (
      <>
        <header className="headerContainerSearchAction">
          <div className="actionButtons">
            {addNewMessage ? (
              <div className="gap-1">
                <Link
                  className="btn btn-sm btn-primary"
                  onClick={handleSelectStaffForMessage}
                >
                  New Chat
                </Link>
                <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <Link
                    className="btn btn-sm btn-primary"
                    onClick={handleCreateGroupModal}
                  >
                    Create Group
                  </Link>
                </ProtectedComponent>
              </div>
            ) : null}
            {addNewTraining ? (
              <>
                <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <Link to="/add-training-quizzes" className="btn btn-sm btn-primary">
                    Add New Quizzes
                  </Link>
                </ProtectedComponent>

                <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <Link to="/add-training" className="btn btn-sm btn-primary">
                    Add New Training
                  </Link>
                </ProtectedComponent>
              </>
            ) : null}
            {serviceUsers ? (
              <>
                <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <Link to="/add-service-user" className="btn btn-sm btn-primary">
                    Add New Service User
                  </Link>
                </ProtectedComponent>

                {/* <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <Link to="/add-service-user" className="btn btn-sm btn-primary">
                    Add New Service User
                  </Link>
                </ProtectedComponent> */}
                <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <Link
                    to="/service-user-enquiries"
                    className="btn btn-sm btn-primary"
                  >
                    Add New Enquiries
                  </Link>
                </ProtectedComponent>
              </>
            ) : null}
            {addNewIncident ? (
              <>
                <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <Link to="/add-incidents" className="btn btn-sm btn-primary">
                    Add New Incident
                  </Link>
                </ProtectedComponent>
              </>
            ) : null}
            {createNewForm ? (
              <>
                <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <Link
                    to="/add-consent-capacity"
                    className="btn btn-sm btn-primary"
                  >
                    Creat New Form
                  </Link>
                </ProtectedComponent>
              </>
            ) : null}
            {addNewAppointment ? (
              <>
                <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <Link
                    to="/add-consent-capacity"
                    className="btn btn-sm btn-primary"
                  >
                    Add New Appointment
                  </Link>
                </ProtectedComponent>
              </>
            ) : null}
            {addNewDocument ? (
              <>
                <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <button className="btn btn-sm btn-primary">
                    Add New Document
                  </button>
                </ProtectedComponent>
              </>
            ) : null}
            {actionButtons ? (
              <>
                <button className="btn btn-sm btn-outline-primary">
                  Export Sheet
                </button>
                <button className="btn btn-sm btn-primary">
                  Finalize Payroll
                </button>
                <button className="btn btn-sm btn-outline-primary img-btn">
                  <img src={settingsIcon} alt="Settings" />
                </button>
              </>
            ) : null}
          </div>
        </header>
        {isNewMsgSelectStaff && (
          <ListAllMembers
            handleShowModel={isNewMsgSelectStaff}
            onClose={handleCloseAddNewMsgModel}
          />
        )}
        {isCreateGroupModal && (
          <CreateGroupChat
            handleShowModel={isCreateGroupModal}
            handleCloseModel={handleCloseCreateGroupModal}
          />
        )}
      </>
    );
  }
}

export default UserDetailHeader;
