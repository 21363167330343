import React from "react";
import Layout from "../../components/layouts/Layout";
import UserDetailHeader from "../../components/user-detail/UserDetailHeader";
import CandidatesBlocks from "./CandidatesBlocks";
import ArchivedBlocks from "./ArchivedBlocks";
import AchievedCandidates from "./AchievedCandidates";
import addUserPlus from "../../assets/images/icons/addUserPlus.svg";
import { Link } from "react-router-dom";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';

function Recruitment() {
  return (
    <Layout pageTitleText="Recruitment">
      <UserDetailHeader />
      <div className="row h-100">
        <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
          <div className="col-md-4">
            <div className="addNewCandidate h-100">
              <Link to="/add-new-candidate">
                <img src={addUserPlus} alt={addUserPlus} />
                <span> Add New Candidate</span>
              </Link>
            </div>
          </div>
        </ProtectedComponent>
        <div className="col-md-4">
          <CandidatesBlocks candidatesBlocksHeading="New Candidates" />
        </div>
        <div className="col-md-4">
          <ArchivedBlocks candidatesBlocksHeading="Archieved Candidates" />
        </div>
      </div>
      <AchievedCandidates />
    </Layout>
  );
}

export default Recruitment;
