import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { editSafeguarding } from "../../../../../../schemas";
import { useFormik } from "formik";
import ReactDOMServer from 'react-dom/server';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  UPDTE_CARE_PLAN_URL,
  ADMIN_AUTH_TOKEN, BASE_URL, UPLOAD_MEMORY_BOX_FILE
} from "../../../../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import Layout from "../../../../../../components/layouts/Layout";
import { Form } from "react-bootstrap";
import HealthCategories from "../../../../../health-issue/HealthCategories";


function EditSafeguarding() {
  const location = useLocation();
  const carePlanDetail = location?.state ? location?.state?.editCarePlanDetail[0] : null;
  // console.log("🚀 ~ EditSafeguarding ~ carePlanDetail:", carePlanDetail)

  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  // console.log("🚀 ~ EditSafeguarding ~ selectedCategoryId:", selectedCategoryId)
  // console.log("🚀 ~ EditSafeguarding ~ selectedSubCategoryId:", selectedSubCategoryId)
  const [parentSubCatValidationStatus, setParentSubCatValidationStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const concernsAboutServiceUserRef = useRef(null);
  const uploadedFilePathRef = useRef(null);
  const voiceOfTheServiceUserRef = useRef(null);
  const areThereAnyImmediateRisksRef = useRef(null);
  const whatActionDoYouFeelRef = useRef(null);
  const fullDetailOfConcernRef = useRef(null);
  const originOfConcernRef = useRef(null);
  const commentsRef = useRef(null);
  const yourNameRef = useRef(null);
  const anyWitnessesRef = useRef(null);
  const yourNameDslDdslRef = useRef(null);
  const actionTakenRef = useRef(null);
  // const nameOfParentContactedRef = useRef(null);
  const dateTimeReportingRef = useRef(null);

  useEffect(() => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = carePlanDetail?.noteDetails || '';

    uploadedFilePathRef.current.innerText = tempDiv.querySelector('#uploadedFilePathData')?.innerText || '';
    concernsAboutServiceUserRef.current.innerText = tempDiv.querySelector('#concernsAboutServiceUserData')?.innerText || '';
    voiceOfTheServiceUserRef.current.innerText = tempDiv.querySelector('#voiceOfTheServiceUserData')?.innerText || '';
    areThereAnyImmediateRisksRef.current.innerText = tempDiv.querySelector('#areThereAnyImmediateRisksData')?.innerText || '';
    whatActionDoYouFeelRef.current.innerText = tempDiv.querySelector('#whatActionDoYouFeelData')?.innerText || '';
    originOfConcernRef.current.innerText = tempDiv.querySelector('#originOfConcernData')?.innerText || '';
    fullDetailOfConcernRef.current.innerText = tempDiv.querySelector('#fullDetailOfConcernData')?.innerText || '';
    commentsRef.current.innerText = tempDiv.querySelector('#commentsData')?.innerText || '';
    // isParentContactedRef.current.innerText = tempDiv.querySelector('#isParentContactedData')?.innerText || '';
    yourNameRef.current.innerText = tempDiv.querySelector('#yourNameData')?.innerText || '';
    anyWitnessesRef.current.innerText = tempDiv.querySelector('#anyWitnessesData')?.innerText || '';
    yourNameDslDdslRef.current.innerText = tempDiv.querySelector('#yourNameDslDdslData')?.innerText || '';
    actionTakenRef.current.innerText = tempDiv.querySelector('#actionTakenData')?.innerText || '';
    dateTimeReportingRef.current.innerText = tempDiv.querySelector('#dateTimeReportingData')?.innerText || '';

    // heightRef.current.innerText = tempDiv.querySelector('#heightData')?.innerText || '';
  }, [carePlanDetail?.noteDetails]);



  // File Upload
  // File Upload
  const [uploadedFilePath, setUploadedFilePath] = useState("");
  const [videoSizeError, setVideoSizeError] = useState(false)

  const handleImageChange = async (e) => {
    setIsSubmitButtonClicked(true);
    try {
      const file = e.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;
      // console.log("🚀 ~ handleImageChange ~ fileSize:", fileSize);

      // Validate file size (1MB = 1,048,576 bytes)
      if (fileSize > 1048576) {
        alert('File size exceeds 1MB');
        setVideoSizeError(true); // Consider renaming this to something like setFileSizeError
        setIsSubmitButtonClicked(false);
        return;
      }

      // Validate file type (only images, PDFs, and document files)
      if (
        fileType === 'image/jpeg' ||
        fileType === 'image/png' ||
        fileType === 'image/jpg' ||
        fileType === 'application/pdf' ||
        fileType === 'application/msword' ||
        fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || // docx
        fileType === 'application/vnd.ms-excel' ||
        fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // xlsx
      ) {
        const fileData = new FormData();
        fileData.append('file', file);

        const apiResponse = await fetch(UPLOAD_MEMORY_BOX_FILE, {
          method: "POST",
          body: fileData,
          headers: {
            Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
          },
        });

        const resultResponse = await apiResponse.json();
        setUploadedFilePath(resultResponse?.data);
        // console.log("File uploaded successfully:", resultResponse?.data);
      } else {
        // Unsupported file format
        alert('Unsupported file format');
      }
      setIsSubmitButtonClicked(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsSubmitButtonClicked(false);
    }
  };


  const [suFormData, setSuFormData] = useState({
    addedby: carePlanDetail?.addedby?._id,
    concernsAboutServiceUser: "",
    voiceOfTheServiceUser: "",
    areThereAnyImmediateRisks: "",
    whatActionDoYouFeel: "",
    comments: "",
    fullDetailOfConcern: "",
    yourName: "",
    yourNameDslDdsl: "",
    originOfConcern: "",
    uploadedFilePath: uploadedFilePath,
    anyWitnesses: "",
    actionTaken: "",
    dateTimeReporting: "",

    category: selectedCategoryId,
    subcategory: selectedSubCategoryId,
    // subcategory: carePlanDetail?.healthIssueId?.category?.parentCategory?._id,
    eventDateTime: carePlanDetail?.eventDateTime,
  });
  useEffect(() => {
    setValues(prevState => ({
      ...prevState,
      // height: heightRef.current?.innerText || '',
      uploadedFilePath: uploadedFilePathRef.current?.innerText || '',
      concernsAboutServiceUser: concernsAboutServiceUserRef.current?.innerText || '',
      voiceOfTheServiceUser: voiceOfTheServiceUserRef.current?.innerText || '',
      areThereAnyImmediateRisks: areThereAnyImmediateRisksRef.current?.innerText || '',
      whatActionDoYouFeel: whatActionDoYouFeelRef.current?.innerText || '',
      fullDetailOfConcern: fullDetailOfConcernRef.current?.innerText || '',
      originOfConcern: originOfConcernRef.current?.innerText || '',
      comments: commentsRef.current?.innerText || '',
      yourName: yourNameRef.current?.innerText || '',
      anyWitnesses: anyWitnessesRef.current?.innerText || '',

      yourNameDslDdsl: yourNameDslDdslRef.current?.innerText || '',
      actionTaken: actionTakenRef.current?.innerText || '',
      // nameOfParentContacted: nameOfParentContactedRef.current?.innerText || '',
      dateTimeReporting: moment(dateTimeReportingRef.current?.innerText, 'DD/MM/YYYY hh:mm:ss A').valueOf() || '',

    }));

  }, [carePlanDetail?.noteDetails]);


  useEffect(() => {
    // debugger
    //testing
    if (carePlanDetail && carePlanDetail.healthIssueId) {
      const { category } = carePlanDetail.healthIssueId;
      if (category.parentCategory && category.parentCategory._id) {
        setFieldValue("category", carePlanDetail?.healthIssueId?.category?.parentCategory?.id);
        setFieldValue("subcategory", carePlanDetail?.healthIssueId?.category?._id);
        setSelectedCategoryId(carePlanDetail?.healthIssueId?.category?.parentCategory?.id)
        setSelectedSubCategoryId(carePlanDetail?.healthIssueId?.category?._id)
        setParentSubCatValidationStatus(true)

      } else {
        setFieldValue("category", carePlanDetail?.healthIssueId?.category?._id);
        setFieldValue("subcategory", null);
        setSelectedCategoryId(carePlanDetail?.healthIssueId?.category?._id)
        setParentSubCatValidationStatus(false)
        setSelectedSubCategoryId("")
      }

    }
  }, [carePlanDetail]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: editSafeguarding,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);

        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "/care-plan/general-notes", 10, 10);
      setIsSubmitButtonClicked(false);
    }
  };
  // console.log("🚀 ~ useEffect ~ dateTimeReporting:", moment(dateTimeReportingRef.current?.innerText, 'DD/MM/YYYY hh:mm:ss A').valueOf())
  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const noteDetailsString = ReactDOMServer.renderToString(htmlData);
    const updateValues = {
      carePlanid: carePlanDetail?._id,
      healthIssueId: carePlanDetail?.healthIssueId?.id,
      userId: carePlanDetail?.userId,
      noteType: carePlanDetail?.noteType,
      flag: false,
      addedby: selectedUserId,
      isHTML: true,
      title: values?.areThereAnyImmediateRisks,
      noteDetails: noteDetailsString,
      category: values?.category,
      healthNote: values?.concernsAboutServiceUser,
      complaint: values?.areThereAnyImmediateRisks,
      eventDateTime: moment(values.eventDateTime).valueOf(),
    }

    console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(UPDTE_CARE_PLAN_URL, updateValues);
    // console.log("🚀 ~ response ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });


  // console.log("🚀 ~ postAddCarePlansFormData ~ values:", suFormData)


  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setSuFormData({
      ...suFormData,
      [name]: value
    });
    handleChange(e);
    handleBlur(e);
  };

  const [htmlData, setHtmlData] = useState("");

  useEffect(() => {
    let fileUploadContent;

    if (uploadedFilePath !== "") {
      if (uploadedFilePath.match(/\.(jpeg|jpg|png)$/i)) {
        fileUploadContent = (
          <a href={`${BASE_URL}${uploadedFilePath}`} target="_blank" rel="noopener noreferrer">
            <img
              src={`${BASE_URL}${uploadedFilePath}`}
              alt="Memory Box"
              style={{ cursor: "pointer" }}
            />
          </a>
        );
      } else {
        fileUploadContent = (
          <a href={`${BASE_URL}${uploadedFilePath}`} target="_blank" rel="noopener noreferrer">
            Click to view
          </a>
        );
      }
    } else {
      fileUploadContent = null;
    }

    setHtmlData(
      <>
        <div className="col-md-12">
          <p><strong>File Upload: </strong>
            <span id="uploadedFilePathData">{fileUploadContent}</span>
          </p>
        </div>

        <div className="col-md-12">
          <p><strong>Concerns about the service user: </strong><span id="concernsAboutServiceUserData">{values?.concernsAboutServiceUser}</span></p>
        </div>

        <div className="col-md-12">
          <p><strong>Voice of the service user: </strong><span id="voiceOfTheServiceUserData">{values?.voiceOfTheServiceUser}</span></p>
        </div>

        <div className="col-md-12">
          <p><strong>Are there any immediate risks: </strong><span id="areThereAnyImmediateRisksData">{values?.areThereAnyImmediateRisks}</span></p>
        </div>

        <div className="col-md-12">
          <p><strong>What action do you feel should be taken?: </strong><span id="whatActionDoYouFeelData">{values?.whatActionDoYouFeel}</span></p>
        </div>

        <div className="col-md-12">
          <p><strong>Comments: </strong><span id="commentsData">{values?.comments}</span></p>
        </div>

        <div className="col-md-4">
          <p><strong>Your Name: </strong><span id="yourNameData">{values?.yourName}</span></p>
        </div>

        <div className="col-md-4">
          <p><strong>Origin of concern: </strong><span id="originOfConcernData">{values?.originOfConcern}</span></p>
        </div>

        <div className="col-md-12">
          <p><strong>Full detail of concern: </strong><span id="fullDetailOfConcernData">{values?.fullDetailOfConcern}</span></p>
        </div>

        <div className="col-md-4">
          <p><strong>Any witnesses: </strong><span id="anyWitnessesData">{values?.anyWitnesses}</span></p>
        </div>

        <div className="col-md-4">
          <p><strong>Date and time of reporting</strong>
            <span id="dateTimeReportingData">{moment(values?.dateTimeReporting).format("DD/MM/YYYY / hh:mm:ss A")}</span></p>
        </div>

        <div className="col-md-6">
          <p>To be completed by DSL/DDSL<br /><strong>Your Name: </strong><span id="yourNameDslDdslData">{values?.yourNameDslDdsl}</span></p>
        </div>

        <div className="col-md-6">
          <p><br /><strong>Action taken: </strong><span id="actionTakenData">{values?.actionTaken}</span></p>
        </div>
      </>
    );
  }, [values, uploadedFilePath]);




  // console.log("🚀 carePlanDetail:", carePlanDetail)
  // console.log("🚀 values:", values)
  // console.log("🚀 Parent:", values?.category)
  // console.log("🚀 Child:", values?.subcategory)

  // useEffect(() => {
  //   if (selectedCategoryId !== "") {
  //     setFieldValue("category", selectedCategoryId)
  //   }

  // }, [selectedCategoryId])

  const handleUserSelect = async (userId) => {
    setSelectedUserId(userId)
    setFieldValue("addedby", userId);
  }
  const handleBodyPartSelect = async (catId) => {
    setSelectedCategoryId(catId);
    // setReceivedSubCategory("no-child");
    setSelectedSubCategoryId("no-child");
    setFieldValue("category", catId);
    setFieldValue("subcategory", "no-child");
  };
  // category: selectedCategoryId,
  // subcategory: selectedSubCategoryId,

  const handleSubCatValidationStatus = (status) => {
    // console.log("Sub Cat Validation Status (Parent):", status);
    setParentSubCatValidationStatus(status);
  };

  useEffect(() => {
    // console.log("Parent Sub Cat Validation Status 01:", parentSubCatValidationStatus);
  }, [parentSubCatValidationStatus]);

  // useEffect(() => {
  //   // setFieldValue("category", selectedCategoryId);
  //   if (!parentSubCatValidationStatus) {
  //     setFieldValue("subcategory", "no-child");
  //   }
  //   // else {
  //   //   setFieldValue("subcategory", "");
  //   // }
  // }, [selectedCategoryId, parentSubCatValidationStatus]);
  // console.log("🚀 ~ EditSafeguarding ~ parentSubCatValidationStatus:", parentSubCatValidationStatus)
  // console.log("🚀 ~ file: AddHealthIssues.jsx:112 ~ useEffect ~ selectedCategoryId:", selectedCategoryId)

  // const [receivedSubCategory, setReceivedSubCategory] = useState(null);
  // console.log("🚀 ~asfsdfdsfsdfry:", receivedSubCategory)

  const handleSubCategoryChange = (subCategoryValue) => {
    setSelectedSubCategoryId(subCategoryValue);
    setFieldValue("category", subCategoryValue?.value);
    setFieldValue("subcategory", subCategoryValue?.value);
    setFieldTouched("subcategory", false);
    // setSelectedCategoryId(subCategoryValue?.value);
    // setSelectedCategoryId(carePlanDetail?.healthIssueId?.category?.parentCategory?.id)
    // setSelectedSubCategoryId(carePlanDetail?.healthIssueId?.category?._id)
  };

  return (
    <>

      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Edit Safeguarding note</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">

                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={values?.addedby}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date and time of accident"
                        customError={touched?.eventDateTime && errors?.eventDateTime}
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="concernsAboutServiceUser"
                        name="concernsAboutServiceUser"
                        value={values?.concernsAboutServiceUser}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Concerns about the service user"
                        rows="3"
                        className="mb-0"
                        customError={touched?.concernsAboutServiceUser && errors?.concernsAboutServiceUser}
                        errorMessage={errors?.concernsAboutServiceUser}
                        erroClass={
                          touched?.concernsAboutServiceUser && errors?.concernsAboutServiceUser
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="voiceOfTheServiceUser"
                        name="voiceOfTheServiceUser"
                        value={values?.voiceOfTheServiceUser}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Voice of the service user"
                        rows="3"
                        className="mb-0"
                        customError={touched?.voiceOfTheServiceUser && errors?.voiceOfTheServiceUser}
                        errorMessage={errors?.voiceOfTheServiceUser}
                        erroClass={
                          touched?.voiceOfTheServiceUser && errors?.voiceOfTheServiceUser
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="areThereAnyImmediateRisks"
                        name="areThereAnyImmediateRisks"
                        value={values?.areThereAnyImmediateRisks}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Are there any immediate risks"
                        rows="3"
                        className="mb-0"
                        customError={touched?.areThereAnyImmediateRisks && errors?.areThereAnyImmediateRisks}
                        errorMessage={errors?.areThereAnyImmediateRisks}
                        erroClass={
                          touched?.areThereAnyImmediateRisks && errors?.areThereAnyImmediateRisks
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="whatActionDoYouFeel"
                        name="whatActionDoYouFeel"
                        value={values?.whatActionDoYouFeel}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="What action do you feel should be taken?"
                        rows="3"
                        className="mb-0"
                        customError={touched?.whatActionDoYouFeel && errors?.whatActionDoYouFeel}
                        errorMessage={errors?.whatActionDoYouFeel}
                        erroClass={
                          touched?.whatActionDoYouFeel && errors?.whatActionDoYouFeel
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-12">

                      <TextArea
                        id="comments"
                        name="comments"
                        value={values?.comments}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Comments"
                        rows="3"
                        className="mb-0"
                        customError={touched?.comments && errors?.comments}
                        errorMessage={errors?.comments}
                        erroClass={
                          touched?.comments && errors?.comments
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="text"
                        id="originOfConcern"
                        name="originOfConcern"
                        value={values?.originOfConcern}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Origin of concern"
                        customError={touched?.originOfConcern && errors?.originOfConcern}
                        errorMessage={errors?.originOfConcern}
                        erroClass={
                          touched?.originOfConcern && errors?.originOfConcern
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">

                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div className="memoryBoxContent">
                        <div className="imageBox text-center">
                          <label htmlFor="profilePicture" className="upload-icon">
                            <div className="avatarAction">
                              {uploadedFilePath !== '' && (
                                <>
                                  {uploadedFilePath.match(/\.(jpeg|jpg|png)$/i) ? (
                                    <img
                                      src={`${BASE_URL}${uploadedFilePath}`}
                                      alt="Memory Box"
                                      style={{ cursor: "pointer" }}
                                    />
                                  ) : (
                                    <a
                                      href={`${BASE_URL}${uploadedFilePath}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
                                    >
                                      Click to view
                                    </a>
                                  )}
                                </>
                              )}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          name="fileName"
                          id="fileName"
                          type="file"
                          accept="image/*, .pdf, .doc, .docx, .xls, .xlsx"
                          onChange={handleImageChange}
                        />
                        {
                          videoSizeError ? ( // Consider renaming this to fileSizeError
                            <>
                              <div className="validationError">
                                <div className="text-danger">Error: File size exceeds the 1MB limit. Please upload a smaller file.</div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div><p><small>Please upload a file that is 1MB or smaller.</small></p></div>
                            </>
                          )
                        }
                      </div>
                    </div>
                    <div className="col-md-12">

                      <TextArea
                        id="fullDetailOfConcern"
                        name="fullDetailOfConcern"
                        value={values?.fullDetailOfConcern}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Full details of concern"
                        rows="3"
                        className="mb-0"
                        customError={touched?.fullDetailOfConcern && errors?.fullDetailOfConcern}
                        errorMessage={errors?.fullDetailOfConcern}
                        erroClass={
                          touched?.fullDetailOfConcern && errors?.fullDetailOfConcern
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-12">
                      {
                        selectedCategoryId !== null && selectedSubCategoryId !== null && (
                          <HealthCategories
                            name="category"
                            onSelect={handleBodyPartSelect}
                            onSelectSubCat={handleSubCategoryChange}
                            selectedSubCategory={selectedSubCategoryId}
                            selectedCategoryId={selectedCategoryId}
                            // value={values?.category}
                            defaultSelectedCatValue={values?.category}
                            defaultSelectedSubCatValue={values?.subcategory}
                            filterBy={""}
                            isDisabled={false}
                            label="Select Category-"
                            sublabel="Select Subcategory"
                            subCatValidationStatus={true}
                            setSubCatValidationStatus={handleSubCatValidationStatus}
                            erroClassCategory={touched.category && errors.category ? "validationError" : ""}
                            customErrorCategory={touched.category && errors.category}
                            errorMessageCategory={touched.category && errors.category ? errors.category : ""}
                            customErrorSubcategory={touched.subcategory && errors.subcategory}
                            errorMessageSubcategory={touched.subcategory && errors.subcategory ? errors.subcategory : ""}
                            erroClassSubcategory={touched.subcategory && errors.subcategory ? "validationError" : ""}

                          />
                        )
                      }

                    </div>

                    <div className="col-md-4">
                      <Input
                        type="text"
                        id="yourName"
                        name="yourName"
                        value={values?.yourName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Your Name"
                        customError={touched?.yourName && errors?.yourName}
                        errorMessage={errors?.yourName}
                        erroClass={
                          touched?.yourName && errors?.yourName
                            ? "validationError"
                            : ""
                        }
                      />

                    </div>

                    <div className="col-md-4">
                      <Input
                        type="text"
                        id="anyWitnesses"
                        name="anyWitnesses"
                        value={values?.anyWitnesses}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Any witnesses"
                        customError={touched?.anyWitnesses && errors?.anyWitnesses}
                        errorMessage={errors?.anyWitnesses}
                        erroClass={
                          touched?.anyWitnesses && errors?.anyWitnesses
                            ? "validationError"
                            : ""
                        }
                      />

                    </div>

                    <div className="col-md-4">
                      <Input
                        // type="text"
                        type="datetime-local"
                        id="dateTimeReporting"
                        name="dateTimeReporting"
                        value={moment(values?.dateTimeReporting).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date and time of reporting"
                        customError={touched?.dateTimeReporting && errors?.dateTimeReporting}
                        errorMessage={errors?.dateTimeReporting}
                        erroClass={
                          touched?.dateTimeReporting && errors?.dateTimeReporting
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="greyBackground">
                    <div className="row">
                      <div className="col-md-12">
                        <p>To be completed by DSL/DDSL</p>
                      </div>

                      <div className="col-md-6">
                        <Input
                          type="text"
                          id="yourNameDslDdsl"
                          name="yourNameDslDdsl"
                          value={values?.yourNameDslDdsl}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          labelText="Your Name"
                          customError={touched?.yourNameDslDdsl && errors?.yourNameDslDdsl}
                          errorMessage={errors?.yourNameDslDdsl}
                          erroClass={
                            touched?.yourNameDslDdsl && errors?.yourNameDslDdsl
                              ? "validationError"
                              : ""
                          }
                        />

                      </div>

                      <div className="col-md-6">
                        <Input
                          type="text"
                          id="actionTaken"
                          name="actionTaken"
                          value={values?.actionTaken}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          labelText="Action taken"
                          customError={touched?.actionTaken && errors?.actionTaken}
                          errorMessage={errors?.actionTaken}
                          erroClass={
                            touched?.actionTaken && errors?.actionTaken
                              ? "validationError"
                              : ""
                          }
                        />

                      </div>

                    </div>
                  </div>
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      // onClick={subModalAddCarePlans}
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </form>
              <div className="referenceDiv">
                <div ref={uploadedFilePathRef}></div>
                <div ref={concernsAboutServiceUserRef}></div>
                <div ref={voiceOfTheServiceUserRef}></div>
                <div ref={areThereAnyImmediateRisksRef}></div>
                <div ref={whatActionDoYouFeelRef}></div>
                <div ref={fullDetailOfConcernRef}></div>
                <div ref={originOfConcernRef}></div>
                <div ref={commentsRef}></div>
                <div ref={yourNameRef}></div>
                <div ref={anyWitnessesRef}></div>
                <div ref={yourNameDslDdslRef}></div>
                <div ref={actionTakenRef}></div>
                <div ref={dateTimeReportingRef}></div>
              </div>
              {isToastAddCarePlans && <ToastContainer />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default EditSafeguarding