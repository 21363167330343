import React, { useState, useEffect } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { POST_QUALITY_SURVEY_URL, GET_QUALITY_SURVEY_QUESTIONS_BY_CAT_URL } from "../../../env";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layouts/Layout";
import UserDetailReview from '../../../components/user-detail/UserDetailReview';
import ProgressComponent from '../../../components/general/ProgressComponent';
import { Link } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import TextArea from '../../../components/forms-fields/TextArea';
import { ToastContainer, toast } from "react-toastify";

// Emoji Icons
import Inadequate from "../../../assets/images/quality-survey/Inadequate.svg";
import RequiresImprovement from "../../../assets/images/quality-survey/RequiresImprovement.svg";
import Good from "../../../assets/images/quality-survey/Good.svg";
import Outstanding from "../../../assets/images/quality-survey/Outstanding.svg";

import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  postRecords,
  catchAsync,
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../helper/helper";

function AddQualitySurvey() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userData = JSON.parse(storedUserDetails);
  const navigate = useNavigate();
  const [qualitySurveyData, setQualitySurveyData] = useState([])
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddSurvey, setIsToastAddSurvey] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  // console.log("🚀 ~ ViewQualitySurvey ~ qualitySurveyData:", qualitySurveyData)
  const [qualitySurveyId, setQualitySurveyId] = useState(
    {
      query: {
        critarion: {}
      }
    });
  const [isLoading, setIsLoading] = useState(true);

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      setQualitySurveyData(data?.data)
      setIsLoading(false);
    }
  };
  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_QUALITY_SURVEY_QUESTIONS_BY_CAT_URL, qualitySurveyId);
    // console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);



  const [allQuestions, setAllQuestions] = useState([]);

  useEffect(() => {
    const updatedQuestion = [];
    Object.values(qualitySurveyData).forEach(category => {
      category.forEach(question => {
        updatedQuestion.push({
          categoryId: question.categoryId._id,
          questionId: question._id,
          question: question.question,
          score: 0,
          isTextAreaEnabled: false,
          textAreaDetail: ""
        });
      });
    });
    setAllQuestions(updatedQuestion);
  }, [qualitySurveyData])

  const [reviewFormData, setReviewFormData] = useState({
    userId: userData?._id,
    question: []

  });



  const handleSurveyChange = (questionId, value, textAreaDetailValue) => {
    setAllQuestions(prevQuestions => {
      return prevQuestions.map(question => {
        if (question.questionId === questionId) {
          if (question.question === "Comments") {
            return {
              ...question,
              isTextAreaEnabled: true,
              textAreaDetail: textAreaDetailValue,
              score: 3
            };
          } else {
            return {
              ...question,
              score: value
            };
          }
        }
        return question;
      });
    });
  };




  // Formik and Yup
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: reviewFormData,
    // validationSchema: addServiceUserReview,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postServiceUserReviewData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });


  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddSurvey(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (
      method === "postServiceUserReviewData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddSurvey(true);
      toastSuccessMessages(toast.success, data?.message, "/quality-survey", 500, 500);
      setIsSubmitButtonClicked(false);
    }
  };


  const postServiceUserReviewData = catchAsync(async (req, res) => {
    const updatedValues = {
      ...values,
      question: allQuestions

    };
    const response = await postRecords(POST_QUALITY_SURVEY_URL, updatedValues);
    console.log("🚀 ~ updatedValues ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postServiceUserReviewData", response);
  });




  return (
    <>
      <Layout
        pageTitleText={
          <>
            Service User <small>Internal Reviews</small>
          </>
        }
      >
        <UserDetailReview
          getUserRecord={userData}
          avatarActionButtons={false}
          viewNotes={false}
          profileDetail={false}
          horizontalDetail={true}
          launchVideoButtons={true}
        />
        <div className="viewEditorWrapper">
          <div className="mainContent mt-3 p-3">
            <div className="reviewContent">

              <div className="personalDetail">
                <header className="mainContentHeader pr-0">
                  <div></div>
                  <div>
                    <span className="btn btn-sm btn-primary" onClick={() => {
                      navigate("/quality-survey");
                    }}>Back to Quality Survey</span>
                  </div>
                </header>
              </div>

              <div className='formBlockContent'>
                <form onSubmit={handleSubmit}>
                  <div className='qualitySurveyUpdate'>


                    <div className="questionsWrapper">
                      {isLoading ? (
                        <ProgressComponent />
                      ) : (
                        <>

                          <div className="instructionsWrapper">
                            <div className="emojiInstructions">
                              <div className="instructionsContent">
                                <div className="item">
                                  <img src={Inadequate} alt={Inadequate} className='Inadequate' />
                                  <p><strong>Inadequate</strong></p>
                                </div>
                                <div className="item">
                                  <img src={RequiresImprovement} alt={RequiresImprovement} className='RequiresImprovement' />
                                  <p><strong>Requires Improvement</strong></p>
                                </div>
                                <div className="item">
                                  <img src={Good} alt={Good} className='Good' />
                                  <p><strong>Good</strong></p>
                                </div>
                                <div className="item">
                                  <img src={Outstanding} alt={Outstanding} className='Outstanding' />
                                  <p><strong>Outstanding</strong></p>
                                </div>
                              </div>
                            </div>
                            <p className='text-center'>Please read the questions below and tick at the grade you feel we perform. This will help us improve and raise the standards</p>
                          </div>
                          {
                            Object.keys(qualitySurveyData).map((categoryTitle, index) => (
                              <>
                                <div className="questions" key={index}>
                                  <div className="table-responsive">
                                    <table
                                      className="dynamicRows table table-striped table-bordered table-hover"
                                    >
                                      <thead>
                                        <tr>
                                          <th>{index + 1} - {categoryTitle}</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          qualitySurveyData[categoryTitle]?.map((question, questionIndex) => (

                                            <tr key={questionIndex}>
                                              {question?.isTextAreaEnabled === false ? (
                                                <>
                                                  <td>{question?.question}</td>
                                                  <td>
                                                    <div className="emojiRadioButtons">
                                                      <div className='col text-center'>
                                                        <input
                                                          type="radio"
                                                          name={question?._id}
                                                          id={`img1-${question?._id}`}
                                                          className="d-none imgbgchk"
                                                          value={1}
                                                          onChange={() => handleSurveyChange(question?._id, 1)}
                                                        />
                                                        <label htmlFor={`img1-${question?._id}`}>
                                                          <OverlayTrigger placement='top' overlay={
                                                            <Tooltip>
                                                              Inadequate
                                                            </Tooltip>
                                                          }
                                                          >
                                                            <img src={Inadequate} alt={Inadequate} className='Inadequate' />
                                                          </OverlayTrigger>

                                                        </label>
                                                      </div>
                                                      <div className='col text-center'>
                                                        <input
                                                          type="radio"
                                                          name={question?._id}
                                                          id={`img2-${question?._id}`}
                                                          className="d-none imgbgchk"
                                                          value={2}
                                                          onChange={() => handleSurveyChange(question?._id, 2)}
                                                        />
                                                        <label htmlFor={`img2-${question?._id}`}>
                                                          <OverlayTrigger placement='top' overlay={
                                                            <Tooltip>
                                                              Requires Improvement
                                                            </Tooltip>
                                                          }
                                                          >
                                                            <img src={RequiresImprovement} alt={RequiresImprovement} className='RequiresImprovement' />
                                                          </OverlayTrigger>

                                                        </label>
                                                      </div>
                                                      <div className='col text-center'>
                                                        <input
                                                          type="radio"
                                                          name={question?._id}
                                                          id={`img3-${question?._id}`}
                                                          className="d-none imgbgchk"
                                                          value={3}
                                                          onChange={() => handleSurveyChange(question?._id, 3)}
                                                        />
                                                        <label htmlFor={`img3-${question?._id}`}>
                                                          <OverlayTrigger placement='top' overlay={
                                                            <Tooltip>
                                                              Good
                                                            </Tooltip>
                                                          }
                                                          >
                                                            <img src={Good} alt={Good} className='Good' />
                                                          </OverlayTrigger>
                                                        </label>
                                                      </div>
                                                      <div className='col text-center'>
                                                        <input
                                                          type="radio"
                                                          name={question?._id}
                                                          id={`img4-${question?._id}`}
                                                          className="d-none imgbgchk"
                                                          value={4}
                                                          onChange={() => handleSurveyChange(question?._id, 4)}
                                                        />
                                                        <label htmlFor={`img4-${question?._id}`}>
                                                          <OverlayTrigger placement='top' overlay={
                                                            <Tooltip>
                                                              Outstanding
                                                            </Tooltip>
                                                          }
                                                          >
                                                            <img src={Outstanding} alt={Outstanding} className='Outstanding' />
                                                          </OverlayTrigger>
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </>
                                              ) : (
                                                <>
                                                  <td colSpan={2}>
                                                    {/* Comments */}
                                                    <p className='mt-2 mb-2'><strong>Comments</strong></p>
                                                    <TextArea
                                                      id={question?._id}
                                                      name={question?._id}
                                                      value={textAreaValue}
                                                      onChange={(e) => {
                                                        const value = e.target.value;
                                                        setTextAreaValue(e.target.value);
                                                        handleSurveyChange(question?._id, 0, value);
                                                      }}
                                                      rows="3"
                                                    />
                                                  </td>
                                                </>
                                              )
                                              }

                                            </tr>

                                          ))
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <hr />
                              </>
                            ))
                          }
                        </>
                      )}
                    </div>

                  </div>

                  <div className="col-md-12">
                    <div className="formActionButtons">
                      <Link to="/quality-survey"
                        className="btn btn-sm btn-outline-primary"
                      >
                        Close
                      </Link>
                      {isSubmitButtonClicked ? (
                        <Button
                          className="btn-sm"
                          variant="primary"
                          disabled
                        >
                          Loading...
                        </Button>
                      ) : (
                        <Button className="btn-sm" variant="primary" type="submit">
                          Submit
                        </Button>
                      )}
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout >
      {isToastAddSurvey && <ToastContainer />}
    </>
  );
}


export default AddQualitySurvey