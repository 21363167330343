import React, { useState, useEffect } from "react";
import chatDel from "../../assets/images/icons/chatDel.svg";
import moment from "moment";
import attachmentIcon from "../../assets/images/icons/attachmentIcon.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
import { Button, Modal } from "react-bootstrap";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import {
  BASE_URL,
  GET_ALL_COMBINED_MESSAGES_URL,
  DELETE_MESSAGE_COMPLETE_CHAT_URL,
  ADMIN_ID,
} from "../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
} from "../../helper/helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addNewChatSingleUser } from '../../redux/actions/addNewChatSingleUserAction';
import { connect } from 'react-redux';
import RoomSpace from "./RoomSpace";
function NameSpace({ selectedUser, addNewChatSingleUser, mobileDisplayStatus, onMobile }) {
  // console.log("🚀 ~ NameSpace ~ selectedUser:", selectedUser)
  const [singleChatData, setSingleChatData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [groupChatData, setGroupChatData] = useState([]);
  const [mergedChatData, setMergedChatData] = useState([]);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [initialSelectedUser, setInitialSelectedUser] = useState(selectedUser);
  const [isChatScreen, setIsChatScreen] = useState(false);
  const [showDeleteChatScreen, setShowDeleteChatScreen] = useState(false);
  const [deleteChatData, setDeleteChatData] = useState(null);
  const [isUserIdPresent, setIsUserIdPresent] = useState(false)
  useEffect(() => {
    setInitialSelectedUser(selectedUser);

  }, [selectedUser]);

  useEffect(() => {
    // Merge the two arrays whenever they change
    setMergedChatData([...singleChatData, ...groupChatData]);
  }, [singleChatData, groupChatData]);

  useEffect(() => {
    // console.log("🚀 ~ NameSpace ~ mergedChatData:", mergedChatData);
  }, [singleChatData, groupChatData, mergedChatData]);

  useEffect(() => {
    if (mergedChatData.some(
      message => message.recieverId === selectedUser?.id ||
        message.senderId === selectedUser?.id)) {
      setIsUserIdPresent(true)

    }
    else {
      setIsUserIdPresent(false)

    }
  }, [mergedChatData])


  const apiQuery = {
    userId: ADMIN_ID,
  };

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const combinedChatData = data?.data;
      setSingleChatData(combinedChatData?.personalMessage?.map((item) => item));
      setGroupChatData(combinedChatData?.sortedArrayGroup?.map((item) => item));
      setIsLoading(false);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_COMBINED_MESSAGES_URL, apiQuery);
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // Sort the merged chat data by date
  const sortedData = [...mergedChatData].sort((a, b) => {
    const dateA = moment(a?.date);
    const dateB = moment(b?.date);
    return dateB - dateA;
  });
  // console.log("🚀 ~ sortedData ~ sortedData:", sortedData[0])
  const shortenMessageLength = (item) => {
    if (item?.length > 20) {
      return item?.slice(0, 5) + "...";
    } else {
      return item;
    }
  };

  // Open Chat window for selected User
  const [openChatWindow, setOpenChatWindow] = useState(null)
  const handleOpenChatWindow = (item) => {
    setOpenChatWindow(item)
  }
  // Date format for last message
  const formatDate = (date) => {
    const today = moment().startOf('day');
    const dateMoment = moment(date);

    if (dateMoment.isSame(today, 'day')) {
      return dateMoment.format('h:mm a'); // Show only time if it's today
    } else if (dateMoment.isSame(today.clone().subtract(1, 'days'), 'day')) {
      return 'Yesterday'; // Show 'Yesterday' if it's one day old
    } else {
      return dateMoment.format('DD/MM/YYYY'); // Show only date if older than yesterday
    }
  };

  const nameSpace = (
    <>
      {sortedData.map((item, index) => (
        // openChatWindow
        <div
          className={`userBox ${(openChatWindow?.isGroup && openChatWindow?._id === item?._id) ||
            (openChatWindow?.isGroup === false && openChatWindow?.recieverId === item?.recieverId)
            ? "activeChat"
            : ""
            }`}
          key={index}
        >
          <div className="usersNameInfo" onClick={() => handleOpenChatWindow(item)}>
            <span className="userImage">
              {item?.image ? (
                <img src={BASE_URL + item?.image} alt={item?.name} />
              ) : item?.groupImage ? (
                <img src={BASE_URL + item?.groupImage} alt={item?.groupName} />
              ) : null}
            </span>
            <div className="userInfo">
              <span className="dayTime" style={{ right: "10px" }}>
                {item?.date ? formatDate(item.date) : "--/--/----"}
              </span>
              <span className="nameBlock">
                {item?.name ? item?.name : item?.groupName ? item?.groupName : null}
              </span>
              <span className="paragraphBlock">
                {item?.message ? (
                  typeof item?.message === 'string' && !item?.message.includes("/uploads/messagedoc") ? (
                    shortenMessageLength(item?.message)
                  ) : (
                    <>
                      <img className="attachmentChat" src={attachmentIcon} alt="attachment" /> attachment
                    </>
                  )

                ) : item?.lastMessages ? (
                  typeof item?.lastMessages?.message === 'string' && !item?.lastMessages?.message.includes("/uploads/messagedoc") ? (
                    shortenMessageLength(item?.lastMessages?.message)
                  ) : (
                    <>
                      <img className="attachmentChat" src={attachmentIcon} alt="attachment" /> attachment
                    </>
                  )
                ) : null}
              </span>
            </div>
          </div>
          <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
            {
              !item?._id && (
                <div className="userAction">
                  <span className="delete" onClick={() => {
                    subModalDeleteChatScreen(item?.senderId, item?.recieverId);
                  }}>
                    <img src={chatDel} alt="Delete" />
                  </span>
                </div>
              )
            }
          </ProtectedComponent>
        </div>
      ))
      }
    </>
  );

  const handleClean = () => {
    addNewChatSingleUser(null); // or addNewChatSingleUser('') if you prefer an empty string
    setOpenChatWindow(null)
  };

  const AddNewUserProfile = ({ user }) => (
    <div className="userBox">
      <div className="usersNameInfo" onClick={() => handleOpenChatWindow(user)}>
        <span className="">
          <img src={BASE_URL + user?.profile_picture_url} alt={user?.name} />
        </span>
        <div className="userInfo">
          <span className="dayTime" style={{ right: "10px" }}>
            {user && (
              <>
                {moment(user?.date).format("LT")}
              </>
            )}
          </span>
          <span className="nameBlock">
            {user?.name}
          </span>
          <span className="paragraphBlock">
            {user?.message && user?.message?.slice(0, 20) + "...."}
          </span>
        </div>
      </div>
      <div
        className="userAction"
        onClick={() => handleClean()}
      >
        <span className="delete">
          <img src={chatDel} alt="Delete" />
        </span>
      </div>
    </div >
  );

  const mdScreenNameSpaceSelectedUser = initialSelectedUser?._id && (
    <AddNewUserProfile
      user={initialSelectedUser}
    />
  );


  const subModalDeleteChatScreen = (senderId, recieverId) => {
    setDeleteChatData({
      senderId: senderId,
      recieverId: recieverId,
    });
    fetchAllRecords();
    setShowDeleteChatScreen(!showDeleteChatScreen);

  };

  // Delete ChatScreen records
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (deleteChatData !== "" || deleteChatData !== null) {
      const response = await deleteRecords(
        DELETE_MESSAGE_COMPLETE_CHAT_URL,
        deleteChatData
      );
      setIsChatScreen(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setOpenChatWindow(null)
      setShowDeleteChatScreen(!showDeleteChatScreen);
    } else {
      setShowDeleteChatScreen(!showDeleteChatScreen);
      setIsSubmitButtonClicked(false);
      setIsChatScreen(true);
      toastErrorMessages(toast.error, "Unable to remove", "", 1000);
    }

  });
  useEffect(() => {

    setTimeout(() => {
      setIsChatScreen(false);
    }, 2000);
  }, [isChatScreen])

  useEffect(() => {
    isUserIdPresent && (
      handleClean()
    )
  }, [isUserIdPresent])

  // Function to toggle the value of updateLastMessage
  const [updateLastMessageStatus, setUpdateLastMessageStatus] = useState(false)
  const handleUpdateLastMessage = () => {
    setUpdateLastMessageStatus(!updateLastMessageStatus)
  };

  const [isDataUpdated, setIsDataUpdated] = useState(false);

  const handleNewChatMessage = async () => {
    await fetchAllRecords(); // Wait for records to be fetched
    addNewChatSingleUser(null);

    // After fetching, mark the data as updated
    setIsDataUpdated(true);
  };

  useEffect(() => {
    if (isDataUpdated && sortedData.length > 0) {
      setOpenChatWindow(sortedData[0]);
      setIsDataUpdated(false); // Reset the state after setting the chat window
    }
  }, [isDataUpdated, sortedData]);

  useEffect(() => {
    fetchAllRecords();
  }, [updateLastMessageStatus]);

  return (
    <>
      <div className={mobileDisplayStatus ? "mobileNameSpace" : "nameSpace"}>
        {mdScreenNameSpaceSelectedUser}
        {nameSpace}
      </div>
      <RoomSpace
        openChatData={openChatWindow}
        updateLastMessageStatus={updateLastMessageStatus}
        handleUpdateLastMessage={handleUpdateLastMessage}
        handleNewChatMessage={handleNewChatMessage}
        onMobile={onMobile}
      />

      {/* Delete Message System */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeleteChatScreen}
          onHide={subModalDeleteChatScreen}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this item?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteChatScreen}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {isChatScreen && <ToastContainer />}
    </>
  );
}

// Map state from Redux store to component props
const mapStateToProps = (state) => ({
  selectedUser: state.addNewChatSingleUser.memberData, // Map selectedUserId from Redux store state
});


const mapDispatchToProps = (dispatch) => ({
  addNewChatSingleUser: (memberData) => dispatch(addNewChatSingleUser(memberData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NameSpace);
