import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../components/forms-fields/Input";
import { addCarePlanNotes } from "../../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDTE_CARE_PLAN_URL } from "../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../helper/helper";
import CustomSelect from "../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";

function EditCareNotes({ noteType, parentListNoteUrl }) {
  const location = useLocation();
  const carePlanDetail = location?.state ? location?.state?.editCarePlanDetail[0] : null;
  console.log("🚀 ~ EditCareNotes ~ carePlanDetail:", carePlanDetail)
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(carePlanDetail?.addedby);
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
  };

  const [suFormData, setSuFormData] = useState({
    carePlanid: carePlanDetail?._id,
    userId: carePlanDetail?.userId,
    addedby: carePlanDetail?.addedby?._id,
    noteType: noteType,
    title: carePlanDetail?.title,
    flag: carePlanDetail?.flag,
    noteDetails: carePlanDetail?.noteDetails,
    eventDateTime: carePlanDetail?.eventDateTime,
  });
  console.log("🚀 ~ EditCareNotes ~ suFormData:", suFormData)
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
   
    initialValues: suFormData,
    validationSchema: addCarePlanNotes,
    onSubmit: async (values) => {
      try {
        console.log("Form Data:", values);
        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, parentListNoteUrl, 10, 10);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
      }, 2000);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const updateValues = {
      ...values,
      eventDateTime: moment(values.eventDateTime).valueOf(),
      addedby: selectedUserId,
    }
    const response = await postRecords(UPDTE_CARE_PLAN_URL, updateValues);
    // console.log("🚀 ~ carenotes ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });



  const handleInputChangePrevFields = (e) => {
    handleChange(e);
    handleBlur(e);
  };


  return (
    <>
      <div className="incidents consentCapacityAddNew">
        <form onSubmit={handleSubmit}>
          <div className="formBlockContent">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <CustomSelect
                    id=""
                    name="staff"
                    onSelect={handleUserSelect}
                    defaultValue={values?.addedby}
                    filterBy={""}
                    label={"Added by"}
                    errorClass={isStaffId ? "validationError" : ""}
                    customError={isStaffId}
                    errorMessage={
                      isStaffId ? errorMessage : "Please select staff"
                    }
                  />
                </div>
              </div>

              <div className="col-md-6">
                <Input
                  type="datetime-local"
                  id="eventDateTime"
                  name="eventDateTime"
                  value={moment(values?.eventDateTime).format('YYYY-MM-DDTHH:mm')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Event Date / Time"
                  customError={touched?.eventDateTime && errors?.eventDateTime}
                  errorMessage={errors?.eventDateTime}
                  erroClass={
                    touched?.eventDateTime && errors?.eventDateTime
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                />

              </div>
              <div className="col-md-12">
                <Input
                  type="text"
                  id="title"
                  name="title"
                  value={values?.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Title"
                  customError={touched?.title && errors?.title}
                  errorMessage={errors?.title}
                  erroClass={
                    touched?.title && errors?.title
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                />

              </div>
              <div className="col-md-12">
                <TextArea
                  id="noteDetails"
                  name="noteDetails"
                  value={values?.noteDetails}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Note Details"
                  rows="3"
                  className="mb-3"
                  customError={errors.noteDetails && touched.noteDetails}
                  errorMessage={errors.noteDetails}
                  erroClass={errors.noteDetails && touched.noteDetails}
                  requiredStar={true}
                />
              </div>
              <div className="col-md-12">
                <Form.Check
                  type="checkbox"
                  id={'flag'}
                  name={'flag'}
                  // value={values?.flag}
                  checked={values?.flag}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  label={'Flag for handover?'}
                />
              </div>
            </div>
          </div>

          <div className="formActionButtons">
            <Link
              to={parentListNoteUrl}
              className="btn btn-sm btn-outline-primary"
            >
              Back
            </Link>
            {isSubmitButtonClicked ? (
              <Button
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Submit
              </Button>
            )}
          </div>
        </form>
      </div>
      {isToastAddCarePlans && <ToastContainer />}
    </>
  );
}

export default EditCareNotes;