import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { addHealthInjury } from "../../../../../../schemas";
import { useFormik } from "formik";
import ReactDOMServer from 'react-dom/server';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDTE_CARE_PLAN_URL } from "../../../../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import Layout from "../../../../../../components/layouts/Layout";
import { Form } from "react-bootstrap";
import HealthCategories from "../../../../../health-issue/HealthCategories";


function EditInjuryHealthIssue() {
  const location = useLocation();
  const carePlanDetail = location?.state ? location?.state?.editCarePlanDetail[0] : null;
  // console.log("🚀 ~ EditInjuryHealthIssue ~ carePlanDetail:", carePlanDetail)

  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  // console.log("🚀 ~ EditInjuryHealthIssue ~ selectedCategoryId:", selectedCategoryId)
  // console.log("🚀 ~ EditInjuryHealthIssue ~ selectedSubCategoryId:", selectedSubCategoryId)
  const [parentSubCatValidationStatus, setParentSubCatValidationStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);



  const nameOfWitnessesRef = useRef(null);
  const placeOfAccidentRef = useRef(null);
  const accidentDescriptionRef = useRef(null);
  const recordOfInjuryRef = useRef(null);
  const conditionOfPatientRef = useRef(null);
  const isParentContactedRef = useRef(null);
  const howParentContactedRef = useRef(null);
  const nameOfParentContactedRef = useRef(null);
  const parentContactedTimeRef = useRef(null);

  useEffect(() => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = carePlanDetail?.noteDetails || '';

    nameOfWitnessesRef.current.innerText = tempDiv.querySelector('#nameOfWitnessesData')?.innerText || '';
    placeOfAccidentRef.current.innerText = tempDiv.querySelector('#placeOfAccidentData')?.innerText || '';
    accidentDescriptionRef.current.innerText = tempDiv.querySelector('#accidentDescriptionData')?.innerText || '';
    recordOfInjuryRef.current.innerText = tempDiv.querySelector('#recordOfInjuryData')?.innerText || '';
    conditionOfPatientRef.current.innerText = tempDiv.querySelector('#conditionOfPatientData')?.innerText || '';
    isParentContactedRef.current.innerText = tempDiv.querySelector('#isParentContactedData')?.innerText || '';
    howParentContactedRef.current.innerText = tempDiv.querySelector('#howParentContactedData')?.innerText || '';
    nameOfParentContactedRef.current.innerText = tempDiv.querySelector('#nameOfParentContactedData')?.innerText || '';
    parentContactedTimeRef.current.innerText = tempDiv.querySelector('#parentContactedTimeData')?.innerText || '';

    // heightRef.current.innerText = tempDiv.querySelector('#heightData')?.innerText || '';
  }, [carePlanDetail?.noteDetails]);


  const [suFormData, setSuFormData] = useState({
    addedby: carePlanDetail?.addedby?._id,
    nameOfWitnesses: "",
    placeOfAccident: "",
    accidentDescription: "",
    recordOfInjury: "",
    conditionOfPatient: "",
    isParentContacted: "",
    howParentContacted: "",
    nameOfParentContacted: "",
    parentContactedTime: moment().format('HH:mm'),
    category: selectedCategoryId,
    subcategory: selectedSubCategoryId,
    // subcategory: carePlanDetail?.healthIssueId?.category?.parentCategory?._id,
    eventDateTime: carePlanDetail?.eventDateTime,
  });
  useEffect(() => {
    setValues(prevState => ({
      ...prevState,
      // height: heightRef.current?.innerText || '',
      nameOfWitnesses: nameOfWitnessesRef.current?.innerText || '',
      placeOfAccident: placeOfAccidentRef.current?.innerText || '',
      accidentDescription: accidentDescriptionRef.current?.innerText || '',
      recordOfInjury: recordOfInjuryRef.current?.innerText || '',
      conditionOfPatient: conditionOfPatientRef.current?.innerText || '',
      isParentContacted: isParentContactedRef.current?.innerText || '',
      howParentContacted: howParentContactedRef.current?.innerText || '',
      nameOfParentContacted: nameOfParentContactedRef.current?.innerText || '',
      parentContactedTime: parentContactedTimeRef.current?.innerText || '',

    }));
  }, [carePlanDetail?.noteDetails]);

  useEffect(() => {
    // debugger
    //testing
    if (carePlanDetail && carePlanDetail.healthIssueId) {
      const { category } = carePlanDetail.healthIssueId;
      if (category.parentCategory && category.parentCategory._id) {
        setFieldValue("category", carePlanDetail?.healthIssueId?.category?.parentCategory?.id);
        setFieldValue("subcategory", carePlanDetail?.healthIssueId?.category?._id);
        setSelectedCategoryId(carePlanDetail?.healthIssueId?.category?.parentCategory?.id)
        setSelectedSubCategoryId(carePlanDetail?.healthIssueId?.category?._id)
        setParentSubCatValidationStatus(true)

      } else {
        setFieldValue("category", carePlanDetail?.healthIssueId?.category?._id);
        setFieldValue("subcategory", null);
        setSelectedCategoryId(carePlanDetail?.healthIssueId?.category?._id)
        setParentSubCatValidationStatus(false)
        setSelectedSubCategoryId("")
      }

    }
  }, [carePlanDetail]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addHealthInjury,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);

        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "/care-plan/general-notes", 10, 10);
      setIsSubmitButtonClicked(false);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const noteDetailsString = ReactDOMServer.renderToString(htmlData);
    const updateValues = {
      carePlanid: carePlanDetail?._id,
      healthIssueId: carePlanDetail?.healthIssueId?.id,
      userId: carePlanDetail?.userId,
      noteType: carePlanDetail?.noteType,
      flag: false,
      addedby: selectedUserId,
      isHTML: true,
      title: values?.accidentDescription,
      noteDetails: noteDetailsString,
      category: values?.category,
      healthNote: values?.placeOfAccident,
      complaint: values?.accidentDescription,
      eventDateTime: moment(values.eventDateTime).valueOf(),
    }

    console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(UPDTE_CARE_PLAN_URL, updateValues);
    // console.log("🚀 ~ response ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });


  // console.log("🚀 ~ postAddCarePlansFormData ~ values:", suFormData)


  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setSuFormData({
      ...suFormData,
      [name]: value
    });
    handleChange(e);
    handleBlur(e);
  };



  // parent contact check boxes list
  const [isParentContacted, setIsParentContacted] = useState([
    { id: 1, values: "Yes", label: "Yes", checked: false },
    { id: 2, values: "No", label: "No", checked: false },
  ]);

  // how parent contact check boxes list
  const [howParentContacted, setHowParentContacted] = useState([
    { id: 1, values: "Call", label: "Call", checked: true },
    { id: 2, values: "Email", label: "Email", checked: false },
    { id: 2, values: "Text", label: "Text", checked: false },
  ]);

  const [htmlData, setHtmlData] = useState("")
  useEffect(() => {
    setHtmlData(
      <>
        <div className="col-md-12">
          <p><strong>Name of witnesses/adults present: </strong><span id="nameOfWitnessesData">{values?.nameOfWitnesses}</span></p>
        </div>

        <div className="col-md-12">
          <p><strong>Place accident occured: </strong><span id="placeOfAccidentData">{values?.placeOfAccident} </span></p>
        </div>

        <div className="col-md-12">
          <p><strong>Description how the accident occured: </strong><span id="accidentDescriptionData">{values?.accidentDescription}</span></p>
        </div>

        <div className="col-md-12">
          <p><strong>Record of any injury and action taken: </strong><span id="recordOfInjuryData">{values?.recordOfInjury}</span></p>
        </div>
        <div className="col-md-12">
          <p><strong>Condition of the patient following of the accident: </strong><span id="conditionOfPatientData">{values?.conditionOfPatient}</span></p>
        </div>
        <div className="col-md-12">
          <p><strong>Parent Contacted: </strong><span id="isParentContactedData">{values?.isParentContacted}</span></p>
        </div>
        {
          values?.isParentContacted === "Yes" && (
            <>
              <div className="col-md-12">
                <p><strong>If Yes how parent was contacted?: </strong><span id="howParentContactedData">{values?.howParentContacted}</span></p>
              </div>
              <div className="col-md-12">
                <p><strong>Name of Parent Contacted: </strong>
                  <span id="nameOfParentContactedData">{values?.nameOfParentContacted}</span><br />
                  Contacted at: <span id="parentContactedTimeData">
                    {moment(values?.parentContactedTime, "HH:mm").format("h:mm A")}</span>
                </p>
              </div>
            </>
          )
        }

      </>
    );

  }, [values]);







  // console.log("🚀 carePlanDetail:", carePlanDetail)
  // console.log("🚀 values:", values)
  console.log("🚀 Parent:", values?.category)
  console.log("🚀 Child:", values?.subcategory)

  // useEffect(() => {
  //   if (selectedCategoryId !== "") {
  //     setFieldValue("category", selectedCategoryId)
  //   }

  // }, [selectedCategoryId])

  const handleUserSelect = async (userId) => {
    setSelectedUserId(userId)
    setFieldValue("addedby", userId);
  }
  const handleBodyPartSelect = async (catId) => {
    setSelectedCategoryId(catId);
    // setReceivedSubCategory("no-child");
    setSelectedSubCategoryId("no-child");
    setFieldValue("category", catId);
    setFieldValue("subcategory", "no-child");
  };
  // category: selectedCategoryId,
  // subcategory: selectedSubCategoryId,

  const handleSubCatValidationStatus = (status) => {
    // console.log("Sub Cat Validation Status (Parent):", status);
    setParentSubCatValidationStatus(status);
  };

  useEffect(() => {
    // console.log("Parent Sub Cat Validation Status 01:", parentSubCatValidationStatus);
  }, [parentSubCatValidationStatus]);

  // useEffect(() => {
  //   // setFieldValue("category", selectedCategoryId);
  //   if (!parentSubCatValidationStatus) {
  //     setFieldValue("subcategory", "no-child");
  //   }
  //   // else {
  //   //   setFieldValue("subcategory", "");
  //   // }
  // }, [selectedCategoryId, parentSubCatValidationStatus]);
  // console.log("🚀 ~ EditInjuryHealthIssue ~ parentSubCatValidationStatus:", parentSubCatValidationStatus)
  // console.log("🚀 ~ file: AddHealthIssues.jsx:112 ~ useEffect ~ selectedCategoryId:", selectedCategoryId)

  // const [receivedSubCategory, setReceivedSubCategory] = useState(null);
  // console.log("🚀 ~asfsdfdsfsdfry:", receivedSubCategory)

  const handleSubCategoryChange = (subCategoryValue) => {
    setSelectedSubCategoryId(subCategoryValue);
    setFieldValue("category", subCategoryValue?.value);
    setFieldValue("subcategory", subCategoryValue?.value);
    setFieldTouched("subcategory", false);
    // setSelectedCategoryId(subCategoryValue?.value);
    // setSelectedCategoryId(carePlanDetail?.healthIssueId?.category?.parentCategory?.id)
    // setSelectedSubCategoryId(carePlanDetail?.healthIssueId?.category?._id)
  };

  return (
    <>

      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Edit Injury Health Issue note</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">

                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={values?.addedby}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date and time of accident"
                        customError={touched?.eventDateTime && errors?.eventDateTime}
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="nameOfWitnesses"
                        name="nameOfWitnesses"
                        value={values?.nameOfWitnesses}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Name of witnesses/adults present"
                        rows="3"
                        className="mb-0"
                        customError={touched?.nameOfWitnesses && errors?.nameOfWitnesses}
                        errorMessage={errors?.nameOfWitnesses}
                        erroClass={
                          touched?.nameOfWitnesses && errors?.nameOfWitnesses
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="placeOfAccident"
                        name="placeOfAccident"
                        value={values?.placeOfAccident}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Place accident occured"
                        rows="3"
                        className="mb-0"
                        customError={touched?.placeOfAccident && errors?.placeOfAccident}
                        errorMessage={errors?.placeOfAccident}
                        erroClass={
                          touched?.placeOfAccident && errors?.placeOfAccident
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="accidentDescription"
                        name="accidentDescription"
                        value={values?.accidentDescription}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Description how the accident occured"
                        rows="3"
                        className="mb-0"
                        customError={touched?.accidentDescription && errors?.accidentDescription}
                        errorMessage={errors?.accidentDescription}
                        erroClass={
                          touched?.accidentDescription && errors?.accidentDescription
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="recordOfInjury"
                        name="recordOfInjury"
                        value={values?.recordOfInjury}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Record of any injury and action taken"
                        rows="3"
                        className="mb-0"
                        customError={touched?.recordOfInjury && errors?.recordOfInjury}
                        errorMessage={errors?.recordOfInjury}
                        erroClass={
                          touched?.recordOfInjury && errors?.recordOfInjury
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-12">

                      <TextArea
                        id="conditionOfPatient"
                        name="conditionOfPatient"
                        value={values?.conditionOfPatient}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Condition of the patient following of the accident"
                        rows="3"
                        className="mb-0"
                        customError={touched?.conditionOfPatient && errors?.conditionOfPatient}
                        errorMessage={errors?.conditionOfPatient}
                        erroClass={
                          touched?.conditionOfPatient && errors?.conditionOfPatient
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-12">
                      {
                        selectedCategoryId !== null && selectedSubCategoryId !== null && (
                          <HealthCategories
                            name="category"
                            onSelect={handleBodyPartSelect}
                            onSelectSubCat={handleSubCategoryChange}
                            selectedSubCategory={selectedSubCategoryId}
                            selectedCategoryId={selectedCategoryId}
                            // value={values?.category}
                            defaultSelectedCatValue={values?.category}
                            defaultSelectedSubCatValue={values?.subcategory}
                            filterBy={""}
                            isDisabled={false}
                            label="Select Category-"
                            sublabel="Select Subcategory"
                            subCatValidationStatus={true}
                            setSubCatValidationStatus={handleSubCatValidationStatus}
                            erroClassCategory={touched.category && errors.category ? "validationError" : ""}
                            customErrorCategory={touched.category && errors.category}
                            errorMessageCategory={touched.category && errors.category ? errors.category : ""}
                            customErrorSubcategory={touched.subcategory && errors.subcategory}
                            errorMessageSubcategory={touched.subcategory && errors.subcategory ? errors.subcategory : ""}
                            erroClassSubcategory={touched.subcategory && errors.subcategory ? "validationError" : ""}

                          />
                        )
                      }

                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="general m-2">Parent Contacted</label>
                      <div className="checkboxList spaceInCheckList">
                        {isParentContacted.map((row, index) => (
                          <>
                            <Form.Check
                              key={row.id}
                              name={"isParentContacted"}
                              type="radio"
                              id={row?.label}
                              label={row?.label}
                              value={row.values}
                              checked={values?.isParentContacted === row?.values ? true : false}
                              onChange={handleInputChangePrevFields}
                              onBlur={handleBlur}
                            />
                          </>
                        ))}

                      </div>
                    </div>

                    {values?.isParentContacted === "Yes" && (
                      <>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id="nameOfParentContacted"
                            name="nameOfParentContacted"
                            value={values?.nameOfParentContacted}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Name of Parent Contacted"
                            customError={touched?.nameOfParentContacted && errors?.nameOfParentContacted}
                            errorMessage={errors?.nameOfParentContacted}
                            erroClass={
                              touched?.nameOfParentContacted && errors?.nameOfParentContacted
                                ? "validationError"
                                : ""
                            }
                          />

                        </div>

                        <div className="col-md-6">
                          <Input
                            type="time"
                            id="parentContactedTime"
                            name="parentContactedTime"
                            value={values?.parentContactedTime}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Contact time"
                            customError={touched?.parentContactedTime && errors?.parentContactedTime}
                            errorMessage={errors?.parentContactedTime}
                            erroClass={
                              touched?.parentContactedTime && errors?.parentContactedTime
                                ? "validationError"
                                : ""
                            }
                          />



                        </div>

                        <div className="col-md-12">
                          <label className="general m-2">How parent was contacted</label>
                          <div className="checkboxList spaceInCheckList">
                            {howParentContacted.map((row, index) => (
                              <>
                                <Form.Check
                                  key={row.id}
                                  name="howParentContacted"
                                  type="radio"
                                  id={row.label}
                                  label={row.label}
                                  value={row.values}
                                  checked={values?.howParentContacted === row?.values ? true : false}
                                  onChange={handleInputChangePrevFields}
                                  onBlur={handleBlur}
                                />
                              </>
                            ))}

                          </div>
                        </div>
                      </>
                    )}


                  </div>
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      // onClick={subModalAddCarePlans}
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </form>
              <div className="referenceDiv">
                <div ref={nameOfWitnessesRef}></div>
                <div ref={placeOfAccidentRef}></div>
                <div ref={accidentDescriptionRef}></div>
                <div ref={recordOfInjuryRef}></div>
                <div ref={conditionOfPatientRef}></div>
                <div ref={isParentContactedRef}></div>
                <div ref={howParentContactedRef}></div>
                <div ref={nameOfParentContactedRef}></div>
                <div ref={parentContactedTimeRef}></div>
              </div>
              {isToastAddCarePlans && <ToastContainer />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default EditInjuryHealthIssue