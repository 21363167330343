import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { addMoodRating } from "../../../../../../schemas";
import { useFormik } from "formik";
import ReactDOMServer from "react-dom/server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDTE_CARE_PLAN_URL } from "../../../../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import Layout from "../../../../../../components/layouts/Layout";


import Angry from "../../../../../../assets/images/mood-rating/Angry.svg";
import Bored from "../../../../../../assets/images/mood-rating/Bored.svg";
import Calm from "../../../../../../assets/images/mood-rating/Calm.svg";
import Confident from "../../../../../../assets/images/mood-rating/Confident.svg";
import Excited from "../../../../../../assets/images/mood-rating/Excited.svg";
import Happy from "../../../../../../assets/images/mood-rating/Happy.svg";
import Hopeful from "../../../../../../assets/images/mood-rating/Hopeful.svg";
import Nervous from "../../../../../../assets/images/mood-rating/Nervous.svg";
import Proud from "../../../../../../assets/images/mood-rating/Proud.svg";
import Relaxed from "../../../../../../assets/images/mood-rating/Relaxed.svg";
import Sad from "../../../../../../assets/images/mood-rating/Sad.svg";
import Scared from "../../../../../../assets/images/mood-rating/Scared.svg";
import Tired from "../../../../../../assets/images/mood-rating/Tired.svg";
import Worried from "../../../../../../assets/images/mood-rating/Worried.svg";

function EditMoodRating() {
  const location = useLocation();
  const carePlanDetail = location?.state
    ? location?.state?.editCarePlanDetail[0]
    : null;
  // console.log("🚀 ~ EditCareNotes ~ carePlanDetail:", carePlanDetail);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(carePlanDetail?.addedby);
  const [errorMessage, setErrorMessage] = useState(false);
  const [moodRatingIndex, setMoodRatingIndex] = useState(carePlanDetail?.moodRating);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
  };



  const [suFormData, setSuFormData] = useState({
    addedby: carePlanDetail?.addedby?._id,
    userId: carePlanDetail?.userId,
    noteType: carePlanDetail?.noteType,
    title: carePlanDetail?.title,
    flag: carePlanDetail?.flag,
    noteDetails: carePlanDetail?.noteDetails,
    moodRating: moodRatingIndex,
    eventDateTime: carePlanDetail?.eventDateTime,
  });
  // console.log("su form data ", suFormData)

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addMoodRating,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);

        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "/care-plan/general-notes", 10, 10);
      setIsSubmitButtonClicked(false);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const updateValues = {
      ...values,
      carePlanid: carePlanDetail?._id,
      addedby: selectedUserId,
      noteDetails: "Mood Rating is " + moodRatingIndex + " at " + moment(values.eventDateTime).format('hh:mm A') + " on " + moment(values.eventDateTime).format('DD/MM/YYYY'),
      title: "Mood Rating is " + moodRatingIndex,
      moodRating: moodRatingIndex,
      eventDateTime: moment(values.eventDateTime).valueOf(),
    }
    // console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(UPDTE_CARE_PLAN_URL, updateValues);
    // console.log("🚀 ~ postAddCarePlansFormData ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });



  // how parent contact check boxes list
  const [moodRatingSmileys, setMoodRatingSmileys] = useState([
    { id: 1, values: "Angry", label: "Angry", imgPath: Angry, checked: false },
    { id: 2, values: "Bored", label: "Bored", imgPath: Bored, checked: false },
    { id: 3, values: "Calm", label: "Calm", imgPath: Calm, checked: false },
    { id: 4, values: "Confident", label: "Confident", imgPath: Confident, checked: false },
    { id: 5, values: "Excited", label: "Excited", imgPath: Excited, checked: false },
    { id: 6, values: "Happy", label: "Happy", imgPath: Happy, checked: false },
    { id: 7, values: "Hopeful", label: "Hopeful", imgPath: Hopeful, checked: false },
    { id: 8, values: "Nervous", label: "Nervous", imgPath: Nervous, checked: false },
    { id: 9, values: "Proud", label: "Proud", imgPath: Proud, checked: false },
    { id: 10, values: "Relaxed", label: "Relaxed", imgPath: Relaxed, checked: false },
    { id: 11, values: "Sad", label: "Sad", imgPath: Sad, checked: false },
    { id: 12, values: "Scared", label: "Scared", imgPath: Scared, checked: false },
    { id: 13, values: "Tired", label: "Tired", imgPath: Tired, checked: false },
    { id: 14, values: "Worried", label: "Worried", imgPath: Angry, checked: false },
  ]);

  return (
    <>
      <Layout pageTitleText="Mood Rating">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Edit MoodRating</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={values?.addedby}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format(
                          "YYYY-MM-DDTHH:mm"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date / Time"
                        customError={
                          touched?.eventDateTime && errors?.eventDateTime
                        }
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-12 mb-3">
                      <div className="emojiRadioButtons moodRatingButtons">
                        {

                          moodRatingSmileys?.map((items, index) => (
                            <div className='col text-center'>
                              <input
                                type="radio"
                                name="mood"
                                id={`rating-${items?.id}`}
                                className="d-none imgbgchk"
                                // value={items?.values}
                                checked={moodRatingIndex === items?.values ? true : false}
                                onChange={() => setMoodRatingIndex(items?.values)}
                              />
                              <label htmlFor={`rating-${items?.id}`}>
                                <img src={items?.imgPath} alt={items?.label} className={items?.label} />
                                <span>{items?.label}</span>
                              </label>
                            </div>
                          ))
                        }

                      </div>

                    </div>
                  </div>
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      // onClick={subModalAddCarePlans}
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </form>

              {isToastAddCarePlans && <ToastContainer />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default EditMoodRating;