import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import password_eye_icon_circle from "../../assets/images/icons/password_eye_icon_circle.svg";
import { GET_ALL_SERVICE_USERS_URL } from "../../env";
import {
    getRecords,
    isSuccess,
    catchAsync,
} from "../../helper/helper";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../env";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function OverviewTaskLists({ overviewTaskData, taskType }) {
    const [taskDataType, setTaskDataType] = useState("upcoming");
    const [isLoading, setIsLoading] = useState(true);
    const [profileUserDetails, setProfileUserDetails] = useState({});
    console.log("🚀 ~ OverviewTaskLists ~ profileUserDetails:", profileUserDetails)
    const [dataID, setDataID] = useState(null); // Moved to top level
    const navigate = useNavigate();
    // console.log("sdfsdf asf", overviewTaskData?.[taskDataType])
    useEffect(() => {
        if (taskType === "Due Tasks") {
            setTaskDataType("due");
        } else if (taskType === "Urgent Tasks") {
            setTaskDataType("urgent");
        } else {
            setTaskDataType("upcoming");
        }
        setIsLoading(false);
    }, [taskType]);

    const fetchServiceUserData = async (serviceUserId) => {
        try {
            const dataID = {
                query: {
                    critarion: {
                        _id: serviceUserId,
                    },
                },
            };

            const getEnquiry = await getRecords(GET_ALL_SERVICE_USERS_URL, dataID);
            if (getEnquiry?.status === isSuccess) {
                await setProfileUserDetails(getEnquiry?.data?.users[0]);
                // Save profileUserDetails in localStorage
                localStorage.setItem("profileUserDetails", JSON.stringify(getEnquiry?.data?.users[0]));

            } else {
                console.error("Failed to fetch service user data.");
            }
        } catch (error) {
            console.error("Error fetching service user data:", error);
        }
    };

    const handleEditViewPBSPlans = async (item, url, serviceUserId) => {
        try {
            // Fetch service user data
            await fetchServiceUserData(serviceUserId);

            // Save PBS plan details in localStorage
            if (item) {
                localStorage.setItem("pbsPlanDetail", JSON.stringify(item));
            } else {
                console.error("Item is undefined");
            }

            navigate(url);

        } catch (error) {
            console.error("Error during handleEditViewPBSPlans: ", error);
        }
    };

    // useEffect(() => {
    //     localStorage.setItem(
    //       "profileUserDetails",
    //       JSON.stringify(profileUserDetails)
    //     );
    //   }, [profileUserDetails])

    const columns = [
        {
            name: "Service User",
            selector: (row) => {
                return (
                    <span className="usersNameInfo">
                        <span className="userImage">
                            <img
                                src={`${BASE_URL}${row?.userId?.profile_picture_url}`}
                                alt={`${row?.userId?.modelId?.name}`}
                            />
                        </span>
                        <span className="userInfo">
                            <span className="nameBlock">{`${row?.userId?.modelId?.name}`}</span>
                        </span>
                    </span>
                );
            },
            center: true,
        },
        {
            name: "Type",
            selector: (row) => <span>PBS Plan</span>,
            center: true,
        },
        {
            name: "Due Date",
            selector: (row) => (
                <span>{moment(row?.completionDate).format("DD/MM/YYYY")}</span>
            ),
            center: true,
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="actionColumns individualReviewsActions">
                    <span className="edit" onClick={() => handleEditViewPBSPlans(row, "/view-plans", row?.userId?._id)}>
                        <img src={password_eye_icon_circle} alt="View" title="View" />
                    </span>
                </div>
            ),
            center: true,
            wrap: true,
        },
    ];

    return (
        <div className="dataTableWrapper taskList">
            <DataTable
                className="maxWidthTable"
                columns={columns}
                data={overviewTaskData?.[taskDataType]}
                pagination
                paginationRowsPerPageOptions={[1, 25, 50, 100]}
                paginationPerPage={10}
                fixedHeader
                fixedHeaderScrollHeight="520px"
                highlightOnHover
                responsive={true}
                progressPending={isLoading}
                progressComponent={<ProgressComponent />}
            />
        </div>
    );
}

export default OverviewTaskLists;
