import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { assignRoleToUser } from "../../schemas";
import { useFormik } from "formik";
import {
  isSuccess,
  toastErrorMessages,
  toastSuccessMessages,
  catchAsync,
  postRecords
} from "../../helper/helper";

import {
  POST_UPDATE_STAFF_MEMBER_DETAILS_URL,
  ADMIN_AUTH_TOKEN,
  BASE_URL,
} from "../../env";

const AssignRole = ({ userProfileDetail, showAssignRole }) => {
  console.log("🚀 ~ AssignRole ~ userProfileDetail:", userProfileDetail);

  const [checkedItems, setCheckedItems] = useState({});
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAssignUserRole, setIsToastAssignUserRole] = useState(false);

  const handleShowHideModal = () => {
    showAssignRole((prevShow) => !prevShow);
  };

  const items = [
    // { value: "superadmin", label: "Super Admin" },
    { value: "slthead", label: "Senior Leadership Team (SLT) HEAD" },
    { value: "sltgeneral", label: "Senior Leadership Team (General)" },
    { value: "officestaff", label: "Office Staff" },
    { value: "financeadmin", label: "Finance Admin" },
    { value: "staffuser", label: "Staff User" },
    { value: "supervisionaccess", label: "Supervision Access" },
    { value: "rosteraccess", label: "Roster Access" },
  ];

  const [suFormData, setSuFormData] = useState({
    userId: userProfileDetail?._id,
    staffRoles: userProfileDetail?.user?.staffRoles || []
  });

  useEffect(() => {
    const initialCheckedItems = {};
    suFormData.staffRoles.forEach(role => {
      initialCheckedItems[role] = true;
    });
    setCheckedItems(initialCheckedItems);
  }, [suFormData.staffRoles]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckedItems(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: assignRoleToUser,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postAddAssignUserRoleFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAssignUserRole(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      handleShowHideModal();
    } else if (method === "postAddAssignUserRoleFormData" && data?.status === isSuccess) {
      setIsToastAssignUserRole(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal();
      }, 2000);
    }
  };

  const postAddAssignUserRoleFormData = catchAsync(async (req, res) => {
    const updateValues = {
      ...values,
      staffRoles: Object.keys(checkedItems).filter(item => checkedItems[item])
    };
    console.log("🚀 ~ postAddAssignUserRoleFormData ~ updateValues:", updateValues);
    const response = await postRecords(POST_UPDATE_STAFF_MEMBER_DETAILS_URL, updateValues);
    console.log("🚀 ~ carenotes ~ response:", response);
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddAssignUserRoleFormData", response);
  });

  return (
    <>
      <div className="incidents consentCapacityAddNew">
        <p>Please select user role for<br /><strong>{userProfileDetail?.staffMemberName}</strong></p>
        <Form onSubmit={handleSubmit}>
          {items.map((item, index) => (
            <Form.Check
              key={index}
              type="checkbox"
              id={`checkbox-${index}`}
              label={item.label}
              name={item.value}
              checked={checkedItems[item.value] || false}
              onChange={handleCheckboxChange}
            />
          ))}
          <div className="formActionButtons mt-3">
            <span
              onClick={handleShowHideModal}
              className="btn btn-sm btn-outline-primary"
            >
              Close
            </span>
            {isSubmitButtonClicked ? (
              <Button
                onClick={handleShowHideModal}
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Submit
              </Button>
            )}
          </div>
        </Form>
      </div>
      {isToastAssignUserRole && <ToastContainer />}
    </>
  );
};

export default AssignRole;
