import React, { useState, useEffect } from "react";
import { BASE_URL, ADMIN_ID } from "../env";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import {
  handleDateChangeNumeric,
  isSuccess,
  postRecords,
  calculateHolidayHours,
  calculateNextYearEndDate,
  phoneRegExp,
  catchAsync,
  formateDate,
} from "./helper";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import { ADMIN_AUTH_TOKEN } from "../env";

// Training Screen props

const trainingPrpsName = "";
const trainingPrpsDescription = "";

const additionalFieldsOptions = [
  // { value: "text", label: "Text" },
  { value: "image", label: "Image" },
  { value: "document", label: "Document" },
  { value: "video", label: "Video" },
];

const formFields = [
  { name: "prpsName", label: "Training Title" },
  { name: "prpsDescription", label: "Training Description" },
  { name: "prpsTrgStartDate", label: "Start Date" },
  { name: "prpsTrgEndDate", label: "End Date" },
  { name: "prpsTrgRegisterationDate", label: "Registration Date" },
  { name: "contentType", label: "Additional Content" },
  // { name: "content", label: "Content" },
];

const trainingType = [
  { value: "annual", label: "Annual" },
  { value: "monthly", label: "Monthly" },
  { value: "onetime", label: "One time" },
];

const trainingClass = [
  { value: "onsite", label: "On Site" },
  { value: "remote", label: "Remote" },
];

const prpsTrgStatusOptions = [
  { value: "started", label: "Started" },
  { value: "pending", label: "Pending" },
  { value: "cancelled", label: "Cancelled" },
  { value: "postponed", label: "Postponed" },
  { value: "completed", label: "Completed" },
];

let isToggled = false;
const SUPER_ADMIN = "superadmin";
const SIX_HOURS = "06:00";
const TWELVE_HOURS = "12:00";
const startDate = moment();
const weeksLimitMsg = "You have reached the 12 week limit for the schedule!";
const rosterTemplateErrorMessage =
  "Ensure that you create a roster template before generating the live roster.";
const rosterTemplateNotFound =
  "! Sorry, we couldn't find any schedule templates matching to this date.";
const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const rotaSchedulesStatusClassMap = {
  active: "publishedCell",
  saved: "savedCell",
  future: "greenCell",
};

const rosterSchedulesStatusClassMap = {
  active: "publishedCell",
  saved: "savedCell",
  future: "greenCell",
};

const hldRequestTypeOptions = [
  { value: "holiday", label: "Holiday" },
  { value: "sickLeave", label: "Sick Leave" },
  { value: "absent", label: "Absent" },
];

const daysOptions = [
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
  { value: "Sunday", label: "Sunday" },
];

const weeksOptions = [
  { value: "1", label: "Week 01" },
  { value: "2", label: "Week 02" },
  { value: "3", label: "Week 03" },
  { value: "4", label: "Week 04" },
];

const twoWeeksOptions = [
  { value: "1", label: "Week 01" },
  { value: "2", label: "Week 02" },
];

const fourWeeksOptions = [
  { value: "1", label: "Week 01" },
  { value: "2", label: "Week 02" },
  { value: "3", label: "Week 03" },
  { value: "4", label: "Week 04" },
];

const locationOptions = [
  { value: "1", label: "Location 01" },
  { value: "2", label: "Location 02" },
  { value: "3", label: "Location 03" },
];

const staffDesignationOptions = [
  { value: "staffmember", label: "Staff Member" },
  { value: "teamlead", label: "Team Lead" },
  { value: "supervisor", label: "Supervisor" },
  { value: "manager", label: "Manager" },
];

const staffRoleOptions = [
  { value: "slthead", label: "Senior Leadership Team (SLT) HEAD" },
  { value: "sltgeneral", label: "Senior Leadership Team (General)" },
  { value: "officestaff", label: "Office Staff" },
  { value: "financeadmin", label: "Finance Admin" },
  { value: "supervisionaccess", label: "Supervision Access" },
  { value: "rosteraccess", label: "Roster Access" },
  { value: "staffuser", label: "Staff Member" },
];

const vehicleTypeOptions = [
  { value: "auto", label: "Automatic" },
  { value: "manual", label: "Manual" },
];

const statusOptions = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

const rotaTempStatusOptions = [
  { value: "active", label: "Active" },
  { value: "Inactive", label: "InActive" },
  { value: "future", label: "Future" },
  { value: "saved", label: "Save" },
];

const rotaTempFutureStatusOptions = [
  { value: "future", label: "Future" },
  { value: "saved", label: "Save" },
];

const rotaTempWeeksOptions = [
  { value: 2, label: 2 },
  { value: 4, label: 4 },
];

const sprStatus = [
  {
    value: "assigned",
    label: "Assigned",
  },
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "overdue",
    label: "Overdue",
  },
];

const sprResult = [
  {
    value: "passed",
    label: "Passed",
  },
];

const userInputFields = [
  {
    type: "string",
    name: "title",
    labelText: "Title",
    required: false,
  },
  {
    type: "string",
    name: "name",
    labelText: "First Name",
    required: true,
  },
  {
    type: "string",
    name: "lastName",
    labelText: "Last Name",
    required: true,
  },
  {
    type: "string",
    name: "initials",
    labelText: "Initials",
    required: false,
  },
  {
    type: "email",
    name: "email",
    labelText: "Email",
    required: true,
  },
  // {
  //   type: "password",
  //   name: "password",
  //   labelText: "Password",
  //   required: true,
  // },
  {
    type: "tel",
    name: "phoneNumber",
    labelText: "Primary Contact No.",
    required: true,
  },
  {
    type: "tel",
    name: "secondaryContactNo",
    labelText: "Secondary Contact No.",
    required: false,
  },
  // {
  //   type: "date",
  //   name: "stafDob",
  //   labelText: "Date of Birth",
  //   required: true,
  // },
];

const workLoadFields = [
  // {
  //   type: "date",
  //   name: "endDate",
  //   labelText: "End Date",
  //   disabled: true,
  //   required: false,
  // },
  // {
  //   type: "number",
  //   name: "numberOfDays",
  //   labelText: "Days",
  //   disabled: true,
  //   required: false,
  // },
  // {
  //   type: "number",
  //   name: "numberOfHours",
  //   labelText: "Hours",
  //   disabled: true,
  // },
  // {
  //   type: "number",
  //   name: "numberOfWeeks",
  //   labelText: "Weeks",
  //   disabled: true,
  //   required: false,
  // },
];

const candidateStatusOptions = [
  {
    value: "new",
    label: "New",
  },
  {
    value: "archive",
    label: "Archive",
  },
  {
    value: "finazlized",
    label: "Finalized",
  },
];

const genderOptions = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
];

const handleFormUpdate = async (setFieldValue, fieldName, value) => {
  setFieldValue(fieldName, value);
};

const getFormDataProps = (formData) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = formData;

  return {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  };
};

const CustomOption = ({ innerProps, label, data, isSelected }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      {...innerProps}
      className={`customOption ${isSelected ? "optionSelected" : isHovered ? "optionHover" : null
        }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {data?.image && (
        <img
          src={BASE_URL + data?.image}
          alt={label}
          className="rounded-circle mr-2"
        />
      )}
      <span className="label">{label}</span>
    </div>
  );
};

const handleReload = () => {
  window.location.reload();
};

const calculateTimeDifference = (startTime, endTime) => {
  const startMoment = moment(startTime);
  const endMoment = moment(endTime);
  const differenceMs = endMoment.diff(startMoment);
  const differenceMinutes = moment.duration(differenceMs).asMinutes();
  const formattedDifference = moment.utc(differenceMs).format("HH:mm");
  return formattedDifference;
};

// const calculateTimeDifference = (startTime, endTime) => {
//   const startMoment = moment(startTime);
//   const endMoment = moment(endTime);
//   const differenceMs = endMoment.diff(startMoment);
//   const differenceMinutes = moment.duration(differenceMs).asMinutes();
//   if (Math.abs(differenceMinutes) < 720) {
//     const formattedDifference = moment.utc(differenceMs).format("hh:mm");
//     return formattedDifference;
//   } else {
//     return "0:00";
//   }
// };

const handleSetIdFormikFormData = async (value, name, setFieldValue) => {
  if (value) {
    setFieldValue(name, value);
  } else {
    setFieldValue(name, "");
  }
};
const getRosterWeekShiftStartDate = (rosterEndDate) => {
  const startDate7DaysBack = moment(rosterEndDate)
    .subtract(6, "days")
    .startOf("day")
    .valueOf();
  return startDate7DaysBack;
};

const getRosterWeekShiftEndDate = (shift) => {
  const {
    primaryWeekNo,
    primaryWeek1EndDate,
    primaryWeek2EndDate,
    primaryWeek3EndDate,
    primaryWeek4EndDate,
    rosterEndDate,
  } = shift;
  switch (primaryWeekNo) {
    case 1:
      return primaryWeek1EndDate || null;
    case 2:
      return primaryWeek2EndDate || rosterEndDate || null;
    case 3:
      return primaryWeek3EndDate || null;
    case 4:
      return primaryWeek4EndDate || null;
    default:
      return null;
  }
};

const handleTimeChangeToNumeric = async (e) => {
  const { value } = e.target;
  const numericValue = await handleDateChangeNumeric(
    value,
    "htmlTimeToNumeric"
  );
  return numericValue;
};

const convertMillisecondsToTime = (milliseconds) => {
  const minutes = Math.floor(milliseconds / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    remainingMinutes
  ).padStart(2, "0")}`;
  return formattedTime;
};

const useShiftEffect = async (values, setTotalShiftHours, handleFormUpdate) => {
  const fetchData = async () => {
    if (values?.shiftStartTime || values?.shiftEndTime) {
      try {
        const formattedDifference = await calculateTimeDifference(
          values?.shiftStartTime,
          values?.shiftEndTime
        );
        setTotalShiftHours(formattedDifference);
        handleFormUpdate("workHrs", formattedDifference);
      } catch (error) {
        console.error("Error calculating time difference:", error);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [values?.shiftStartTime, values?.shiftEndTime]);
};

const GenerateScheduleFormData = (
  apiUrl,
  validationSchema,
  setIsFormSubmitting,
  handleCloseModel,
  fetchAllRecords
) => {
  const formData = useFormik({
    initialValues: {
      tempId: "",
      shiftId: "",
      shitArray: "",
      locationId: "",
      dayId: "",
      staffUserId: "",
      patientId: "",
      day: "",
      shiftStartTime: null,
      shiftEndTime: null,
      workHrs: "",
      isSleepOver: false,
      isOverNightStay: false,
      secondaryWeeks: [],
      addedby: ADMIN_ID,
      lastModifiedBy: ADMIN_ID,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const trimmedValues = {

        ...values,
        note: values?.note?.trim(),
      };
      try {
        setIsFormSubmitting(true);
        console.log("🚀 ~ onSubmit: ~ values:", values)
        const response = await postRecords(apiUrl, trimmedValues);
        console.log("🚀 ~ onSubmit: ~ apiUrl:", apiUrl)
        console.log("🚀 ~ onSubmit: ~ response:", response)
        await fetchAllRecords();
        if (response?.status === isSuccess) {
          await fetchAllRecords();
          handleCloseModel();
          setIsFormSubmitting(false);
        }
      } catch (error) {
        console.error("Error", error);
        setIsFormSubmitting(false);
      }
    },
  });
  return formData;
};

const GenerateRosterScheduleFormData = (apiUrl, validationSchema) => {
  const formData = useFormik({
    initialValues: {
      id: "",
      dayId: "",
      day: "",
      staffUserId: "",
      shiftStartTime: null,
      shiftEndTime: null,
      workHrs: "",
      patientId: "",
      isSleepOver: false,
      isOverNightStay: false,
      note: "",
      shiftLocation: "",
      lastModifiedBy: ADMIN_ID,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const trimmedValues = {
        ...values,
        note: values?.note?.trim(),
      };
      setSubmitting(true);
      try {
        const response = await postRecords(apiUrl, trimmedValues);
        if (response?.status === isSuccess) {
          handleReload();
        }
      } catch (error) {
        console.error("Error", error);
      }
    },
  });
  return formData;
};

const handleCheckboxChange = (e, setFieldValue, fieldName) => {
  const isChecked = e?.target?.checked;
  setFieldValue(fieldName, isChecked);
};

const useCheckedEffect = (fieldName, setIsChecked, values) => {
  useEffect(() => {
    if (values?.[fieldName]) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [values?.[fieldName]]);
};

const uploadFile = async ({
  isUpload,
  updateFieldName,
  updateFieldId,
  fileName,
  file,
  apiUrl,
  successCallback,
}) => {
  try {
    if (!file) {
      throw new Error("No file selected");
    }
    const formDataToSend = new FormData();
    formDataToSend.append(fileName, file);
    if (!isUpload) {
      formDataToSend.append(updateFieldName, updateFieldId);
    }
    const apiResponse = await fetch(apiUrl, {
      method: "POST",
      body: formDataToSend,
      headers: {
        Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
      },
    });
    const response = await apiResponse?.json();
    console.log("🚀 ~ response:", response);
    if (response?.status !== isSuccess) {
      throw new Error(`Failed to upload document. Status: ${response?.status}`);
    } else {
      return successCallback(response?.data);
    }
  } catch (error) {
    return error;
  }
};

const uploadGroupFile = async ({
  groupId,
  fileName,
  file,
  apiUrl,
  successCallback,
}) => {
  try {
    if (!file) {
      throw new Error("No file selected");
    }
    const formDataToSend = new FormData();
    formDataToSend.append("groupId", groupId);
    formDataToSend.append(fileName, file);
    const apiResponse = await fetch(apiUrl, {
      method: "POST",
      body: formDataToSend,
      headers: {
        Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
      },
    });
    const response = await apiResponse?.json();
    if (response?.status !== isSuccess) {
      throw new Error(`Failed to upload document. Status: ${response?.status}`);
    } else {
      return successCallback(response?.data);
    }
  } catch (error) {
    return error;
  }
};

const handleSelectChange = (setFieldValue, fieldName, value) => {
  setFieldValue(fieldName, value);
};

const updateDesignationFields = (
  setValues,
  values,
  setStateManager,
  setStateSupervisor
) => {
  if (!values?.staffDesignation || values?.staffDesignation === null) {
    setStateManager(true);
    setStateSupervisor(true);
  } else {
    setStateManager(values?.staffDesignation !== "manager");
    setStateManager(values?.staffDesignation !== "supervisor");
    setValues({
      ...values,
      managerId:
        values?.staffDesignation === "manager" ? "" : values?.managerId || "",
      supervisorId:
        values?.staffDesignation === "supervisor"
          ? ""
          : values?.supervisorId || "",
    });
  }
};

const updateStaffWorkLoad = (values, setValues) => {
  const startDate = values?.staffWorkLoad?.startDate;
  if (startDate && startDate !== null) {
    const newEndDate = calculateNextYearEndDate(startDate);
    if (newEndDate && newEndDate !== null) {
      const result = calculateHolidayHours(startDate, newEndDate);
      setValues({
        ...values,
        staffWorkLoad: {
          ...values?.staffWorkLoad,
          endDate: newEndDate,
          holidayAlwnNoOfDys: result?.daysDifference,
          holidayAlwnNoOfHours: result?.hoursDifference,
          holidaysAvailed: 0,
          holidaysRemaining: result?.daysDifference,
          holidayEntitlement: {
            ...values.staffWorkLoad.holidayEntitlement,
            numberOfDays: result?.daysDifference,
            numberOfHours: result?.hoursDifference,
            numberOfWeeks: result?.weeksDifference,
          },
        },
      });
    }
  }
};

const staffValidationSchema = Yup.object({
  // profile_picture_url: Yup.string(),
  name: Yup.string().required("Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  // password: Yup.string()
  //   .required("Password is required")
  //   .matches(
  //     passwordRegex,
  //     "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character"
  //   ),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Contact is not valid")
    .required("Contact is required"),
  stafDob: Yup.string().required("Date of Birth is required"),
  // inductionDate: Yup.string().required("Start Date is required"),
  staffDesignation: Yup.string().required("Designation is required"),
  staffWorkLoad: Yup.object().shape({
    // isCurrentWrkLd: Yup.boolean(),
    startDate: Yup.string().required("Start Date is required"),
  }),
  contractedHours: Yup.object().shape({
    contractedHours: Yup.number().required("Contract Hours is required"),
    perHourRate: Yup.number().required("Per Hours Rate is required"),
    overNightSleepRate: Yup.number().required("Overnight Sleep Rate is required"),
    enhancedRate: Yup.number().required("Enhance Rate is required"),
    trainingRate: Yup.number().required("Training Rate is required"),
    overtimeRate: Yup.number().required("Overtime is required"),

  }),
  // supervision: Yup.object().shape({
  //   sprDueDate: Yup.date().required("Supervision Due Date is required"),
  //   templateTitleId: Yup.string().required("Supervision Template is required"),
  // }),
});

const updateStaffValidationSchema = Yup.object({
  // profile_picture_url: Yup.string(),
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: Yup.string().required("Contact is required"),
  stafDob: Yup.string().required("Date of Birth is required"),
  staffDesignation: Yup.string().required("Designation is required"),
  contractedHours: Yup.object().shape({
    contractedHours: Yup.number().required("Contract Hours is required"),

  }),
  // supervision: Yup.object().shape({
  //   sprDueDate: Yup.date().required("Supervision Due Date is required"),
  //   templateTitleId: Yup.string().required("Supervision Template is required"),
  // }),
});

const toggleFunction = () => {
  isToggled = !isToggled;
  return isToggled;
};

const handleSetTimeStates = async (
  currentState,
  setStartTimeState,
  startTimeState,
  setEndTimeState,
  endTimeState,
  setFieldValue,
  startTime,
  endTime
) => {
  currentState(true);
  setStartTimeState(startTimeState);
  setEndTimeState(endTimeState);
  setFieldValue("shiftStartTime", startTime);
  setFieldValue("shiftEndTime", endTime);
};

const handleSelectPreDefinedTime = (
  e,
  condition,
  currentState,
  shiftStartTimeState,
  shiftEndTimeState,
  setFieldValue,
  startTime,
  endTime,
  state1,
  state2,
  state3,
  state4
) => {
  state1(false);
  state2(false);
  state3(false);
  state4(false);
  switch (condition) {
    case 1:
      handleSetTimeStates(
        currentState,
        shiftStartTimeState,
        TIME_10_16?.startTime,
        shiftEndTimeState,
        TIME_10_16?.endTime,
        setFieldValue,
        startTime,
        endTime
      );
      break;
    case 2:
      handleSetTimeStates(
        currentState,
        shiftStartTimeState,
        TIME_08_14?.startTime,
        shiftEndTimeState,
        TIME_08_14?.endTime,
        setFieldValue,
        startTime,
        endTime
      );
      break;
    case 3:
      handleSetTimeStates(
        currentState,
        shiftStartTimeState,
        TIME_14_20?.startTime,
        shiftEndTimeState,
        TIME_14_20?.endTime,
        setFieldValue,
        startTime,
        endTime
      );
      break;
    case 4:
      handleSetTimeStates(
        currentState,
        shiftStartTimeState,
        TIME_08_20?.startTime,
        shiftEndTimeState,
        TIME_08_20?.endTime,
        setFieldValue,
        startTime,
        endTime
      );
      break;
    case 5:
      handleSetTimeStates(
        currentState,
        shiftStartTimeState,
        TIME_20_08?.startTime,
        shiftEndTimeState,
        TIME_20_08?.endTime,
        setFieldValue,
        startTime,
        endTime
      );
      break;
    default:
  }
};

const setShiftPreDefinedTimeState = (
  startTimeState,
  endTimeState,
  start,
  end,
  stateSetter
) => {
  if (
    startTimeState?.toString() === start &&
    endTimeState?.toString() === end
  ) {
    stateSetter(true);
  }
};

const numericToTime = (numericValue) => {
  const hours =
    typeof numericValue === "number" ? numericValue : parseFloat(numericValue);
  if (isNaN(hours)) {
    return "Invalid";
  }
  const totalMinutes = hours * 60;
  const hh = Math.floor(totalMinutes / 60)
    .toString()
    .padStart(2, "0");
  const mm = (totalMinutes % 60).toString().padStart(2, "0");
  return `${hh}:${mm}`;
};

const TIME_10_16 = {
  startTime: "10:00",
  endTime: "16:00",
  startTimeNum: 1719550800000,
  endTimeNum: 1722164400000,
  label: "10:00am to 4:00pm",
};
const TIME_08_14 = {
  startTime: "08:00",
  endTime: "14:00",
  startTimeNum: 1704942041315,
  endTimeNum: 1704963648390,
  label: "8:00am to 2:00pm",
};
const TIME_14_20 = {
  startTime: "14:00",
  endTime: "20:00",
  startTimeNum: 1704963632001,
  endTimeNum: 1704985237536,
  label: "2:00pm to 8:00pm",
};
const TIME_08_20 = {
  startTime: "08:00",
  endTime: "20:00",
  startTimeNum: 1704942016232,
  endTimeNum: 1704985223328,
  label: "8:00am to 8:00pm",
};
const TIME_20_08 = {
  startTime: "20:00",
  endTime: "08:00",
  startTimeNum: 1704985250816,
  endTimeNum: 1704942052656,
  label: "8:00pm to 8:00am",
};

const defaultHours = "0:00";

const convertShiftTime = (sTime, eTime) => {
  const startTime = moment(sTime).format("LT");
  const endTime = moment(eTime).format("LT");
  if (startTime === "10:00 AM" && endTime === "04:00 PM") {
    return "10am-4pm";
  }

  if (startTime === "08:00 AM" && endTime === "02:00 PM") {
    return "8am-2pm";
  }

  if (startTime === "02:00 PM" && endTime === "08:00 PM") {
    return "2pm-8pm";
  }

  if (startTime === "08:00 AM" && endTime === "08:00 PM") {
    return "8am-8pm";
  }

  if (startTime === "08:00 PM" && endTime === "08:00 AM") {
    return "8pm-8am";
  }
  return startTime + "-" + endTime;
};

function millisecondsTimeToString(milliseconds) {
  const date = new Date(milliseconds);
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  const timeString = `${hours}:${minutes}:${seconds}`;
  return timeString;
}

function formatLiveRosterDates(startDate, endDate) {
  const startDateOptions = { day: "numeric", month: "short" };
  const endDateOptions = { day: "numeric", month: "short", year: "numeric" };
  const startDateFormat = new Intl.DateTimeFormat(
    "en-US",
    startDateOptions
  ).format(startDate);
  const endDateFormat = new Intl.DateTimeFormat("en-US", endDateOptions).format(
    endDate
  );
  if (startDate && endDate) {
    return `${startDateFormat} to ${endDateFormat}`;
  } else {
    return null;
  }
}

const navigateWeekState = async (
  navigate,
  start,
  end,
  weekTab,
  tempData,
  roster
) => {
  const stateObj = {
    startDate: start,
    endDate: end,
    activeWeekTabNum: weekTab,
    templateDataState: tempData,
    selectedDateRoster: roster,
  };

  navigate("/live-roster", {
    state: stateObj,
  });
};

function isCurrentTimeAfterEndTime(startTime, endTime) {
  const currentTime = getCurrentTimeMilliseconds();
  return currentTime !== null && currentTime >= endTime;
}

// const getWeekNumber = (date) => {
//   const target = new Date(date.valueOf());
//   const dayNr = (date.getDay() + 6) % 7;
//   target.setDate(target.getDate() - dayNr + 3);
//   const firstThursday = target.valueOf();
//   target.setMonth(0, 1);
//   if (target.getDay() !== 4) {
//     target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
//   }
//   return 1 + Math.ceil((firstThursday - target) / 604800000);
// };

function getCurrentTimeMilliseconds() {
  const currentTimeMoment = moment();
  const midnightMoment = moment().startOf("day");
  const diff = currentTimeMoment.diff(midnightMoment);
  return diff >= 0 ? diff : null;
}

// const getFullDayName = (dayAbbreviation) => {
//   const daysMap = {
//     mon: "Monday",
//     tue: "Tuesday",
//     wed: "Wednesday",
//     thu: "Thursday",
//     fri: "Friday",
//     sat: "Saturday",
//     sun: "Sunday",
//   };
//   const filteredNames = dayAbbreviation
//     ?.replace(/\d+/g, "")
//     ?.toLowerCase()
//     ?.trim();
//   const formattedAbbreviation = filteredNames;
//   return daysMap[formattedAbbreviation] || "Invalid Day Abbreviation";
// };

const getFullDayName = (dayAbbreviation) => {
  const daysMap = {
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
    sun: "Sunday",
  };
  const filteredNames = dayAbbreviation
    ?.replace(/\d+/g, "")
    ?.toLowerCase()
    ?.trim();
  const formattedAbbreviation = filteredNames;
  return daysMap[formattedAbbreviation];
};

function navigateUrl(navigate, url, state) {
  navigate(url, state);
}

function calculateWeeksDates(start, end, templateWeekNum) {
  const primaryWeek1EndDate = moment(start).clone().endOf("isoWeek")?.valueOf();
  const primaryWeek2EndDate = moment(start)
    .add(2, "weeks")
    .subtract(1, "day")
    .endOf("day")
    .valueOf();
  const primaryWeek3EndDate = moment(start)
    .add(3, "weeks")
    .subtract(1, "day")
    .endOf("day")
    .valueOf();
  const rosterEndDate = moment(start)
    .add(templateWeekNum, "weeks")
    .subtract(1, "day")
    .endOf("day")
    .valueOf();
  if (templateWeekNum === 2) {
    return {
      primaryWeek1Num: primaryWeek1EndDate,
      primaryWeek2Num: primaryWeek2EndDate,
      rosterEndDateNum: rosterEndDate,
    };
  } else {
    return {
      primaryWeek1Num: primaryWeek1EndDate,
      primaryWeek2Num: primaryWeek2EndDate,
      primaryWeek3Num: primaryWeek3EndDate,
      rosterEndDateNum: rosterEndDate,
    };
  }
}

function getNextWeekDatesStartingFrom(weekNum) {
  const startDate = moment().clone().add(weekNum, "weeks").startOf("isoWeek");
  const endDate = startDate.clone().endOf("isoWeek");
  return {
    startDate: startDate.valueOf(),
    endDate: endDate.valueOf(),
  };
}

// function getNextWeekDatesStartingFrom(weekNum) {
//   const currentDate = moment();
//   if (weekNum > 1) {
//     const nextStartDate = currentDate
//       .clone()
//       .add(weekNum * 7, "days")
//       .startOf("week");
//     const nextEndDate = nextStartDate.clone().endOf("week");
//     return {
//       startDate: nextStartDate?.valueOf(),
//       endDate: nextEndDate?.valueOf(),
//     };
//   } else {
//     const startDateNum = getNextDayOfWeekStart(currentDate, 1);
//     const calculateEndDate = moment(startDateNum)
//       .add(1, "weeks")
//       .subtract(1, "day");
//     const endDateNum = calculateEndDate.endOf("day").valueOf();
//     return {
//       startDate: startDateNum,
//       endDate: endDateNum,
//     };
//   }
// }

const formatTimeRange = (startTimeNum, endTimeNum) => {
  const startTimeMilliseconds = startTimeNum;
  const endTimeMilliseconds = endTimeNum;
  const startTime = new Date(startTimeMilliseconds);
  const endTime = new Date(endTimeMilliseconds);

  const formatHour = (time) => {
    const hour = time.getHours();
    const ampm = hour >= 12 ? "pm" : "am";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}${ampm}`;
  };
  const formattedStartTime = formatHour(startTime);
  const formattedEndTime = formatHour(endTime);

  return `${formattedStartTime} - ${formattedEndTime}`;
};

const renderOverlayHours = (item, menuToggleVertical) => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip className="toolTipInfo dropDownRota">
          <p className="mb-2">
            <strong>Contracted hours: </strong>
            {item?.staffUserId?.contractedHours?.contractedHours
              ? item?.staffUserId?.contractedHours?.contractedHours + "hrs"
              : "0hrs"}
          </p>
          <p className="mb-2">
            <strong>Total hours: </strong>
            {item?.workHrs ? item?.workHrs + "hrs" : "0hrs"}
          </p>
          <p className="mb-2">
            <strong>Total shifts:</strong>
            {item?.noOfShifts ? item?.noOfShifts + "shifts" : "0shifts"}
          </p>
        </Tooltip>
      }
      className="tooltipOverlay"
    >
      <Button>
        <img src={menuToggleVertical} alt={menuToggleVertical} />
      </Button>
    </OverlayTrigger>
  );
};

export {
  CustomOption,
  handleReload,
  calculateTimeDifference,
  handleSetIdFormikFormData,
  handleTimeChangeToNumeric,
  useShiftEffect,
  daysOptions,
  weeksOptions,
  getFormDataProps,
  GenerateScheduleFormData,
  handleFormUpdate,
  handleCheckboxChange,
  useCheckedEffect,
  uploadFile,
  uploadGroupFile,
  staffDesignationOptions,
  staffRoleOptions,
  vehicleTypeOptions,
  userInputFields,
  workLoadFields,
  sprStatus,
  sprResult,
  handleSelectChange,
  updateDesignationFields,
  updateStaffWorkLoad,
  passwordRegex,
  staffValidationSchema,
  updateStaffValidationSchema,
  hldRequestTypeOptions,
  candidateStatusOptions,
  genderOptions,
  toggleFunction,
  twoWeeksOptions,
  fourWeeksOptions,
  handleSetTimeStates,
  handleSelectPreDefinedTime,
  setShiftPreDefinedTimeState,
  locationOptions,
  numericToTime,
  convertShiftTime,
  millisecondsTimeToString,
  TIME_10_16,
  TIME_08_14,
  TIME_14_20,
  TIME_08_20,
  TIME_20_08,
  defaultHours,
  getFullDayName,
  SIX_HOURS,
  TWELVE_HOURS,
  formatLiveRosterDates,
  GenerateRosterScheduleFormData,
  SUPER_ADMIN,
  statusOptions,
  rotaTempStatusOptions,
  rotaTempFutureStatusOptions,
  rotaTempWeeksOptions,
  navigateUrl,
  getNextWeekDatesStartingFrom,
  weeksLimitMsg,
  rosterTemplateErrorMessage,
  rosterTemplateNotFound,
  formatTimeRange,
  convertMillisecondsToTime,
  getCurrentTimeMilliseconds,
  isCurrentTimeAfterEndTime,
  rotaSchedulesStatusClassMap,
  rosterSchedulesStatusClassMap,
  calculateWeeksDates,
  getRosterWeekShiftEndDate,
  getRosterWeekShiftStartDate,
  navigateWeekState,
  trainingPrpsName,
  trainingPrpsDescription,
  additionalFieldsOptions,
  formFields,
  trainingType,
  trainingClass,
  prpsTrgStatusOptions,
  renderOverlayHours,
};
