import React, { useState, useEffect } from "react";
import avatarEdit from "../../assets/images/icons/avatarEdit.svg";
import avatarMessage from "../../assets/images/icons/avatarMessage.svg";
import { BASE_URL, GET_ALL_STAFF_MEMBERS_URL } from "../../env";
import { displayEnumProperty, formateDate } from "../../helper/helper";
import UserProfileUpdate from "../modals/UserProfileUpdate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../general/ProgressComponent";
import ProtectedComponent from "../../permissions/ProtectedComponent";
import { permissions } from "../../permissions/roles";
import {
  isSuccess,
  catchAsync,
  toastErrorMessages,
  filterRowsByProperty,
  getRecords,
} from "../../helper/helper";
import moment from "moment/moment";

function ProfileDetail({ getUserRecord }) {
  const userLoggedInDetails = JSON.parse(localStorage.getItem('userLoggedInDetails'));
  const loggedInUserId = userLoggedInDetails?._id || "";
  // console.log("🚀 ~ ProfileDetail ~ loggedInUserId:", userLoggedInDetails?.staffRoles[0])
  const [userData, setUserData] = useState();
  // console.log("🚀 ~ ProfileDetail ~ userDsdsdfsdfdsfata:", userData)
  const [editUserData, setEditUserData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isToastProfileDetails, setIsToastProfileDetails] = useState(false);
  const [isShowModel, setIsShowModel] = useState(false);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { _id: getUserRecord?._id },
    },
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastProfileDetails(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const staffMember = data?.data?.staffMembers[0];
      // console.log("🚀 ~ processFetchRecords ~ staffdddddddddddddddddddddMember:", staffMember)
      setUserData(staffMember);
      setIsLoading(false);
    }
  };
  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
    processFetchRecords("get", response);
  });

  const handleIsShowModel = () => {
    fetchAllRecords();
    setIsShowModel(true);
  };
  const handleCloseModal = () => {
    setIsShowModel(false);
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {
    if (loggedInUserId === userData?._id) {
      setEditUserData(true)
    } else {
      setEditUserData(false)
    }
  })

  const staffDesignation = displayEnumProperty("staffDesignation", userData?.staffDesignation);
  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <div className="profileDetailBlock">
          <div className="userAvatar">
            <img
              src={BASE_URL + userData?.user?.profile_picture_url}
              alt={"profilePic"}
            />
            <div className="avatarAction">
              {
                userLoggedInDetails?.staffRoles[0] === "superadmin" ? (
                  <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                    <span onClick={handleIsShowModel}>
                      <img src={avatarEdit} alt=" avatar edit" />
                    </span>
                  </ProtectedComponent>
                ) : (
                  editUserData && (
                    <span onClick={handleIsShowModel}>
                      <img src={avatarEdit} alt=" avatar edit" />
                    </span>
                  )
                )
              }
              {/* <span>
                <img src={avatarMessage} alt="avatar message" />
              </span> */}
            </div>
            {userData?.user?.name && (
              <h3>
                {userData?.user?.name}
                {userData?.lastName && ` ${userData?.lastName}`}
              </h3>
            )}
          </div>
          <div className="userDetail">
            <p>
              <small>Start Date</small>
              {moment(userData?.staffWorkLoads[0]?.startDate).format("DD/MM/YYYY")} </p>
            <p>
              <small>Role</small>
              {staffDesignation !== "" ? staffDesignation : userData?.staffDesignation}
            </p>
            <p>
              <small>Manager</small>
              {userData?.managerId?.name}
            </p>
            <p>
              <small>Supervisor</small>
              {userData?.supervisorId?.staffMemberName}
            </p>
            <p>
              <small>Email</small>
              <a href={`mailto:${userData?.user?.email}`} className="greyColor">
                <small>{userData?.user?.email}</small>
              </a>
            </p>
            <p>
              <small>Contact Number</small>
              {userData?.user?.phoneNumber}
            </p>
            <p>
              <small>Address</small> {userData?.houseFlatNumber}{" "}{userData?.addressLine1}{" "}{userData?.town}{" "}{userData?.city}
            </p>
            <p>
              <small>Post Code</small> {userData?.postcode}
            </p>
            <p>
              <small>Dob</small> {moment(userData?.stafDob).format("DD/MM/YYYY")}
            </p>
            <p>
              <small>NI Number</small>
              {userData?.niNumber}
            </p>
          </div>
        </div >
      )
      }
      {
        isShowModel && (
          <UserProfileUpdate
            showModal={isShowModel}
            closeModal={handleCloseModal}
            userData={userData}
            fetchAllRecords={fetchAllRecords}
          />
        )
      }
      {isToastProfileDetails && <ToastContainer />}
    </>
  );
}

export default ProfileDetail;
