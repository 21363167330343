import React from 'react'
import Layout from '../../components/layouts/Layout'
import { useLocation } from "react-router-dom";
import StaffRecordsIndividual from "./StaffRecordsIndividual";
function StaffMemberProfile() {
    const location = useLocation();

    const userData = location?.state && location?.state?.userId;
    console.log("🚀 ~ StaffMdfdfemberProfile ~ userData:", userData)
    return (
        <>
            <Layout pageTitleText="Staff Profile">
                <StaffRecordsIndividual getUserRecord={userData} />
            </Layout>
        </>

    )
}

export default StaffMemberProfile