import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import Layout from "../../components/layouts/Layout";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import useColumnsWithPermission from "../../permissions/useColumnsWithPermission";
import StatusUpdated from "../../components/general/StatusUpdated";
import {
  BASE_URL,
  GET_STAFFS_HOLIDAYS_DETAILS_URL,
  PUT_HOLIDAY_STAFF_MEMBER_URL,
  ADMIN_ID
} from "../../env";
import {
  updateRecords,
  getRecords,
  isSuccess,
  toastSuccessMessages,
  catchAsync,
} from "../../helper/helper";
import moment from "moment/moment";
// import EditHolidaysGroup from "./EditHolidaysGroup";
function HolidaysGroup() {
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      staffRequesterFields: "_id email name profile_picture_url",
      staffMemberFields: "staffMemberName",
      usersFields: "profile_picture_url",
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };
  const [filterSearch, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccessReturn, setIsSuccessReturn] = useState(false);
  const [isHolidaysGroup, setIsHolidaysGroup] = useState(false);

  // Modal Popup
  const [showAddMedication, setShowAddMedication] = useState(false);
  const subModalAddMedicine = () => {
    setShowAddMedication(!showAddMedication);
    fetchAllRecords();
  };



  const [showEditMedication, setShowEditMedication] = useState(false);
  const subModalEditMedication = (emarItem) => {
    // setMedicationStockDetail(emarItem);
    setShowEditMedication(!showEditMedication);
    fetchAllRecords();
  };

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      // const holidayStock = data?.data?.staffHolidayRequests?.map((item) => item);
      const holidayStock = data?.data?.staffHolidayRequests?.filter((item) => item.hldRequestType === "holiday");
      // console.log("🚀 ~ processFetchRecords ~ holidayStock:", holidayStock)
      setFilterSearch(holidayStock);
      setIsLoading(false);
      // toastSuccessMessages(toast.success, data?.message, "", 500, 500);
    } else if (method === "update" && data?.status === isSuccess) {
      console.log("87987", data?.status)
      setIsHolidaysGroup(true)
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsHolidaysGroup(false);
      }, 2000);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsHolidaysGroup(false);
      }, 2000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_STAFFS_HOLIDAYS_DETAILS_URL, apiQuery);
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);


  const updateStaffHoliday = catchAsync(async (req, res) => {
    try {
      // console.log("🚀 ~ updateStaffHoliday ~ req:", req)
      const response = await updateRecords(PUT_HOLIDAY_STAFF_MEMBER_URL, req);
      processFetchRecords("update", response);
    } catch (error) {
      console.error("Error updating staff holidays:", error);
    }
  });
  const [holidayRequestStatus, setHolidayRequestStatus] = useState(null);
  useEffect(() => {
    const updateHoliday = async () => {
      if (holidayRequestStatus !== null) {  // Only run if there's a valid status
        console.log("🚀 ~ HolidaysGroup ~ holidayRequestStatus:", holidayRequestStatus);
        await updateStaffHoliday(holidayRequestStatus);
        fetchAllRecords();
      }
    };
    updateHoliday();
    // updateStaffHoliday(holidayRequestStatus);
  }, [holidayRequestStatus]);
  // Update Status of the Shifts
  const subModalUpdateRosterUserShift = (onChanageReject, shiftData) => {
    setHolidayRequestStatus({
      staffHolidayRequestid: shiftData?._id,
      hldRqStartDate: shiftData?.hldRqStartDate,
      hldRqEndDate: shiftData?.hldRqEndDate,
      hldRqTotalDays: shiftData?.hldRqTotalDays,
      hldRqTotalHours: shiftData?.hldRqTotalHours,
      hldRqStatus: onChanageReject === "rejected" ? "rejected" : onChanageReject === "approved" ? "approved" : "pending",
      approvedBy: shiftData?.approvedBy,
      staffRequester: shiftData?.staffRequester,
      active: shiftData?.active,
    })
  };


  // Table Columns

  const baseColumns = [
    {
      name: "#",
      selector: (row, index) => <span>{index + 1}</span>,
      width: "5%",
      center: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <span className="usersNameInfo">
          <span className="userImage">
            <img
              src={`${BASE_URL}${row?.staffRequester?.profile_picture_url}`}
              alt={row?.staffRequester?.name}
            />
          </span>
          <span className="userInfo">
            <span className="nameBlock">{row?.staffRequester?.name}</span>
          </span>
        </span>
      ),
      width: "200px",
    },
    {
      name: "Dates",
      selector: (row) => {
        <span className={`statusButton ${row?.hldRqStatus === "rejected" ? "background-danger" : row?.hldRqStatus === "approved" ? "background-success" : "background-warning"}`}>
          {row?.hldRqStatus}</span>
        return (
          <span className={`tableText ${row?.hldRqStatus === "rejected" ? "danger" : row?.hldRqStatus === "approved" ? "success" : "warning"}`}>
            From: {moment(row?.hldRqStartDate).format("DD/MM/YYYY")}<br />
            To: {moment(row?.hldRqEndDate).format("DD/MM/YYYY")}

          </span>
        );
      },
      width: "200px",
    },
    {
      name: "Request Days",
      selector: (row) => <span>{row?.hldRqTotalDays}</span>,
      center: true,
    },
    {
      name: "Request Hours",
      selector: (row) => <span>{`${row?.hldRqTotalHours} hours`}</span>,
      center: true,
    },
    {
      name: "Hours Remaining",
      selector: (row) => {
        const remainingHours = row?.staffRequester?.modelId?.staffWorkLoads?.[0]?.holidayHoursRemaining || 0;
        return <span>{`${remainingHours} hours`}</span>;
      },
      center: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
          {row?.hldRqStatus !== "pending" ? (
            <span className={`statusButton ${row?.hldRqStatus === "rejected" ? "background-danger" : row?.hldRqStatus === "approved" ? "background-success" : "background-warning"}`}>
              {row?.hldRqStatus}</span>
          ) : (
            <StatusUpdated
              initialStatus={row?.hldRqStatus}
              isSuccessReturn={isSuccessReturn}
              onChange={(e) => subModalUpdateRosterUserShift(e.target.value, row)}
            />
          )
          }
        </ProtectedComponent>
      ),
      width: "12%",
      center: true,
    },

  ];

  const columns = useColumnsWithPermission(baseColumns, [permissions.EDIT, permissions.DELETE]);


  useEffect(() => {
    setTimeout(() => {
      setIsHolidaysGroup(false);
    }, 2000);
  }, [isHolidaysGroup])

  return (
    <>
      <div className="mainContent rowClickUnset absenceLeave">
        <div className="dataTableWrapper">
          <DataTable
            className="maxWidthTable"
            columns={columns}
            data={filterSearch}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationPerPage={10}
            fixedHeader
            fixedHeaderScrollHeight="520px"
            highlightOnHover
            responsive={true}
            progressPending={isLoading}
            progressComponent={<ProgressComponent />}
          />
        </div>
      </div>

    
      {isHolidaysGroup && <ToastContainer />}
    </>
  );
}

export default HolidaysGroup;
