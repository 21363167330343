import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import Layout from "../../components/layouts/Layout";
import editBlock from "../../assets/images/icons/editBlock.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import useColumnsWithPermission from "../../permissions/useColumnsWithPermission";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_RISK_ASSESSMENTS_URL,
  DELETE_RISK_ASSESSMENTS_URL,
} from "../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
} from "../../helper/helper";
import { Link } from "react-router-dom";
function RiskAssessments() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [userData, setUserData] = useState([]);
  const apiQuery = {
    userId: userDetails?._id,
  };
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [assesmentIdData, setAssesmentIdData] = useState({
    assesmentId: null,
  });
  const [riskAssessmentsDetail, setRiskAssessmentsDetail] = useState();
  const [isRiskAssessment, setIsRiskAssessment] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const navigate = useNavigate();

  // Modal Popup
  const [showAddRiskAssessment, setShowAddRiskAssessment] = useState(false);
  const subModalAddAssessment = () => {
    setShowAddRiskAssessment(!showAddRiskAssessment);
    fetchAllRecords();
  };

  const [showDeleteRiskAssessment, setShowDeleteRiskAssessment] = useState(false);
  const subModalDeleteAssessment = (riskAssessmentDelId) => {
    setAssesmentIdData({ assesmentId: riskAssessmentDelId });
    setShowDeleteRiskAssessment(!showDeleteRiskAssessment);
    fetchAllRecords();
  };

  const [showEditRiskAssessment, setShowEditRiskAssessment] = useState(false);
  const subModalEditAssessment = (emarItem) => {
    setRiskAssessmentsDetail(emarItem);
    setShowEditRiskAssessment(!showEditRiskAssessment);
    fetchAllRecords();
  };

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const riskAssessmentData = data?.data?.map((item) => item);
      setUserData(riskAssessmentData);
      setFilterSearch(riskAssessmentData);
      setIsLoading(false);
      // toastSuccessMessages(toast.success, data?.message, "", 500, 500);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsRiskAssessment(false);
      }, 2000);
    }
    // else {
    //   console.log({ data });
    //   toastErrorMessages(toast.error, data?.message, 1000);
    // }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_RISK_ASSESSMENTS_URL, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // Table Columns

  const baseColumns = [
    {
      name: "Hazard",
      selector: (row) => {
        return row?.hazard;
      },
      width: "200px",
      wrap: true
    },
    {
      name: "Person (s) exposed to Hazard",
      selector: (row) => {
        return row?.personsExposedToHazard;
      },
      width: "300px",
      wrap: true
    },
    {
      name: "Risk Identified",
      selector: (row) => {
        return row?.riskIdentified;
      },
      width: "300px",
      wrap: true
    },
    {
      name: (<>
        <table className="multipleColumnRowsTable">
          <tbody>
            <tr>
              <td colSpan={3}>Pure Risk Rating</td>
            </tr>
            <tr>
              <td>C</td>
              <td>L</td>
              <td>R</td>
            </tr>
          </tbody>
        </table></>),
      selector: (row) => {
        return row?.pureRiskRating;
      },
      cell: (row) => (
        <>
          <>
            <table className="cellColumnRowsTable">
              <tbody>
                <tr>
                  <td>{row?.pureRiskRating?.c}</td>
                  <td>{row?.pureRiskRating?.l}</td>
                  <td
                    className={
                      row?.pureRiskRating?.r <= 3 ? "low" :
                        row?.pureRiskRating?.r > 3 && row?.pureRiskRating?.r <= 6 ? "medium" :
                          row?.pureRiskRating?.r > 6 && row?.pureRiskRating?.r <= 9 ? "high" : ''
                    }
                  >{row?.pureRiskRating?.r}</td>
                </tr>
              </tbody>
            </table></>
        </>
      ),

      width: "250px",
      center: true,
    },
    {
      name: "Control Measures Required",
      selector: (row) => {
        return row?.coldMeasureRequired;
      },
      width: "400px",
      wrap: true
    },
    {
      name: (<>
        <table className="multipleColumnRowsTable">
          <tbody>
            <tr>
              <td colSpan={3}>In Place</td>
            </tr>
            <tr>
              <td>Y</td>
              <td>N</td>
            </tr>
          </tbody>
        </table></>),
      selector: (row) => {
        return row?.inPlace;
      },
      cell: (row) => (
        <>
          <>
            <table className="cellColumnRowsTable">
              <tbody>
                <tr>
                  <td>{row?.inPlace?.y}</td>
                  <td>{row?.inPlace?.n}</td>
                </tr>
              </tbody>
            </table></>
        </>
      ),

      width: "250px",
      center: true,
    },
    {
      name: (<>
        <table className="multipleColumnRowsTable">
          <tbody>
            <tr>
              <td colSpan={3}>Residual Risk Rating</td>
            </tr>
            <tr>
              <td>C</td>
              <td>L</td>
              <td>R</td>
            </tr>
          </tbody>
        </table></>),
      selector: (row) => {
        return row?.residualRiskRating;
      },
      cell: (row) => (
        <>
          <>
            <table className="cellColumnRowsTable">
              <tbody>
                <tr>
                  <td>{row?.residualRiskRating?.c}</td>
                  <td>{row?.residualRiskRating?.l}</td>
                  <td
                    className={
                      row?.residualRiskRating?.r <= 3 ? "low" :
                        row?.residualRiskRating?.r > 3 && row?.residualRiskRating?.r <= 6 ? "medium" :
                          row?.residualRiskRating?.r > 6 && row?.residualRiskRating?.r <= 9 ? "high" : ''
                    }
                  >{row?.residualRiskRating?.r}</td>

                  {/* <td className="danger">

                    {row?.residualRiskRating?.r}

                  </td> */}
                </tr>
              </tbody>
            </table></>
        </>
      ),

      width: "250px",
      center: true,
    },
    {
      name: "Last Modified Date",
      selector: (row) => {
        return moment(row?.updatedAt).format("DD/MM/YYYY");
      },
      center: true
    },

    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="actionColumns">
            <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
              <span
                className="edit"
                onClick={() => {
                  editSuEnquiry(row);
                }}
              >
                <img src={editBlock} alt="Edit" title="Edit" />
              </span>
            </ProtectedComponent>
            <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
              <span
                className="delete"
                onClick={() => {
                  subModalDeleteAssessment(row?._id);
                }}
              >
                <img src={eventDel} alt="Delete" title="Delete" />
              </span>
            </ProtectedComponent>
          </div>
        );
      },
      width: "150px",
      center: true,
    },
  ];

  const columns = useColumnsWithPermission(baseColumns, [permissions.EDIT, permissions.DELETE]);
  // Delete Risk Assessments
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (assesmentIdData !== "") {
      const response = await deleteRecords(
        DELETE_RISK_ASSESSMENTS_URL,
        assesmentIdData
      );

      setIsRiskAssessment(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteRiskAssessment(!showDeleteRiskAssessment);
    } else {
      setShowDeleteRiskAssessment(!showDeleteRiskAssessment);
      setIsSubmitButtonClicked(false);
      setIsRiskAssessment(true);
      toastErrorMessages(toast.error, "Unable to remove", "", 1000);
    }
  });
  useEffect(() => {
    setTimeout(() => {
      setIsRiskAssessment(false);
    }, 2000);
  }, [isRiskAssessment])


  const editSuEnquiry = (item) => {
    navigate("/edit-risk-assessments", { state: { riskAssessmentsDetail: [item] } });
  };
  return (
    <>
      <Layout pageTitleText="Risk Assessment">
        <div className="rowClickUnset">
          <div className="mainContent riskAssessments">
            <div className="dataTableWrapper">
              <DataTable
                className="maxWidthTable"
                columns={columns}
                data={userData}
                pagination
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationPerPage={10}
                fixedHeader
                fixedHeaderScrollHeight="520px"
                highlightOnHover
                actions={
                  <>
                    <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                      <Link
                        className="btn btn-sm btn-primary"
                        to="/add-risk-assessments"
                      >
                        Add Risk Assessment
                      </Link>
                    </ProtectedComponent>
                  </>
                }
                responsive={true}
                subHeader={true}
                subHeaderComponent={
                  <div className="searchContainer">&nbsp;</div>
                }
                subHeaderAlign="left"
                subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
                progressPending={isLoading}
                progressComponent={<ProgressComponent />}
              />
            </div>
          </div>
        </div>
      </Layout>



      {/* Delete Risk Assessment */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeleteRiskAssessment}
          onHide={subModalDeleteAssessment}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this record?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteAssessment}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {isRiskAssessment && <ToastContainer />}
    </>
  );
}

export default RiskAssessments;
