import React, { useState,useEffect } from "react";

import Layout from "../../components/layouts/Layout";
import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Input from "../../components/forms-fields/Input";
import SelectBox from "../../components/forms-fields/SelectBox";
import {
  getRecords,
  isSuccess,
  catchAsync,
  isFail,
  formateDate,
  updateRecords,
  deleteRecords,
  toastSuccessMessages,
  toastErrorMessages,
  apiQuery,
  postRecords,
  handleRedirectIfProfileNotPresent
} from "../../helper/helper";
import {
  POST_CONSENT_TEMPLATE_URL,
  POST_SERVICE_USER_ENQUIRY_URL,
} from "../../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { addConsentTemplate } from "../../schemas";
import TextArea from "../../components/forms-fields/TextArea";
import moment from "moment";

function CCAddNewForm() {
  // Getting user detail data
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);

  const navigate = useNavigate();

  useEffect(() => {
    handleRedirectIfProfileNotPresent('profileUserDetails', '/service-users', navigate)
  }, [navigate]);

  // console.log("🚀 ~ file: CCAddNewForm.jsx:10 ~ CCAddNewForm ~ userDetails:", userDetails);
  const [disabledStatus, setDisableStatus] = useState(true);
  const [isToast, setIsToast] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

  const suSexOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const [formData, setFormData] = useState({
    staffId: userDetails?.modelId?._id,
    description: "",
  });

  const handleFormSubmit = async (values) => {
    setIsSubmitButtonClicked(true);
    try {
      const result = await postRecords(POST_CONSENT_TEMPLATE_URL, values);

      if (result?.status === isSuccess) {
        setIsSubmitButtonClicked(false);
        toastSuccessMessages(
          toast?.success,
          result?.message,
          "/consent-capacity",
          1000,
          1000
        );
      } else if (result?.status === isFail) {
        setIsSubmitButtonClicked(false);
        toastErrorMessages(toast.error, result?.message, 1000);
      }
    } catch (error) {
      // Handle error
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: formData,
      validationSchema: addConsentTemplate,
      onSubmit: handleFormSubmit, // Use the custom form submission handler
    });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedData = { ...formData };
    let trimmedValue = value.trim();

    updatedData = {
      ...updatedData,
      [name]: trimmedValue,
    };

    setFormData(updatedData);

    handleChange(e);
    handleBlur(e);
    console.log(updatedData);
  };

  return (
    <>
      <Layout
        pageTitleText={
          <>
            Consent & Capacity <small>Add New Form</small>
          </>
        }
      >
        <div className="incidents consentCapacityAddNew">
          <form onSubmit={handleSubmit}>
            <div className="mainContent ">
              <div className="mainContentText">
                <h3>Young Information</h3>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id="suFirstMiddleName"
                        name="suFirstMiddleName"
                        value={userDetails?.modelId?.suFirstMiddleName}
                        labelText="First Name"
                        disabledStatus={disabledStatus}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id="suLastName"
                        name="suLastName"
                        value={userDetails?.modelId?.suLastName}
                        labelText="Last Name"
                        disabledStatus={disabledStatus}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id="suMobileHomeNo"
                        name="suMobileHomeNo"
                        value={userDetails?.modelId?.suMobileHomeNo}
                        labelText="Mobile Home No."
                        disabledStatus={disabledStatus}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id="suEmailHome"
                        name="suEmailHome"
                        value={userDetails?.modelId?.suEmailHome}
                        labelText="Email"
                        disabledStatus={disabledStatus}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <SelectBox
                        name="suSex"
                        id="suSex"
                        options={suSexOptions}
                        defaultValue={userDetails?.modelId?.suSex}
                        labelText={userDetails?.modelId?.suSex}
                        disabled={disabledStatus}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="multipleFieldsBox">
                        <div className="div70">
                          <Input
                            type="text"
                            id="suDOB"
                            name="suDOB"
                            value={moment(userDetails?.modelId?.suDOB).format("DD/MM/YYYY")}
                            labelText="DOB"
                            disabledStatus={disabledStatus}
                          />
                        </div>
                        <div className="div30">
                          <Input
                            type="text"
                            id="suAge"
                            name="suAge"
                            value={userDetails?.modelId?.suAge}
                            // onChange={handleInputChange}
                            // onBlur={handleBlur}
                            labelText="Age"
                            disabledStatus={disabledStatus}
                          // customError={
                          //   errors.suFirstMiddleName && touched.suFirstMiddleName
                          // }
                          // errorMessage={errors.suFirstMiddleName}
                          // erroClass={
                          //   errors.suFirstMiddleName && touched.suFirstMiddleName
                          // }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <TextArea
                        id="description"
                        name="description"
                        value={values?.description}
                        rows="3"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        labelText="Description"
                        customError={errors.description && touched.description}
                        errorMessage={errors.description}
                        erroClass={errors.description && touched.description}
                        requiredStar={true}
                      />

                      {/* <div className="form-group">
                        <extarea
                          onChange={handleInputChangePrevFields}
                          onBlur={handleBlur}
                          customError={values.description && values.description}
                          errorMessage={values.description}
                          erroClass={values.description ? "validationError" : ""}
                          requiredStar={true}
                        >
                        
                        
                      </div> */}
                    </div>
                    <div className="col-md-12">
                      <div className="formActionButtons">
                        <Link
                          to="/consent-capacity"
                          className="btn btn-sm btn-outline-primary"
                        >
                          Back
                        </Link>
                        {isSubmitButtonClicked ? (
                          <Button className="btn-sm" variant="primary" disabled>
                            Loading...
                          </Button>
                        ) : (
                          <Button type="submit" className="btn-sm" variant="primary">
                            Submit
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
      </Layout>
      <ToastContainer />
    </>
  );
}

export default CCAddNewForm;
