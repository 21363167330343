import React, { useState, useEffect } from "react";
import Layout from "../../components/layouts/Layout";
import UserDetailHeader from "../../components/user-detail/UserDetailHeader";
import { FileUploader } from "react-drag-drop-files";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelectBox from "../../components/forms-fields/SelectBox";
import Input from "../../components/forms-fields/Input";
import eyeView from "../../assets/images/icons/eyeview.svg";
import Button from "react-bootstrap/Button";
import TextArea from "../../components/forms-fields/TextArea";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  POST_UPDATE_TRAINING_TEMPLATE_URL,
  POST_UPLOAD_TRAINING_FILE_URL,
  ADMIN_AUTH_TOKEN,
  ADMIN_ID,
  BASE_URL,
} from "../../env";
import {
  toastErrorMessages,
  isSuccess,
  catchAsync,
  postRecords,
  handleDateChangeNumeric,
  formateDate,
  toastSuccessMessages,
} from "../../helper/helper";

const additionalFieldsOptions = [
  { value: "text", label: "Text" },
  { value: "image", label: "Image" },
  { value: "document", label: "Document" },
  { value: "video", label: "Video" },
];

const formFields = [
  { name: "prpsName", label: "Training Title" },
  { name: "prpsDescription", label: "Training Description" },
  { name: "prpsTrgStartDate", label: "Start Date" },
  { name: "prpsTrgEndDate", label: "End Date" },
  { name: "prpsTrgRegisterationDate", label: "Registration Date" },
  { name: "contentType", label: "Additional Content" },
  { name: "content", label: "Content" },
];

const trainingType = [
  { value: "onetime", label: "One time" },
  { value: "monthly", label: "Monthly" },
  { value: "annual", label: "Annual" },
];
const trainingClass = [
  { value: "onsite", label: "On Site" },
  { value: "remote", label: "Remote" },
];

const prpsTrgStatusOptions = [
  { value: "pending", label: "Pending" },
  { value: "started", label: "Started" },
  { value: "completed", label: "Completed" },
  { value: "cancelled", label: "Cancelled" },
  { value: "postponed", label: "Postponed" },
];

const UpdateTrainingModel = ({ handleShowModel, onClose, item }) => {
  console.log("🚀 ~ UpdateTrainingModel ~ item:", item)
  const navigate = useNavigate();
  const [isUpdateTrainingTempToast, setIsUpdateTrainingTempToast] =
    useState(false);
  const [isSubmitClicked, setIsSubmittedClicked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([
    item?.contentType === additionalFieldsOptions[0]?.value
      ? additionalFieldsOptions[0]
      : item?.contentType === additionalFieldsOptions[1]?.value
        ? additionalFieldsOptions[1]
        : item?.contentType === additionalFieldsOptions[2]?.value
          ? additionalFieldsOptions[2]
          : item?.contentType === additionalFieldsOptions[3]?.value
            ? additionalFieldsOptions[3]
            : null,
  ]);
  const [startDate, setStartDate] = useState(
    handleDateChangeNumeric(item?.prpsTrgStartDate, "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    handleDateChangeNumeric(item?.prpsTrgEndDate, "yyyy-MM-dd")
  );
  const [registrationDate, setRegistrationDate] = useState(
    handleDateChangeNumeric(item?.prpsTrgRegisterationDate, "yyyy-MM-dd")
  );

  const generateValidationSchema = (fields) => {
    const validationObject = {};

    fields?.forEach((field) => {
      if (field?.name === "prpsTrgStartDate") {
        validationObject[field?.name] = Yup.number().required(
          `${field?.label} is required`
        );
      } else if (field?.name === "prpsTrgEndDate") {
        validationObject[field?.name] = Yup.number()
          .required("End Date is required")
          .min(
            Yup.ref("prpsTrgStartDate"),
            "End Date must be greater than Start Date"
          );
      } else {
        validationObject[field?.name] = Yup.string().required(
          `${field?.label} is required`
        );
      }
    });

    return Yup.object().shape(validationObject);
  };
  const validationSchema = generateValidationSchema(formFields);

  const updateTraFormData = useFormik({
    initialValues: {
      proposedTrainingid: item?._id,
      prpsName: item?.prpsName,
      prpsTrgType: item?.prpsTrgType,
      prpsTrgClass: item?.prpsTrgClass,
      prpsTrgStatus: item?.prpsTrgStatus,
      prpsTrgStartDate: item?.prpsTrgStartDate,
      prpsTrgEndDate: item?.prpsTrgEndDate,
      prpsTrgRegisterationDate: item?.prpsTrgRegisterationDate,
      trainingHours: item?.trainingHours,
      prpsDescription: item?.prpsDescription,
      // contentType: item?.contentType,
      content: [
        {
          filePath: item?.content[0]?.filePath,
          contentType: item?.content[0]?.contentType,
          docName: item?.content[0]?.docName
        }
      ]
      // content: item?.content,
    },
    // validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      setIsSubmittedClicked(true);
      try {
        await updateTrainingTempFunc(values);
        console.log("Form submitted successfully!");
        resetForm();
      } catch (error) {
        setIsSubmittedClicked(false);
        console.error("Error submitting form:", error);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = updateTraFormData;

  // console.log(updateTraFormData);
  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsUpdateTrainingTempToast(true);
      setIsSubmittedClicked(false);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "updateTrainingTemp" && data?.status === isSuccess) {
      setIsSubmittedClicked(false);
      onClose(data?.message);
    }
  };

  const updateTrainingTempFunc = catchAsync(async (req, res) => {
    const response = await postRecords(POST_UPDATE_TRAINING_TEMPLATE_URL, req);
    console.log("🚀 ~ updateTrainingTempFunc ~ response:", response)
    processFetchRecords("updateTrainingTemp", response);
  });

  const handleFileChangeUpload = catchAsync(async (file) => {
    const selectedUploadDocFile = file;
    if (!selectedUploadDocFile) {
      setIsUpdateTrainingTempToast(true);
      toastErrorMessages(toast?.error, "No file selected.", "", 1000, 1000);
      return;
    }
    const updateTraFormDataToSend = new FormData();
    updateTraFormDataToSend?.append("doc", selectedUploadDocFile);
    const response = await fetch(POST_UPLOAD_TRAINING_FILE_URL, {
      method: "POST",
      body: updateTraFormDataToSend,
      headers: {
        Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
      },
    });
    const result = await response.json();
    if (result?.status === isSuccess) {
      setFieldValue("content", {
        filePath: result?.data,
        contentType: values?.content[0]?.contentType,
        docName: file?.name
      });
    }
  });

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    const value = e.target?.value;
    setFieldValue(name, value);
  };

  const selectBoxValueChange = (selected) => {
    const contentTypeValue = selected?.value;
    setFieldValue("content[0].contentType", contentTypeValue);  // Corrected this line
    setSelectedOptions([selected]);
  };
  const setTrainingDate = (name, value) => {
    setFieldValue(name, handleDateChangeNumeric(value, "numeric"));
    switch (name) {
      case "prpsTrgStartDate":
        setStartDate(value);
        break;
      case "prpsTrgEndDate":
        setEndDate(value);
        break;
      case "prpsTrgRegisterationDate":
        setRegistrationDate(value);
        break;
      default:
        break;
    }
  };

  const handleDateChangeToNumeric = (e) => {
    const name = e.target?.name;
    const dateValue = e.target?.value;
    setTrainingDate(name, dateValue);
  };

  return (
    <>
      <Modal
        show={handleShowModel}
        onHide={onClose}
        className="ftcModalPopup"
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update {item?.prpsName}</Modal.Title>
        </Modal.Header>
        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit}
        >
          <Modal.Body>
            <div className="incidents training" key={item?._id}>
              <div className="mainContent">
                {/* <h4 className="trainingTitleHr">
                  {values?.prpsTrgType === trainingType[0]?.value
                    ? trainingType[0]?.label
                    : values?.prpsTrgType === trainingType[1]?.value
                      ? trainingType[1]?.label
                      : values?.prpsTrgType === trainingType[2]?.value
                        ? trainingType[2]?.label
                        : ""}{" "}
                  Training
                </h4> */}
                {/* <p>{values?.prpsName}</p>
                <p className="mb-1">
                  <strong>Introduction</strong>
                </p>
                <p>{values?.prpsDescription}</p> */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <Input
                        type="text"
                        name="prpsName"
                        value={values?.prpsName}
                        onChange={handleChange}
                        omBlurFunc={handleBlur}
                        labelText="Training Title"
                        customError={touched?.prpsName && errors?.prpsName}
                        errorMessage={errors?.prpsName}
                        errorClass={
                          touched?.prpsName && errors?.prpsName
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <Input
                        type="date"
                        name="prpsTrgStartDate"
                        value={startDate}
                        onChange={handleDateChangeToNumeric}
                        omBlurFunc={handleBlur}
                        labelText="Training Start Date"
                        customError={
                          touched?.prpsTrgStartDate && errors?.prpsTrgStartDate
                        }
                        errorMessage={errors?.prpsTrgStartDate}
                        errorClass={
                          touched?.prpsTrgStartDate && errors?.prpsTrgStartDate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      // disabledStatus={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <Input
                        type="date"
                        name="prpsTrgEndDate"
                        value={endDate}
                        onChange={handleDateChangeToNumeric}
                        omBlurFunc={handleBlur}
                        labelText="Training End Date"
                        customError={
                          touched?.prpsTrgEndDate && errors?.prpsTrgEndDate
                        }
                        errorMessage={errors?.prpsTrgEndDate}
                        errorClass={
                          touched?.prpsTrgEndDate && errors?.prpsTrgEndDate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      // disabledStatus={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <Input
                        type="date"
                        name="prpsTrgRegisterationDate"
                        value={registrationDate}
                        onChange={handleDateChangeToNumeric}
                        omBlurFunc={handleBlur}
                        labelText="Training Registration Date"
                        customError={
                          touched?.prpsTrgRegisterationDate &&
                          errors?.prpsTrgRegisterationDate
                        }
                        errorMessage={errors?.prpsTrgRegisterationDate}
                        errorClass={
                          touched?.prpsTrgRegisterationDate &&
                            errors?.prpsTrgRegisterationDate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      // disabledStatus={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <Input
                        type="number"
                        name="trainingHours"
                        value={values?.trainingHours}
                        onChange={handleChange}
                        omBlurFunc={handleBlur}
                        labelText="Training Hours"
                        // customError={
                        //   touched?.trainingHours && errors?.trainingHours
                        // }
                        // errorMessage={errors?.trainingHours}
                        // errorClass={
                        //   touched?.trainingHours && errors?.trainingHours
                        //     ? "validationError"
                        //     : ""
                        // }
                        // requiredStar={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <SelectBox
                        name="prpsTrgStatus"
                        value={values?.prpsTrgStatus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={prpsTrgStatusOptions}
                        labelText="Training Status"
                        customError={
                          touched?.prpsTrgStatus && errors?.prpsTrgStatus
                        }
                        errorMessage={errors?.prpsTrgStatus}
                        errorClass={
                          touched?.prpsTrgStatus && errors?.prpsTrgStatus
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <SelectBox
                        name="prpsTrgType"
                        value={values?.prpsTrgType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={trainingType}
                        labelText="Training Type"
                        customError={
                          errors?.prpsTrgType && touched?.prpsTrgType
                        }
                        errorMessage={errors?.prpsTrgType}
                        errorClass={
                          errors?.prpsTrgType && touched?.prpsTrgType
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <SelectBox
                        name="prpsTrgClass"
                        value={values?.prpsTrgClass}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={trainingClass}
                        labelText="Training Class"
                        customError={
                          touched?.prpsTrgClass && errors?.prpsTrgClass
                        }
                        errorMessage={errors?.prpsTrgClass}
                        errorClass={
                          touched?.prpsTrgClass && errors?.prpsTrgClass
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <Link target="_blank" to={BASE_URL + values?.content[0]?.filePath} className="btn btn-sm btn-primary">View Additional Content</Link>
                  </div>
                  <div
                    className={`col-sm-12 col-md-12 customSelectBox ${touched?.contentType && errors?.contentType
                      ? "validationError"
                      : ""
                      }`}
                  >
                    <>
                      <Select
                        className="basic-single cursor-pointer"
                        classNamePrefix="select"
                        options={additionalFieldsOptions}
                        value={additionalFieldsOptions.find(option => option.value === values?.content[0]?.contentType)}
                        onChange={selectBoxValueChange}
                        onBlur={handleBlur}
                        isClearable
                        isSearchable={false}
                        menuPlacement="auto"
                        placeholder="Select an option..."
                      />
                      <label
                        className="floatingLabel customValidationLabel"
                        style={{ color: "#777d80" }}
                      >
                        Additional Content
                      </label>
                      {touched?.contentType && errors?.contentType ? (
                        <div className="text-danger customValidationMsg">
                          {errors?.contentType}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                  <div className="clearfix"></div>
                  {selectedOptions?.map((option) => (
                    <div className="col-sm-12 col-md-12" key={+1}>
                      {option?.value === "text" && (
                        <div className="form-group">
                          <TextArea
                            name="content"
                            className="editableTextArea"
                            value={values?.content}
                            onChange={(e) => handleInputChange(e)}
                            labelText="Text"
                            placeholder={"Type here"}
                            rows={2}
                            customError={touched?.content && errors?.content}
                            errorMessage={errors?.content}
                            errorClass={
                              touched?.content && errors?.content
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                        </div>
                      )}
                      {option?.value === "image" && (
                        <div
                          className={`customFileUpload form-group customSelectBox ${touched?.content && errors?.content
                            ? "validationError"
                            : ""
                            }`}
                        >
                          <>
                            <span
                              className={
                                values?.content !== "" &&
                                  values?.content !== null
                                  ? "d-grid-2-fr"
                                  : null
                              }
                            >
                              <FileUploader
                                handleChange={(files) =>
                                  handleFileChangeUpload(files)
                                }
                                name="content"
                                types={getAllowedFileTypes("image")}
                                classes={`drop_area drop_zone ${touched?.content && errors?.content
                                  ? "docUploadValidation"
                                  : ""
                                  }`}
                                label="Drag and drop new image here!"
                              />
                              {values?.content !== null &&
                                values?.content !== "" ? (
                                <a
                                  href={BASE_URL + values?.content}
                                  target="_blank"
                                >
                                  <img src={eyeView} alt="" />
                                </a>
                              ) : null}
                            </span>
                            {touched?.content && errors?.content ? (
                              <div className="text-danger customValidationMsg">
                                {errors?.content}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        </div>
                      )}
                      {option?.value === "document" && (
                        <div
                          className={`customFileUpload form-group customSelectBox ${touched?.content && errors?.content
                            ? "validationError"
                            : ""
                            }`}
                        >
                          <>
                            <span
                              className={
                                values?.content !== "" &&
                                  values?.content !== null
                                  ? "d-grid-2-fr"
                                  : null
                              }
                            >
                              <FileUploader
                                handleChange={(files) =>
                                  handleFileChangeUpload(files)
                                }
                                name="content"
                                types={getAllowedFileTypes("document")}
                                classes={`drop_area drop_zone ${touched?.content && errors?.content
                                  ? "docUploadValidation"
                                  : ""
                                  }`}
                                label="Drag and drop document here!"
                              />
                              {values?.content !== null &&
                                values?.content !== "" ? (
                                <a
                                  href={BASE_URL + values?.content}
                                  target="_blank"
                                >
                                  <img src={eyeView} alt="" />
                                </a>
                              ) : null}
                            </span>
                            {touched?.content && errors?.content ? (
                              <div className="text-danger customValidationMsg">
                                {errors?.content}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        </div>
                      )}
                      {option?.value === "video" && (
                        <div
                          className={`customFileUpload form-group customSelectBox ${touched?.content && errors?.content
                            ? "validationError"
                            : ""
                            }`}
                        >
                          <>
                            <span
                              className={
                                values?.content !== "" &&
                                  values?.content !== null
                                  ? "d-grid-2-fr"
                                  : null
                              }
                            >
                              <FileUploader
                                handleChange={(files) =>
                                  handleFileChangeUpload(files)
                                }
                                name="content"
                                types={getAllowedFileTypes("video")}
                                classes={`drop_area drop_zone ${touched?.content && errors?.content
                                  ? "docUploadValidation"
                                  : ""
                                  }`}
                                label="Drag and drop video here!"
                              />
                              {values?.content !== null &&
                                values?.content !== "" ? (
                                <a
                                  href={BASE_URL + values?.content}
                                  target="_blank"
                                >
                                  <img src={eyeView} alt="" />
                                </a>
                              ) : null}
                            </span>
                            {touched?.content && errors?.content ? (
                              <div className="text-danger customValidationMsg">
                                {errors?.content}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group">
                      <TextArea
                        type="text"
                        name="prpsDescription"
                        value={values?.prpsDescription}
                        onChange={handleChange}
                        omBlurFunc={handleBlur}
                        labelText="Description"
                        limitCounter={3000}
                        rows={3}
                        cols={3}
                        customError={
                          touched?.prpsDescription && errors?.prpsDescription
                        }
                        errorMessage={errors?.prpsDescription}
                        errorClass={
                          touched?.prpsDescription && errors?.prpsDescription
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="formActionButtons">
              {isSubmitClicked ? (
                <Button type="button" className="btn-sm btn-primary" disabled>
                  Loading...
                </Button>
              ) : (
                <>
                  <Button
                    className="btn-sm"
                    variant="outline-primary"
                    onClick={onClose}
                  >
                    Close
                  </Button>
                  <Button type="submit" className="btn-sm btn-primary">
                    Submit
                  </Button>
                </>
              )}
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {isUpdateTrainingTempToast && <ToastContainer />}
    </>
  );
};
const getAllowedFileTypes = (optionValue) => {
  switch (optionValue) {
    case "image":
      return ["JPG", "PNG", "GIF", "JPEG"];
    case "document":
      return ["DOC", "PDF", "WORD"];
    case "video":
      return ["MP4", "AVI", "MKV"];
    default:
      return [];
  }
};
export default UpdateTrainingModel;
