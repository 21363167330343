import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import TextArea from "../../components/forms-fields/TextArea";
import Input from "../../components/forms-fields/Input";
import { addAppointmentValidation } from "../../schemas";
import { useFormik } from "formik";
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import { POST_APPOINTMENTS_URL } from "../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";

function AddAppointments({ userDataId, addAppointmentModal, aptForSelectedDate }) {
  const handleShowHideModal = () => {
    addAppointmentModal((prevShow) => !prevShow);
  };

  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddAppointment, setIsToastAddAppointment] = useState(false);
  const [durationHours, setDurationHours] = useState(0);
  const [durationMinutes, setDurationMinutes] = useState(0);
  const [aptMinutes, setAptMinutes] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
  };
  const [suFormData, setSuFormData] = useState({
    appointmentTitle: "",
    user: userDataId,
    appointmentDate: moment(aptForSelectedDate).format('YYYY-MM-DD'),
    staff: "",
    appointmentDetails: "",
    appointmentStartTime: "00:00",
    appointmentEndTime: "00:00",
    appointmentMin: "",
  });
  // console.log("🚀 ~ file: AddAppointments.jsx:46 ~ AddAppointments ~ aptMinutes:", aptMinutes)



  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addAppointmentValidation,
    onSubmit: async (values) => {
      try {
        console.log("Form Data:", values);
        setIsSubmitButtonClicked(true);
        await postHealthIssueFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddAppointment(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      handleShowHideModal();
    } else if (
      method === "postHealthIssueFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddAppointment(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal();
      }, 2000);
    }
  };



  useEffect(() => {
    const startTime = moment(values.appointmentStartTime, 'HH:mm').toDate();
    const endTime = moment(values.appointmentEndTime, 'HH:mm').toDate();

    if ((startTime !== NaN) && (endTime !== NaN)) {
      const durationInMilliseconds = endTime - startTime;
      // console.log("🚀 ~ file: AddAppointments.jsx:107 ~ useEffect ~ durationInMilliseconds:",  )

      const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
      const minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      const durationInMinutes = durationInMilliseconds / (1000 * 60);
      setAptMinutes(durationInMinutes);
      setDurationHours(hours);
      setDurationMinutes(minutes);
    }
    else {
      setDurationHours(0);
      setDurationMinutes(0);
      setAptMinutes(0);
    }
  }, [values.appointmentStartTime, values.appointmentEndTime]);

  const postHealthIssueFormData = catchAsync(async (req, res) => {
    const updatedValues = {
      ...values,
      appointmentDate: moment(values.appointmentDate).valueOf(),
      appointmentStartTime: moment(values.appointmentStartTime, 'HH:mm').format('hh:mm A'),
      appointmentEndTime: moment(values.appointmentEndTime, 'HH:mm').format('hh:mm A'),
      staff: selectedUserId,
      appointmentMin: aptMinutes,
    };
    const response = await postRecords(POST_APPOINTMENTS_URL, updatedValues);
    console.log("🚀 ~ file: AddAppointments.jsx:100 ~ postHealthIssueFormData ~ response:", response)
    processPostRecords("postHealthIssueFormData", response);
  });






  return (
    <>
      <div className="incidents consentCapacityAddNew">
        <form onSubmit={handleSubmit}>
          {/* <h3>{aptForSelectedDate}</h3> */}
          <div className="formBlockContent">
            <div className="row">
              <div className="col-md-12">
                <Input
                  type="text"
                  id=""
                  name="appointmentTitle"
                  value={values?.appointmentTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Appointment Title"
                  customError={touched?.appointmentTitle && errors?.appointmentTitle}
                  errorMessage={errors?.appointmentTitle}
                  erroClass={
                    touched?.appointmentTitle && errors?.appointmentTitle
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                />
              </div>
              <div className="col-md-12">
                <Input
                  type="date"
                  datePicker={true}
                  name="appointmentDate"
                  selected={values?.appointmentDate ? moment(values?.appointmentDate).toDate() : null}
                  dateFormat="dd/MM/yyyy"
                  onChange={(appointmentDate) => {
                    if (!appointmentDate) {
                      setFieldValue("appointmentDate", NaN);
                    } else {
                      setFieldValue("appointmentDate", moment(appointmentDate).valueOf());
                    }
                  }}
                  onBlur={() => setFieldTouched("appointmentDate", true)}
                  labelText="Appointment Date"
                  placeholderText="Select Appointment Date"
                  customError={touched?.appointmentDate && errors?.appointmentDate}
                  errorMessage={errors?.appointmentDate}
                  errorClass={
                    touched?.appointmentDate && errors?.appointmentDate
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                  showYearDropdown={true}
                />
                {/* <Input
                  type="date"
                  id=""
                  name="appointmentDate"
                  value={values?.appointmentDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Appointment Date"
                  customError={touched?.appointmentDate && errors?.appointmentDate}
                  errorMessage={errors?.appointmentDate}
                  erroClass={
                    touched?.appointmentDate && errors?.appointmentDate
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                /> */}

              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <CustomSelect
                    id=""
                    name="staff"
                    onSelect={handleUserSelect}
                    filterBy={""}
                    label={"Select Staff"}
                    errorClass={isStaffId ? "validationError" : ""}
                    customError={isStaffId}
                    errorMessage={
                      isStaffId ? errorMessage : "Please select staff"
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">

              <div className="col-md-6">
                <Input
                  type="time"
                  id="appointmentStartTime"
                  name="appointmentStartTime"
                  value={values?.appointmentStartTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Appointment Start Time"
                  marginBottom={true}
                  customError={touched?.appointmentStartTime && errors?.appointmentStartTime}
                  errorMessage={errors?.appointmentStartTime}
                  erroClass={
                    touched?.appointmentStartTime && errors?.appointmentStartTime
                      ? "validationError"
                      : ""
                  }
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="time"
                  id="appointmentEndTime"
                  name="appointmentEndTime"
                  value={values?.appointmentEndTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Appointment End Time"
                  marginBottom={true}
                  customError={touched?.appointmentEndTime && errors?.appointmentEndTime}
                  errorMessage={errors?.appointmentEndTime}
                  erroClass={
                    touched?.appointmentEndTime && errors?.appointmentEndTime
                      ? "validationError"
                      : ""
                  }
                />
              </div>
              <div className="col-md-12">
                <p>
                  <strong><small>Appointment duration {durationHours} hours and {durationMinutes} minutes</small></strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <TextArea
                  id="appointmentDetails"
                  name="appointmentDetails"
                  value={values?.appointmentDetails}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Appointment Details"
                  rows="3"
                  className="mb-3"
                  customError={errors.appointmentDetails && touched.appointmentDetails}
                  errorMessage={errors.appointmentDetails}
                  erroClass={errors.appointmentDetails && touched.appointmentDetails}
                  requiredStar={true}
                />
              </div>
            </div>
          </div>

          <div className="formActionButtons">
            <span
              onClick={addAppointmentModal}
              className="btn btn-sm btn-outline-primary"
            >
              Close
            </span>
            {isSubmitButtonClicked ? (
              <Button
                onClick={addAppointmentModal}
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Submit
              </Button>
            )}
          </div>
        </form>
      </div>
      {isToastAddAppointment && <ToastContainer />}
    </>
  );
}

export default AddAppointments;