import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import Layout from "../../components/layouts/Layout";
import editBlock from "../../assets/images/icons/editBlock.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
import AddMedicineStock from "./AddMedicineStock";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import useColumnsWithPermission from "../../permissions/useColumnsWithPermission";
import {
  GET_MEDICINE_STOCK_URL,
  DELETE_MEDICINE_STOCK_URL,
} from "../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
} from "../../helper/helper";
import EditMedicineStock from "./EditMedicineStock";
function MedicineStock() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  // console.log("🚀 ~ MedicineStock ~ userDetails:", userDetails)
  const [userData, setUserData] = useState([]);
  const apiQuery = {
    userId: userDetails?._id,
    skip: 0,
    limit: 10000
  };
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [medicationIdData, setMedicationIdData] = useState({
    medicationId: null,
  });
  const [medicationStockDetail, setMedicationStockDetail] = useState();
  const [isMedicineStock, setIsMedicineStock] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

  // Modal Popup
  const [showAddMedication, setShowAddMedication] = useState(false);
  const subModalAddMedicine = () => {
    setShowAddMedication(!showAddMedication);
    fetchAllRecords();
  };

  const [showDeleteMedication, setShowDeleteMedication] = useState(false);
  const subModalDeleteMedication = (medicationStockId) => {
    setMedicationIdData({ medicationId: medicationStockId });
    setShowDeleteMedication(!showDeleteMedication);
    fetchAllRecords();
  };

  const [showEditMedication, setShowEditMedication] = useState(false);
  const subModalEditMedication = (emarItem) => {
    setMedicationStockDetail(emarItem);
    setShowEditMedication(!showEditMedication);
    fetchAllRecords();
  };

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const medicationStock = data?.data?.result?.map((item) => item);
      console.log("🚀 ~ processFetchRecords ~ medicationStock:", medicationStock)
      setUserData(medicationStock);
      setFilterSearch(medicationStock);
      setIsLoading(false);
      // toastSuccessMessages(toast.success, data?.message, "", 500, 500);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsMedicineStock(false);
      }, 2000);
    }
    // else {
    //   console.log({ data });
    //   toastErrorMessages(toast.error, data?.message, 1000);
    // }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_MEDICINE_STOCK_URL, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ apiQuery:", apiQuery)
    // console.log("🚀 ~ fetchAllRecords ~ apiQuery:", apiQuery)
    // console.log(
    //   "🚀 ~ file: MedicineStock.jsx:89 ~ fetchAllRecords ~ response:",
    //   response
    // );
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // Table Columns
    // Table Columns

    const getFilteredProperty = (row, property) => {
      return row?.medicationDetails
        ?.filter((item) => item.user === undefined)
        .map((item) => item[property])
        .join(", ");
    };

  const baseColumns = [
    {
      name: "Medicine Name",
      selector: (row) => {
        return row?.medicationName;
      },

    },
    {
      name: "Medicine Type",
      selector: (row) => {
        return row?.medicineType;
      },
      width: "15%",
    },
    {
      name: "Inventory",
      selector: (row) => {
        return row?.inventory;
      },
      width: "20%",
      center: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="actionColumns">
            <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
              <span
                className="edit"
                onClick={() => {
                  subModalEditMedication(row);
                }}
              >
                <img src={editBlock} alt="Edit" title="Edit" />
              </span>
            </ProtectedComponent>
            <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
              <span
                className="delete"
                onClick={() => {
                  subModalDeleteMedication(row?._id);
                }}
              >
                <img src={eventDel} alt="Delete" title="Delete" />
              </span>
            </ProtectedComponent>
          </div>
        );
      },
      width: "20%",
      center: true,
    },
  ];

  const columns = useColumnsWithPermission(baseColumns, [permissions.EDIT, permissions.DELETE]);
  // Search Filter
  useEffect(() => {
    const result = userData.filter((user) => {
      return user.medicationName.toLowerCase().match(search.toLowerCase());
    });
    setFilterSearch(result);
  }, [search, userData]);

  // Delete Medicine records
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (medicationIdData !== "") {
      const response = await deleteRecords(
        DELETE_MEDICINE_STOCK_URL,
        medicationIdData
      );

      setIsMedicineStock(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteMedication(!showDeleteMedication);
    } else {
      setShowDeleteMedication(!showDeleteMedication);
      setIsSubmitButtonClicked(false);
      setIsMedicineStock(true);
      toastErrorMessages(toast.error, "Unable to remove", "", 1000);
    }
  });
  useEffect(() => {
    setTimeout(() => {
      setIsMedicineStock(false);
    }, 2000);
  }, [isMedicineStock])

  return (
    <>
      <Layout pageTitleText="Medicine Stock">
        <div className="rowClickUnset">
          <div className="mainContent">

            <div className="dataTableWrapper">
              <DataTable
                className="maxWidthTable"
                columns={columns}
                data={filterSearch}
                pagination
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationPerPage={10}
                fixedHeader
                fixedHeaderScrollHeight="520px"
                highlightOnHover
                actions={
                  <>
                    <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                      <span
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          subModalAddMedicine(filterSearch);
                        }}
                      >
                        Add Medicine
                      </span>
                    </ProtectedComponent>
                  </>
                }
                responsive={true}
                subHeader={true}
                subHeaderComponent={
                  <div className="searchContainer">
                    <div className="searchTextBox">
                      <input
                        type="text"
                        placeholder="Type your search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                }
                subHeaderAlign="left"
                subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
                progressPending={isLoading}
                progressComponent={<ProgressComponent />}
              />
            </div>
          </div>
        </div>
      </Layout>

      {/* Add Medication Stock */}
      <ProtectedComponent requiredPermissions={[permissions.ADD]}>
        <Modal
          show={showAddMedication}
          onHide={subModalAddMedicine}
          className="ftcModalPopup medicineInventoryStock"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Medicine</Modal.Title>
          </Modal.Header>
          <Modal.Body className="rosterPopup">
            <AddMedicineStock
              userId={userDetails?._id}
              subModalAddMedicine={subModalAddMedicine}
            />
          </Modal.Body>
        </Modal>
      </ProtectedComponent>
      {/* Edit Medication Stock */}
      <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
        <Modal
          show={showEditMedication}
          onHide={subModalEditMedication}
          className="ftcModalPopup medicineInventoryStock"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Medicine</Modal.Title>
          </Modal.Header>
          <Modal.Body className="rosterPopup">
            <EditMedicineStock
              userId={userDetails?._id}
              subModalEditMedication={subModalEditMedication}
              medicationStockDetail={medicationStockDetail}
            />
          </Modal.Body>
        </Modal>
      </ProtectedComponent>
      {/* Delete Medication Stock */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeleteMedication}
          onHide={subModalDeleteMedication}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this medication?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteMedication}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {isMedicineStock && <ToastContainer />}
    </>
  );
}

export default MedicineStock;
