import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import StatusUpdated from "../../../components/general/StatusUpdated";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BASE_URL,
  GET_STAFFS_HOLIDAYS_DETAILS_URL,
  PUT_HOLIDAY_STAFF_MEMBER_URL,
  ADMIN_ID,
} from "../../../env";
import {
  getRecords,
  updateRecords,
  isSuccess,
  catchAsync,
  formateDate,
  calculateHours,
  filterColumns,
  filterRowsByProperty,
  toastErrorMessages,
  toastSuccessMessages,
} from "../../../helper/helper";

function HolidaysGroup({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccessReturn, setIsSuccessReturn] = useState(false);
  const [isToastHolidays, setIsToastHolidays] = useState(false);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true, staffRequester: initialUserData?._id },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastHolidays(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const userData = data?.data?.staffHolidayRequests || [];
      setUserData(userData);
      setIsLoading(false);
    } else if (method === "update" && data?.status === isSuccess) {
      setIsToastHolidays(true);
      fetchAllRecords();
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    try {
      const response = await getRecords(
        GET_STAFFS_HOLIDAYS_DETAILS_URL,
        apiQuery
      );
      processFetchRecords("get", response);
    } catch (error) {
      console.error("Error fetching staff holidays:", error);
    }
  });

  const updateStaffHoliday = catchAsync(async (req, res) => {
    try {
      const response = await updateRecords(PUT_HOLIDAY_STAFF_MEMBER_URL, req);
      processFetchRecords("update", response);
    } catch (error) {
      console.error("Error updating staff holidays:", error);
    }
  });

  const handleStatusChange = (event, statusValue, row) => {
    event.preventDefault();

    const startDate = row?.hldRqStartDate;
    const endDate = row?.hldRqEndDate;
    const totalDays = row?.hldRqTotalDays;
    const totalHours = row?.hldRqTotalHours;
    const holidayId = row?._id;
    const status = statusValue;
    const approveBy = ADMIN_ID;
    const staffId = row?.staffRequester?.modelId?._id;

    if (
      startDate &&
      endDate &&
      totalDays &&
      totalHours &&
      holidayId &&
      status &&
      approveBy &&
      staffId
    ) {
      const payload = {
        hldRqStartDate: startDate,
        hldRqEndDate: endDate,
        hldRqTotalDays: totalDays,
        hldRqTotalHours: totalHours,
        staffHolidayRequestid: holidayId,
        hldRqStatus: status,
        approvedBy: approveBy,
        staffRequester: staffId,
      };
      updateStaffHoliday(payload);
    } else {
      console.error("Invalid data for updating status.");
    }
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const columns = [
    {
      name: "Request Date",
      selector: (row) => {
        const formatEdDate = formateDate(row?.createdAt, "m/d");
        const formatEdHours = calculateHours(
          row?.hldRqStartDate,
          row?.hldRqEndDate
        );
        return <div>{`${formatEdDate} - ${formatEdHours} hours`}</div>;
      },
      width: "20%",
    },
    {
      name: "Remain Hours",
      selector: (row) => {
        return (
          <span className="p-left-15">{`${row?.hldRqTotalHours} hours`}</span>
        );
      },
      width: "20%",
    },
    {
      name: "Carried Over",
      selector: (row) => {
        return (
          <span className="p-left-15">{`${row?.hldRqTotalHours} hours`}</span>
        );
      },
      width: "20%",
    },
    {
      name: "Entitlement",
      selector: (row) => {
        return <span>{`${row?.hldRqTotalHours} hours`}</span>;
      },
      width: "20%",
    },
    {
      name: "Allowance",
      selector: (row) => {
        return <span>{`${row?.hldRqTotalHours} hours`}</span>;
      },
      width: "20%",
      center: false
    },
    // {
    //   name: "Status",
    //   selector: (row) => (
    //     <StatusUpdated
    //       initialStatus={row?.hldRqStatus}
    //       isSuccessReturn={isSuccessReturn}
    //       onChangeFun={(e, statusValue) =>
    //         handleStatusChange(e, statusValue, row)
    //       }
    //     />
    //   ),
    //   center: true,
    // },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== "");
  // const filteredRows = filterRowsByProperty(allRecords, (row) => {
  //   return getUserRecord?.staffHolidayRequests?.some(
  //     (holidayRequest) =>
  //       row?._id === holidayRequest && row?.hldRqStatus !== undefined
  //   );
  // });

  return (
    <div className="mainContent rowClickUnset absenceLeave">
      <h3>Holidays & Absence</h3>
      <div className="dataTableWrapper">
        <DataTable
          className="maxWidthTable"
          columns={filteredColumns}
          data={userData}
          pagination
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationPerPage={10}
          fixedHeader
          fixedHeaderScrollHeight="470px"
          highlightOnHover
          responsive={true}
          progressPending={isLoading}
          progressComponent={<ProgressComponent />}
        />
      </div>
      {isToastHolidays && <ToastContainer />}
    </div>
  );
}

export default HolidaysGroup;
