import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../components/forms-fields/Input";
import { addScheduleWeekDays } from "../../../schemas";
import { useFormik } from "formik";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_ASSIGN_TRAINING_TEMPLATE_URL, ADMIN_ID } from "../../../env";
import moment from 'moment';
import {
    postRecords,
    catchAsync,
    isSuccess,
    toastSuccessMessages,
    toastErrorMessages,
} from "../../../helper/helper";
import MedicineListDetail from "../../../components/forms-fields/MedicineListDetail";
import CustomSelect from "../../../components/forms-fields/CustomSelectStaffMembers";
import CustomSelectServiceUsers from "../../../components/forms-fields/CustomSelectServiceUsers";
import TextArea from "../../../components/forms-fields/TextArea";
import CustomSelectTrainingTemp from "../../../components/forms-fields/CustomSelectTrainingTemp";
import { Form } from "react-bootstrap";
import CustomSelectQuizzTemp from "../../../components/forms-fields/CustomSelectQuizzTemp";

function AssignTraining({
    handleCloseAssignTraTempModel }) {


    const handleShowHideModal = () => {
        handleCloseAssignTraTempModel((prevShow) => !prevShow);
    };


    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
    const [isToastEditUserShift, setIsToastEditUserShift] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState("");


    const handleUserSelect = async (staffId) => {
        setSelectedUserId(staffId)
        setFieldValue("user", staffId);
    }

    const [selectedTrainingIdd, setSelectedTrainingIdd] = useState("");

    const handleTrainingSelect = async (trainingId) => {
        setSelectedTrainingIdd(trainingId)
        setFieldValue("trainingId", trainingId);
    }


    const [selectedQuizId, setSelectedQuizId] = useState("");
    const handleQuizSelect = async (quizId) => {
        setSelectedQuizId(quizId)
        setFieldValue("quizId", quizId);
    }



    const [suFormData, setSuFormData] = useState({
        trainingId: selectedTrainingIdd,
        quizId: selectedQuizId,
        user: [selectedUserId],
        dueDate: moment().valueOf()
    });
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
    } = useFormik({

        initialValues: suFormData,
        // validationSchema: addScheduleWeekDays,
        onSubmit: async (values) => {
            try {
                setIsSubmitButtonClicked(true);
                await postEditUserShiftFormData(values);
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        },
    });

    const processPostRecords = (method, data) => {
        // console.log(data);
        if (data?.status !== isSuccess) {
            setIsToastEditUserShift(true);
            toastErrorMessages(toast.error, data?.message, "", 500, 500);
            setIsSubmitButtonClicked(false);
            handleShowHideModal();
        } else if (
            method === "postEditUserShiftFormData" &&
            data?.status === isSuccess
        ) {
            setIsToastEditUserShift(true);
            toastSuccessMessages(toast.success, data?.message, "", 500, 500);
            setIsSubmitButtonClicked(false);
            setTimeout(() => {
                handleShowHideModal();
            }, 2000);
        }
    };

    const postEditUserShiftFormData = catchAsync(async (req, res) => {
        const updateValues = {
            ...values,
            trainingId: selectedTrainingIdd,
            quizId: selectedQuizId,
            user: [selectedUserId],
            dueDate: moment(values?.dueDate).valueOf(),
            trainingStatus: "assigned"
        }
        console.log("🚀 ~ postEditUserShiftFormData ~ updateValues:", updateValues)
        const response = await postRecords(POST_ASSIGN_TRAINING_TEMPLATE_URL, updateValues);
        console.log("🚀 ~ postEditUserShiftFormData ~ response:", response)
        processPostRecords("postEditUserShiftFormData", response);
        setIsSubmitButtonClicked(false);
    });

    return (
        <>
            <div className="incidents consentCapacityAddNew">
                <form onSubmit={handleSubmit}>
                    <div className="formBlockContent">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <CustomSelectTrainingTemp
                                        onSelect={handleTrainingSelect}
                                        value={values?.trainingId}
                                        label="Training Templates"
                                        customError={errors?.trainingId}
                                        errorMessage={errors?.trainingId}
                                        errorClass={errors?.trainingId ? "validationError" : ""}
                                        required={true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <CustomSelectQuizzTemp
                                        onSelect={handleQuizSelect}
                                        value={values?.quizId}
                                        label="Training Quizzes"
                                        customError={errors?.quizId}
                                        errorMessage={errors?.quizId}
                                        errorClass={errors?.quizId ? "validationError" : ""}
                                        required={true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <CustomSelect
                                        id=""
                                        name="user"
                                        onSelect={handleUserSelect}
                                        defaultValue={selectedUserId}
                                        filterBy=""
                                        label="Staff Member"
                                        customError={touched?.user && errors?.user}
                                        errorMessage={errors?.user}
                                        errorClass={
                                            touched?.user && errors?.user
                                                ? "validationError"
                                                : ""
                                        }
                                    // isDisabled={staffId ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Input
                                    type="date"
                                    name="dueDate"
                                    value={moment(values?.dueDate).format("YYYY-MM-DD")}
                                    onChange={handleChange}
                                    omBlurFunc={handleBlur}
                                    labelText="Training Due Date"
                                    customError={
                                        touched?.dueDate && errors?.dueDate
                                    }
                                    errorMessage={errors?.dueDate}
                                    errorClass={
                                        touched?.dueDate && errors?.dueDate
                                            ? "validationError"
                                            : ""
                                    }
                                    requiredStar={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="formActionButtons">
                        <span
                            onClick={handleShowHideModal}
                            className="btn btn-sm btn-outline-primary"
                        >
                            Close
                        </span>
                        {isSubmitButtonClicked ? (
                            <Button
                                className="btn-sm"
                                variant="primary"
                                disabled
                            >
                                Loading...
                            </Button>
                        ) : (
                            <Button className="btn-sm" variant="primary" type="submit">
                                Submit
                            </Button>
                        )}
                    </div>
                </form>
            </div>
            {isToastEditUserShift && <ToastContainer />}
        </>
    );
}

export default AssignTraining