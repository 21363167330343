// rolePermissions.js
import { roles, permissions } from "./roles";

export const rolePermissions = {
  [roles.SUPER_ADMIN]: [
    permissions.ADMIN,
    permissions.ADD,
    permissions.EDIT,
    permissions.DELETE,
    permissions.VIEW,
    permissions.PAYROLL,
    permissions.ROSTER,
    permissions.DISCIPLINARYINFO,
    permissions.SUPERVISION,
    permissions.HOLIDAYS,
    permissions.RECRUITMENT,
    permissions.TRAINING,
  ],
  [roles.SLT_HEAD]: [
    // permissions.ADD, //will be removed
    // permissions.EDIT,//will be removed
    permissions.VIEW,
    permissions.DISCIPLINARYINFO,
    permissions.SUPERVISION,
    permissions.HOLIDAYS,
    permissions.RECRUITMENT,
    permissions.TRAINING,
  ],
  [roles.SLT_GENERAL]: [
    permissions.VIEW,
    permissions.DISCIPLINARYINFO,
    permissions.SUPERVISION,
    permissions.HOLIDAYS,
    permissions.RECRUITMENT,
    permissions.TRAINING,
  ],
  [roles.OFFICE_STAFF]: [permissions.VIEW],
  [roles.FINANCE_ADMIN]: [permissions.VIEW],
  [roles.STAFF_USER]: [permissions.VIEW],
  [roles.SUPERVISION_ACCESS]: [permissions.VIEW],
  [roles.ROSTER_ACCESS]: [permissions.ROSTER],
};
