import React, { useState, useEffect } from "react";
import chatDel from "../../assets/images/icons/chatDel.svg";
import RoomSpace from "./RoomSpace";
import moment from "moment";
import DeletePopup from "../../components/modals/DeletePopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressComponent from "../../components/general/ProgressComponent";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import eventDel from "../../assets/images/icons/eventDel.svg";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import {
  BASE_URL,
  GET_ALL_COMBINED_MESSAGES_URL,
  DELETE_MESSAGE_COMPLETE_CHAT_URL,
  ADMIN_ID,
} from "../../env";
import {
  getRecords,
  postRecords,
  isSuccess,
  catchAsync,
  toastErrorMessages,
  toastSuccessMessages,
  formateDate,
  deleteRecords,
} from "../../helper/helper";
import { connect } from 'react-redux';
import { addNewChatSingleUser } from '../../redux/actions/addNewChatSingleUserAction';
function NameSpace({ selectedUser, addNewChatSingleUser, mobileDisplayStatus, onMobile }) {
  const [allPersonalMessages, setAllPersonalMessages] = useState([]);
  const [selectedUserIdForNewChat, setSelectedUserIdForNewChat] = useState(selectedUser?._id)
  console.log("🚀 ~ NameSpace ~ selectedUserIdForNewChat:", selectedUserIdForNewChat)
  const [isUserIdPresent, setIsUserIdPresent] = useState(false)


  useEffect(() => {
    if (allPersonalMessages.some(
      message => message.recieverId === selectedUserIdForNewChat ||
        message.senderId === selectedUserIdForNewChat)) {
      setIsUserIdPresent(true)

    }
    else {
      setIsUserIdPresent(false)

    }
  }, [allPersonalMessages, selectedUserIdForNewChat])

  useEffect(() => {
    isUserIdPresent && (
      handleClean()
    )
  }, [isUserIdPresent])
  const location = useLocation();
  const navigate = useNavigate();
  const getUser = location?.state && location?.state?.addNewMessage;
  const [initialSelectedUser, setInitialSelectedUser] = useState(selectedUser);

  useEffect(() => {
    setInitialSelectedUser(selectedUser);

  }, [selectedUser]);

  const [allGroupMessages, setAllGroupMessages] = useState(false);
  const [openMessagesScreen, setOpenMessagesScreen] = useState(false);
  const [openGroupMessagesScreen, setOpenGroupMessagesScreen] = useState(false);
  const [isToastMessage, setIsToastMessage] = useState(false);
  const [activeMessageState, setActiveMessageState] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const apiQuery = {
    userId: ADMIN_ID,
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    if (!req) {
      const response = await getRecords(
        GET_ALL_COMBINED_MESSAGES_URL,
        apiQuery
      );
      processFetchRecords("get", response);
    } else {
      const response = await getRecords(GET_ALL_COMBINED_MESSAGES_URL, req);
      // console.log("🚀 ~ fetchAllRecords ~ response:", response)
      processFetchRecords("get", response);
    }
    // console.log("🚀 ~ fetchAllRecords ~ apiQuery:", apiQuery)
  });


  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastMessage(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
      return;
    }

    switch (method) {
      case "get":
        const personalMessages = data?.data?.personalMessage?.filter(
          (item) => item && item?.isDeleted !== true
        );
        const groupMessages = data?.data?.sortedArrayGroup?.map((item) => item);
        if (groupMessages?.length > 0) {
          setOpenGroupMessagesScreen(groupMessages && groupMessages[0]);
          setActiveMessageState(groupMessages && groupMessages[0]);
        } else {
          setOpenMessagesScreen(personalMessages && personalMessages[0]);
          setActiveMessageState(personalMessages && personalMessages[0]);
        }
        setAllPersonalMessages(personalMessages);
        setAllGroupMessages(groupMessages);
        setIsLoading(false);
        break;
      default:
        break;
    }
  };



  const handleOpenMessages = (e, item) => {
    setActiveMessageState(item);
    setOpenGroupMessagesScreen(false);
    setOpenMessagesScreen(item);
  };

  const handleOpenGroupMessages = (e, item) => {
    setActiveMessageState(item);
    setOpenMessagesScreen(false);
    setOpenGroupMessagesScreen(item);
  };



  const UserListItem = ({ user, onClick }) => (
    <div
      className={`userBox ${user?.senderId === activeMessageState?.senderId &&
        user?.recieverId === activeMessageState?.recieverId
        ? "activeMessageRow"
        : null
        }`}
    >
      <div className="usersNameInfo usersNameContainer" onClick={onClick}>
        <span className="">
          <img src={BASE_URL + user?.image} alt={user?.name} />
        </span>
        <div className="userInfo userInfoContainer">
          <span className="dayTime messageTime">
            {user && (
              <>
                {formateDate(user?.date, "m/d/y")}
                {"  "}
                {moment(user?.date).format("LT")}
              </>
            )}
          </span>
          <span className="nameBlock nameBlockContainer">
            {user?.name}
            <span className="paragraphBlock">
              {user?.seen ? (
                user?.message && user?.message?.slice(0, 20) + "...."
              ) : (
                <span className="color-black">
                  {user?.message && user?.message?.slice(0, 20) + "...."}
                </span>
              )}
            </span>
          </span>
        </div>
      </div>
      <div className="userAction" onClick={(e) => subModalDeleteChatMessages(user)}>
        <span className="delete">
          <img src={chatDel} alt="Delete" />
        </span>
      </div>
    </div>
  );

  const GroupsListItem = ({ group, onClick }) => (
    <div
      className={`userBox ${group?._id === activeMessageState?._id ? "activeMessageRow" : null
        }`}
    >
      <div className="usersNameInfo usersNameContainer" onClick={onClick}>
        <span className="">
          <img src={BASE_URL + group?.groupImage} alt={group?.groupImage} />
        </span>
        <div className="userInfo userInfoContainer">
          <span className="dayTime  messageTime">
            {group?.lastMessages?.messageTime ? (
              <>
                {formateDate(group?.lastMessages?.messageTime, "m/d/y")}
                {"  "}
                {moment(group?.lastMessages?.messageTime).format("LT")}
              </>
            ) : (
              ""
            )}
          </span>
          <span className="nameBlock nameBlockContainer">
            {group?.groupName}
            <span className="paragraphBlock">
              {group?.seen ? (
                group?.lastMessages &&
                group?.lastMessages?.message?.slice(0, 20) + "...."
              ) : (
                <span className="color-black">
                  {group?.lastMessages &&
                    group?.lastMessages?.message?.slice(0, 20) + "...."}
                </span>
              )}
            </span>
          </span>
        </div>
      </div>
      {/* <div className="userAction" onClick={(e) => subModalDeleteChatMessages(group)}>
        <span className="delete">
          <img src={chatDel} alt="Delete" />
        </span>
      </div> */}
    </div>
  );


  const handleClean = () => {
    addNewChatSingleUser(null); // or addNewChatSingleUser('') if you prefer an empty string
  };

  const AddNewUserProfile = ({ user, onClick }) => (
    <div className="userBox">
      <div className="usersNameInfo usersNameContainer" onClick={onClick}>
        <span className="">
          <img src={BASE_URL + user?.profile_picture_url} alt={user?.name} />
        </span>
        <div className="userInfo userInfoContainer">
          <span className="dayTime fs-12 r-0">
            {user && (
              <>
                {formateDate(user?.date, "m/d/y")}
                {"  "}
                {moment(user?.date).format("LT")}
              </>
            )}
          </span>
          <span className="nameBlock nameBlockContainer">
            {user?.name}
            <span className="paragraphBlock">
              {user?.message && user?.message?.slice(0, 20) + "...."}
            </span>
          </span>
        </div>
      </div>
      <div
        className="userAction"
        onClick={() => handleClean()}
        style={{ width: "12%" }}
      >
        1
        <span className="delete">
          <img src={chatDel} alt="Delete" />
        </span>
      </div>
    </div>
  );

  const mdScreenNameSpaceSelectedUser = initialSelectedUser?._id && (
    <AddNewUserProfile
      user={initialSelectedUser}
      onClick={(e) => handleOpenMessages(e, initialSelectedUser)}
    />
  );

  const renderPersonalMessagesJsx = (personalMessages) => {
    return personalMessages?.map((item, index) => (
      <UserListItem
        key={index}
        user={item}
        onClick={(e) => handleOpenMessages(e, item)}
      />
    ));
  };

  const renderGroupsMessagesJsx = (groups) => {
    return groups?.map((item, index) => (
      <GroupsListItem
        key={index}
        group={item}
        onClick={(e) => handleOpenGroupMessages(e, item)}
      />
    ));
  };

  useEffect(() => {
    fetchAllRecords(apiQuery);
  }, []);

  useEffect(() => {
    if (getUser?._id) {
      if (allPersonalMessages?.length > 0) {
        const filteredMessages =
          allPersonalMessages &&
          allPersonalMessages?.filter(
            (item) =>
              item?.senderId === getUser?._id ||
              item?.recieverId == getUser?._id
          );
        if (filteredMessages?.length > 0) {
          setOpenGroupMessagesScreen(false);
          setInitialSelectedUser(filteredMessages[0]);
          setOpenMessagesScreen(filteredMessages[0]);
          setActiveMessageState(filteredMessages[0]);
        }
      } else {
        setInitialSelectedUser(getUser);
        setOpenMessagesScreen(getUser);
        setActiveMessageState(getUser);
        setOpenGroupMessagesScreen(false);
      }
    }
  }, [getUser]);

  useEffect(() => {
    if (openGroupMessagesScreen) {
      setOpenMessagesScreen(false);
    } else if (openMessagesScreen) {
      setOpenGroupMessagesScreen(false);
    }
  }, [openGroupMessagesScreen, openMessagesScreen]);


  // Delete Modal Popup

  const [showDeleteChatMessages, setShowDeleteChatMessages] = useState(false);
  const [deleteChatData, setDeleteChatData] = useState(null);
  // console.log("🚀 ~ NameSpace ~ deleteChatData:", deleteChatData)
  const subModalDeleteChatMessages = (chatData) => {
    setShowDeleteChatMessages(!showDeleteChatMessages);
    setDeleteChatData({
      senderId: chatData?.senderId,
      recieverId: chatData?.recieverId,
    });

    fetchAllRecords(apiQuery);
  };


  // Delete Chat Messages
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);

    if (deleteChatData !== "") {
      const response = await deleteRecords(
        DELETE_MESSAGE_COMPLETE_CHAT_URL,
        deleteChatData
      );
      console.log("🚀 ~ deleteRecord ~ response:", response)
      setIsToastMessage(true);
      processFetchRecords("delete", response);
      fetchAllRecords(apiQuery);
      setIsSubmitButtonClicked(false);
      setShowDeleteChatMessages(!showDeleteChatMessages);
    } else {
      setShowDeleteChatMessages(!showDeleteChatMessages);
      setIsSubmitButtonClicked(false);
      setIsToastMessage(true);
      toastErrorMessages(toast.error, "Unable to remove", "", 1000);
    }
  });


  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <>
          {
            mobileDisplayStatus ? (
              <div className="mobileNameSpace">
                {mdScreenNameSpaceSelectedUser}
                {renderGroupsMessagesJsx(allGroupMessages && allGroupMessages)}
                {renderPersonalMessagesJsx(allPersonalMessages && allPersonalMessages
                )}
              </div>
            ) : (
              <div className="nameSpace">
                {mdScreenNameSpaceSelectedUser}
                {renderGroupsMessagesJsx(allGroupMessages && allGroupMessages)}
                {renderPersonalMessagesJsx(
                  allPersonalMessages && allPersonalMessages
                )}
              </div>
            )
          }
          <RoomSpace
            getMessages={openMessagesScreen}
            isIndividual={openMessagesScreen ? true : false}
            getGroupMessages={openGroupMessagesScreen}
            isGroup={openGroupMessagesScreen ? true : false}
            fetchAllMessagesRecords={fetchAllRecords}
            onMobile={onMobile}
          />
        </>
      )}
      {showDeleteChatMessages && (
        // <DeletePopup
        //   show={isDeleteChat}
        //   onHide={handleCloseDeleteMsgModel}
        //   title="Confirmation"
        //   content="Are you sure you want to delete this conversation?"
        //   onConfirm={deleteChatFun}
        //   onCancel={handleCloseDeleteMsgModel}
        // />
        <>
          {/* Delete Care Note */}
          <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
            <Modal
              show={showDeleteChatMessages}
              onHide={subModalDeleteChatMessages}
              className="ftcModalPopup confirmationPopup deletionConfirmation"
            >
              <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="editBlockContent text-center">
                  <img src={eventDel} alt={eventDel} />
                  <p>Are you sure you want to delete this record?</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {isSubmitButtonClicked ? (
                  <Button className="btn-sm" variant="danger" disabled>
                    Loading...
                  </Button>
                ) : (
                  <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                    Yes
                  </Button>
                )}

                <Button
                  className="btn-sm"
                  variant="outline-secondary"
                  onClick={subModalDeleteChatMessages}
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </ProtectedComponent>
        </>
      )}
      {isToastMessage && <ToastContainer />}
    </>
  );
}

// Map state from Redux store to component props
const mapStateToProps = (state) => ({
  selectedUser: state.addNewChatSingleUser.memberData, // Map selectedUserId from Redux store state
});


const mapDispatchToProps = (dispatch) => ({
  addNewChatSingleUser: (memberData) => dispatch(addNewChatSingleUser(memberData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NameSpace);


// export default connect(mapStateToProps)(NameSpace);
