import React, { useState, useEffect } from "react";
import Layout from "../../../components/layouts/Layout";
import Button from "react-bootstrap/Button";
import CustomSelectServiceUsers from "../../../components/forms-fields/CustomSelectServiceUsers";
import SelectBox from "../../../components/forms-fields/SelectBox";
import TextArea from "../../../components/forms-fields/TextArea";
import Input from "../../../components/forms-fields/Input";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { addEditSUEnquiry } from "../../../schemas";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_SERVICE_USER_BY_ID_URL,
  POST_SERVICE_USER_ENQUIRY_URL,
  ADMIN_ID
} from "../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  getRecords,
  toastErrorMessages,
} from "../../../helper/helper";
import moment from "moment/moment";

const suStatus = [
  { value: "pending", label: "Pending" },
  { value: "accepted", label: "Accept" },
  { value: "rejected", label: "Reject" },
];

const requestTypeFields = [
  { value: "billing", label: "Billing Enquiry" },
  { value: "services", label: "Services Enquiry" },
  { value: "availability", label: "Availability Enquiry" },
  { value: "misc", label: "Misc Enquiry" },
];

function AddSuEnquiry() {
  const navigate = useNavigate();
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [isSuFormData, setIsSuFormData] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastSuEnquire, setIsToastSuEnquire] = useState(false);

  const [suFormData, setSuFormData] = useState({
    addedby: ADMIN_ID,
    referenceId: "",
    initialContactDate: moment().valueOf(),
    suEnqContactNo: "",
    suEnqEmail: "",
    reasonForNeed: "",
    suEnqStatus: "pending",
    requestType: "billing",
    suEnqComments: "",
    serviceUser: "",
    name: "",
    suLastName: "",
  });

  // const validationSchema = Yup.object().shape({
  //   name: Yup.string().required("First Name is required"),
  //   suEnqContactNo: Yup.string().required("Contact Number is required"),
  //   suEnqEmail: Yup.string()
  //     .email("Invalid email")
  //     .required("Email is required"),
  // });

  const formik = useFormik({
    initialValues: {
      addedby: ADMIN_ID,
      referenceId: "",
      initialContactDate: moment().valueOf(),
      suEnqContactNo: "",
      suEnqEmail: "",
      reasonForNeed: "",
      suEnqStatus: "pending",
      requestType: "billing",
      suEnqComments: "",
      serviceUser: selectedUserId,
      name: "",
      suLastName: "",
    },

    validationSchema: addEditSUEnquiry,
    onSubmit: async (values) => {
      try {
        setIsSuFormData(false);
        setIsSubmitButtonClicked(true);
        await postFormDataNewEnquires(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });
  // console.log(formik);
  // console.log(selectedUserId);
  const handleUserSelect = async (userId) => {
    setSelectedUserId(userId);
    setIsSuFormData(true);
    const userCriteria = {
      critarion: {
        _id: userId,
      },
    };
    console.log({ userId });

    const getAll = await getRecords(GET_SERVICE_USER_BY_ID_URL, userCriteria);

    console.log("single user data: ", getAll?.data);

    setSuFormData((prevFormData) => ({
      ...prevFormData,
      referenceId: getAll?.data?.referenceId,
      suEnqContactNo: getAll?.data?.suMobileHomeNo,
      suEnqEmail: getAll?.data?.suEmailHome,
      serviceUser: userId,
      name: getAll?.data?.suFirstMiddleName,
      suLastName: getAll?.data?.suLastName,
    }));
  };

  const processFetchRecords = (method, data) => {
    console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastSuEnquire(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (method === "postSuUserFormData" && data?.status === isSuccess) {
      setIsToastSuEnquire(true);
      toastSuccessMessages(
        toast?.success,
        "Enquiry has been successfully added.",
        "/service-users",
        500,
        500
      );
    } else if (
      method === "postFormDataNewEnquires" &&
      data?.status === isSuccess
    ) {
      setIsToastSuEnquire(true);
      toastSuccessMessages(
        toast?.success,
        "Enquiry has been successfully added.",
        "/service-users",
        500,
        500
      );
    }
  };

  const postSuUserFormData = catchAsync(async (req, res) => {
    const response = await postRecords(
      POST_SERVICE_USER_ENQUIRY_URL,
      suFormData
    );
    processFetchRecords("postSuUserFormData", response);
  });

  const postFormDataNewEnquires = catchAsync(async (req, res) => {

    console.log("🚀 ~ postFormDataNewEnquires ~ req:", req)
    setIsSubmitButtonClicked(false);
    const response = await postRecords(POST_SERVICE_USER_ENQUIRY_URL, req);
    console.log("🚀 ~ postFormDataNewEnquires ~ response:", response)
    processFetchRecords("postFormDataNewEnquires", response);
  });

  const handleSubmitPrevFieldsForm = () => {
    console.log({ suFormData });
    setIsSubmitButtonClicked(true);
    if (isSuFormData) {
      postSuUserFormData();
    }
  };

  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setSuFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <Layout
        pageTitleText={
          <>
            Service User <small>New Enquiries</small>
          </>
        }
      >
        <div className="incidents consentCapacityAddNew">
          <form onSubmit={formik?.handleSubmit}>
            <div className="mainContent ">
              <div className="mainContentText">
                <h3>Service User Enquiries</h3>

                <div className="formBlockContent">
                  <div className="row">
                    {/* <div className="col-sm-6 col-md-4">
                        <div className="form-group">
                          <CustomSelectServiceUsers
                            onSelect={handleUserSelect}
                            filterBy={""}
                            errorClass={isStaffId ? "validationError" : ""}
                            customError={isStaffId}
                            errorMessage={isStaffId ? errorMessage : ""}
                          />
                        </div>
                      </div> */}
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id=""
                        name="referenceId"
                        value={formik?.values?.referenceId}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Ref ID"
                        customError={
                          formik?.touched?.referenceId &&
                          formik?.errors?.referenceId
                        }
                        errorMessage={formik?.errors?.referenceId}
                        erroClass={
                          formik?.touched?.referenceId &&
                            formik?.errors?.referenceId
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id=""
                        name="name"
                        value={formik?.values?.name}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="First Name"
                        customError={
                          formik?.touched?.name && formik?.errors?.name
                        }
                        errorMessage={formik?.errors?.name}
                        erroClass={
                          formik?.touched?.name && formik?.errors?.name
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id=""
                        name="suLastName"
                        value={formik?.values?.suLastName}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Last Name"
                      />
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <div className="form-group">
                        <Input
                          type="date"
                          datePicker={true}
                          name="initialContactDate"
                          selected={formik?.values?.initialContactDate ? moment(formik?.values?.initialContactDate).toDate() : null}
                          dateFormat="dd/MM/yyyy"
                          onChange={(initialContactDate) => formik.setFieldValue("initialContactDate", moment(initialContactDate).valueOf())}
                          onBlur={() => formik.setFieldTouched("initialContactDate", true)} // Ensure Formik's touched state is set on blur
                          labelText="Date of Initial Contact"
                          placeholderText="Date of Initial Contact"
                          showYearDropdown={true}
                        />
                      </div>
                      {/* <Input
                        type="date"
                        id=""
                        name="initialContactDate"
                        value={formik?.values?.initialContactDate}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Date of Initial Contact"
                      /> */}
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id=""
                        name="suEnqContactNo"
                        value={formik?.values?.suEnqContactNo}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Contact Number"
                        customError={
                          formik?.touched?.suEnqContactNo &&
                          formik?.errors?.suEnqContactNo
                        }
                        errorMessage={formik?.errors?.suEnqContactNo}
                        erroClass={
                          formik?.touched?.suEnqContactNo &&
                            formik?.errors?.suEnqContactNo
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="email"
                        id=""
                        name="suEnqEmail"
                        value={formik?.values?.suEnqEmail}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Email Address"
                        customError={
                          formik?.touched?.suEnqEmail &&
                          formik?.errors?.suEnqEmail
                        }
                        errorMessage={formik?.errors?.suEnqEmail}
                        erroClass={
                          formik?.touched?.suEnqEmail &&
                            formik?.errors?.suEnqEmail
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id=""
                        name="reasonForNeed"
                        value={formik?.values?.reasonForNeed}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Reason for need"
                      />
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <SelectBox
                        name="suEnqStatus"
                        id=""
                        value={formik?.values?.suEnqStatus}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        options={suStatus}
                        labelText="Status"
                      />
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <SelectBox
                        name="requestType"
                        id=""
                        value={formik?.values?.requestType}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        options={requestTypeFields}
                        labelText="Request Type"
                      />
                    </div>

                    <div className="col-sm-12">
                      <TextArea
                        id="suEnqComments"
                        name="suEnqComments"
                        value={formik?.values?.suEnqComments}
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        labelText="Comments"
                        rows="3"
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="formActionButtons">
                        <Link
                          to="/service-users"
                          className="btn btn-sm btn-outline-primary"
                        >
                          Back
                        </Link>
                        {isSubmitButtonClicked ? (
                          <Button className="btn-sm" variant="primary" disabled>
                            Loading...
                          </Button>
                        ) : (
                          <Button
                            className="btn-sm"
                            variant="primary"
                            // onClick={handleFormSubmit}
                            type="submit"
                          >
                            Save
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </form>
        </div>
      </Layout>
      {isToastSuEnquire && <ToastContainer />}
    </>
  );
}

export default AddSuEnquiry;
