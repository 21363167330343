import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import DeletePopup from "../../components/modals/DeletePopup";
import editIconCircle from "../../assets/images/icons/editIconCircle.svg";
import ProgressComponent from "../../components/general/ProgressComponent";
import CreateRotaTemplate from "./popup/CreateRotaTemplate";
import ModifyRotaTemplate from "./popup/ModifyRotaTemplate";
import SaveFutureTemplate from "./popup/SaveFutureRotaTemplate";
import RotaScheduleLocations from "./RotaSchedule";
import editBlock from "../../assets/images/icons/editBlock.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
import duplicateIcon from "../../assets/images/icons/duplicateIcon.svg";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import moment from "moment";
import {
  GET_ALL_ROTA_TEMPLATES_URL,
  DELETE_ROTA_TEMPLATE_URL,
  ADMIN_ID,
  LOAD_STAFF_TO_ROTA_TEMPLATE_URL,
  PUT_UPDATE_ROTA_TEMPLATE_URL,
} from "../../env";
import {
  getRecords,
  postRecords,
  isSuccess,
  filterColumns,
  filterArrayBySearchTerm,
  catchAsync,
  toastErrorMessages,
  formateDate,
  deleteRecords,
  updateRecords
} from "../../helper/helper";
import Layout from "../../components/layouts/Layout";
import DuplicateRotaTemplate from "../rota_advanced/DuplicateRotaTemplate";
import EditRotaTemplate from "../rota_advanced/EditRotaTemplate";
import AddRotaTemplate from "../rota_advanced/AddRotaTemplate";

function RotaTemplateGroups() {
  const location = useLocation();
  const sideNaveRoute = location?.state && location?.state?.navigateState;
  const editModeTemplateDataState =
    location?.state && location?.state?.editModeTemplateCreated;


  // Azad Var
  const [filterSearch, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [tempIdData, setTempIdData] = useState({
    tempId: "",
    isDelete: true
  });
  const [rotaStockDetail, setRotaStockDetail] = useState();
  const [isRotaTemplateStock, setIsRotaTemplateStock] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [todayDate, setTodayDate] = useState(null);

  useEffect(() => {
    setTodayDate(moment().valueOf());
  }, []);

  // Azad Var
  const [search, setSearch] = useState("");
  const [isLoadingSchedule, setIsLoadingSchedule] = useState(false);
  const [filteredRows, setFilteredRows] = useState();
  const [userData, setUserData] = useState();
  const [isDeleteModel, setShowDeleteModel] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState(false);
  const [showAddRotaTemplate, setShowAddTemplate] = useState(false);
  const [showUpdateTemplate, setShowUpdateTemplate] = useState(false);
  const [isRotaSchedule, setIsRotaSchedule] = useState(false);
  const [showSaveFutureTemplateCopy, setShowSaveFutureTemplateCopy] =
    useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(false);
  const dropdownRef = useRef(null);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { isDelete: false },
    },
  };


  // Azad code start
  // Modal Popup
  const [showAddRota, setShowAddRota] = useState(false);
  const subModalAddRotaTemplate = () => {
    setShowAddRota(!showAddRota);
    fetchAllRecords();
  };

  const [showDeleteRota, setShowDeleteRota] = useState(false);
  const subModalDeleteRota = (delTempId) => {
    setTempIdData({ tempId: delTempId, isDelete: true });
    setShowDeleteRota(!showDeleteRota);
    showDeleteRota && (
      fetchAllRecords()
    )

  };

  const [showEditRota, setShowEditRota] = useState(false);
  const subModalEditRota = (emarItem) => {
    setRotaStockDetail(emarItem);
    setShowEditRota(!showEditRota);
    showEditRota && (
      fetchAllRecords()
    )

  };

  const [showDuplicateRota, setShowDuplicateRota] = useState(false);
  const subModalDuplicateRota = (emarItem) => {
    setRotaStockDetail(emarItem);
    setShowDuplicateRota(!showDuplicateRota);
    showDuplicateRota && (
      fetchAllRecords()
    )
  };

  // Delete RotaTemplate records
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (tempIdData !== "") {
      const response = await deleteRecords(
        PUT_UPDATE_ROTA_TEMPLATE_URL,
        tempIdData
      );
      console.log("🚀 ~ deleteRecord ~ tempIdData:", tempIdData)
      console.log("🚀 ~ deleteRecord ~ response:", response)
      setIsRotaTemplateStock(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteRota(!showDeleteRota);
    } else {
      setShowDeleteRota(!showDeleteRota);
      setIsSubmitButtonClicked(false);
      setIsRotaTemplateStock(true);
      toastErrorMessages(toast.error, "Unable to remove", "", 1000);
    }

  });
  // Azad Code End
  const statusClassMapping = {
    active: "background-active",
    Inactive: "background-danger-inactive",
    saved: "background-warning",
    future: "background-success",
  };

  const processFetchRecords = async (method, data) => {
    if (data?.status !== isSuccess) {
      setIsLoading(false);
      setIsToast(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const result = data?.data?.scheduleTemplates?.map((item) => item);
      if (sideNaveRoute) {
        setIsRotaSchedule(false);
      } else if (editModeTemplateDataState) {
        const filteredData = data?.data?.scheduleTemplates?.find(
          (item) => item?._id === editModeTemplateDataState?._id
        );
        if (filteredData) {
          setIsRotaSchedule(false);
          setIsRotaSchedule(editModeTemplateDataState);
          setIsLoadingSchedule(false);
        }
      } else if (isRotaSchedule) {
        const filteredData = data?.data?.scheduleTemplates?.find(
          (item) => item?._id === isRotaSchedule?._id
        );
        if (filteredData) {
          setIsRotaSchedule(filteredData);
        }
      }
      setUserData(result);
      setFilteredRows(result);
      setIsLoading(false);
    } else if (method === "delete" && data?.status === isSuccess) {
      await fetchAllRecords();
      setIsDeleteLoading(false);
      handleCloseDeleteTempModel();
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    setIsLoading(true);
    const response = await getRecords(GET_ALL_ROTA_TEMPLATES_URL, apiQuery);
    setOpenDropdownId(false);
    processFetchRecords("get", response);
  });

  const deleteScheduleFunc = async () => {
    setIsDeleteLoading(true);
    const response = await postRecords(DELETE_ROTA_TEMPLATE_URL, {
      ...isDeleteModel,
      lastModifiedBy: ADMIN_ID,
    });
    console.log("🚀 ~ deleteScheduleFunc ~ response:", response)
    processFetchRecords("delete", response);
  };

  const handleSearch = (e) => {
    const searchVal = e?.target?.value;
    setSearch(searchVal);
    if (searchVal !== "") {
      const lowercaseSearch = searchVal?.toLowerCase();
      const filteredRecords = filterArrayBySearchTerm(

        filteredRows,
        lowercaseSearch,
        (record) => record?.rotaTemplateTitle?.toLowerCase()
      );
      setFilteredRows(filteredRecords);
    } else {
      setFilteredRows(userData);
    }
  };


  const handleDeleteRotaTemplate = async (e, row) => {
    e.preventDefault();
    setDeleteRecordId(row?._id);
    setShowDeleteModel(row);
  };

  const handleShowAddNewCopyTempModel = (e, row) => {
    e?.preventDefault();
    setShowSaveFutureTemplateCopy(row);
  };

  const handleShowUpdateTempModel = (e, row) => {
    e?.preventDefault();
    setShowUpdateTemplate(row);
  };

  const handleCloseDeleteTempModel = async () => {
    setDeleteRecordId(false);
    setShowDeleteModel(false);
  };

  const handleShowAddNewTemplate = (e) => {
    e.preventDefault();
    setShowAddTemplate(true);
  };

  const handleHideAddNewTemplate = () => {
    setShowAddTemplate(false);
  };

  const handleHideUpdateTemplate = () => {
    setOpenDropdownId(null);
    setShowUpdateTemplate(false);
  };

  const handleHideSaveFutureTemplateCopyModal = () => {
    setOpenDropdownId(null);
    setShowSaveFutureTemplateCopy(false);
  };

  const handleTableRowClick = (row) => {
    setIsRotaSchedule(row);
    // const clickedOnFirstColumn = e?.target?.dataset?.columnId === "2";
    // if (clickedOnFirstColumn) {
    //   console.log("Clicked on the first column:", row);
    //   setShowUpdateTemplate(row);
    // }
  };

  const handleRowClickRotaSchedule = (row) => {
    setIsRotaSchedule(row);
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {
    fetchAllRecords();
  }, [sideNaveRoute]);

  useEffect(() => {
    setIsLoadingSchedule(true);
    fetchAllRecords();
  }, [editModeTemplateDataState]);

  const handleToggleDropdown = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  // useEffect(() => {
  //   const handleClickOutside = () => {
  //     setOpenDropdownId(null);
  //   };
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [dropdownRef]);

  const columns = [
    {
      name: "#",
      selector: (row, index) => (
        <div onClick={() => handleRowClickRotaSchedule(row)} className="curSorPointer pointerEvent-none text-center">{index + 1}</div>
      ),
      width: "5%",
      center: true,
    },
    {
      name: "Name",
      selector: (row) => {
        return (
          <div onClick={() => handleRowClickRotaSchedule(row)} className="curSorPointer">
            {row?.rotaTemplateTitle}
          </div>
        );
      },
      width: "25%",
    },
    {
      name: "Weeks",
      selector: (row) => {
        return (
          <div
            onClick={() => handleRowClickRotaSchedule(row)}
            className="curSorPointer text-center"
          >
            {row?.templateWeeks}
          </div>
        );
      },
      width: "10%",
      center: true,
    },
    {
      name: "Start Date",
      selector: (row) => {
        return (
          <div
            onClick={() => handleRowClickRotaSchedule(row)}
            className="curSorPointer text-center"
          >
            {
              (row?.startDate === 0 || row?.startDate === null) ? (
                "-"
              ) : (
                moment(row?.startDate).format("DD/MM/YYYY")
              )
            }

          </div>
        );
      },
      width: "15%",
      center: true,
    },
    {
      name: "End Date",
      selector: (row) => {
        return (
          <div
            onClick={() => handleRowClickRotaSchedule(row)}
            className="curSorPointer text-center"
          >
            {
              (row?.endDate === 0 || row?.endDate === null) ? (
                "-"
              ) : (
                moment(row?.endDate).format("DD/MM/YYYY")
              )
            }

          </div>
        );
      },
      width: "15%",
      center: true,
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <div
            onClick={() => handleRowClickRotaSchedule(row)}
            className={`curSorPointer statusButton ${row?.status}`}
          >
            <span className="statusText">
              {row?.status &&
                row?.status?.charAt(0)?.toUpperCase() + row?.status?.slice(1)}
            </span>
          </div>
        );
      },
      width: "15%",
      center: true,
    },


    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="actionColumns">
            <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
              <span
                className="edit"
                onClick={() => {
                  subModalEditRota(row);
                }}
              >
                <img src={editBlock} alt="Edit" title="Edit" />
              </span>
            </ProtectedComponent>
            <span
              className="duplicate"
              onClick={() => {
                subModalDuplicateRota(row);
              }}
            >
              <img src={duplicateIcon} alt="Duplicate" title="Duplicate" />
            </span>
            <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
              <span
                className="delete"
                onClick={() => {
                  subModalDeleteRota(row?._id);
                }}
              >
                <img src={eventDel} alt="Delete" title="Delete" />
              </span>
            </ProtectedComponent>
          </div>
        );
      },
      width: "15%",
      center: true,
    },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== null);

  useEffect(() => {

    setTimeout(() => {
      setIsToast(false);
    }, 2000);
  }, [isToast])




  // Update Record from Future to Active if start date is equal to today date
  const updateFutureStatusRecord = catchAsync(async (templateId, status) => {
    const updateValues = {
      tempId: templateId,
      status: status
    };
    const response = await updateRecords(PUT_UPDATE_ROTA_TEMPLATE_URL, updateValues);
    console.log("🚀 ~ updateFutureStatusRecord ~ response:", response)

    // Optional: Handle the response or errors if needed
    if (response?.status === isSuccess) {
      fetchAllRecords();
    }
  });


  // Status update from future to active
  useEffect(() => {
    if (filteredRows && filteredRows.length > 0) {
      // Ensure updateFutureStatusRecord is called only once when the component mounts
      if (todayDate && filteredRows.length > 0) {
        filteredRows.forEach((row) => {
          if (row?.startDate <= todayDate && row?.status === "future") {
            updateFutureStatusRecord(row?._id, "active");
          }
        });
      }
    }
  }, [todayDate, filteredRows]);

  // Status Update from active to inactive
  useEffect(() => {
    if (filteredRows && filteredRows.length > 0) {
      // Ensure updateFutureStatusRecord is called only once when the component mounts
      if (todayDate && userData.length > 0) {
        userData.forEach((row) => {
          if ((row?.endDate < todayDate && row?.endDate !== 0 && row?.endDate !== null) && row?.status === "active") {
            updateFutureStatusRecord(row?._id, "Inactive");
          }
        });
      }
    }
  }, [todayDate, filteredRows]);
  return (
    <>
      {isRotaSchedule ? (
        <RotaScheduleLocations
          scheduleTemplateData={isRotaSchedule}
          isLoadingScheduleState={isLoadingSchedule}
          fetchAllTempRecords={fetchAllRecords}
        />
      ) : (
        !isRotaSchedule && (
          <Layout pageTitleText={"Rota Templates"}>
            <div className="rowClickUnset">
              <div className="mainContent">

                <div className="dataTableWrapper">
                  <DataTable
                    className="maxWidthTable"
                    columns={filteredColumns}
                    data={filteredRows}
                    pagination
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    paginationPerPage={10}
                    fixedHeader
                    fixedHeaderScrollHeight="520px"
                    highlightOnHover
                    actions={
                      <>
                        <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                          <span
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              subModalAddRotaTemplate(filterSearch);
                            }}
                          >
                            Add Template
                          </span>
                        </ProtectedComponent>
                      </>
                    }
                    responsive={true}
                    subHeader={true}
                    subHeaderComponent={
                      <div className="searchContainer">
                        <div className="searchTextBox">
                          <input
                            type="text"
                            placeholder="Type your search"
                            value={search}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    }
                    subHeaderAlign="left"
                    subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
                    progressPending={isLoading}
                    progressComponent={
                      <div className="mainContent minHeight p-0 mt-3 mb-3">
                        <div className="subTabsWrapper">
                          <ProgressComponent />
                        </div>
                      </div>
                    }
                    onRowClicked={handleTableRowClick}
                  />
                </div>
              </div>
            </div>
          </Layout>
        )
      )}

      {/* Add Rota Stock */}
      <Modal
        show={showAddRota}
        onHide={subModalAddRotaTemplate}
        className="ftcModalPopup medicineInventoryStock"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Template</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <AddRotaTemplate
            subModalAddRotaTemplate={subModalAddRotaTemplate}
          />
        </Modal.Body>
      </Modal>

      {/* Edit Rota Stock */}
      <Modal
        show={showEditRota}
        onHide={subModalEditRota}
        className="ftcModalPopup medicineInventoryStock"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Template</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <EditRotaTemplate
            subModalEditRota={subModalEditRota}
            rotaStockDetail={rotaStockDetail}
          />
        </Modal.Body>
      </Modal>


      {/* Duplicate Rota Stock */}
      <Modal
        show={showDuplicateRota}
        onHide={subModalDuplicateRota}
        className="ftcModalPopup medicineInventoryStock"
      >
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Template</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <DuplicateRotaTemplate
            subModalDuplicateRota={subModalDuplicateRota}
            rotaStockDetail={rotaStockDetail}
          />
        </Modal.Body>
      </Modal>

      {/* Delete Rota Stock */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeleteRota}
          onHide={subModalDeleteRota}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this template?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteRota}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {/* {isRotaTemplateStock && <ToastContainer />} */}

      {showSaveFutureTemplateCopy && (
        <SaveFutureTemplate
          handleShowModel={showSaveFutureTemplateCopy}
          handleCloseModel={handleHideSaveFutureTemplateCopyModal}
          scheduleTemplate={showSaveFutureTemplateCopy}
          fetchAllRecords={fetchAllRecords}
        />
      )}

      {isToast && <ToastContainer />}

    </>
  );
}

export default RotaTemplateGroups;
