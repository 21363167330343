import React, { useState, useEffect } from "react";
import settingsIcon from "../../assets/images/icons/settingsIcon.svg";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import moment from 'moment';
import { BASE_URL } from "../../env";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
function UserDetailWithStatus({ eMAR, carePlans, subModalAddMedication, viewCareNotes, careNotesOptions }) {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  // console.log("🚀 ~ UserDetailWithStatus ~ userDetails:", userDetails)
  // const [profileUserDetails, setprofileUserDetails] = useState(userDetails);
  return (
    <>
      <header className="headerContainerSearchAction headerContainerUserWithStatus">
        <div className="usersNameInfo">
          <div className="userImage">
            <img src={BASE_URL + userDetails?.profile_picture_url} alt={userDetails?.modelId?.suFirstMiddleName + userDetails?.modelId?.suLastName} />
          </div>
          <div className="userInfo">
            <span className="nameBlock">{userDetails?.modelId?.suFirstMiddleName}&nbsp;
              {userDetails?.modelId?.suLastName}</span>
            <span className="infoBlock">
              {moment(userDetails?.modelId?.suDOB).format("DD/MM/YYYY")} |
              <span>&nbsp;
                {userDetails?.modelId?.currSU ? (
                  <>
                    Active <span className="statusCircle green">&nbsp;</span>
                  </>
                ) : (
                  <>
                    Inactive
                    <span className="statusCircle red">&nbsp;</span>
                  </>
                )}
              </span>

            </span>
            {/* <small> {userDetails?._id}</small> */}
          </div>
        </div>
        {eMAR && (
          <ProtectedComponent requiredPermissions={[permissions.ADD]}>
            <div className="actionButtons">
              <Link
                className="btn btn-sm btn-primary"
                to="/add-medication"
              >
                Add Medication Schedule
              </Link>
              {/* <span className="btn btn-sm btn-primary" onClick={subModalAddMedication}>Add Medication Schedule</span> */}
            </div>
          </ProtectedComponent>
        )}
        {carePlans && (
          <div className="personalAllowance">
            <p>
              Personal Allowance Balance <span>€37.100</span>
            </p>
          </div>
        )}
        {viewCareNotes && (
          <div className="personalAllowance">
            <p>
              <Link className="btn-sm" to="/care-notes">
                View Care Plan Summary
              </Link></p>
          </div>
        )}
        {
          careNotesOptions && (
            <>
              <div className="personalAllowance generalNotes">
                <p>

                  <Link className="btn-sm mr-3" to="/care-plan/general-notes">
                    View All Notes
                  </Link>
                  <span>&nbsp;|&nbsp;</span>
                  <Link className="btn-sm ml-3" to="/common-options">
                    Back to Care Notes Options
                  </Link>

                </p>
              </div>
            </>
          )
        }


      </header>
    </>
  );
}

export default UserDetailWithStatus;
