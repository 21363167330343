import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import Layout from "../../components/layouts/Layout";
import editBlock from "../../assets/images/icons/editBlock.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
import AddPbsPlans from "./AddPbsPlans";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactHtmlParser from 'html-react-parser';
import { ToastContainer, toast } from "react-toastify";
import password_eye_icon_circle from "../../assets/images/icons/password_eye_icon_circle.svg";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import "react-toastify/dist/ReactToastify.css";
import {
  BASE_URL,
  GET_PBS_PLANS_URL,
  DELETE_PBS_PLANS_URL,
} from "../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
} from "../../helper/helper";
import EditPbsPlans from "./EditPbsPlans";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import IsCompleted from "./IsCompleted";
function PbsPlans() {
  if (localStorage.getItem('pbsPlanDetail')) {
    localStorage.removeItem('pbsPlanDetail');
  }
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [isReviewed, setIsReviewed] = useState(false);
  const [userData, setUserData] = useState([]);
  const apiQuery = {
    query: {
      critarion: {
        userId: userDetails?._id,
      }
    },
    sortProperty: "createdAt",
    sortOrder: -1
  };
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [pbsIdData, setPbsPlansIdData] = useState({
    pbsId: null,
  });
  const [pbsPlansDetail, setPbsPlansDetail] = useState();
  const [isPbsPlans, setIsPbsPlans] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

  // Modal Popup
  const [showAddPbsPlans, setShowAddPbsPlans] = useState(false);
  const subModalAddPbsPlans = () => {
    setShowAddPbsPlans(!showAddPbsPlans);
    fetchAllRecords();
  };

  const [showDeletePbsPlans, setShowDeletePbsPlans] = useState(false);
  const subModalDeletePbsPlans = (pbsItemId) => {
    setPbsPlansIdData({ pbsId: pbsItemId });
    setShowDeletePbsPlans(!showDeletePbsPlans);
    fetchAllRecords();
  };

  const [showEditPbsPlans, setShowEditPbsPlans] = useState(false);
  const subModalEditPbsPlans = (pbsItem) => {
    setPbsPlansDetail(pbsItem);
    setShowEditPbsPlans(!showEditPbsPlans);
    fetchAllRecords();
  };

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const pbsPlanList = data?.data?.pbsList?.map((item) => item);
      console.log("🚀 ~ processFetchRecords ~ pbsPlanList:", pbsPlanList)
      setUserData(pbsPlanList);
      setFilterSearch(pbsPlanList);
      setIsLoading(false);
      // toastSuccessMessages(toast.success, data?.message, "", 500, 500);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsPbsPlans(false);
      }, 2000);
    }
    // else {
    //   console.log({ data });
    //   toastErrorMessages(toast.error, data?.message, 1000);
    // }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_PBS_PLANS_URL, apiQuery);
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {
    fetchAllRecords();
  }, [isReviewed]);

  // Table Columns

  const columns = [
    {
      name: "Staff Member",
      selector: (row) => {
        return (
          <div className="usersNameInfo">
            <span className="userImage">
              <img
                src={`${BASE_URL}${row?.staffId?.profile_picture_url}`}
                alt={row?.staffId?.name}
              />
            </span>
            <div className="contentBox">
              <p><strong>{row?.staffId?.name}</strong>
                <br />
                {moment(row?.updatedAt).format("DD/MM/YYYY / hh:mm:ss A")}</p>
             
                <span className='isReviewed'>
                  <IsCompleted
                    isReviewed={row?.isReviewed === true}
                    setIsReviewed={setIsReviewed}
                    individualQueryData={row} />
                </span>
              
            </div>
          </div>
        );
      },
      width: "80%",
    },
    {
      name: "Due Date",
      selector: (row) => {
        return (
          <div className="text-center">
            <p>{moment(row?.completionDate).format("DD/MM/YYYY")}</p>
          </div>
        );
      },
      width: "10%",
      center: true,
    },


    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="actionColumns individualReviewsActions">

            <span className="edit" onClick={() => handleEditViewPBSPlans(row, "/view-plans")}>
              <img src={password_eye_icon_circle} alt="View" title="View" />
            </span>
            <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
              <span className="edit" onClick={() => handleEditViewPBSPlans(row, "/edit-plans")}>
                <img src={editBlock} alt="Edit" title="Edit" />
              </span>
            </ProtectedComponent>
            <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
              <span
                className="delete"
                onClick={() => {
                  subModalDeletePbsPlans(row?._id);
                }}
              >
                <img src={eventDel} alt="Delete" title="Delete" />
              </span>
            </ProtectedComponent>
          </div>
        );
      },
      width: "10%",
      center: true,
      wrap: true,
    },
  ];

  // Search Filter
  useEffect(() => {
    const result = userData.filter((user) => {
      return user?.staffId?.name.toLowerCase().match(search.toLowerCase());
    });
    setFilterSearch(result);
  }, [search, userData]);

  // Delete PbsPlans records
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (pbsIdData !== "") {
      const response = await deleteRecords(
        DELETE_PBS_PLANS_URL,
        pbsIdData
      );

      setIsPbsPlans(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeletePbsPlans(!showDeletePbsPlans);
    } else {
      setShowDeletePbsPlans(!showDeletePbsPlans);
      setIsSubmitButtonClicked(false);
      setIsPbsPlans(true);
      toastErrorMessages(toast.error, "Unable to remove", "", 1000);
    }
  });
  useEffect(() => {
    setTimeout(() => {
      setIsPbsPlans(false);
    }, 2000);
  }, [isPbsPlans])

  const handleEditViewPBSPlans = (item, url) => {
    localStorage.setItem(
      "pbsPlanDetail",
      JSON.stringify(item)
    );
    navigate(url);
  };

  return (
    <>
      <Layout pageTitleText="PBS Plans">
        <div className="pbsPlan rowClickUnset pbsPlanUpdated">
          <div className="mainContent">

            <div className="dataTableWrapper">
              <DataTable
                className="maxWidthTable minWidthTable"
                columns={columns}
                data={filterSearch}
                pagination
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationPerPage={10}
                fixedHeader
                fixedHeaderScrollHeight="520px"
                highlightOnHover
                actions={
                  <>
                    <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                      <Link to="/add-plans">
                        <span
                          className="btn btn-sm btn-primary"
                        // onClick={() => {
                        //   subModalAddPbsPlans(filterSearch);
                        // }}
                        >
                          Add Plan
                        </span>
                      </Link>
                    </ProtectedComponent>
                  </>
                }
                responsive={true}
                subHeader={true}
                subHeaderComponent={
                  <div className="searchContainer">
                    <div className="searchTextBox">
                      <input
                        type="text"
                        placeholder="Type your search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                }
                subHeaderAlign="left"
                subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
                progressPending={isLoading}
                progressComponent={<ProgressComponent />}
              />
            </div>
          </div>
        </div>
      </Layout>

      {/* Add PBS Plans */}
      <ProtectedComponent requiredPermissions={[permissions.ADD]}>
        <Modal
          show={showAddPbsPlans}
          onHide={subModalAddPbsPlans}
          className="ftcModalPopup medicineInventoryStock"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Pbs Plans</Modal.Title>
          </Modal.Header>
          <Modal.Body className="rosterPopup">
            <AddPbsPlans
              userId={userDetails?._id}
              subModalAddPbsPlans={subModalAddPbsPlans}
            />
          </Modal.Body>
        </Modal>
      </ProtectedComponent>
      {/* Edit PBS Plans */}
      <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
        <Modal
          show={showEditPbsPlans}
          onHide={subModalEditPbsPlans}
          className="ftcModalPopup medicineInventoryStock"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Pbs Plans</Modal.Title>
          </Modal.Header>
          <Modal.Body className="rosterPopup">
            <EditPbsPlans
              userId={userDetails?._id}
              subModalEditPbsPlans={subModalEditPbsPlans}
              pbsPlansDetail={pbsPlansDetail}
            />
          </Modal.Body>
        </Modal>
      </ProtectedComponent>
      {/* Delete PBS Plans */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeletePbsPlans}
          onHide={subModalDeletePbsPlans}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this item?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeletePbsPlans}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {isPbsPlans && <ToastContainer />}
    </>
  );
}

export default PbsPlans;