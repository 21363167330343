import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../components/forms-fields/Input";
import { addMemoryBox } from "../../../schemas";
import { useFormik } from "formik";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL, ADMIN_AUTH_TOKEN, UPLOAD_MEMORY_BOX_FILE, POST_MEMORY_BOX_URL } from "../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../helper/helper";
import CustomSelect from "../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../components/forms-fields/TextArea";
import moment from "moment";
import memoryContent from "../../../assets/images/memoryContent.png";
function AddMemoryBox({ userId, subModalAddCarePlans }) {
  const handleShowHideModal = () => {
    subModalAddCarePlans((prevShow) => !prevShow);
  };
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [uploadedFilePath, setUploadedFilePath] = useState("");
  const [videoSizeError, setVideoSizeError] = useState(false)
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    setFieldValue("addedBy", userId);
  };

  const [suFormData, setSuFormData] = useState({
    userId: userId,
    addedBy: selectedUserId,
    note: "",
    filePath: uploadedFilePath
  });
  console.log("🚀 ~ AddMemoryBox ~ selectedUserId:", selectedUserId)

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addMemoryBox,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);

        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      handleShowHideModal();
    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal();
      }, 2000);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {

    const updateValues = {

      ...values,
      filePath: uploadedFilePath,
      addedBy: selectedUserId,
    }
    // console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(POST_MEMORY_BOX_URL, updateValues);
    // console.log("🚀 ~ carenotes ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });



  const handleInputChangePrevFields = (e) => {
    handleChange(e);
    handleBlur(e);
  };

  const handleImageChange = async (e) => {
    setIsSubmitButtonClicked(true);
    try {
      const file = e.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;
      console.log("🚀 ~ handleVideoUpload ~ fileSize:", fileSize)
      // Validate file size (1MB = 1,048,576 bytes)
      if (fileSize > 1048576) {
        alert('File size exceeds 1MB');
        setVideoSizeError(true)
        setIsSubmitButtonClicked(false);
        return;
      }

      // Validate file type
      if (
        fileType === 'image/jpeg' ||
        fileType === 'image/png' ||
        fileType === 'image/jpg' ||
        fileType === 'video/mp4' ||
        fileType === 'video/avi' ||
        fileType === 'video/mov' ||
        fileType === 'video/wmv' ||
        fileType === 'video/flv' ||
        fileType === 'video/webm' ||
        fileType === 'video/mkv' ||
        fileType === 'video/quicktime' // Add additional video formats as needed
      ) {
        const fileData = new FormData();
        fileData.append('file', file);
        const apiResponse = await fetch(UPLOAD_MEMORY_BOX_FILE, {
          method: "POST",
          body: fileData,
          headers: {
            Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
          },
        });
        const resultResponse = await apiResponse.json();
        setUploadedFilePath(resultResponse?.data);
        console.log("File uploaded successfully:", resultResponse?.data);
      } else {
        // Unsupported file format
        alert('Unsupported file format');
      }
      setIsSubmitButtonClicked(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsSubmitButtonClicked(false);
    }
  };
  return (
    <>
      <div className="incidents consentCapacityAddNew">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="formBlockContent">
            <div className="row">
              <div className="col-md-12">
                <div className="memoryBoxContent">
                  <div className="imageBox text-center">
                    <label htmlFor="profilePicture" className="upload-icon">
                      <div className="avatarAction">
                        {uploadedFilePath !== '' && (
                          <>
                            {uploadedFilePath.match(/\.(jpeg|jpg|png)$/i) ? (
                              <img
                                src={`${BASE_URL}${uploadedFilePath}`}
                                alt="Memory Box"
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <video
                                controls
                                src={`${BASE_URL}${uploadedFilePath}`}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    name="fileName"
                    id="fileName"
                    type="file"
                    accept="image/*, video/*,"
                    onChange={handleImageChange}
                  />
                  {
                    videoSizeError ? (
                      <>
                        <div className="validationError">
                          <div className="text-danger">Error: File size exceeds the 1MB limit. Please upload a smaller file.</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div><p><small>Please upload a file that is 1MB or smaller.</small></p></div>
                      </>
                    )
                  }
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <CustomSelect
                    id=""
                    name="addedBy"
                    onSelect={handleUserSelect}
                    filterBy=""
                    label="Added By"
                    customError={touched?.addedBy && errors?.addedBy}
                    errorMessage={errors?.addedBy}
                    errorClass={
                      touched?.addedBy && errors?.addedBy
                        ? "validationError"
                        : ""
                    }
                  />
                </div>
              </div>

              <div className="col-md-12">

                <TextArea
                  id="note"
                  name="note"
                  value={values?.note}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Note Details"
                  rows="3"
                  className="mb-3"
                  customError={touched?.note && errors?.note}
                  errorMessage={errors?.note}
                  erroClass={
                    touched?.note && errors?.note
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                />
              </div>

            </div>
          </div>

          <div className="formActionButtons">
            {isSubmitButtonClicked ? (
              <Button
                onClick={subModalAddCarePlans}
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Submit
              </Button>
            )}
            <span
              onClick={subModalAddCarePlans}
              className="btn btn-sm btn-outline-primary"
            >
              Close
            </span>

          </div>
        </form>
      </div>
      {isToastAddCarePlans ? <ToastContainer /> : ''}

    </>
  );
}

export default AddMemoryBox;