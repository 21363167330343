import moment from "moment";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import {
    BASE_URL,
} from "../../env";

const ViewQuizModel = ({ onClose, item, quizData }) => {
    console.log("🚀 ~ ViewQuizModel ~ quizData:", quizData)


    return (
        <>
            <Modal
                show={onClose}
                onHide={onClose}
                className="ftcModalPopup"
                size={"md"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Quiz</Modal.Title>
                </Modal.Header>

                <Modal.Body className="careNotesPopup">
                    <div className="row">

                        <div className="col-md-12">
                            <p><strong>Title:</strong>
                                {quizData?.quizName}
                                <br />
                                <small>Passing Marks: {quizData?.passMarksPercent}</small></p>
                        </div>
                        <div className="col-md-12">
                            <ul className="quizList">
                                {
                                    quizData?.questionData.map((quizItem, quizIndex) => (
                                        <li key={quizIndex}>{quizIndex + 1 }- {quizItem?.question}
                                            <ul>
                                                {quizItem?.questionOptions?.map((optionsItem, optionsIndex) => (
                                                    <li key={optionsIndex}>{optionsItem?.option}</li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))

                                }
                            </ul>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

        </>
    );
};

export default ViewQuizModel;